import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import NewIndicator, { IndicatorType } from "../../../components/indicator/NewIndicator";
import UseStyles from "../../design-dependencies/UseStyles";
import FolderService from "../../../services/folder-service";
import { Roles, User } from "../../../models/user";

interface IKpiFolder {
    draft: number,
    to_send: number,
    sent: number,
    confirmed: number,
}
interface IProps{
    user: User;
}

function KpiFolders({user}: IProps) {
    const [kpis, setKpis] = useState<IKpiFolder>();
    const { t } = useTranslation();

    useEffect( () => {
        const getKpis = async () => {
            try{
                const service = new FolderService();
                setKpis(await service.getKpis());               
            } catch(error){
                console.log('error', error);
            }
        };

        getKpis();
    }, []);

    function getKpisAdmin(){
        return (
            <div className="row">
                <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                    <NewIndicator
                        className={UseStyles().cyanGradient}
                        index={0}
                        text={t('status.draft')}
                        type={IndicatorType.Folder}
                        total={kpis?.draft ? kpis.draft : 0}
                    />
                </div>
                <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                    <NewIndicator
                        className={UseStyles().redBg}
                        index={1}
                        text={t('status.to_send')}
                        type={IndicatorType.Folder}
                        total={kpis?.to_send ? kpis.to_send : 0}
                    />
                </div>
                <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                    <NewIndicator
                        className={UseStyles().greenBg}
                        index={2}
                        text={t('status.sent')}
                        type={IndicatorType.Folder}
                        total={kpis?.sent ? kpis.sent : 0}
                    />
                </div>
                <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                    <NewIndicator
                        className={UseStyles().mintBg}
                        index={3}
                        text={t('status.confirmed')}
                        type={IndicatorType.Folder}
                        total={kpis?.confirmed ? kpis.confirmed : 0}
                    />
                </div>
            </div>
    
        )
    }

    function getKpisClient(){

        function getKpiInBuild(){
            const nbDraft = kpis?.draft ? kpis?.draft : 0;
            const nbToSent = kpis?.to_send ? kpis?.to_send : 0;

            return  nbDraft + nbToSent;
        }

        return (
            <div className="row">
                <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                    <NewIndicator
                        className={UseStyles().greenLightBg}
                        index={0}
                        text={t('status.validated')}
                        type={IndicatorType.FolderClient}
                        total={kpis?.confirmed ? kpis.confirmed : 0}
                    />
                </div>
                <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                    <NewIndicator
                        className={UseStyles().yellowBg}
                        index={1}
                        text={t('status.to_validate')}
                        type={IndicatorType.FolderClient}
                        total={kpis?.sent ? kpis.sent : 0}
                    />
                </div>
                <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                    <NewIndicator
                        className={UseStyles().redBg}
                        index={2}
                        text={t('status.in_build')}
                        type={IndicatorType.FolderClient}
                        total={getKpiInBuild()}
                    />
                </div>
            </div>
        )
    }

    return user.role === Roles.CLIENT ? getKpisClient() : getKpisAdmin();

    
}

export default KpiFolders;
