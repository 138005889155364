import React, { useState } from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import ListBeneficiaries from "./list-beneficiaries/ListBeneficiaries";
import KpiBeneficiary from "./kpi-beneficiary/KpiBeneficiary";

interface PropsBeneficiaryPage {}

const BeneficiaryPage: React.FunctionComponent<PropsBeneficiaryPage> = (
    props
) => {
    const { t } = useTranslation();
    const [changeKpis, setChangeKpis] = useState<number>(0);

    const refreshKpis = () => {
        setChangeKpis(changeKpis + 1);
    };
    return (
        <>
            <ContentSection title={t("nav.beneficiaries")} addAction={"beneficiary"}>
                <KpiBeneficiary changeKpis={changeKpis} />
                <ListBeneficiaries refreshKpis={refreshKpis} />
            </ContentSection>
        </>
    );
};

export default BeneficiaryPage;
