import React from "react";
import "./ActionButton.scss";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

interface IActionButton {
    onClickAction: () => void;
    children: any;
    width?: string;
    height?: string;
    marginLeft?: string;
}

const ActionButton = ({
                          onClickAction,
                          children,
                          width = "237px",
                          height = "55px",
                          marginLeft = "60px",
    }: IActionButton) => {
    return (
        <div className="validate-section">
            <div className="validate mgl2" onClick={() => onClickAction()}>
                <Button className="btn-form btn-icon mr-2 submit-input" variant="contained" color="primary" size="large" type="submit">
                    {children} <Icon>{ 'done'}</Icon>
                </Button>
            </div>
        </div>
    );
};

export default ActionButton;
