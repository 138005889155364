import React from "react";

const SupportSvg = (props: any) => {
    return (
        <svg id="help_3_" data-name="help (3)" xmlns="http://www.w3.org/2000/svg" width="31.887" height="31.887" viewBox="0 0 31.887 31.887">
            <path id="Tracé_23835" fill={props.fill} data-name="Tracé 23835" d="M9.4,97.414a6.477,6.477,0,0,1,1.32-3.9L4.09,86.882a15.607,15.607,0,0,0,0,21.065l6.634-6.635A6.477,6.477,0,0,1,9.4,97.414Z" transform="translate(0 -81.471)"/>
            <path id="Tracé_23836" fill={props.fill} data-name="Tracé 23836" d="M97.414,9.4a6.477,6.477,0,0,1,3.9,1.32l6.634-6.634a15.607,15.607,0,0,0-21.065,0l6.635,6.634A6.477,6.477,0,0,1,97.414,9.4ZM92.18,4.986a12.192,12.192,0,0,1,10.469,0l-.806,1.684a10.327,10.327,0,0,0-8.856,0Z" transform="translate(-81.471)"/>
            <path id="Tracé_23837" fill={props.fill} data-name="Tracé 23837" d="M346.435,86.882,339.8,93.517a6.414,6.414,0,0,1,0,7.8l6.635,6.634a15.607,15.607,0,0,0,0-21.065Z" transform="translate(-318.638 -81.471)"/>
            <path id="Tracé_23838" fill={props.fill} data-name="Tracé 23838" d="M97.414,341.12a6.477,6.477,0,0,1-3.9-1.32l-6.635,6.635a15.607,15.607,0,0,0,21.065,0l-6.635-6.635A6.477,6.477,0,0,1,97.414,341.12Zm5.235,4.418a12.137,12.137,0,0,1-10.469,0l.806-1.684a10.327,10.327,0,0,0,8.856,0Z" transform="translate(-81.471 -318.638)"/>
        </svg>
    );
};

export default SupportSvg;