import React from "react";
import {useTranslation} from "react-i18next";
import ContentSection from "../../components/content-section/ContentSection";
import ListNotifications from "./list-notifications/ListNotifications";

const NotificationPage = () => {
    const {t} = useTranslation();

    return (
        <>
            <ContentSection title={t("manage_notif")}>
                <ListNotifications/>
            </ContentSection>
        </>
    );
};

export default NotificationPage;
