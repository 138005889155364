import {Roles} from '../../models/user'
import Colors from "./Colors";

export const getPrimaryColor = (role?: string) => {
    if(role){
        if(role === Roles.ADMIN){
            return Colors.primaryColorAdmin
        } else if(role === Roles.PROVIDER){
            return Colors.primaryColorProvider;
        } else if(role === Roles.CLIENT){
            return 'linear-gradient(to right, #E8B01B, #E8B01B, 50%, white, white)';
        }
    }

    return 'transparent';
}

export const getPrimaryColorButton = (role?: string) => {
    if(role){
        if(role === Roles.ADMIN){
            return Colors.primaryColorAdmin
        } else if(role === Roles.PROVIDER){
            return Colors.primaryColorProvider;
        } else if(role === Roles.CLIENT){
            return Colors.primaryColorAdmin
        }
    }

    return 'transparent';
}

export default {getPrimaryColor, getPrimaryColorButton}