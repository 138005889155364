import React from "react";
import { useTranslation } from "react-i18next";
import FilterColumn, {
    FilterType,
} from "../../../components/filter-column/filterColumn";
import { FiltersEnabled } from "../../../models/utils/filters/filter-enabled";
import {TicketFilters} from "../../../models/utils/filters/tickets/ticket-filters";

interface IPropsFiltersTickets {
    filters?: TicketFilters;
    filtersEnabled?: FiltersEnabled;
    setFilters: (filters: FiltersEnabled) => void;
}

const FiltersTickets = ({
                              filters,
                              filtersEnabled,
                              setFilters,
                          }: IPropsFiltersTickets) => {
    const { t } = useTranslation();
    const styles = {
        display: "flex",
        flexDirection: "row",
    } as React.CSSProperties;

    // we set the currentValue of each filter to  -1 because we start the list of value to 1
    // And in the api we start with the array $types or $states from 0 to 2, we just fix the index
    const changeValue = (name: string, value: any, type: FilterType) => {
        if (filters && filtersEnabled) {
            const newFiltersEnabled = filtersEnabled.addFilter(
                name,
                value,
                type
            );
            setFilters(new FiltersEnabled(newFiltersEnabled));

        }
    };
    return (
        <div style={styles}>
            {filters && (
                <>
                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.types}
                        label={t("categories")}
                        name="type"
                        type={FilterType.Unique}
                    />
                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.states}
                        label={t("states")}
                        name="state"
                        type={FilterType.Unique}
                    />
                </>
            )}
        </div>
    );
};

export default FiltersTickets;
