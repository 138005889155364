import React from "react";
import "./InfosRecap.scss";
import { useTranslation } from "react-i18next";
import { Client } from "../../../models/client";
import { formatDatePicker } from "../../../utils/DateHelper";
import { documentType, documentTypeIsQuote } from "../../../models/utils/document-type";
import { Address } from "../../../models/address";

interface IProps {
    code: string;
    client: Client;
    quoteDate: any;
    dueDate: any;
    onEdit?: boolean;
    onDeadlineChange?: (value: any) => void;
    type?: documentType,
    address?: Address | null
}
export default function InfosRecap({ code, client, quoteDate, dueDate, onEdit, onDeadlineChange, type = documentType.QUOTE, address}: IProps) {
    const { t } = useTranslation();
    
    return (
        <div className="details-previewing">
            <div className="folder">
                <span className="code-folder">
                    {  documentTypeIsQuote(type) ?
                        `${t("nav.estimate")} / ${code}` : `${t("invoice")} / ${code}`
                    }
                </span>
                <div className="recipient">
                    <span className="label">{t("recipient")} :</span>
                    <span className="value">{client?.company_name}</span>
                </div>
            </div>
            <div className="client">
                <div className="addresses">
                    <span className="name to-upper">
                        {client?.company_name}
                    </span>
                    <span>{address ? address.street : '' }</span>
                    <span>{ address ? `${address.post_code} ${address.city}` : '' }</span>
                </div>
                <div className="date">
                    <div>
                        <span className="label">{ documentTypeIsQuote(type) ? t("quote_date") : t("invoice_date")} : </span>
                        <span className="value">
                            {quoteDate ? quoteDate.toLocaleDateString() : '-'}
                        </span>
                    </div>
                    <div>
                        <span className="label">{t("due_date")} : </span>
                        <span className="value">
                            {onEdit ?
                                <>
                                    <input
                                        type={"date"}
                                        name="due_date"
                                        value={formatDatePicker(dueDate)}
                                        onChange={(e: any) => {
                                            const value = e.target.value;
                                            if (onDeadlineChange) {
                                                onDeadlineChange(value);
                                            }
                                            return value;
                                          }}
                                    />
                                </>
                                : (dueDate ? dueDate.toLocaleDateString() : '-')
                            }
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
