export function handleCheckBoxChange(
    event: any,
    checkboxs: any,
    setCheckboxs: any,
    list: any
) {
    let id = event.target.id;
    //HANDLE ROW - SINGLE CHECKBOXS
    if (id !== "checkAll") {
        //if it's already checked
        !checkboxs.selections.includes(id)
            ? setCheckboxs({
                checkAll: false,
                selections: [...checkboxs.selections, event.target.id],
            })
            : //if not
            setCheckboxs({
                checkAll: false,
                selections: checkboxs.selections.filter((selection: string) => {
                    return selection !== id;
                }),
            });

        //HANDLE CHECK ALL
    } else {
        let allItems: Array<string> = [];
        //if it's already checked0
        if (checkboxs.checkAll === false) {
            list.data.map((elem: any) => allItems.push(elem.id.toString()));
            setCheckboxs({
                checkAll: true,
                selections: allItems,
            });
        }
        //if not
        else {
            setCheckboxs({checkAll: false, selections: []});
        }
    }
}
