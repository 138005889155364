import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import ContentSection from "../../../components/content-section/ContentSection";
import "./InvoicePage.scss";
import { LineEstimate } from "../../../models/utils/line-estimate/line-estimate";
import { Passenger, Transfer } from "../../../models/transfer";
import { Price } from "../../../models/utils/line-estimate/price";
import { connect } from "react-redux";
import { Roles, User } from "../../../models/user";
import InvoiceService from "../../../services/invoice-service";
import { Invoice, Penality } from "../../../models/invoice";
import Actions from "./actions/Actions";
import InfosRecap from "../../../components/previouwing/infos-recap/InfosRecap";
import { documentType } from "../../../models/utils/document-type";
import Line from "../../../components/previouwing/line/Line";
import Recap from "../../../components/previouwing/recap/Recap";
import { toastr } from "react-redux-toastr";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import ModalPdf from "../../../components/previouwing/modal-pdf/ModalPdf";
import { ErrorForm } from "../../../components/ErrorForm";
import PaymentModal from "../../../components/payment-modal/PaymentModal";
import { Payment } from "../../../models/payment";
import { Folder } from "../../../models/folder";
import FolderService from "../../../services/folder-service";
import ModalCancel from "./modal/ModalCancel";
import ModalPenality from "./modal/ModalPenality";
import { format } from "../../../utils/AmountHelper";
import { loadPdf } from "../../../utils/PdfReaderHelper";
import { Button, MenuItem, Select } from "@material-ui/core";

interface IProps {
    user: User;
    invoiceId?: number;
    refreshPayments?: () => void;
    folder?: Folder;
}
function InvoicePage({ user, invoiceId, refreshPayments, folder }: IProps) {
    const { t } = useTranslation();
    let history = useHistory();
    const { id } = useParams();
    const [invoice, setInvoice] = useState<Invoice>();
    const [lines, setLines] = useState<LineEstimate[]>([]);
    const [onEdit, setOnEdit] = useState<boolean>(false);
    const [plus, setPlus] = useState<any>();
    const [pdf, setPdf] = useState<any>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [openPayment, setOpenPayment] = useState<boolean>(false);
    const isAdmin = user.role === Roles.ADMIN;
    const [folderInvoice, setFolderInvoice] = useState<Folder>(
        folder ? folder : new Folder([])
    );

    const [passengerMode, setPassengerMode] = useState("none");

    const [showModalCancel, setShowModalCancel] = useState<boolean>(false);
    const [showModalPenality, setShowModalPenality] = useState<boolean>(false);

    useEffect(() => {
        const getInvoice = async () => {
            const invoiceService = new InvoiceService();
            if (invoiceId) {
                setInvoice(await invoiceService.get(invoiceId));
            } else {
                setInvoice(await invoiceService.get(id));
            }
        };

        getInvoice();
    }, [invoiceId, id]);

    useEffect(() => {
        if (!invoiceId) {
            const getFolder = async () => {
                const folderService = new FolderService();
                invoice &&
                    setFolderInvoice(
                        await folderService.get(invoice.folder_id)
                    );
            };
            getFolder();
        }
        if (invoice) {
            //init stored passenger display mode
            if (invoice.datas?.passengerMode && invoice.datas?.passengerMode != passengerMode) {
                setPassengerMode(invoice.datas?.passengerMode);
            }
        }
    }, [invoice, invoiceId]);

    useEffect(() => {
        const getSellingPrice = (transferId: number) => {
            if (invoice && invoice.datas) {
                let initialPrice = invoice.folder?.getTransfer(transferId)?.getTotalCost();

                return invoice?.datas.transfers &&
                    invoice?.datas.transfers[transferId]
                    ? parseFloat(invoice?.datas.transfers[transferId])
                    : initialPrice;
            }
        };
        setLines([]);
        if (invoice && invoice.folder) {
            invoice.folder.transfers?.map(
                (transfer: Transfer, index: number) => {
                    setLines((arr) => [
                        ...arr,
                        new LineEstimate(
                            transfer.id,
                            index + 1,
                            `${t("transfer")} N° ${transfer.code}`,
                            false,
                            [
                                new Price({
                                    total: transfer.getTotalCost(),
                                }),
                            ],
                            getSellingPrice(transfer.id),
                            true,
                            transfer.getRateTva()
                        ),
                        new LineEstimate(
                            transfer.id,
                            index + 1,
                            `${t("passengers")}: ${formatPassenger(transfer)}`
                        ),
                        new LineEstimate(
                            transfer.id,
                            index + 1,
                            `${t(
                                "departure_location"
                            )} \n ${transfer.getHourDeparture()} | ${transfer.getDateDeparture()} | ${
                                transfer.departure_location
                            }`
                        ),
                        new LineEstimate(
                            transfer.id,
                            index + 1,
                            `${t(
                                "drop_off"
                            )} \n ${transfer.getHourArrival()} | ${transfer.getDateArrival()} | ${
                                transfer.arrival_location
                            }`
                        ),
                    ]);
                }
            );

            invoice.folder.extras.forEach((extra) => {
                setLines((arr) => [
                    ...arr,
                    new LineEstimate(
                        0,
                        2,
                        extra.type,
                        false,
                        [],
                        extra.price,
                        false,
                        extra.tva
                    ),
                ]);
            });

            setPlus({
                display: "details",
                transfers: invoice.datas?.transfers
                    ? invoice.datas.transfers
                    : [],
                deposit: {
                    currency: "money",
                    amount:
                        invoice.datas && invoice.datas.deposit?.amount
                            ? invoice.datas?.deposit.amount
                            : 0,
                },
                reduction: {
                    currency: invoice.datas?.reduction?.currency
                        ? invoice.datas.reduction.currency
                        : "money",
                    amount:
                        invoice.datas && invoice.datas.reduction?.amount
                            ? invoice.datas?.reduction.amount
                            : 0,
                },
            });
        }
    }, [invoice, t, folderInvoice, passengerMode]);

    function formatPassenger(transfer: Transfer) : string {
        let output: string = '' + transfer.nb_passenger;

        if (passengerMode && passengerMode!= 'none') {

            let passengers = transfer.passengers;

            if (passengers?.length === 0) {
                return output + ' | - ' + t('no_name_for_transfer') +' - '
            }

            if (passengerMode === 'first') {
                passengers = passengers?.slice(0,1); //only keep first
            }

            passengers?.map((passenger: Passenger, idx) => {
                output += (idx === 0 ? ' | ' : ', ') + passenger.getFullName();
            })
        }

        return output;
    }

    const getDataForm = () => {
        let transfers: any = [];
        lines.map((line) => {
            if (line.isDetail === false) {
                transfers[line.id] = line.sellingPrice;
            }

            return line;
        });

        return {
            ...plus,
            transfers: transfers,
            passengerMode: passengerMode,
        };
    };

    function updateLine(line: LineEstimate) {
        const newLines = [...lines];
        const index = newLines.findIndex(
            (oldLine) => oldLine.id === line.id && oldLine.isDetail === false
        );
        newLines[index] = line;

        if (invoice && invoice.datas && invoice.datas?.transfers) {
            invoice.datas.transfers[line.id] = line.sellingPrice;
        }

        setLines(newLines);
    }

    const updateSellingPrice = (transferId: number, price: number) => {
        const editedLine = lines.find(
            (line) => line.id === transferId && line.isDetail === false
        );
        if (editedLine) {
            editedLine?.setSellingPrice(price);
            updateLine(editedLine);
        }
    };

    const unpaidAmount = (): number => {
        let amount = 0;

        if (invoice && invoice.price_incl_tax) {
            amount = invoice.price_incl_tax;
            if (invoice.datas?.reduction) {
                const { currency, amount: AmountReduction } =
                    invoice.datas.reduction;
                if (currency == "money".toString()) {
                    amount -= Number(AmountReduction);
                } else {
                    amount = amount - (amount * Number(AmountReduction)) / 100;
                }
            }
            if (folderInvoice.total_payments)
                amount -= folderInvoice.total_payments;
            if (amount < 0) {
                amount = 0;
            }
        }
        return amount;
    };

    const openPdf = async () => {
        if (invoice) {
            setPdfLoading(true);
            if (invoice.pdf) {
                let fileURL = await loadPdf(invoice);
                setPdf(fileURL);
                setIsOpen(true);
            } else if (user && user.role === Roles.ADMIN) {
                const invoiceService = new InvoiceService();
                let file = new Blob(
                    [
                        await invoiceService.update(invoice.id, true, {
                            datas: getDataForm(),
                        }),
                    ],
                    {
                        type: "application/pdf",
                    }
                );
                let fileURL = URL.createObjectURL(file);
                setPdf(fileURL);
                setIsOpen(true);
            }

            setPdfLoading(false);
        }
    };

    const penalityPdf = async (penality: Penality) => {
        let fileURL = await loadPdf(penality);
        setPdf(fileURL);
        setIsOpen(true);
    };

    const send = async () => {
        if (invoice) {
            const invoiceService = new InvoiceService();
            setIsSending(true);
            try {
                await invoiceService.send(invoice.id, {
                    datas: getDataForm(),
                });
                toastr.success(t("success"), t("success_invoice_send"));
                setIsSending(false);

                if (invoiceId) {
                    setInvoice(await invoiceService.get(invoiceId));
                } else {
                    setInvoice(await invoiceService.get(id));
                }
            } catch (error) {
                setIsSending(false);
                setErrorApi(new ErrorFormData(error.response.data));
            }
        }
    };

    const refreshInvoice = async () => {
        refreshPayments && refreshPayments();
        const invoiceService = new InvoiceService();
        if (invoiceId) {
            setInvoice(await invoiceService.get(invoiceId));
        } else {
            setInvoice(await invoiceService.get(id));
        }
    };
    function canEditEstimate() {
        return invoice?.sent_at == null;
    }

    function getIncidentsByLine(transferId: number) {
        if (invoice && invoice.folder && invoice.folder.transfers) {
            return invoice.folder?.transfers.find(
                (transfer) => transfer.id === transferId
            )?.incidents;
        }

        return null;
    }

    return (
        <ContentSection title={t("entity.invoice")}>
            {invoice ? (
                <div className="row">
                    <div className="big col-12">
                        <Actions
                            invoice={invoice}
                            user={user}
                            onEdit={onEdit}
                            pdfLoading={pdfLoading}
                            isSending={isSending}
                            setOnEdit={(e: any) => setOnEdit(e)}
                            onClickPdf={() => openPdf()}
                            send={() => send()}
                            setOpenPayment={() => setOpenPayment(true)}
                            onClickCancel={() => setShowModalCancel(true)}
                            onClickPenality={() => setShowModalPenality(true)}
                        />

                        {invoice.penalities.length > 0 && (
                            <div className="penalities quote-refused">
                                <div className="">
                                    <span className="designation">
                                        {t("penalities")}
                                    </span>
                                </div>
                                {invoice.penalities.map(
                                    (penality: Penality, i: number) => (
                                        <div
                                            className="penality "
                                            key={`penality-line-${i}`}
                                        >
                                            <span className="date">
                                                {penality.created_at.toLocaleDateString()}
                                            </span>
                                            <span className="code">
                                                {penality.code}
                                            </span>
                                            <span className="amount">
                                                {format(penality.amount)}
                                            </span>
                                            <span className="actions">
                                                <Button
                                                    className="pdf"
                                                    style={{ minWidth: "15px" }}
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() =>
                                                        penalityPdf(penality)
                                                    }
                                                >
                                                    <span>{t("pdf")}</span>
                                                </Button>
                                            </span>
                                        </div>
                                    )
                                )}
                            </div>
                        )}

                        {invoice.folder && invoice.folder.client && (
                            <>
                                <InfosRecap
                                    type={documentType.INVOICE}
                                    code={invoice.code}
                                    client={invoice.folder.client}
                                    quoteDate={invoice.sent_at}
                                    dueDate={invoice.deadline_at}
                                    address={invoice.folder?.address}
                                />

                                <div className="previewing-body">
                                    <div className="header-previouwing">
                                        <span className="designation">
                                            {t("Designation")}
                                            {onEdit &&
                                                <span style={{
                                                    paddingLeft: 50,
                                                }}>
                                                    <span>{t('passenger_display')} : &nbsp;  </span>
                                                    <Select
                                                        name="level"
                                                        style={{
                                                            background: '#FFF',
                                                            borderRadius: 5,
                                                            paddingLeft: 10,
                                                            paddingRight: 5,
                                                        }}
                                                        onChange={(e:any) => {setPassengerMode(e.target.value)}}
                                                        defaultValue={passengerMode}
                                                        className="grant-selector"
                                                    >
                                                        <MenuItem value="none">{t('none')}</MenuItem>
                                                        <MenuItem value="first">{t('first')}</MenuItem>
                                                        <MenuItem value="all">{t('all')}</MenuItem>
                                                    </Select>
                                                </span>
                                            }
                                        </span>
                                        <div className="flex-row">
                                            {canEditEstimate() ? (
                                                <>
                                                    <span
                                                        style={{
                                                            width: 200,
                                                            textAlign: "right",
                                                        }}
                                                        className="amount"
                                                    >
                                                        {t("provider_price")}
                                                    </span>
                                                    <span
                                                        style={{
                                                            width: 100,
                                                            textAlign: "right",
                                                        }}
                                                        className="amount"
                                                    >
                                                        {t("selling_price")}
                                                    </span>
                                                    <span
                                                        style={{
                                                            width: 100,
                                                            textAlign: "right",
                                                        }}
                                                        className="amount"
                                                    >
                                                        {t("margin")} (€)
                                                    </span>
                                                    <span
                                                        style={{
                                                            width: 100,
                                                            textAlign: "right",
                                                        }}
                                                        className="amount"
                                                    >
                                                        {t("margin")} (%)
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <span
                                                        style={{
                                                            width: 200,
                                                            textAlign: "right",
                                                        }}
                                                        className="amount"
                                                    >
                                                        {t("selling_price")}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {lines.map(
                                        (line: LineEstimate, index: number) => (
                                            <div
                                                key={`previewing-line-${index}`}
                                            >
                                                <Line
                                                    class_name="transfers-lines"
                                                    transferId={line.id}
                                                    index={line.indexList}
                                                    label={line.designation}
                                                    value={
                                                        line?.prices
                                                            ? line.prices[0]?.total?.toFixed(
                                                                  2
                                                              )
                                                            : 0
                                                    }
                                                    amountMargin={
                                                        line.amountMargin
                                                    }
                                                    percentMargin={
                                                        line.percentMargin
                                                    }
                                                    isDetail={line.isDetail}
                                                    canEdit={canEditEstimate()}
                                                    isEditing={onEdit}
                                                    lineIsEditable={
                                                        line.canEdit
                                                    }
                                                    sellingPrice={
                                                        line.sellingPrice
                                                    }
                                                    incidents={getIncidentsByLine(
                                                        line.id
                                                    )}
                                                    setSellingPrice={(
                                                        transferId: number,
                                                        price: number
                                                    ) =>
                                                        updateSellingPrice(
                                                            transferId,
                                                            price
                                                        )
                                                    }
                                                />
                                            </div>
                                        )
                                    )}
                                    <Recap
                                        type={documentType.INVOICE}
                                        lines={lines}
                                        onEdit={onEdit}
                                        plus={plus}
                                        /*alreadyPaid={
                                            folderInvoice.total_payments
                                                ? folderInvoice.total_payments
                                                : 0
                                        }*/
                                        setPlus={(e: any) => setPlus(e)}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <span>{t("loading")}</span>
            )}

            <ErrorForm errorApi={errorApi} />

            {pdf && isOpen && invoice && (
                <ModalPdf
                    closeDialog={() => setIsOpen(false)}
                    pdf={pdf}
                    isOpen={isOpen}
                    code={invoice.code}
                    type={documentType.INVOICE}
                />
            )}
            {isAdmin && (
                <PaymentModal
                    isOpen={openPayment}
                    closeDialog={() => setOpenPayment(false)}
                    refreshPayments={() => refreshInvoice()}
                    payment={
                        new Payment({
                            folder: folderInvoice,
                            invoice: invoice,
                            amount: unpaidAmount(),
                            date: new Date(), //default today
                        })
                    }
                    entity={"invoice"}
                />
            )}
            {invoice && showModalCancel && (
                <ModalCancel
                    isOpen={showModalCancel}
                    invoice={invoice}
                    closeDialog={() => setShowModalCancel(false)}
                    afterCancel={() =>
                        history.push(
                            `/app/folder/${invoice.folder_id}/edit?tab=invoice`
                        )
                    }
                />
            )}
            {invoice && showModalPenality && (
                <ModalPenality
                    isOpen={showModalPenality}
                    invoice={invoice}
                    closeDialog={() => setShowModalPenality(false)}
                    afterAdd={(updated) => {
                        invoice.penalities = updated.penalities;
                        invoice.can_be_cancelled = updated.can_be_cancelled;
                    }}
                />
            )}
        </ContentSection>
    );
}

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(InvoicePage);
