import React from "react";
import Modal from "../../../../components/modal/Modal";
import { useTranslation } from "react-i18next";
import "../../modal-accept-provider/ModalAcceptProvider.scss";
import { Button, CircularProgress, Icon } from "@material-ui/core";
import { Transfer } from "../../../../models/transfer";

interface IPopupUpdate {
    isOpen: boolean;
    closeDialog: () => void;
    tender: Transfer;
    handleAccept: () => void;
    loading: boolean;
    handleRefuse: () => void;
    accept: boolean;
}

const ModalConfirmUpdateTender = ({
    isOpen,
    closeDialog,
    tender,
    handleAccept,
    loading,
    handleRefuse,
    accept,
}: IPopupUpdate) => {
    const { t } = useTranslation();
    return (
        <Modal
            label={t("update_validated_tender")}
            isOpen={isOpen}
            closeModal={() => closeDialog()}
        >
            <div className="modal-accept-provider">
                <div className="title-section">
                    <h2
                        className="title-content to-upper"
                        style={{ textAlign: "center" }}
                    >
                        {t("sure_to_update_tender")}
                    </h2>
                    <p className="provider-name">
                        <span>{t("code")} :</span> {tender.code}
                    </p>
                </div>

                <div className="buttons-section">
                    <div className="buttons-content">
                        {!loading ? (
                            <>
                                <Button
                                    className="btn-form btn-icon"
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={() => closeDialog()}
                                    disabled={loading}
                                >
                                    <span>{t("cancel")}</span>{" "}
                                    <Icon>close</Icon>
                                </Button>
                                <Button
                                    className="btn-form btn-icon"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => {
                                        accept
                                            ? handleAccept()
                                            : handleRefuse();
                                    }}
                                    disabled={loading}
                                >
                                    <span>{t("validate")}</span>{" "}
                                    <Icon>check</Icon>
                                </Button>
                            </>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                }}
                            >
                                <CircularProgress />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ModalConfirmUpdateTender;
