
export function findError(errors : any[], selector: string) : any {

    for (let key in errors) {
        let error = errors[key];
        
        if(error.ref) {
            if (error.ref.name === selector) {
                return error;
            }
        } else if (Array.isArray(error)) {
            //inspect child
            for (let i in error) {
                let match = findError(error[i], selector);
                if (match) {
                    return match;
                }
            }
        } else {
            let match = findError(error, selector);
            if (match) {
                return match;
            }
        }
    }

    return null;
}
