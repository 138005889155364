import React from "react";
import { useTranslation } from "react-i18next";
import FilterColumn, {
    FilterType,
} from "../../../components/filter-column/filterColumn";
import { FiltersEnabled } from "../../../models/utils/filters/filter-enabled";
import {ProviderFilters} from "../../../models/utils/filters/providers/provider-filters";

interface IPropsFiltersProviders {
    filters?: ProviderFilters;
    filtersEnabled?: FiltersEnabled;
    setFilters: (filters: FiltersEnabled) => void;
}

const FiltersProviders = ({
                              filters,
                              filtersEnabled,
                              setFilters,
                          }: IPropsFiltersProviders) => {
    const { t } = useTranslation();
    const styles = {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row",
    } as React.CSSProperties;

    const changeValue = (name: string, value: any, type: FilterType) => {
        if (filters && filtersEnabled) {
            const newFiltersEnabled = filtersEnabled.addFilter(
                name,
                value === t("no") ? '0' : value,
                type
            );
            setFilters(new FiltersEnabled(newFiltersEnabled));
        }
    };

    console.log(filters?.is_tenders)
    return (
        <div className="filter-std-row" style={styles}>
            {filters && (
                <>
                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.types}
                        label={t("type")}
                        name="type"
                        type={FilterType.Multiple}
                    />
                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.cities}
                        label={t("city")}
                        name="city"
                        type={FilterType.Multiple}
                    />
                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.is_tenders}
                        label={t("is_tender")}
                        name="is_tender"
                        type={FilterType.Unique}
                    />
                </>
            )}
        </div>
    );
};

export default FiltersProviders;
