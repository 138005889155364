import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorForm } from "../../../components/ErrorForm";
import GlobalSearch from "../../../components/global-search/globalSearch";
import KpiContentSeparator from "../../../components/kpi-content-separator/KpiContentSeparator";
import Pagination from "../../../components/pagination/pagination";
import HeadTableCell from "../../../components/table/HeadTableCell";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { Invoice } from "../../../models/invoice";
import { User } from "../../../models/user";
import { DataPaginate } from "../../../models/utils/data-paginate";
import InvoiceService from "../../../services/invoice-service";
import ItemInvoice from "./ItemInvoice";
import { FiltersEnabled } from "../../../models/utils/filters/filter-enabled";
import FiltersInvoices from "./FiltersInvoices";
import { InvoiceFilters } from "../../../models/utils/filters/invoices/invoice-filters";
import { debounce } from "lodash";

interface IPropsListInvoices {
    user: User;
}

const ListInvoices = ({ user }: IPropsListInvoices) => {
    const { t } = useTranslation();
    const [dataPaginateInvoices, setDataPaginateInvoices] = useState(
        new DataPaginate()
    );
    const [pageInvoices, setPageInvoices] = useState(1);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState<string | null>(null);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [filters, setFilters] = useState<InvoiceFilters>();
    const [filtersEnabled, setFiltersEnabled] = useState<FiltersEnabled>(
        new FiltersEnabled()
    );
    const [date, setDate] = useState(new Date());

    const fetchAllInvoices = async () => {
        if (!loading) setLoading(true);
        try {
            const invoiceService = new InvoiceService();
            setDataPaginateInvoices(
                await invoiceService.all(
                    pageInvoices,
                    search,
                    null,
                    filtersEnabled
                )
            );
        } catch (e) {
            console.log("error", e);
        }
        setLoading(false);
    };

    const debounceQuery = useCallback(debounce(fetchAllInvoices, 500), [
        pageInvoices,
        search,
        filtersEnabled,
        date,
    ]);

    useEffect(() => {
        debounceQuery();
        return debounceQuery.cancel;
    }, [pageInvoices, search, filtersEnabled, date, debounceQuery]);

    useEffect(() => {
        const fetchAllInvoices = async () => {
            if (!loading) setLoading(true);
            try {
                const invoiceService = new InvoiceService();
                setDataPaginateInvoices(
                    await invoiceService.all(
                        pageInvoices,
                        search,
                        null,
                        filtersEnabled
                    )
                );
            } catch (e) {
                console.log("error", e);
            }
            setLoading(false);
        };

        fetchAllInvoices();
    }, [pageInvoices, search, filtersEnabled]);

    const handleFiltersChange = (filters: FiltersEnabled) => {
        setFiltersEnabled(filters);
        setPageInvoices(1);
    };

    return (
        <>
            {/* SEARCHBAR */}
            <GlobalSearch
                onSearch={(value: string) => {
                    setPageInvoices(1);
                    setSearch(value);
                }}
                onResetFilter={() => setDate(new Date())}
            />
            <FiltersInvoices
                filtersEnabled={filtersEnabled}
                filters={filters}
                setFilters={(filters) => handleFiltersChange(filters)}
            />
            <KpiContentSeparator />
            {dataPaginateInvoices &&
            dataPaginateInvoices.data?.length !== 0 &&
            !loading ? (
                <>
                    <TableContainer component={Paper}>
                        <Table
                            aria-label="customized table"
                            className="invoice-list"
                        >
                            <TableHead>
                                <TableRow>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("num_invoice")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("client")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("folders")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("number_transfer")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("price_ht")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("price_ttc")}
                                    </HeadTableCell>
                                    {user.isAdmin() && (
                                        <HeadTableCell align="center">
                                            {t("net_margin")}
                                        </HeadTableCell>
                                    )}
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("down_payment")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("due_date")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh see-invoice"
                                        align="center"
                                    >
                                        {t("invoice")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("sent")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("status_table")}
                                    </HeadTableCell>
                                    {user.isAdmin() && (
                                        <HeadTableCell
                                            className="testTh"
                                            align="left"
                                        />
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataPaginateInvoices.data.map(
                                    (invoice: Invoice) => (
                                        <ItemInvoice
                                            key={`table-invoice-${invoice.id}`}
                                            invoice={invoice}
                                            setErrorApi={(error) =>
                                                setErrorApi(error)
                                            }
                                        />
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        dataPaginate={dataPaginateInvoices}
                        onPageChange={(page: number) => setPageInvoices(page)}
                    />
                </>
            ) : loading ? (
                <CircularProgress />
            ) : !errorApi || dataPaginateInvoices.data?.length === 0 ? (
                <div>{t("no_items")}</div>
            ) : (
                ""
            )}
            <ErrorForm errorApi={errorApi} />
        </>
    );
};

export default ListInvoices;
