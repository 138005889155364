import React, { useEffect, useState } from "react";
import NewIndicator from "../../../components/indicator/NewIndicator";
import UseStyles from "../../design-dependencies/UseStyles";
import { IndicatorType } from "../../../components/indicator/NewIndicator";
import { useTranslation } from "react-i18next";
import TicketService from "../../../services/ticket-service";
import {TicketState} from "../../../models/ticket";

const KpiTicket: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const [kpis, setKpis] = useState<any>([]);
    //Fetch kpis
    useEffect(() => {
        const getKpis = async () => {
            try {
                const ticketService = new TicketService();
                const data = await ticketService.getKpis();
                setKpis(data);
            } catch (e) {
                console.log(e);
            }
        };
        getKpis();
    }, []);

    return (
        <div className="row">
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().redBg}
                    index={0}
                    text={t(`ticket_state.${TicketState.UNPROCESS}`)}
                    type={IndicatorType.Support}
                    total={kpis && kpis.nbUnprocess > 0 ? kpis.nbUnprocess : '0'}
                />
            </div>
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().greyBgLight}
                    index={1}
                    text={t(`ticket_state.${TicketState.RUNNING}`)}
                    type={IndicatorType.Support}
                    total={kpis && kpis.nbRunning > 0 ? kpis.nbRunning : '0'}
                />
            </div>
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().greenLightBg}
                    index={2}
                    text={t(`ticket_state.${TicketState.RESOLVE}`)}
                    type={IndicatorType.Support}
                    total={kpis && kpis.nbResolve > 0 ? kpis.nbResolve : '0'}
                />
            </div>
        </div>
    );
};

export default KpiTicket;
