import React, { useState } from "react";
import IconEdit from "@material-ui/icons/Edit";
import IconClear from "@material-ui/icons/Delete";
import IconCheck from "@material-ui/icons/Check";
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorFormData } from "../../../../models/interfaces/errorForm";
import {toastr} from 'react-redux-toastr'
import { ErrorForm } from "../../../../components/ErrorForm";
import ModelService from "../../../../services/model-service";
import { VehicleModel } from "../../../../models/vehicle-model";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import "./ItemModel.scss";


interface IItemModel{
    vehicleModel:  VehicleModel,
    onUpdateSuccess: (vehicleModel: VehicleModel) => void,
    onDeleteSuccess: () => void,
}

interface IFormBrand{
    label: string;
    img_base64: any;
}

const ItemModel: React.FunctionComponent<IItemModel> = ({vehicleModel, onUpdateSuccess, onDeleteSuccess}) => {
    const [editing, setIsEditing] = useState<boolean>(false);
    const [disabledForm, setDisabledForm] = useState<boolean>(false);
    const { handleSubmit, errors, control, reset } = useForm<IFormBrand>();
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const modelService = new ModelService();

    const onSubmit = async (data: any, e:any) => {
        setErrorApi(new ErrorFormData());
        try{
            setDisabledForm(true);
            const editedVehicleModel = await modelService.update(data, vehicleModel.brand_id, vehicleModel.id );
            onUpdateSuccess(editedVehicleModel);
            toastr.success(t('success'), t('success_updated_model'));
            setDisabledForm(false);
            cancelEditing();
        } catch(error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    const deleteVehicleModel = async () => {
        setErrorApi(new ErrorFormData());
        try{
            await modelService.delete(vehicleModel.id);
            onDeleteSuccess();
            toastr.success(t('success'), t('success_deleted_model'));
            cancelEditing();
        } catch(error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    const cancelEditing = () => {
        setIsEditing(false);
        reset();
    };

    return (
        <>
            { !editing ? (
                <>
                    <td>
                        {vehicleModel.label}
                    </td>
                    <td className="actions">
                        <IconClear onClick={() => deleteVehicleModel()} className="delete-action"/>
                        <IconEdit onClick={() => setIsEditing(true)} className="edit-action"/>
                    </td>
                    <ErrorForm errorApi={errorApi} />
                </>
            ) : (
                <>
                    <td className="actions" colSpan={3}>
                        <form className="form_row align_items_center" onSubmit={handleSubmit(onSubmit)}>
                            <InputControlled
                                name="label"
                                label={t('name')}
                                value={vehicleModel.label}
                                required={true}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                            />               
                            <ErrorForm errorApi={errorApi} />

                            <button className="submit_icon" type="submit">
                                <IconCheck />
                            </button>
                            <CloseIcon onClick={() => cancelEditing()} className="cancel-edit"/>
                        </form>
                    </td>
                </>
            )}
        </>                      
    );
};

export default ItemModel;
