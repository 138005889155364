import React, { useState, useEffect } from "react";
import ContentSection from "../../../components/content-section/ContentSection";
import {useTranslation} from "react-i18next";
import { useParams } from "react-router-dom";
import { Client } from "../../../models/client";
import FormAccount from "./form-account/FormAccount";
import AccountService from "../../../services/account-service";

const AccountFormPage: React.FunctionComponent = props => {
    const { t } = useTranslation();
    const { id, client_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [datas, setDatas] = useState<Client>();

    useEffect( () => {
        const fetchAccount = async () => {
            if (id) {
                try{
                    setLoading(true);
                    const dataService = new AccountService(client_id);
                    setDatas(await dataService.get(id));
                    setLoading(false);
                } catch(error){
                    setLoading(false);
                    console.log('error', error);
                }
            }
            
        };

        fetchAccount();
    }, [id, client_id]);

    return(
        <>
            <ContentSection
                title={  id ? t('edit') +' '+ t('entity.account') : t('add') +' '+ t('entity.account')}
                returnAction={true}
            >
                {!id || !loading ? (
                    <FormAccount clientId={client_id} account={datas}/>
                ) : <div>{t('loading')}</div>
                }
                
            </ContentSection>
        </>
    )
}

export default AccountFormPage;