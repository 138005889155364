import { Language } from "./language";
import { Deserializable } from "./interfaces/deserializable";
import { Provider } from "./provider";


export class Driver implements Deserializable {
    public id: number;
    public provider_id: number;
    public provider: Provider;
    public img_profil_key: string;
    public img_profil_url: string;
    public img_card_key: string;
    public img_card_url: string;
    public last_name: string;
    public first_name: string;
    public phone: string;
    public email: string;
    public notes: string;
    public is_tender: boolean;
    public languages: Array<Language>;
    public token_activation: string;
    public token_resetting_password: string;
    public created_at: Date;
    public updated_at: Date;

    constructor(data:any) {
        this.id = data.id;
        this.provider_id = data.provider_id;
        this.provider = data.provider
        this.img_profil_key = data.img_profil_key;
        this.img_profil_url = data.img_profil_url;
        this.img_card_key = data.img_card_key;
        this.img_card_url = data.img_card_url;
        this.last_name = data.last_name;
        this.first_name = data.first_name;
        this.phone = data.phone;
        this.email = data.email;
        this.notes = data.notes;
        this.is_tender = data.is_tender;
        this.languages = data.languages ? data.languages.map( (language: Language) => new Language(language) ) : null;
        this.token_activation = data.token_activation;
        this.token_resetting_password = data.token_resetting_password;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    getFullName(): string {
        return this.first_name + " " + this.last_name;
    }
}