import React from "react";

import { useTranslation } from "react-i18next";

import NewIndicator, {
    IndicatorType,
} from "../../../components/indicator/NewIndicator";
import UseStyles from "../../design-dependencies/UseStyles";

interface PropsKpisTenders {
    nbApply: string;
    nbApplied: string;
    nbAccepted: string;
}

const KpiTenders = ({ nbApply, nbApplied, nbAccepted }: PropsKpisTenders) => {
    const { t } = useTranslation();

    return (
        <div className="row">
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().blueBg}
                    index={0}
                    text={t("tenders_kpis_status.to_apply")}
                    type={IndicatorType.Tender}
                    total={nbApply}
                />
            </div>
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().greyBg}
                    index={1}
                    text={t("tenders_kpis_status.applied")}
                    type={IndicatorType.Tender}
                    total={nbApplied}
                />
            </div>
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().mintBg}
                    index={2}
                    text={t("tenders_kpis_status.accepted")}
                    type={IndicatorType.Tender}
                    total={nbAccepted}
                />
            </div>
        </div>
    );
};

export default KpiTenders;
