import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorForm } from "../../../components/ErrorForm";
import GlobalSearch from "../../../components/global-search/globalSearch";
import KpiContentSeparator from "../../../components/kpi-content-separator/KpiContentSeparator";
import Pagination from "../../../components/pagination/pagination";
import HeadTableCell from "../../../components/table/HeadTableCell";
import { Credit } from "../../../models/credit";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { DataPaginate } from "../../../models/utils/data-paginate";
import CreditService from "../../../services/credit-service";
import ItemCredit from "./ItemCredit";

const ListCredits = () => {
    const { t } = useTranslation();
    const [dataPaginate, setDataPaginate] = useState(new DataPaginate());
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState<string | null>(null);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());

    useEffect(() => {
        setLoading(true);
        const fetchAll = async () => {
            try {
                const service = new CreditService();
                setDataPaginate(await service.all(page, search));
            } catch (e) {
                setErrorApi(new ErrorFormData(e.response.data));
            }
            setLoading(false);
        };
        fetchAll();
    }, [page, search]);

    return (
        <>
            {/* SEARCHBAR */}
            <GlobalSearch
                onSearch={(value: string) => {
                    setPage(1);
                    setSearch(value);
                }}
                onResetFilter={() => setSearch("")}
            />
            <KpiContentSeparator />
            {dataPaginate && dataPaginate.data?.length !== 0 && !loading ? (
                <>
                    <TableContainer component={Paper}>
                        <Table
                            aria-label="customized table"
                            className="invoice-list"
                        >
                            <TableHead>
                                <TableRow>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("num_credit")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("client")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("value")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("status_table")}
                                    </HeadTableCell>
                                    {/*<HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("left_amount")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                         {t("expiration")}
                                    </HeadTableCell>*/}
                                    <HeadTableCell
                                        className="testTh"
                                        align="left"
                                    >
                                        {t("comment")}
                                    </HeadTableCell>
                                    {/*<HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                    </HeadTableCell>*/}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataPaginate.data
                                .map(
                                    (credit: Credit) => (
                                        <ItemCredit
                                            key={`table-invoice-${credit.id}`}
                                            credit={credit}
                                        />
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        dataPaginate={dataPaginate}
                        onPageChange={(page: number) => setPage(page)}
                    />
                </>
            ) : loading ? (
                <CircularProgress />
            ) : !errorApi || dataPaginate.data?.length === 0 ? (
                <div>{t("no_items")}</div>
            ) : (
                ""
            )}
            <ErrorForm errorApi={errorApi} />
        </>
    );
};

export default ListCredits;
