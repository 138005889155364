import React from "react";
import { useTranslation } from "react-i18next";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import { Delete } from "@material-ui/icons";
import { Passenger } from "../../../../models/transfer";
import CountryPhoneControlled from "../../../../components/fields-controlled/CountryPhoneControlled";
import { Language } from "../../../../models/language";
import AutocompleteControlled from "../../../../components/fields-controlled/AutocompleteControlled";
import "./SubFormPassenger.scss";

interface Iprops {
    passenger: Passenger;
    index: number;
    disabled: boolean;
    control: any;
    errors: any;
    languages: Language[];
    onDelete: (index: number) => void;
    onUpdate: (index: number, key: string, value: any) => void;
}

const SubFormPassenger = ({
    control,
    errors,
    disabled,
    passenger,
    index,
    languages,
    onDelete,
    onUpdate,
}: Iprops) => {
    const { t } = useTranslation();
    let fieldPrefix = "passengers[" + index + "]";

    const civilities = ['mr', 'ms', 'mlle'];

    if (!errors.passengers) {
        errors.passengers = [];
    }

    return (
        <>
            <hr />
            <div
                className="sub_form_passenger"
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div className="row first_row">
                    <div className="col-12 col-lg"> 
                        <div className="form-group">
                            <AutocompleteControlled
                                label={t("form.civility")}
                                name={fieldPrefix + ".civility"}
                                disabled={disabled}
                                listItems={civilities}
                                value={
                                    civilities.find( (civility: string) => civility === passenger.civility)
                                }
                                width={200}
                                getOptionLabel={(civility: string) => t(`civility_type.${civility}`)}
                                control={control}
                                errors={errors}
                                onChange={(value:any) => onUpdate(index, 'civility', value) }
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg">
                        <div className="form-group">
                            <InputControlled
                                name={fieldPrefix + ".lastname"}
                                label={`${t("lastname")} *`}
                                required={true}
                                value={passenger.lastname ? passenger.lastname : ""}
                                disabled={disabled}
                                control={control}
                                errors={errors}
                                onChange={(value:any) => onUpdate(index, 'lastname', value) }
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg">
                        <div className="form-group">
                            <InputControlled
                                name={fieldPrefix + ".firstname"}
                                required={true}
                                label={`${t("firstname")} *`}
                                value={
                                    passenger.firstname ? passenger.firstname : ""
                                }
                                disabled={disabled}
                                control={control}
                                errors={errors}
                                onChange={(value:any) => onUpdate(index, 'firstname', value) }
                            />
                        </div>
                    </div>
                    <div className="col-12 col-xl mb-3 mb-xl-0">
                        <div className="form-group has-phone-input-as-input-height">
                            <CountryPhoneControlled
                                name={fieldPrefix + ".phone"}
                                label={t("form.phone")}
                                value={passenger.phone ? passenger.phone : ""}
                                disabled={disabled}
                                errors={errors}
                                control={control}
                                onChange={(value:any) => onUpdate(index, 'phone', value) }
                            />
                        </div>
                    </div>
                </div>
                <div className="row second_row">
                    <div className="col-12 col-lg">
                        <div className="form-group">
                            <InputControlled
                                name={fieldPrefix + ".email"}
                                label={t("form.email")}
                                value={passenger.email ? passenger.email : ""}
                                disabled={disabled}
                                control={control}
                                errors={errors}
                                onChange={(value:any) => onUpdate(index, 'email', value) }
                            />
                        </div>
                    </div>
                    <div className="col-auto col-lg">
                        <div className="form-group">
                            <AutocompleteControlled
                                label={t("form.language")}
                                name={fieldPrefix + ".language"}
                                disabled={disabled}
                                listItems={languages}
                                value={
                                    passenger.language_id
                                        ? languages.filter(
                                            (lang: Language) =>
                                                lang.id === passenger.language_id
                                        )[0]
                                        : ""
                                }
                                width={160}
                                getOptionLabel={(lang: any) => lang.label}
                                renderOption={(props: any)=>{
                                    console.log(props)
                                    return (
                                      <div>
                                        <img style={{width: 16, height: 11, marginTop: 0, marginBottom: 0, marginLeft: 10, marginRight: 10}} src={props.iso_code?   `https://countryflagsapi.com/png/${props.iso_code}` : '../../../public/images/unknow-flag-icon.svg'}/>
                                        <span>{props.label}</span>
                                      </div>
                                    )
                                  }}
                                control={control}
                                errors={errors}
                                onChange={(value:any) => onUpdate(index, 'language', value) }
                            />
                        </div>
                    </div>
                    <div className="col-auto fg-passenger-trash-col">
                        <div className="form-group">
                        {!disabled &&
                            <Delete
                                className="delete_row"
                                onClick={() => onDelete(index)}
                            />
                        }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubFormPassenger;
