import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import BeneficiaryService from "../../../services/beneficiary-service";
import { DataPaginate } from "../../../models/utils/data-paginate";
import { Beneficiary } from "../../../models/beneficiary";
import Pagination from "../../../components/pagination/pagination";
import ItemBeneficiary from "./ItemBeneficiary";
import KpiContentSeparator from "../../../components/kpi-content-separator/KpiContentSeparator";
import Paper from "@material-ui/core/Paper";
import {
    Checkbox,
    CircularProgress,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import HeadTableCell from "../../../components/table/HeadTableCell";
import { handleCheckBoxChange } from "../../../utils/HandleCheckboxs";
import GlobalSearch from "../../../components/global-search/globalSearch";
import { ErrorForm } from "../../../components/ErrorForm";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import ActionsSection from "../../../components/actions-section/ActionsSection";
import { toastr } from "react-redux-toastr";
import "./ListBeneficiaries.scss";
import { Client } from "../../../models/client";
import { FiltersEnabled } from "../../../models/utils/filters/filter-enabled";
import { BeneficiaryFilters } from "../../../models/utils/filters/beneficiaries/beneficiary-filters";
import FiltersBeneficiaries from "./FiltersBeneficiaries";
import { debounce } from "lodash";

interface IPropsListBenef {
    client?: Client | null;
    refreshKpis?: () => void | null;
}

const ListBeneficiaries = ({ client, refreshKpis }: IPropsListBenef) => {
    const { t } = useTranslation();
    const [dataPaginateBeneficiaries, setDataPaginateBeneficiaries] = useState(
        new DataPaginate()
    );
    const [pageBenefs, setPageBenefs] = useState(1);
    const [checkboxs, setCheckboxs] = useState<any>({
        checkAll: false,
        selections: [],
    });
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState<string | null>(null);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [action, setAction] = useState("merge");
    const [isDeleted, setisDeleted] = useState<number>(0);
    const [filters, setFilters] = useState<BeneficiaryFilters>();
    const [filtersEnabled, setFiltersEnabled] = useState<FiltersEnabled>(
        new FiltersEnabled()
    );
    const [date, setDate] = useState(new Date());

    const beneficiaryService = new BeneficiaryService();
    const fetchAllBeneficiaries = async () => {
        if (!loading) setLoading(true);
        try {
            
            setDataPaginateBeneficiaries(
                await beneficiaryService.all(
                    pageBenefs,
                    search,
                    null,
                    client?.id,
                    filtersEnabled
                )
            );
        } catch (e) {
            console.log("error", e);
        }
        setLoading(false);
    };

    const debounceQuery = useCallback(debounce(fetchAllBeneficiaries, 500), [
        pageBenefs,
        search,
        isDeleted,
        client,
        filtersEnabled,
        date,
    ]);

    useEffect(() => {
        debounceQuery();
        return debounceQuery.cancel;
    }, [
        pageBenefs,
        search,
        isDeleted,
        client,
        filtersEnabled,
        date,
        debounceQuery,
    ]);

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const beneficiaryService = new BeneficiaryService(t);
                setFilters(await beneficiaryService.getFilter());
            } catch (e) {
                console.log("error", e);
            }
        };
        fetchFilters();
    }, [t]);

    //Handle checkboxs
    const handleCheckboxs = (event: any) => {
        handleCheckBoxChange(
            event,
            checkboxs,
            setCheckboxs,
            dataPaginateBeneficiaries
        );
    };

    //Change provider actions
    const handleActionChange = (event: any) => {
        setAction(event.target.value);
    };

    //Handle validate provider action
    const validateAction = () => {
        if (action === "merge") {
            checkboxs.selections.length > 1
                ? toastr.success(
                      t("success"),
                      t("success_beneficiary_lines_merge")
                  )
                : toastr.warning(
                      t("warning"),
                      t("warning_beneficiary_lines_merge")
                  );
        }
    };
    async function handleDeleted (id:number)  {
        setisDeleted(isDeleted + 1);
        try{
            await beneficiaryService.delete(id)
            toastr.success(t("success"), t("success_delete_benefeciary"));
        }
        catch(error){
            toastr.error(t("warning"), t("error_deleted_benefeciary"));
        }
    };

    const handleFiltersChange = (filters: FiltersEnabled) => {
        setFiltersEnabled(filters);
        setPageBenefs(1);
    };

    return (
        <>
            {/* SEARCHBAR */}
            <GlobalSearch
                onSearch={(value: string) => {
                    setPageBenefs(1);
                    setSearch(value);
                }}
                onResetFilter={() => setDate(new Date())}
            />
            <FiltersBeneficiaries
                filtersEnabled={filtersEnabled}
                filters={filters}
                setFilters={(filters) => handleFiltersChange(filters)}
            />
            <KpiContentSeparator />
            {dataPaginateBeneficiaries &&
            dataPaginateBeneficiaries.data?.length !== 0 &&
            !loading ? (
                <>
                    <TableContainer component={Paper} style={{ width: "75%" }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {!client && (
                                        <HeadTableCell
                                            className="testTh beneficiary-checkbox"
                                            align="center"
                                        >
                                            {/* Checkbox to check all items */}
                                            {dataPaginateBeneficiaries.data !==
                                                undefined &&
                                            dataPaginateBeneficiaries.data
                                                .length > 1 ? (
                                                <Checkbox
                                                    onChange={(e) =>
                                                        handleCheckboxs(e)
                                                    }
                                                    id="checkAll"
                                                    checked={
                                                        checkboxs.checkAll ||
                                                        false
                                                    }
                                                    inputProps={{
                                                        "aria-label":
                                                            "checkbox with default color",
                                                    }}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </HeadTableCell>
                                    )}
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("company_name")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("logo")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("name")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("active_switch")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="left"
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataPaginateBeneficiaries.data
                                .map(
                                    (beneficiary: Beneficiary) => (
                                        <ItemBeneficiary
                                            key={`table-benefeciary-${beneficiary.id}`}
                                            context={client}
                                            beneficiary={beneficiary}
                                            checkboxs={checkboxs}
                                            handleCheckbox={(e) =>
                                                handleCheckboxs(e)
                                            }
                                            setErrorApi={(error) =>
                                                setErrorApi(error)
                                            }
                                            refreshKpis={() =>
                                                refreshKpis ? refreshKpis() : {}
                                            }
                                            handleDeleted={() =>
                                                handleDeleted(beneficiary.id)
                                            }
                                        />
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        dataPaginate={dataPaginateBeneficiaries}
                        onPageChange={(page) => (page: number) =>
                            setPageBenefs(page)}
                    />
                    {/* DropdownListe actions + validate button */}
                    {!client && (
                        <ActionsSection
                            onChangeDropdown={(e) => handleActionChange(e)}
                            valueDropdown={action}
                            labelDropdown={t("actions")}
                            onClickButton={validateAction}
                        >
                            <option value="merge">{t("line_merge")}</option>
                            <option value="mail">Envoyer un mail</option>
                        </ActionsSection>
                    )}
                </>
            ) : loading ? (
                <CircularProgress />
            ) : !errorApi || dataPaginateBeneficiaries.data?.length === 0 ? (
                <div>{t("no_items")}</div>
            ) : (
                ""
            )}
            <ErrorForm errorApi={errorApi} />
        </>
    );
};

export default ListBeneficiaries;
