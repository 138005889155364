import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ContentSection from "../../components/content-section/ContentSection";
import { ErrorForm } from "../../components/ErrorForm";
import { Driver } from "../../models/driver";
import { Language } from "../../models/language";
import { ErrorFormData } from "../../models/interfaces/errorForm";
import { User } from "../../models/user";
import LanguageService from "../../services/language-service";
import DriverService from "../../services/driver-service";
import FormDriver from "./form-driver/FormDriver";

interface IDriverFormProps {
    edit?: boolean;
    user: User;
}

const DriverFormPage = ({ edit = false, user }: IDriverFormProps) => {
    const { t } = useTranslation();
    const [driver, setDriver] = useState<Driver>();
    const [languages, setLanguages] = useState<
        Array<Language>
    >([]);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [loading, setLoading] = useState<boolean>(true);
    const { id } = useParams();
    useEffect(() => {
        const fetchAll = async () => {
            try {
                const languageService = new LanguageService();
                setLanguages(await languageService.all());
                if (edit) {
                    const driverService = new DriverService();
                    setDriver(new Driver(await driverService.get(id)));
                }
            } catch (e) {
                setLoading(false);
                setErrorApi(new ErrorFormData(e.response.data));
            }
            setLoading(false);
        };
        fetchAll();
    }, [id, edit]);

    return (
        <>
            <ContentSection
                title={driver ? t("driver_update") : t("driver_add")}
                returnAction={true}
            >
                {loading ? (
                    <CircularProgress />
                ) : driver ? (
                    <FormDriver
                        driver={driver}
                        languages={languages}
                        //user={user}
                    />
                ) : (
                    <FormDriver languages={languages} //user={user} 
                    />
                )}
                <ErrorForm errorApi={errorApi} />
            </ContentSection>
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(DriverFormPage);
