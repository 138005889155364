import {Filter} from "../filter";

interface IDictionary {
    [index: string]: string;
}

export class QuoteFilters {
    public status: Array<Filter>;

    constructor(data: any) {
        this.status = data.status.map((itemStatus: Object) => new Filter(itemStatus));
    }
}
