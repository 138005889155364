import { Ticket } from "../../../models/ticket";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal/Modal";
import React from "react";
import DetailTicket from "../detail-ticket/DetailTicket";
import { Reply } from "../../../models/reply";
import "./ModalHistoryConversation.scss";
import { getDateTime } from "../../../utils/DateHelper";
import { connect } from "react-redux";
import { User } from "../../../models/user";

interface Iprops {
    isOpen: boolean;
    closeModal: () => void;
    ticket?: Ticket;
    user: User;
}

const ModalHistoryConversation = ({
    isOpen,
    closeModal,
    ticket,
    user,
}: Iprops) => {
    const { t } = useTranslation();

    return (
        <Modal
            label={t("conversation_history")}
            isOpen={isOpen}
            closeModal={() => closeModal()}
        >
            <>
                {ticket && (
                    <>
                        <div className="header-history-conv">
                            <DetailTicket ticket={ticket} />
                        </div>
                        <hr className="header-separate"/>
                        {ticket.replies.map((reply: Reply) =>
                            <div key={reply.id} className="history-conv-ticket">
                                <div className="box-history-conv-ticket"/>
                                <span>{
                                    user.id === reply.from_user.id
                                        ? 'Vous'
                                        : `${reply.from_user.firstname} ${reply.from_user.lastname} (${reply.from_user.role})`}</span>
                                <div className="info-history-conv-email-date">
                                    <span>{reply.from_user.email}</span>
                                    <span>{getDateTime(reply.created_at)}</span>
                                </div>
                                {reply.img_url &&
                                <a className="link-download" href={reply.img_url} target="_blank"
                                rel="noopener noreferrer">{t('see_attachment')}</a>
                                }
                                <div className="content-reply-history-conv"
                                    dangerouslySetInnerHTML={{__html: reply.answer}}/>
                                <hr className="reply-separate"/>
                            </div>
                        )}
                    </>
                )}
            </>
        </Modal>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(ModalHistoryConversation);
