import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContentSection from "../../components/content-section/ContentSection";
import { ErrorForm } from "../../components/ErrorForm";
import { ErrorFormData } from "../../models/interfaces/errorForm";
import DriverService from "../../services/driver-service";
import KpiDrivers from "./kpi-drivers/KpiDrivers";
import ListDrivers from "./list-drivers/ListDrivers";
import WhiteButton from "../../components/white-button/WhiteButton";
import {useHistory, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {User} from "../../models/user";

interface IPropsDriversPage {
    user: User;
}

const DriversPage:React.FC<IPropsDriversPage> = ({user}) => {
    const { t } = useTranslation();
    const [dataKpis, setDataKpis] = useState<any>({
        pourcentTenders: "-",
        nbDrivers: "-",
    });
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const query = new URLSearchParams(useLocation().search);
    const providerId = query.get("providerId");
    const history = useHistory();

    const fetchKpis = async () => {
        try {
            const driverService = new DriverService();
            setDataKpis(await driverService.getKpis());
        } catch (e) {
            setErrorApi(new ErrorFormData(e.response.data));
        }
    };

    const renderButtonAdd = (label: string, url: string) => {
        return (user.isAdmin() && providerId) ? (
            <WhiteButton className="btn-rounded" variant="contained" color="primary"
                         onClick={() => history.push(url)}
            >{label}</WhiteButton>
        ) : (
            <></>
        );
    };

    useEffect(() => {
        fetchKpis();
    }, []);

    //Return the appropriate content
    return (
        <>
            <ContentSection
                title={t("nav.drivers")}
                addAction={"driver"}
                suppContent={renderButtonAdd(
                    t("add_driver"),
                    `/app/providers/${providerId}/drivers/add`
                )}
            >
                <KpiDrivers
                    nbDrivers={dataKpis.nbDrivers}
                    pourcentTenders={dataKpis.pourcentTenders + "%"}
                />
                <ListDrivers fetchKpis={fetchKpis} />
            </ContentSection>
            <ErrorForm errorApi={errorApi} />
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(DriversPage);
