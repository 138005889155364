import Colors from "../../containers/design-dependencies/Colors";
import React from "react";
import ClientSvg from "../../containers/design-dependencies/svgs/ClientSvg";
import CustomerSvg from "../../containers/design-dependencies/svgs/CustomerSvg";
import EuroSvg from "../../containers/design-dependencies/svgs/EuroSvg";
import { VehicleType } from "../../models/vehicle-type";
import CarSvg from "../../containers/design-dependencies/svgs/CarSvg";
import ChatSvg from "../../containers/design-dependencies/svgs/ChatSvg";
import BusSvg from "../../containers/design-dependencies/svgs/BusSvg";
import UserSvg from "../../containers/design-dependencies/svgs/UserSvg";
import MotoSvg from "../../containers/design-dependencies/svgs/MotoSvg";
import MiniMap from "../../containers/design-dependencies/svgs/MiniMap";
import SpeakerSvg from "../../containers/design-dependencies/svgs/SpeakerSvg";
import SupportSvg from "../../containers/design-dependencies/svgs/SupportSvg";

interface propsNewIndicator {
    className: string;
    index: number;
    text: string;
    total?: any;
    isMoney?: boolean;
    vehicleType?: VehicleType;
    type: IndicatorType;
}

export enum IndicatorType {
    Vehicle,
    Provider,
    Client,
    Folder,
    FolderClient,
    Beneficiary,
    Driver,
    Transfer,
    TransferProvider,
    TransferClient,
    Quote,
    Invoice,
    Tender,
    Support,
}

const getSvg = (
    indicatorType: IndicatorType,
    index: number,
    vehicleType?: VehicleType
) => {
    switch (indicatorType) {
        case IndicatorType.Client:
            if (index === 0) {
                return <CustomerSvg fill={Colors.blue} />;
            } else if (index === 1) {
                return <ClientSvg fill={Colors.orange} />;
            } else if (index === 2) {
                return <ChatSvg fill={Colors.yellow} />;
            } else {
                return <CustomerSvg fill={Colors.blue} />;
            }

        case IndicatorType.Folder:
            if (index === 0) {
                return <EuroSvg fill={Colors.cyan} />;
            } else if (index === 1) {
                return <EuroSvg fill={Colors.red} />;
            } else if (index === 2) {
                return <EuroSvg fill={Colors.green} />;
            } else {
                return <EuroSvg fill={Colors.mint} />;
            }

        case IndicatorType.FolderClient:
            if (index === 0) {
                return <EuroSvg fill={Colors.greenLight} />;
            } else if (index === 1) {
                return <EuroSvg fill={Colors.yellow} />;
            } else {
                return <EuroSvg fill={Colors.red} />;
            }

        case IndicatorType.Transfer:
            if (index === 0) {
                return <EuroSvg fill={Colors.red} />;
            } else if (index === 1) {
                return <EuroSvg fill={Colors.cyan} />;
            } else if (index === 2) {
                return <EuroSvg fill={Colors.mint} />;
            } else {
                return <EuroSvg fill={Colors.blue} />;
            }
        case IndicatorType.TransferClient:
            if (index === 0) {
                return <EuroSvg fill={Colors.mint} />;
            } else if (index === 1) {
                return <EuroSvg fill={Colors.cyan} />;
            } else if (index === 2) {
                return <EuroSvg fill={Colors.yellow} />;
            } else {
                return <EuroSvg fill={Colors.blue} />;
            }

        case IndicatorType.TransferProvider:
            if (index === 0) {
                return <MiniMap fill={Colors.green} />;
            } else if (index === 1) {
                return <MiniMap fill={Colors.mint} />;
            } else if (index === 2) {
                return <MiniMap fill={Colors.orange} />;
            } else if (index === 3) {
                return <MiniMap fill={Colors.grey} />;
            } else {
                return <MiniMap fill={Colors.blue} />;
            }

        case IndicatorType.Vehicle:
            if (index === 0) {
                return <CarSvg fill={Colors.blue} />;
            } else if (index === 1) {
                return <BusSvg fill={Colors.orange} />;
            }
            // else if (index === 2) {
            //     return <MiniVanSvg fill={Colors.yellow} />;
            // }
            else if (index === 2) {
                // PUT MOTO LOGO HERE
                return <MotoSvg fill={Colors.greyClear} />;
            } else {
                return <ClientSvg fill={Colors.orange} />;
            }

        case IndicatorType.Provider:
            if (index === 0) {
                return <CustomerSvg fill={Colors.blue} />;
            } else {
                return <EuroSvg fill={Colors.green} />;
            }

        case IndicatorType.Beneficiary:
            if (index === 0) {
                return <CarSvg fill={Colors.blue} />;
            } else {
                return <BusSvg fill={Colors.red} />;
            }
        case IndicatorType.Driver:
            if (index === 0) {
                return <UserSvg fill={Colors.blue} />;
            } else {
                return <EuroSvg fill={Colors.orange} />;
            }
        default:
            return <ClientSvg fill={Colors.orange} />;

        case IndicatorType.Quote:
            if (index === 0) {
                return <CustomerSvg fill={Colors.green} />;
            }
            if (index === 1) {
                return <CustomerSvg fill={Colors.red} />;
            } else {
                return <EuroSvg fill={Colors.yellow} />;
            }
        case IndicatorType.Invoice:
            if (index === 0) {
                return <EuroSvg fill={Colors.mint} />;
            } else if (index === 1) {
                return <EuroSvg fill={Colors.red} />;
            } else if (index === 2) {
                return <EuroSvg fill={Colors.yellow} />;
            }
            break;
        case IndicatorType.Tender:
            if (index === 0) {
                return <SpeakerSvg fill={Colors.blue} />;
            } else if (index === 1) {
                return <SpeakerSvg fill={Colors.grey} />;
            } else if (index === 2) {
                return <SpeakerSvg fill={Colors.mint} />;
            }
            break;
        case IndicatorType.Support:
            if (index === 0) {
                return <SupportSvg fill={Colors.red} />;
            } else if (index === 1) {
                return <SupportSvg fill={Colors.greyClear} />;
            } else if (index === 2) {
                return <SupportSvg fill={Colors.greenLight} />;
            }
    }
};

const NewIndicator: React.FunctionComponent<propsNewIndicator> = (props) => {
    const svg = getSvg(props.type, props.index, props?.vehicleType);
    return (
        <div className="media-icon-box mic-sm media">
            <div className={"mib-media-left " + props.className}>
                <div className="mib-round">{svg}</div>
            </div>
            <div className="mib-body media-body">
                <div className="mibb-content row">
                    <span className="col">{props.text}</span>
                    <strong className="col-auto ml-auto">
                        {props.total ? props.total : "-"}{" "}
                        {props.isMoney ? "€" : ""}
                    </strong>
                </div>
            </div>
        </div>
    );
};

export default NewIndicator;
