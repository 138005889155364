import React from "react";
import { useTranslation } from "react-i18next";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import { Add } from "@material-ui/icons";
import "./SubFormOptions.scss";

interface Iprops {
    disabled: boolean;
    control: any;
    errors: any;
    onAdd: () => void;
}

const SubFormOptions = ({ control, errors, disabled, onAdd }: Iprops) => {
    const { t } = useTranslation();

    if (!errors.priceOption) {
        errors.priceOption = [];
    }

    return (
        <>
            <div className="form-part-align-center subform-options">
                <div className="field-form mgl3 col-5">
                    <InputControlled
                        name={"price"}
                        label={t("price_option")}
                        value={""}
                        required={true}
                        disabled={disabled}
                        control={control}
                        errors={errors}
                        decorator={
                            <span className="decorator">
                                {t("money_symbol")}
                            </span>
                        }
                    />
                </div>
                <div className="field-form mgl3 col-5">
                    <InputControlled
                        name={"tva"}
                        label={t("form.tva")}
                        value={""}
                        required={true}
                        disabled={disabled}
                        control={control}
                        errors={errors}
                        decorator={<span className="decorator">%</span>}
                    />
                </div>

                <div className="add-option mgl3">
                    <Add
                        style={{ cursor: "pointer" }}
                        className="add"
                        onClick={() => onAdd()}
                    />
                </div>
            </div>
        </>
    );
};

export default SubFormOptions;
