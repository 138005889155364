import axios, {AxiosResponse} from "axios";

import {getBaseUrl, getParamsUrl} from "./setting-service";
import {Quote, RefusalCodes} from "../models/quote";
import {DataPaginate} from "../models/utils/data-paginate";
import {QuoteFilters} from "../models/utils/filters/quotes/quote-filters";
import {FiltersEnabled} from "../models/utils/filters/filter-enabled";

export default class QuoteService {
    create(folderId: number | string) {
        return axios
            .post(`${getBaseUrl()}quotes/${folderId}`, null)
            .then((response: AxiosResponse) => new Quote(response.data))
            .catch();
    }

    get(quoteId: number | string) {
        return axios
            .get(`${getBaseUrl()}quotes/${quoteId}`)
            .then((response: AxiosResponse) => new Quote(response.data))
            .catch();
    }

    getFilter() {
        return axios
            .get(`${getBaseUrl()}quotes/filters`)
            .then((response: AxiosResponse) => {
                return new QuoteFilters(response.data);
            });
    }

    getAll(
        page?: number | null,
        search?: string | null,
        filter?: string | null,
        filters?: FiltersEnabled |null
    ) {
        let paramsUrl = "";

        if (filters)
            paramsUrl = getParamsUrl([
                { key: "page", value: page },
                { key: "search", value: search },
                { key: "filter", value: filter },
                ...filters.list,
            ]);
        else {
            paramsUrl = getParamsUrl([
                { key: "page", value: page },
                { key: "search", value: search },
                { key: "filter", value: filter },
            ]);
        }
        return axios
            .get(`${getBaseUrl()}quotes${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map((quote: Object) => new Quote(quote));

                return new DataPaginate(res);
            });
    }

    update(quoteId: number | string, preview = false, $data: any) {
        return axios
            .put(`${getBaseUrl()}quotes/${quoteId}?preview=${preview}`, $data, {
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    send(quoteId: number | string, $data: any) {
        return axios
            .post(`${getBaseUrl()}quotes/${quoteId}/send`, $data)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    cancel(quoteId: number | string) {
        return axios
            .delete(`${getBaseUrl()}quotes/${quoteId}`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    preview(quoteId: number | string, $data: any) {
        return axios
            .put(`${getBaseUrl()}quotes/${quoteId}?preview=true`, $data, {
                responseType: "blob",
            })
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    accept(quoteId: number | string, signature: any) {
        const body = {
            accepted: true,
            signature: signature,
            cgv: true,
        };
        return axios
            .post(`${getBaseUrl()}quotes/${quoteId}/accept`, body)
            .then((response: AxiosResponse) => new Quote(response.data))
            .catch();
    }

    decline(quoteId: number, refusalCode: RefusalCodes, comment?: string) {
        const body = {
            accepted: false,
            refusal: refusalCode,
            comment: comment,
        };
        return axios
            .post(`${getBaseUrl()}quotes/${quoteId}/accept`, body)
            .then((response: AxiosResponse) => new Quote(response.data))
            .catch();
    }

    acceptAdmin(quoteId: number | string, comment: any) {
        const body = {
            accepted: true,
            comment: comment,
        };
        return axios
            .post(`${getBaseUrl()}quotes/${quoteId}/accept`, body)
            .then((response: AxiosResponse) => new Quote(response.data))
            .catch();
    }

    getKpis() {
        return axios
            .get(`${getBaseUrl()}quotes/kpis`)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch();
    }
}
