import React from 'react';
import './Homepage.scss';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { User } from '../../models/user';
import ClientDashboard from './ClientDashboard';
import { useHistory } from 'react-router-dom';

interface IProps {
  user: User;
}
const Homepage = ({user}:IProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  if(user.isProvider()){
    history.push('/app/transfers');
  } else if(user.isAdmin()){
    history.push('/app/folders');
  }
  
  return (
      <>
        {user && user.isClient()
          ? <ClientDashboard user={user} />
          : (<span>{t('homepage')}</span>)
        }
      </>  
  );
}

function mapStateToProps(state: any) {
  return {
      user: state.auth.user,
  };
}

export default connect(mapStateToProps)(Homepage);

