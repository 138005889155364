import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import NewIndicator, {
    IndicatorType,
} from "../../../components/indicator/NewIndicator";
import UseStyles from "../../design-dependencies/UseStyles";
import TransferService from "../../../services/transfer-service";

interface IKpiTransfer {
    validated: number;
    to_assign: number;
}

interface IListParams {
    folderId?: number | null;
    providerId?: number | null;
}

const KpiTransfers: React.FunctionComponent<IListParams> = (props) => {
    const [kpis, setKpis] = useState<IKpiTransfer>();

    const { t } = useTranslation();

    useEffect(() => {
        const getKpis = async () => {
            try {
                const service = new TransferService();
                let res = props.providerId
                    ? await service.getKpisTransfersOfProvider(props.providerId)
                    : await service.getKpis(props.folderId);
                setKpis(res);
            } catch (error) {
                console.log("error", error);
            }
        };

        getKpis();
    }, [props.folderId, props.providerId]);

    return (
        <div className="row">
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().cyanBg}
                    index={1}
                    text={t("provider_status.pending")}
                    type={IndicatorType.Transfer}
                    total={kpis?.to_assign ? kpis.to_assign : 0}
                />
            </div>
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().mintBg}
                    index={2}
                    text={t("provider_status.ok")}
                    type={IndicatorType.Transfer}
                    total={kpis?.validated ? kpis.validated : 0}
                />
            </div>
        </div>
    );
};

export default KpiTransfers;
