import Modal from "../../modal/Modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ModalAcceptEstimate.scss";
import { ReactSVG } from "react-svg";
import { Button, Checkbox, FormControl, FormLabel, Icon, TextareaAutosize } from "@material-ui/core";
import Colors from "../../../containers/design-dependencies/Colors";
import ConditionsOfUse from "../../conditions-of-use/ConditionsOfUse";
import { toastr } from "react-redux-toastr";
import SignatureCanvas from "react-signature-canvas";
import QuoteService from "../../../services/quote-service";
import { Quote } from "../../../models/quote";

interface IProps {
    quote: Quote;
    isOpen: boolean;
    isAdmin?: boolean | false;
    closeDialog: () => void;
    onAccept: (quote: Quote) => void;
}

export default function ModalAcceptEstimate({
    isOpen,
    isAdmin,
    quote,
    closeDialog,
    onAccept,
}: IProps) {
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [checkedStep2, setCheckedStep2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    let sigPad: any = {};

    function renderStep() {

        if (isAdmin) {
            return adminForm();
        }

        if (currentStep === 1) {
            return step1();
        } else if (currentStep === 2) {
            return step2();
        } else if (currentStep === 3) {
            return step3();
        }

        return <></>;
    }

    async function accept() {
        if (!isAdmin && sigPad.isEmpty()) {
            toastr.error(t("error"), t("error_quotation_not_signed"));
            return;
        }

        try {
            setLoading(true);
            const quoteService = new QuoteService();
            const quoteUpdated = isAdmin 
                ? await quoteService.acceptAdmin(quote.id,comment)
                : await quoteService.accept(
                    quote.id,
                    sigPad.getTrimmedCanvas().toDataURL("image/png")
                );

            toastr.success(t("success"), t("sucess_quotation_validated"));
            onAccept(quoteUpdated);
            setLoading(false);
        } catch (e) {
            toastr.error(t("error"), t("unexpected_error"));
            setLoading(false);
        }
    }

    function adminForm() {
        return (
            <div className="content-modal-admin">
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t('comment')}</FormLabel>
                    <TextareaAutosize disabled={loading} onChange={(e) => setComment(e.target.value)} value={comment} className="textarea w-100" aria-label="empty textarea" rowsMin={4} placeholder={t('comment')} />
                </FormControl>
                <div className="actions">
                    <Button
                        disabled={loading}
                        onClick={() => closeDialog()}
                        className="btn-form btn-icon mr-2 submit-input"
                        variant="contained"
                        style={{ backgroundColor: Colors.red }}
                        size="large"
                        type="button"
                    >
                        {t("cancel")}{" "}
                        <ReactSVG src="/images/app_icons/icon-delete.svg" />
                    </Button>

                    <Button
                        disabled={loading}
                        onClick={() => accept()}
                        className="btn-form btn-icon mr-2 submit-input"
                        variant="contained"
                        color="primary"
                        size="large"
                        type="button"
                    >
                        {t("validate")} <Icon>{"done"}</Icon>
                    </Button>
                </div>
            </div>
        );
    }

    function step1() {
        return (
            <div className="content-modal-step1">
                <div className="label-important">
                    {t("validate_and_process_digital_sign")}
                </div>

                <div className="actions">
                    <Button
                        disabled={loading}
                        onClick={() => closeDialog()}
                        className="btn-form btn-icon mr-2 submit-input"
                        variant="contained"
                        style={{ backgroundColor: Colors.red }}
                        size="large"
                        type="button"
                    >
                        {t("cancel")}{" "}
                        <ReactSVG src="/images/app_icons/icon-delete.svg" />
                    </Button>

                    <Button
                        disabled={loading}
                        onClick={() => setCurrentStep(2)}
                        className="btn-form btn-icon mr-2 submit-input"
                        variant="contained"
                        color="primary"
                        size="large"
                        type="button"
                    >
                        {t("validate")} <Icon>{"done"}</Icon>
                    </Button>
                </div>
            </div>
        );
    }

    function step2() {
        function validateStep2() {
            if (checkedStep2) {
                setCurrentStep(3);
            } else {
                toastr.error(
                    t("error"),
                    "Veuillez accepter les condition d'utilisation pour poursuivre !"
                );
            }
        }

        return (
            <div className="content-modal-step1">
                <div className="label-important">
                    {t("terms_of_use_and_legal_notice")}
                </div>

                <ConditionsOfUse />

                <div className="flex-row">
                    <div style={{ width: "50%" }}>
                        <Checkbox
                            name="term_checked"
                            id="term_checked"
                            checked={checkedStep2}
                            onChange={(e) => setCheckedStep2(!checkedStep2)}
                            inputProps={{
                                "aria-label": "checkbox with default color",
                            }}
                        />
                        <span>{t("checking_terms")}</span>
                    </div>
                    <div className="flex-row">
                        <Button
                            onClick={() => closeDialog()}
                            className="btn-form btn-icon mr-2 submit-input"
                            variant="contained"
                            style={{ backgroundColor: Colors.red }}
                            size="large"
                            type="button"
                        >
                            {t("cancel")}{" "}
                            <ReactSVG src="/images/app_icons/icon-delete.svg" />
                        </Button>

                        <Button
                            onClick={() => validateStep2()}
                            className="btn-form btn-icon mr-2 submit-input"
                            variant="contained"
                            color="primary"
                            size="large"
                            type="button"
                        >
                            {t("validate")} <Icon>{"done"}</Icon>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    function step3() {
        return (
            <div className="content-modal-step1">
                <div className="label-important">
                    {t("validate_and_process_digital_sign")}
                </div>

                <SignatureCanvas
                    penColor="black"
                    ref={(ref) => {
                        sigPad = ref;
                    }}
                    canvasProps={{
                        width: 300,
                        height: 200,
                        className: "sig-canvas box-shadow",
                    }}
                />

                <Button
                    onClick={() => sigPad.clear()}
                    style={{
                        display: "flex",
                        margin: "auto",
                        marginBottom: "40px",
                    }}
                >
                    {t("restart")} ?
                </Button>

                <div className="actions" style={{ marginBottom: "20px" }}>
                    <Button
                        onClick={() => closeDialog()}
                        className="btn-form btn-icon mr-2 submit-input"
                        variant="contained"
                        style={{ backgroundColor: Colors.red }}
                        size="large"
                        type="button"
                    >
                        {t("cancel")}{" "}
                        <ReactSVG src="/images/app_icons/icon-delete.svg" />
                    </Button>

                    <Button
                        onClick={() => accept()}
                        className="btn-form btn-icon mr-2 submit-input"
                        variant="contained"
                        color="primary"
                        size="large"
                        type="button"
                    >
                        {t("validate")} <Icon>{"done"}</Icon>
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <Modal
            label={t(isAdmin ? "admin_accept_quotation" : "accept_and_sign_quotation")}
            isOpen={isOpen}
            closeModal={() => closeDialog()}
        >
            {renderStep()}
        </Modal>
    );
}
