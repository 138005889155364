export function formatDatePicker(date?: Date | null | undefined) {

    if (date) {
        let month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }
}

export function formatDateTimePicker(datetime?: Date | null | undefined) {
    if (datetime) {
        let month = "" + (datetime.getMonth() + 1),
            day = "" + datetime.getDate(),
            year = datetime.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        let hour =
                datetime.getHours().toString().length !== 2
                    ? "0" + datetime.getHours().toString()
                    : datetime.getHours().toString(),
            min =
                datetime.getMinutes().toString().length !== 2
                    ? datetime.getMinutes().toString() + "0"
                    : datetime.getMinutes().toString();

        return [year, month, day].join("-") + "T" + [hour, min].join(":");
    }
}

export function formatDateDDMMYYYY(date: Date | null | undefined) {
    if (date) {
        let month = (date.getMonth() + 1).toString(),
            day = date.getDate().toString(),
            year = date.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return date ? `${day}/${month}/${year}` : "";
    }

    return "";
}

export function formatDateDDMMYYYYAndHoursAndMinutes(date: Date) {
    const formatDate = formatDateDDMMYYYY(date);
    const hours =
        date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes =
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

    return `${formatDate} ${hours}:${minutes}`;
}

export function dateAddMonth(date: Date, number: number) {
    date.setMonth(date.getMonth() + number);

    return date;
}

export function getDateTime(date: Date, at?: string | undefined) {
    const dt = new Date(date);

    let month = "" + (dt.getMonth() + 1),
        day = "" + dt.getDate(),
        year = dt.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    let hour =
            dt.getHours().toString().length !== 2
                ? "0" + dt.getHours().toString()
                : dt.getHours().toString(),
        min =
            dt.getMinutes().toString().length !== 2
                ? dt.getMinutes().toString() + "0"
                : dt.getMinutes().toString();

    return `${day}/${month}/${year} ${at ? at : ""} ${hour}:${min}`;
}
