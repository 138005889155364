import {Filter} from "../filter";

interface IDictionary {
    [index: string]: string;
}

export class FolderFilters {
    public status: Array<Filter>;
    public start_dates: Array<Filter>;
    public end_dates: Array<Filter>;

    constructor(data: any) {
        this.status = data.status.map((itemStatus: Object) => new Filter(itemStatus));
        this.start_dates = data.start_dates.map((itemStartDate: Object) => new Filter(itemStartDate));
        this.end_dates = data.end_dates.map((itemEndDate: Object) => new Filter(itemEndDate));
    }
}
