import React from 'react';
import './RoundedImage.scss';

interface IProps{
    image_url?: string,
    size?: number,
}
export default function RoundedImage({image_url, size = 66}: IProps) {
    return image_url ? <img className="rounded-image" height={size} width={size} src={image_url} alt="" /> 
                     : <div className="rounded-image" style={{width: size, height: size}} /> 
    
}