import React, { useEffect } from 'react';
import { User } from '../../models/user';
import Header from '../header/Header';
import LeftSideNav from '../left-side-nav/LeftSideNav';
import './TemplateBaseFront.scss';
import { connect } from 'react-redux';

interface ITemplateBaseFrontProps{
    user: User;
    is_authentificated: boolean | null
}

const TemplateBaseFront: React.FunctionComponent<ITemplateBaseFrontProps> = (props) => {

    useEffect( () =>{
        if(props.user){
            let element = document.getElementsByTagName("body");
            if(element){
                element[0].classList.add("loaded")
            }
        }
    }, [props.user] )
    return (
        <>
            { props.is_authentificated && props.user ? 
                <div>
                    <Header user={props.user}/>
                    <div className="page">
                        <LeftSideNav user={props.user}/>
                        <div className="content">
                            { props.children }
                        </div>
                    </div>
                </div> : <></> 
            }
            <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>
        </>
    )


   /* if(props.is_authentificated && props.user){
        return (
            
        );
    }

    return <div id="loader-wrapper">
        <div id="loader"></div>
        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
    </div>;*/
}

function mapStateToProps(state:any) {
    return {
        user: state.auth.user,
        is_authentificated: state.auth.is_authentificated
    };
}

export default connect(mapStateToProps)(TemplateBaseFront);
