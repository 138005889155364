import { Deserializable } from "./interfaces/deserializable";

export class ProviderType implements Deserializable {
    public id: number;
    public label: string;
    public code: string;
    public created_at: Date;
    public updated_at: Date;

    constructor(data:any) {
        this.id = data.id;
        this.label = data.label;
        this.code = data.code;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}