import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorFormData } from "../../../../models/interfaces/errorForm";
import { ErrorForm } from "../../../../components/ErrorForm";
import { toastr } from "react-redux-toastr";
import ModelService from "../../../../services/model-service";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { DataPaginate } from "../../../../models/utils/data-paginate";
import { VehicleBrand } from "../../../../models/vehicle-brand";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import FormTitle from "../../../../components/form-title/FormTitle";
import SubmitComponent from "../../../../components/submit-component/SubmitComponent";
import "./FormModels.scss";

interface IFormModel {
    label: string;
    brand_id: number;
}

interface IFormProps {
    onAddSuccess: () => void;
    allBrandsPaginate: DataPaginate;
}

const FormModel: React.FunctionComponent<IFormProps> = ({
    onAddSuccess,
    allBrandsPaginate,
}) => {
    const { register, handleSubmit, errors, setValue, control } =
        useForm<IFormModel>();
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState(false);
    const [imagePreview, setImagePreview] = useState<any>(null);
    const [isActivateButton, setIsActivateButton] = useState(true);
    const textAdd = t("add");

    React.useEffect(() => {
        register("brand_id"); // custom register Antd input
    }, [register]);

    const onSubmit = async (data: any, e: any) => {
        setErrorApi(new ErrorFormData());
        const modelService = new ModelService();
        try {
            setIsActivateButton(false);
            setDisabledForm(true);

            await modelService.add(data, data.brand_id);
            onAddSuccess();
            toastr.success(t("success"), t("success_add_model"));
            setDisabledForm(false);
            setImagePreview("");
            setValue("label", "");
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setIsActivateButton(true);
    };

    const previewImage = (brandId: number) => {
        const brand = allBrandsPaginate.data.find(
            (brand: VehicleBrand) => brand.id === brandId
        );
        brand && brand.img_url
            ? setImagePreview(brand.img_url)
            : setImagePreview(null);
        setValue("brand_id", brandId);
    };

    return (
        <>
            <FormTitle title={t("model_add")} margin={true} />
            <form
                className="form-react models-form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormControl className="mgl2">
                    <InputLabel>{t("brand")}</InputLabel>
                    <Select
                        name="brand_id"
                        onChange={(e: any) => previewImage(e.target.value)}
                        defaultValue=""
                        className="brand-selector"
                    >
                        {allBrandsPaginate.data.map((brand: VehicleBrand) => {
                            return (
                                <MenuItem
                                    key={`form_models_brand_id_${brand.id}`}
                                    value={brand.id}
                                >
                                    {brand.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                {imagePreview ? (
                    <div className="preview_image mgl2">
                        <img src={imagePreview} alt="" />
                    </div>
                ) : (
                    ""
                )}

                <div className="field-form mgl2">
                    <InputControlled
                        name="label"
                        label={t("model")}
                        value=""
                        required={true}
                        disabled={disabledForm}
                        control={control}
                        errors={errors}
                    />
                </div>

                <SubmitComponent
                    loading={false}
                    text={textAdd}
                    icon="add"
                    mainClass="vehicle-details-submit"
                    isActivate={isActivateButton}
                />

                {errors.label && <p>{errors.label.message}</p>}
                <ErrorForm errorApi={errorApi} />
            </form>
        </>
    );
};

export default FormModel;
