import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import FormSection from "../../../components/form-section/FormSection";
import SubmitComponent from "../../../components/submit-component/SubmitComponent";
import { ErrorForm } from "../../../components/ErrorForm";
import { Editor, RawDraftContentState } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Ticket, TicketState } from "../../../models/ticket";
import FormTitleMultiple from "../form-title-multiple/FormTitleMultiple";
import ModalHistoryConversation from "../modal-history-conversation/ModalHistoryConversation";
import { ReactSVG } from "react-svg";
import draftToHtml from "draftjs-to-html";
import { Button, CircularProgress, Icon } from "@material-ui/core";
import { connect } from "react-redux";
import { User } from "../../../models/user";
import { useParams } from "react-router-dom";
import TicketReplyService from "../../../services/ticket-reply-service";
import { toastr } from "react-redux-toastr";
import DetailTicket from "../detail-ticket/DetailTicket";
import { getDateTime } from "../../../utils/DateHelper";
import TicketService from "../../../services/ticket-service";
import "./FormAnswerReply.scss";

interface IFormProps {
    ticket?: Ticket;
    user: User;
    refresh: () => void;
}

interface IPropsFormReply {
    answer: string;
    attachment: File;
}

const FormAnswerReply: React.FunctionComponent<IFormProps> = ({
    ticket,
    user,
    refresh,
}) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { register, handleSubmit, setValue } = useForm<IPropsFormReply>();
    const [errorApi, setErrorApi] = useState<ErrorFormData>(
        new ErrorFormData()
    );
    const [disabledForm, setDisabledForm] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [answer, setAnswer] = useState<string>("");
    const [file, setFile] = useState<any>();
    const [isActivateButton,setIsActivateButton]=useState(true)

    const onSubmit = async (data: any, e: any) => {
        setLoadingSubmit(true);
        setDisabledForm(true);
        setIsActivateButton(false)
        const ticketReplyService = new TicketReplyService(id);
        const newData = { ...data, answer: answer };
        try {
            await ticketReplyService.post(newData);
            toastr.success(t("success"), t("success_add_reply"));
            setLoadingSubmit(false);
            setDisabledForm(false);
            refresh();
        } catch (e) {
            setLoadingSubmit(false);
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(e.response.data));
        }
        setIsActivateButton(true)
    };

    const changeState = async () => {
        setLoadingState(true);
        const ticketService = new TicketService();
        try {
            await ticketService.changeState(id);
            toastr.success(t("success"), t("success_change_state"));
            refresh();
            setLoadingState(false);
        } catch (e) {
            toastr.error(t("error"), t("error_add_reply"));
            setLoadingState(false);
        }
    };

    const onInternalChange = (currentContentState: RawDraftContentState) => {
        setAnswer(draftToHtml(currentContentState));
    };

    const setImgBase64 = (files: any) => {
        if (files.length === 0) return;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        setFile(files[0]);
        reader.onload = (_event) =>
            reader.result ? setValue("img_base64", reader.result) : null;
    };

    const resetFile = () => {
        setValue("img_base64", null);
        setFile(undefined);
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="ticket-reply-content">
                    <div className="form-reply-ticket">
                        <FormSection title={t("reply")} className="mgl2">
                            <div className="field-form mgl4">
                                <Editor
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onChange={onInternalChange}
                                />
                            </div>
                        </FormSection>
                        <FormSection title={t("various")}>
                            <div className="field-form mgl4 col-2">
                                <Button variant="contained" component="label">
                                    {t("choose_a_file")}
                                    <input
                                        type="hidden"
                                        name="img_base64"
                                        ref={register()}
                                    />
                                    <input
                                        disabled={disabledForm}
                                        type="file"
                                        hidden
                                        onChange={(e) =>
                                            setImgBase64(e.target.files)
                                        }
                                    />
                                </Button>
                            </div>
                            {file && file.name && (
                                <div className="file-selected">
                                    <label>{file.name}</label>
                                    <ReactSVG
                                        className="icon-delete"
                                        src="/images/app_icons/icon-delete.svg"
                                        onClick={() => resetFile()}
                                    />
                                </div>
                            )}
                        </FormSection>
                        <ErrorForm errorApi={errorApi} />
                    </div>
                    <div className="ticket-history">
                        <FormTitleMultiple
                            className="mgl2 detail-ticket-reply"
                            title={t("message")}
                            field={t(`ticket_state.${ticket?.state}`)}
                        >
                            <DetailTicket ticket={ticket} />
                        </FormTitleMultiple>
                        <FormTitleMultiple
                            className="mgl2"
                            title={t("conversation_history")}
                            field={
                                <ReactSVG src="/images/app_icons/history.svg" />
                            }
                            sectionFlex={true}
                            onClick={() => setShowModal(!showModal)}
                            clickable={true}
                        >
                            {ticket?.replies.map((reply) => (
                                <div
                                    key={reply.id}
                                    className="ticket-reply-row"
                                >
                                    <span>
                                        {user.id === reply.from_user.id
                                            ? "Vous"
                                            : `${reply.from_user.firstname} ${reply.from_user.lastname} (${reply.from_user.role})`}
                                    </span>
                                    <span>{getDateTime(reply.created_at)}</span>
                                </div>
                            ))}
                        </FormTitleMultiple>
                    </div>
                </div>
                {(ticket?.state === TicketState.RUNNING ||
                    ticket?.state === TicketState.UNPROCESS) && (
                    <div
                        className="submit-container"
                        onClick={() => changeState()}
                    >
                        <Button
                            className="btn-form btn-icon mr-2 submit-input"
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            {!loadingState ? (
                                <span>{t("close_the_request")} </span>
                            ) : (
                                <div className="loader">
                                    <CircularProgress />
                                </div>
                            )}
                            <Icon>check</Icon>
                        </Button>
                    </div>
                )}

                {ticket?.state === TicketState.RESOLVE && (
                    <div
                        className="submit-container"
                        onClick={() => changeState()}
                    >
                        <Button
                            className="btn-form btn-icon mr-2 submit-input"
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            {!loadingState ? (
                                <span>{t("reopen_the_request")} </span>
                            ) : (
                                <div className="loader">
                                    <CircularProgress />
                                </div>
                            )}
                            <Icon>check</Icon>
                        </Button>
                    </div>
                )}

                <SubmitComponent
                    loading={loadingSubmit}
                    text={t("form.validate")}
                    isActivate={isActivateButton}
                />
            </form>

            <ModalHistoryConversation
                ticket={ticket}
                isOpen={showModal}
                closeModal={() => setShowModal(false)}
            />
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(FormAnswerReply);
