import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorFormData } from "../../../../models/interfaces/errorForm";
import { ErrorForm } from "../../../../components/ErrorForm";
import { toastr } from "react-redux-toastr";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import FormTitle from "../../../../components/form-title/FormTitle";
import SubmitComponent from "../../../../components/submit-component/SubmitComponent";
import LanguageService from "../../../../services/language-service";

interface FormRegister {
    label: string;
    isoCode: string;
}

interface IFormLanguageistrationProps {
    onAddLanguageSuccess: () => void;
}

const FormLanguages: React.FunctionComponent<IFormLanguageistrationProps> = ({
    onAddLanguageSuccess,
}) => {
    const { handleSubmit, errors, control, reset } = useForm<FormRegister>();
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState(false);
    const [isActivateButton, setIsActivateButton] = useState(true);
    const textValidate = t("validate");

    const onSubmit = async (data: any, e: any) => {
        setErrorApi(new ErrorFormData());
        const languagesService = new LanguageService();
        setIsActivateButton(false);

        try {
            setDisabledForm(true);
            await languagesService.add(data);
            onAddLanguageSuccess();
            toastr.success(t("success"), t("success_add_language"));
            setDisabledForm(false);
            reset();
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setIsActivateButton(true);
    };

    return (
        <>
            <FormTitle title={t("language_add")} />
            <form
                className="form-react admin-form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <InputControlled
                    name="label"
                    label={t("language_name")}
                    value=""
                    required={true}
                    disabled={disabledForm}
                    control={control}
                    errors={errors}
                />

                <InputControlled
                    name="iso_code"
                    label={t("code")}
                    value=""
                    required={true}
                    disabled={disabledForm}
                    control={control}
                    errors={errors}
                />

                <SubmitComponent
                    loading={false}
                    text={textValidate}
                    icon="add"
                    mainClass="admin-submit"
                    isActivate={isActivateButton}
                />

                <ErrorForm errorApi={errorApi} />
            </form>
        </>
    );
};

export default FormLanguages;
