import {
    CircularProgress,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ErrorForm} from "../../../components/ErrorForm";
import GlobalSearch from "../../../components/global-search/globalSearch";
import KpiContentSeparator from "../../../components/kpi-content-separator/KpiContentSeparator";
import HeadTableCell from "../../../components/table/HeadTableCell";
import {ErrorFormData} from "../../../models/interfaces/errorForm";
import {DataPaginate} from "../../../models/utils/data-paginate";
import Paper from "@material-ui/core/Paper";
import Pagination from "../../../components/pagination/pagination";
import QuoteService from "../../../services/quote-service";
import {User} from "../../../models/user";
import ItemQuote from "./ItemQuote";
import {Quote} from "../../../models/quote";
import "./ListQuote.scss";
import {FiltersEnabled} from "../../../models/utils/filters/filter-enabled";
import {QuoteFilters} from "../../../models/utils/filters/quotes/quote-filters";
import FiltersQuotes from "./FiltersQuotes";
import {debounce} from "lodash";

interface IPropsListQuotes {
    user: User;
}

const ListQuotes = ({user}: IPropsListQuotes) => {
    const {t} = useTranslation();
    const [dataPaginateQuotes, setDataPaginateQuotes] = useState(
        new DataPaginate()
    );
    const [pageQuotes, setPageQuotes] = useState(1);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState<string | null>(null);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [isDeleted, setIsDeleted] = useState<number>(0);
    const [filters, setFilters] = useState<QuoteFilters>();
    const [date, setDate] = useState(new Date());
    const [filtersEnabled, setFiltersEnabled] = useState<FiltersEnabled>(new FiltersEnabled());

    const fetchAllQuotes = async () => {
        if (!loading) setLoading(true);
        try {
            const quoteService = new QuoteService();
            setDataPaginateQuotes(
                await quoteService.getAll(pageQuotes, search, null, filtersEnabled)
            );
        } catch (e) {
            console.log("error", e);
        }
        setLoading(false);
    };

    const debounceQuery = useCallback(
        debounce(fetchAllQuotes, 500),
        [pageQuotes, search, isDeleted, filtersEnabled, date]
    );

    useEffect(() => {
        debounceQuery();
        return debounceQuery.cancel;
    }, [pageQuotes, search, isDeleted, filtersEnabled, date, debounceQuery]);

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const quoteService = new QuoteService();
                setFilters(await quoteService.getFilter());
            } catch (e) {
                console.log(e);
            }
        }
        fetchFilters();
    }, []);

    const handleDeleted = () => {
        setIsDeleted(isDeleted + 1);
    };

    const handleFiltersChange = (filters: FiltersEnabled) => {
        setFiltersEnabled(filters);
        setPageQuotes(1);
    }

    return (
        <>
            {/* SEARCHBAR */}
            <GlobalSearch
                onSearch={(value: string) => {
                    setPageQuotes(1);
                    setSearch(value);
                }}
                onResetFilter={() => setDate(new Date())}
            />
            <FiltersQuotes
                filtersEnabled={filtersEnabled}
                filters={filters}
                setFilters={(filters) => handleFiltersChange(filters)}
            />
            <KpiContentSeparator/>
            {dataPaginateQuotes &&
            dataPaginateQuotes.data?.length !== 0 &&
            !loading ? (
                <>
                    <TableContainer component={Paper}>
                        <Table
                            aria-label="customized table"
                            className="quote-list"
                        >
                            <TableHead>
                                <TableRow>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("num_bill")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("client")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("folder_code")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("number_transfer")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("price_ht")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("price_ttc")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("net_margin")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("due_date")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh see-quote"
                                        align="center"
                                    >
                                        {t("quote")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("status_table")}
                                    </HeadTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataPaginateQuotes.data
                                .map((quote: Quote) => (
                                    <ItemQuote
                                        key={`table-benefeciary-${quote.id}`}
                                        quote={quote}
                                        setErrorApi={(error) =>
                                            setErrorApi(error)
                                        }
                                        handleDeleted={() => handleDeleted()}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        dataPaginate={dataPaginateQuotes}
                        onPageChange={(page: number) => setPageQuotes(page)}
                    />
                </>
            ) : loading ? (
                <CircularProgress/>
            ) : !errorApi || dataPaginateQuotes.data?.length === 0 ? (
                <div>{t("no_items")}</div>
            ) : (
                ""
            )}
            <ErrorForm errorApi={errorApi}/>
        </>
    );
};

export default ListQuotes;
