import React, { useState, useEffect } from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import ClientService from "../../services/client-service";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ListAddresses from "./address/list-addresses/ListAddresses";
import { User } from "../../models/user";
import { connect } from "react-redux";
import { getPrimaryColorButton } from "../design-dependencies/ColorHelper";
import FormClient from "./form-client/FormClient";
import { Client } from "../../models/client";
import ClientGrants from "./grants/ClientGrants";
import WhiteButton from "../../components/white-button/WhiteButton";
import ListBeneficiaries from "../beneficiary/list-beneficiaries/ListBeneficiaries";


enum EnumTab {
    client = 0,
    adresses = 1,
    beneficiaries = 2,
    users = 3,
}

interface IProps {
    user: User;
}

function ClientTabPage({ user }: IProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [datas, setDatas] = useState<Client>();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    function getInitialTab() {
        const tab = EnumTab[query.get("tab") as keyof typeof EnumTab];

        return tab ? tab : EnumTab.client;
    }
    const [currentTab, setCurrentTab] = useState(getInitialTab());

    const colorTabSelected = getPrimaryColorButton(user?.role);
    const getStyleTab = (tabIndex: number) => {
        if (tabIndex === currentTab) {
            return {
                paddingLeft: '14px',
                paddingRight: '14px',
                background: colorTabSelected,
                color: "white",
            };
        }
        return {
            paddingLeft: '16px',
            paddingRight: '16px',
        };
    };

    useEffect(() => {
        const fetchAllClients = async () => {
            if (id) {
                try {
                    setLoading(true);
                    const dataService = new ClientService();
                    const client = await dataService.get(id);
                    setDatas(client);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    console.log("error", error);
                }
            }
        };

        fetchAllClients();
    }, [id]);

    const refreshClient = async () => {
        try {
            setLoading(true);
            const dataService = new ClientService();
            const client = await dataService.get(id);
            setDatas(client);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("error", error);
        }
    }

    const renderButtonAdd = (label: string, url: string) => {
        return (!datas || (user.isClient() && user.isReader(datas))) ? (
            <></>
        ) : (
            <WhiteButton className="btn-rounded" variant="contained" color="primary"
                onClick={() => history.push(url)}
            >{label}</WhiteButton>
        );
    };

    return (
        <>
            {id && (<>
                <AppBar position="static">
                    <Tabs
                        value={currentTab}
                        onChange={(event, newValue) => setCurrentTab(newValue)}
                        className="form-tabs"
                    >
                        <Tab
                            style={getStyleTab(EnumTab.client)}
                            label={t("entity.client")}
                        />
                        <Tab
                            style={getStyleTab(EnumTab.adresses)}
                            label={t("billing_addresses")}
                        />
                        <Tab
                            style={getStyleTab(EnumTab.beneficiaries)}
                            label={t("nav.beneficiaries")}
                        />
                        <Tab
                            style={getStyleTab(EnumTab.users)}
                            label={t("grants.users")}
                            disabled={!datas || user.isReader(datas)}
                        />
                    </Tabs>
                </AppBar>

                {currentTab === EnumTab.client && (
                    <ContentSection
                        title={datas?.company_name ? datas.company_name : ''}
                        returnAction={true}
                        style={id ? { borderTopLeftRadius: 0 } : {}}
                    >
                        {!loading? (
                            <>
                                <FormClient client={datas} />
                            </>
                        ) : (
                            <div>{t("loading")}</div>
                        )}
                    </ContentSection>
                )}

                {currentTab === EnumTab.adresses && (
                    <ContentSection
                        title={datas?.company_name ? datas.company_name : ''}
                        style={id ? { borderTopLeftRadius: 0 } : {}}
                        suppContent={renderButtonAdd(
                            t("address_add"),
                            `/app/client/${id}/address/add`
                        )}
                    >
                        {!loading && datas ? (
                            <>
                                <ListAddresses client={datas} refreshClient={() => refreshClient()} />
                            </>
                        ) : (
                            <div>{t("loading")}</div>
                        )}
                    </ContentSection>
                )}

                {currentTab === EnumTab.beneficiaries && (
                    <ContentSection
                        title={datas?.company_name ? datas.company_name : ''}
                        style={id ? { borderTopLeftRadius: 0 } : {}}
                        suppContent={renderButtonAdd(
                            t("add_a") + " " + t("beneficiary"),
                            `/app/beneficiary/add/${id}`
                        )}
                    >
                        {!loading ? (
                            <>
                                <ListBeneficiaries client={datas} />
                            </>
                        ) : (
                            <div>{t("loading")}</div>
                        )}
                    </ContentSection>
                )}

                {currentTab === EnumTab.users && datas && !user.isReader(datas) && (
                    <ContentSection
                        title={datas?.company_name ? datas.company_name : ''}
                        style={id ? { borderTopLeftRadius: 0 } : {}}
                    >
                        {!loading ? (
                            <>
                                <ClientGrants client={datas} me={user}/>
                            </>
                        ) : (
                            <div>{t("loading")}</div>
                        )}
                    </ContentSection>
                )}
              
            </>)}
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(ClientTabPage);
