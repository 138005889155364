import React from "react";
import NewIndicator from "../../../components/indicator/NewIndicator";
import UseStyles from "../../design-dependencies/UseStyles";
import { IndicatorType } from "../../../components/indicator/NewIndicator";
import { useTranslation } from "react-i18next";

interface PropsKpisDriver {
  nbDrivers: number;
  pourcentTenders: string;
}

const KpiDrivers = ({ nbDrivers, pourcentTenders }: PropsKpisDriver) => {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
        <NewIndicator
          className={UseStyles().blueGradient}
          index={0}
          text={t("drivers")}
          type={IndicatorType.Driver}
          total={nbDrivers}
        />
      </div>
      <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
        <NewIndicator
          className={UseStyles().orangeGradient}
          index={1}
          text={t("is_tenders")}
          type={IndicatorType.Driver}
          total={pourcentTenders}
        />
      </div>
    </div>
  );
};

export default KpiDrivers;
