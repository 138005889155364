import {Filter} from "../filter";

interface IDictionary {
    [index: string]: string;
}

export class ProviderFilters {
    public cities: Array<Filter>;
    public types: Array<Filter>;
    public is_tenders: Array<Filter> = [
        {
            id: 0,
            label: ''
        },
        {
            id: 1,
            label: ''
        }
    ];

    constructor(data: any, t: any) {
        this.cities = data.cities.map((city: Filter) => new Filter(city));
        this.types = data.types.map((type: Object) => new Filter(type));
        this.is_tenders = this.is_tenders.map((is_tender: Filter) =>
            new Filter({
                id: is_tender.id,
                label: is_tender.label = is_tender.id === 0
                    ? t("no")
                    : t("yes")
            }));
    }
}
