import React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import TemplateBaseFront from "../../components/template-base-front/TemplateBaseFront";
import { getUser } from "../../redux/store/auth/actions";
import AuthService from "../../services/auth-service";
import BeneficiaryFormPage from "../beneficiary/BeneficiaryFormPage";
import BeneficiaryPage from "../beneficiary/BeneficiaryPage";
import AccountFormPage from "../client/account/AccountFormPage";
import AddressFormPage from "../client/address/AddressFormPage";
import ClientFormPage from "../client/ClientFormPage";
import UserFormPage from "../user/form-user/FormUser";
import ClientPage from "../client/ClientPage";
import ClientTabPage from "../client/ClientTabPage";
import DriverFormPage from "../drivers/DriverFormPage";
import DriversPage from "../drivers/DriverPage";
import EstimatePage from "../folder/estimate-page/EstimatePage";
import FolderFormPage from "../folder/FolderFormPage";
import FolderPage from "../folder/FolderPage";
import InvoicePage from "../folder/invoice-page/InvoicePage";
import Homepage from "../homepage/Homepage";
import InvoicesPage from "../invoice/InvoicesPage";
import NotificationPage from "../notification/NotificationPage";
import ParameterPage from "../parameter/ParameterPage";
import ProviderFormPage from "../provider/ProviderFormPage";
import ProviderPage from "../provider/ProviderPage";
import QuotePage from "../quote/QuotePage";
import TenderPage from "../tender/TenderPage";
import TrackingsPage from "../tracking/TrackingsPage";
import TransferAmountFormPage from "../transfer/TransferAmountFormPage";
import TransferFormPage from "../transfer/TransferFormPage";
import TransferPage from "../transfer/TransferPage";
import TransferProvidersPage from "../transfer/TransferProvidersPage";
import AddOrEditVehiclePage from "../vehicles/AddOrEditVehiclePage";
import VehiclesPage from "../vehicles/VehiclesPage";
import SupportPage from "../support/SupportPage";
import SupportFormPage from "../support/SupportFormPage";
import CreditPage from "../credit/CreditPage";


function Logged() {
    let { path } = useRouteMatch();
    const dispatch = useDispatch();

    useEffect(() => {
        AuthService.updateAxiosInterceptors();
        let authService = new AuthService();
        const token = authService.getAuthToken();
        if (token) {
            authService.updateAxiosAuthorization();
        }
        dispatch(getUser());
    }, [dispatch]);

    const PrivateRoute = ({ children, ...rest }: any) => {
        return <Route {...rest} render={() => children} />;
    };

    return (
        <TemplateBaseFront>
            <Switch>
                <PrivateRoute exact={true} path={path}>
                    <Homepage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/user/informations`}>
                    <UserFormPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/providers`}>
                    <ProviderPage />
                </PrivateRoute>
                <PrivateRoute
                    path={[
                        `${path}/providers/:providerId/vehicles/add`,
                        `${path}/vehicle/add`,
                    ]}
                >
                    <AddOrEditVehiclePage />
                </PrivateRoute>
                <PrivateRoute
                    path={[
                        `${path}/providers/:providerId/vehicles/:vehicleId/edit`,
                        `${path}/vehicle/:vehicleId/edit`,
                    ]}
                >
                    <AddOrEditVehiclePage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/provider/add`}>
                    <ProviderFormPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/provider/:id`}>
                    <ProviderFormPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/provider/:id/transfers`}>
                    <TransferPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/drivers`}>
                    <DriversPage />
                </PrivateRoute>
                <PrivateRoute
                    path={[
                        `${path}/providers/:id/drivers/add`,
                        `${path}/driver/add`,
                    ]}
                >
                    <DriverFormPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/driver/:id`}>
                    <DriverFormPage edit />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/beneficiaries`}>
                    <BeneficiaryPage />
                </PrivateRoute>
                <PrivateRoute
                    exact={true}
                    path={[
                        `${path}/beneficiary/add/:client_id`,
                        `${path}/beneficiary/add`,
                        `${path}/beneficiary/:id/:client_id`,
                        `${path}/beneficiary/:id`,
                    ]}
                >
                    <BeneficiaryFormPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/parameter`}>
                    <ParameterPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/vehicles`}>
                    <VehiclesPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/vehicles`}>
                    <VehiclesPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/clients`}>
                    <ClientPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/client/add`}>
                    <ClientFormPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/client/:id`}>
                    <ClientTabPage />
                </PrivateRoute>
                <PrivateRoute
                    exact={true}
                    path={`${path}/client/:client_id/account/add`}
                >
                    <AccountFormPage />
                </PrivateRoute>
                <PrivateRoute
                    exact={true}
                    path={`${path}/client/:client_id/account/:id/edit`}
                >
                    <AccountFormPage />
                </PrivateRoute>
                <PrivateRoute
                    exact={true}
                    path={`${path}/client/:client_id/address/add`}
                >
                    <AddressFormPage />
                </PrivateRoute>
                <PrivateRoute
                    exact={true}
                    path={`${path}/client/:client_id/address/:address_id`}
                >
                    <AddressFormPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/folders`}>
                    <FolderPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/folder/add`}>
                    <FolderFormPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/folder/:id/edit`}>
                    <FolderFormPage />
                </PrivateRoute>
                <PrivateRoute
                    exact={true}
                    path={`${path}/folder/:id/transfers`}
                >
                    <TransferPage />
                </PrivateRoute>
                <PrivateRoute
                    exact={true}
                    path={`${path}/folder/:folderId/transfer/add`}
                >
                    <TransferFormPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/transfers`}>
                    <TransferPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/transfers/history`}>
                    <TransferPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/transfer/add`}>
                    <TransferFormPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/transfer/:id/edit`}>
                    <TransferFormPage />
                </PrivateRoute>
                <PrivateRoute
                    exact={true}
                    path={`${path}/transfer/:id/providers`}
                >
                    <TransferProvidersPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/transfer/:id/amount`}>
                    <TransferAmountFormPage />
                </PrivateRoute>
                <PrivateRoute
                    exact={true}
                    path={`${path}/transfer/:id/amount/:idAssignment`}
                >
                    <TransferAmountFormPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/notification`}>
                    <NotificationPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/quotes`}>
                    <QuotePage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/quote/:id`}>
                    <EstimatePage archive={true} />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/invoices`}>
                    <InvoicesPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/invoices/:id`}>
                    <InvoicePage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/credits`}>
                    <CreditPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/tenders`}>
                    <TenderPage />
                </PrivateRoute>

                <PrivateRoute exact={true} path={`${path}/support`}>
                    <SupportPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/support/add`}>
                    <SupportFormPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={`${path}/support/:id/reply`}>
                    <SupportFormPage />
                </PrivateRoute>
                <PrivateRoute
                    exact={true}
                    path={[`${path}/trackings`, `${path}/trackings/:id`]}
                >
                    <TrackingsPage />
                </PrivateRoute>
            </Switch>
        </TemplateBaseFront>
    );
}

const actionCreators = {
    getUser,
};

export default connect(null, actionCreators)(Logged);
