import React, {useState} from "react";
import "./AddAction.scss";
import {useTranslation} from "react-i18next";
import WhiteButton from "../../white-button/WhiteButton";
import {useHistory} from "react-router-dom";
import {Roles} from "../../../models/user";
import CheckPermissions from "../../../utils/CheckPermissions";
import ModalSendMailTicket from "../../../containers/support/modal-send-mail-ticket/ModalSendMailTicket";
import ModalConfigPerimeters from "../../../containers/tender/modal-config-perimeters/ModalConfigPerimeters";

interface IPropsContextAction {
    model: string;
}

const AddAction: React.FunctionComponent<IPropsContextAction> = (props) => {
        const {t} = useTranslation();
        const [showModal, setShowModal] = useState<boolean>(false);
        const [modal, setModal] = useState<string>();
        let history = useHistory();
        let location = {
            pathname: props.model + "/add",
        };

        return (
            <>
                {props.model === "vehicle" || props.model === "driver" ? (
                    <CheckPermissions permissions={[Roles.PROVIDER]}>
                        <WhiteButton
                            onClick={() => history.push(location)}
                            className="btn-rounded"
                            variant="contained"
                            color="primary"
                        >
                            {t("add_a").toUpperCase() +
                            " " +
                            t("entity." + props.model).toUpperCase()}
                        </WhiteButton>
                    </CheckPermissions>
                ) : props.model === "ticket" ? (
                    <CheckPermissions permissions={[Roles.ADMIN]}>
                        <WhiteButton
                            onClick={() => setShowModal(true)}
                            className="btn-rounded mr-2"
                            variant="contained"
                            color="primary"
                        >
                            {t("export_by_email")}
                        </WhiteButton>
                    </CheckPermissions>
                ) : props.model === "provider_perimeters" ? (
                    <CheckPermissions permissions={[Roles.PROVIDER]}>
                        <WhiteButton
                            onClick={() => {
                                setModal('provider_perimeters')
                                setShowModal(true)
                            }}
                            className="btn-rounded mr-2"
                            variant="contained"
                            color="primary"
                        >
                            {t("config_perimeters")}
                        </WhiteButton>
                    </CheckPermissions>
                ) : props.model !== "vehicle" && props.model !== "driver" && props.model !== "ticket" ? (
                    <WhiteButton
                        onClick={() => history.push(location)}
                        className="btn-rounded"
                        variant="contained"
                        color="primary"
                    >
                        {t("add_a").toUpperCase() +
                        " " +
                        t("entity." + props.model).toUpperCase()}
                    </WhiteButton>
                ) : (
                    ""
                )}
                {modal === "provider_perimeters" ? (
                    <ModalConfigPerimeters
                        isOpen={showModal}
                        closeModal={() => setShowModal(false)}
                    />
                ) : (
                    <ModalSendMailTicket
                        isOpen={showModal}
                        closeModal={() => setShowModal(false)}
                    />
                )}
            </>
        );
    }
;

export default AddAction;
