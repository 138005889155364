import React, { useEffect, useState } from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import KpiTenders from "./kpi-tenders/KpiTenders";
import { User } from "../../models/user";
import { connect } from "react-redux";
import ListTenders from "./list-tenders/ListTenders";
import TransferService from "../../services/transfer-service";

interface IPropsTenderPage {
    user: User;
}

const TenderPage: React.FunctionComponent<IPropsTenderPage> = (props) => {
    const { t } = useTranslation();
    const [kpis, setKpis] = useState({
        to_apply: "-",
        applied: "-",
        accepted: "-",
    });

    const getKpis = async () => {
        try {
            const service = new TransferService();
            setKpis(await service.getKpisTenders());
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getKpis();
    }, []);

    return (
        <>
            <div className="transfer-list">
                <ContentSection
                    title={t("adjucication")}
                    addAction={"provider_perimeters"}
                >
                    <KpiTenders
                        nbApply={kpis.to_apply}
                        nbApplied={kpis.applied}
                        nbAccepted={kpis.accepted}
                    />
                    <ListTenders user={props.user} fetchKpis={getKpis} />
                </ContentSection>
            </div>
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(TenderPage);
