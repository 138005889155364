import React from "react";
import "./Header.scss";
import { User, Roles } from "../../models/user";
import i18next from "i18next";
import { logout } from "../../redux/store/auth/actions";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { AccountCircle, ArrowDropDown } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import { getPrimaryColor } from "../../containers/design-dependencies/ColorHelper";
import MenuNotification from "./MenuNotification/MenuNotification";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import UserService from "../../services/user-service";
import AuthService from "../../services/auth-service";
import { history } from "../../containers/app/App";

interface IHeaderProps {
    user?: User;
}

interface IMapDispatchToProps {
    logout: () => void;
}

type HeaderProps = IHeaderProps & IMapDispatchToProps;

const Header: React.FunctionComponent<HeaderProps> = (props) => {
    let { url } = useRouteMatch();
    const Colors = {
        blue: "#107DE8",
        blueLight: "rgba(17, 126, 234, 0.12)",
        cyan: "#00C6FB",
        red: "#F36666",
        orange: "#FE8456",
        green: "#1ABCB0",
        mint: "#A1E89B",
        yellow: "#FED128",
    };
    const useStyles = makeStyles((theme) => ({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            background: getPrimaryColor(props.user?.role),
        },
        blueGradient: {
            backgroundImage:
                "linear-gradient(to bottom, #1482EF 0%, #0065C9 100%)",
        },
        orangeGradient: {
            backgroundImage:
                "linear-gradient(to bottom, #FF8F63 0%, #FF6F37 100%)",
        },
        yellowGradient: {
            backgroundImage:
                "linear-gradient(to bottom, #FFDD26 0%, #FFBC31 100%)",
        },
        cyanGradient: {
            backgroundImage:
                "linear-gradient(to bottom, #31D0FF 0%, #07A9D9 100%)",
        },
        blueBg: {
            backgroundColor: Colors.blue,
        },
        blueBgLight: {
            backgroundColor: Colors.blueLight,
        },
        blueTextColor: {
            color: "#1757FB",
        },
        cyanBg: {
            backgroundColor: Colors.cyan,
        },
        redBg: {
            backgroundColor: Colors.red,
        },
        orangeBg: {
            backgroundColor: Colors.orange,
        },
        yellowBg: {
            backgroundColor: Colors.yellow,
        },
        greenBg: {
            backgroundColor: Colors.green,
        },
        mintBg: {
            backgroundColor: Colors.mint,
        },
    }));
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const { t } = useTranslation();

    const getLogo = () => {
        if (props.user && props.user.role) {
            if (props.user.role === Roles.ADMIN) {
                return (
                    <img
                        className="header-logo"
                        src="/images/sparklanes-administration.png"
                        alt="sparklanes administration"
                    />
                );
            } else if (props.user.role === Roles.PROVIDER) {
                return (
                    <div className="logo">
                        <img
                            className="header-logo"
                            src="/images/logo-sparklanes-white.png"
                            alt="sparklanes administration"
                        />
                        <span>Prestataire</span>
                    </div>
                );
            } else if (props.user.role === Roles.CLIENT) {
                return (
                    <div className="logo">
                        <img
                            className="header-logo"
                            src="/images/logo-sparklanes-white.png"
                            alt="sparklanes administration"
                        />
                        <span>Client</span>
                    </div>
                );
            }
        }

        return <></>;
    };

    const isClient = () => {
        return props.user && props.user.role === Roles.CLIENT;
    };

    return (
        <AppBar id="header" className={classes.appBar}>
            <Toolbar>
                {getLogo()}

                <div className="appbar-user-actions d-flex ml-auto">
                    <div
                        className="flag-container"
                        onClick={() => updateLanguage("en-EN")}
                    >
                        <div className="lang-flag en" />
                    </div>
                    <div
                        className="flag-container"
                        onClick={() => updateLanguage("fr-FR")}
                    >
                        <div className="lang-flag fr" />
                    </div>
                    {props.user && <MenuNotification user={props.user} />}
                    <Button
                        color={isClient() ? "primary" : "inherit"}
                        className="appbar-user-dropdown"
                        aria-controls="menu-header-account"
                        aria-haspopup="true"
                        onClick={(event: any) =>
                            setAnchorEl(event.currentTarget)
                        }
                    >
                        <div className="user-info-section">
                            <div className="aud-placeholder">
                                <AccountCircle
                                    color={isClient() ? "primary" : "inherit"}
                                />
                            </div>
                            <span className="pseudo-user">
                                {props.user
                                    ? `${props.user.firstname} ${props.user.lastname}`
                                    : ""}
                            </span>
                        </div>
                        <ArrowDropDown
                            className="aud-caret"
                            color={isClient() ? "primary" : "inherit"}
                        />
                    </Button>
                    <div className="sub-menu"></div>
                    <Menu
                        id="menu-header-account"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        className="sub-menu"
                        onClick={()=>setAnchorEl(null)}
                    >
                        <MenuItem
                            onClick={() => setAnchorEl(null)}
                            className="sub-menu-header"
                        >
                            {t("dashboard")}
                        </MenuItem>
                        <MenuItem
                            onClick={() => setAnchorEl(null)}
                            className="sub-menu-header"
                        >
                            <Link className="side-nav-link" to={`${url}/user/informations`}>
                               {t("my_informations")}
                            </Link>
                           
                        </MenuItem>
                        <MenuItem
                            onClick={() => setAnchorEl(null)}
                            className="sub-menu-header"
                        >
                            {t("my_alerts")}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {setAnchorEl(null);}}
                            className="sub-menu-header"
                        >
                            <a
                              className="side-nav-link"
                              onClick={() => logoutUser(props)}
                              href="#logout"
                            >
                                {t("disconnect")}
                            </a>
                        </MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );
};

function updateLanguage(lang: string) {
    i18next.changeLanguage(lang);

    //push chosen language to api
    const service = new UserService();
    service.updateLanguage();
}

function logoutUser(props: HeaderProps) {
    const authService = new AuthService();
    authService.logout().then(() => {
        props.logout();
        let element = document.getElementsByTagName("body");
        if (element) {
            element[0].classList.remove("loaded");
        }
        history.push("/login");
    });
}

const actionCreators = {
    logout,
};

export default connect(null, actionCreators)(Header);
