import React, {useState} from "react";
import IconEdit from "@material-ui/icons/Edit";
import IconClear from "@material-ui/icons/Delete";
import IconCheck from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {ErrorFormData} from "../../../../models/interfaces/errorForm";
import {toastr} from "react-redux-toastr";
import {ErrorForm} from "../../../../components/ErrorForm";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import {Division} from "../../../../models/client";
import DivisionService from "../../../../services/division-service";

interface IItemDivision {
    division: Division;
    onUpdateSuccess: (division: Division) => void;
    onDeleteSuccess: () => void;
}

interface IFormDivision {
    label: string;
}

const ItemColor: React.FunctionComponent<IItemDivision> = ({division, onUpdateSuccess, onDeleteSuccess,}) => {
    const [editing, setIsEditing] = useState<boolean>(false);
    const [disabledForm, setDisabledForm] = useState<boolean>(false);
    const {
        handleSubmit,
        errors,
        control,
        reset,
    } = useForm<IFormDivision>();
    const {t} = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const onSubmit = async (data: any, e: any) => {
        setErrorApi(new ErrorFormData());
        const divisionService = new DivisionService();
        try {
            setDisabledForm(true);
            const editedDivision = await divisionService.update(
                data,
                division.id
            );
            onUpdateSuccess(editedDivision);
            toastr.success(t("success"), t("success_updated_division"));
            setDisabledForm(false);
            cancelEditing();
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    const deleteDivision = async () => {
        setErrorApi(new ErrorFormData());
        const divisionService = new DivisionService();
        try {
            await divisionService.delete(division.id);
            onDeleteSuccess();
            toastr.success(t("success"), t("success_deleted_division"));
            cancelEditing();
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    const cancelEditing = () => {
        setIsEditing(false);
        reset();
    };

    return (
        <>
            {!editing ? (
                <>
                    <td>{division.label}</td>
                    <td className="actions">
                        <IconClear onClick={() => deleteDivision()} className="delete-action"/>
                        <IconEdit onClick={() => setIsEditing(true)} className="edit-action"/>
                        <ErrorForm errorApi={errorApi}/>
                    </td>
                </>
            ) : (
                <>
                    <td className="actions" colSpan={3}>
                        <form
                            className="form_row align_items_center"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <InputControlled
                                name="label"
                                className="edit-item-label mgr1"
                                label={t("label")}
                                value={division.label}
                                required={true}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                            />
                            <ErrorForm errorApi={errorApi}/>

                            <button className="submit_icon" type="submit">
                                <IconCheck/>
                            </button>
                            <CloseIcon onClick={() => cancelEditing()} className="cancel-edit"/>
                        </form>
                    </td>
                </>
            )}
        </>
    );
};

export default ItemColor;
