import {Filter} from "../filter";

interface IDictionary {
    [index: string]: string;
}

export class TicketFilters {
    public types: Array<Filter>;
    public states: Array<Filter>;

    constructor(data: any) {
        this.types = data.types.map((type: Object) => new Filter(type));
        this.states = data.states.map((state: Object) => new Filter(state));
    }
}
