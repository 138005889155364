import axios from "axios";
import { getLocale } from "../services/setting-service";

export async function loadPdf(model: any) {

    if (!model.pdf) {
        throw new Error("Entity has no PDF file to load.");
    }

    let pdfUrl = model.pdf;
    if ('en' === getLocale() && model.pdf_en) {
        pdfUrl = model.pdf_en;
    }

    const response = await axios.get(pdfUrl, {
        responseType: "arraybuffer",
        headers: {
            Accept: "application/pdf",
        },
    });
    let file = new Blob([response.data], {
        type: "application/pdf",
    });
    
    return URL.createObjectURL(file);
}

