import React from "react";
import "./App.scss";
import Login from "../auth/login/Login";
import Register from "../auth/register/Register";
import DemandPassword from "../auth/demand-password/DemandPassword";
import ResetPassword from "../auth/reset-password/ResetPassword";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Provider, connect } from "react-redux";
import { createBrowserHistory } from "history";
import { getUser } from "../../redux/store/auth/actions";
import ReduxToastr from "react-redux-toastr";
import Ui from "../ui/Ui";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import Logged from "./Logged";
import ConfidentialityApp from "../confidentiality-app/ConfidentialityApp";
import ConditionOfUseApp from "../confidentiality-app/ConditionOfUseApp";

const theme = createTheme({
    typography: {
        fontFamily: [
            "Roboto",
            "Arial",
            '"Helvetica Neue"',
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        fontSize: 16,
    },
    overrides: {
        MuiButton: {
            contained: {
                backgroundColor: "#2F2F2F",
                color: "#fff",
                "&:hover": {
                    backgroundColor: "#000",
                },
            },
            containedPrimary: {
                backgroundColor: "#A1E89B",
                "&:hover": {
                    backgroundColor: "#70cc68",
                },
            },
            containedSecondary: {
                backgroundColor: "#F36666",
                "&:hover": {
                    backgroundColor: "#f53737",
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#2F2F2F",
            contrastText: "#fff",
        },
    },
});

interface IAppProps {
    store: any;
}

interface IMapDispatchToProps {
    getUser: () => void;
}

type AppProps = IAppProps & IMapDispatchToProps;

export const history = createBrowserHistory();

class App extends React.Component<AppProps, {}> {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Provider store={this.props.store}>
                    <Router history={history}>
                        <Switch>
                            <Route exact={true} path="/ui">
                                <Ui />
                            </Route>
                            <Route exact={true} path="/login">
                                <Login />
                            </Route>
                            <Route exact={true} path="/confidentiality-app">
                                <ConfidentialityApp />
                            </Route>

                            <Route exact={true} path="/privacy">
                                <ConditionOfUseApp />
                            </Route>

                            <Route exact={true} path="/">
                                <Redirect to="/app" />
                            </Route>

                            <Route path="/app">
                                <Logged />
                            </Route>
                            <Route path="/account/register/:relationName/:token">
                                <Register />
                            </Route>
                            <Route
                                exact={true}
                                path="/account/resetting-password/reset/:relationName"
                            >
                                <DemandPassword />
                            </Route>
                            <Route
                                exact={true}
                                path="/account/resetting-password/:relationName/:token"
                            >
                                <ResetPassword />
                            </Route>
                        </Switch>
                    </Router>
                    <div>
                        <ReduxToastr
                            timeOut={4000}
                            newestOnTop={false}
                            preventDuplicates
                            position="top-right"
                            transitionIn="fadeIn"
                            transitionOut="fadeOut"
                            progressBar
                            closeOnToastrClick
                        />
                    </div>
                </Provider>
            </ThemeProvider>
        );
    }
}

const actionCreators = {
    getUser,
};

export default connect(null, actionCreators)(App);
