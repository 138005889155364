import React from "react";

const MiniMap = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.512"
            height="19.96"
            viewBox="0 0 25.512 19.96"
        >
            <g
                id="placeholder-on-map-paper-in-perspective"
                transform="translate(0.001 -66.585)"
            >
                <path
                    id="Tracé_23752"
                    data-name="Tracé 23752"
                    d="M21.522,77.88l3.928,8.07a.383.383,0,0,1-.373.6H.433a.383.383,0,0,1-.373-.6l3.928-8.07a.455.455,0,0,1,.373-.233H7.729a.472.472,0,0,1,.312.143c.229.264.461.522.693.777s.442.488.662.739h-4a.455.455,0,0,0-.373.233l-2.6,5.346H23.086l-2.6-5.346a.455.455,0,0,0-.373-.233H16.1c.22-.251.441-.5.662-.739s.467-.514.7-.778a.471.471,0,0,1,.311-.143h3.375A.455.455,0,0,1,21.522,77.88ZM18.53,72.365c0,4.409-3.671,5.236-5.4,9.474a.413.413,0,0,1-.765,0c-1.557-3.821-4.7-4.868-5.3-8.272a5.9,5.9,0,0,1,5.116-6.953A5.781,5.781,0,0,1,18.53,72.365Zm-2.727,0a3.052,3.052,0,1,0-3.052,3.052A3.052,3.052,0,0,0,15.8,72.365Z"
                    transform="translate(0)"
                    fill={props.fill}
                />
            </g>
        </svg>
    );
};

export default MiniMap;
