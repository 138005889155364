import React from "react";

import { useTranslation } from "react-i18next";
import { Client } from "../../../models/client";
import ActionTable from "../../../components/action-table/ActionTable";
import LinkArrayNumber from "../../../components/link-array-number/LinkArrayNumber";
import BodyTableRow from "../../../components/table/BodyTableRow";
import BodyTableCell from "../../../components/table/BodyTableCell";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import ClientService from "../../../services/client-service";

interface IItemSubClient {
    client: Client;
    onDeleteSuccess: () => void;
    setClose: () => void;
}

const ItemSubClient: React.FunctionComponent<IItemSubClient> = ({
    client,
    onDeleteSuccess,
    setClose,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const service = new ClientService();

    const deleteClient = async () => {
        await service.delete(client.id);
        toastr.success(t("success"), t("success_deleted_client"));
        onDeleteSuccess();
    };

    return (
        <>
            <BodyTableRow nameClass="subForm">
                <BodyTableCell align="center">
                    {client.company_name}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {client.division?.label}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {client.referent_name}
                </BodyTableCell>
                <BodyTableCell align="center">{client.email}</BodyTableCell>
                <BodyTableCell align="center">
                    {client.getPhoneForList()}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {
                        <div
                            className="hide-sub-account to-upper"
                            onClick={() => setClose()}
                        >
                            {t("back_sub_account")}
                        </div>
                    }
                </BodyTableCell>
                <BodyTableCell align="center">
                    <LinkArrayNumber
                        number={client.nbAddresses}
                        link={`client/${client.id}?tab=adresses`}
                    />
                </BodyTableCell>
                <BodyTableCell align="center">{client.nbFolders}</BodyTableCell>
                {/*<BodyTableCell align="center"></BodyTableCell>
                <BodyTableCell align="center"></BodyTableCell>*/}
                <BodyTableCell align="right">
                    <ActionTable
                        deleteAction={() => deleteClient()}
                        updateAction={() =>
                            history.push(`client/${client.id}`)
                        }
                    />
                </BodyTableCell>
            </BodyTableRow>
        </>
    );
};

export default ItemSubClient;
