import { Deserializable } from "./interfaces/deserializable";

export class VehicleModel implements Deserializable {
    public id: number;
    public label: string;
    public brand_id: number;

    constructor(data:any){
        this.id = data.id;
        this.label = data.label
        this.brand_id = data.brand_id
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}