import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LineEstimate } from "../../../../models/utils/line-estimate/line-estimate";
import "./PreviewingEstimate.scss";
import Line from "../../../../components/previouwing/line/Line";
import Recap from "../../../../components/previouwing/recap/Recap";
import Actions from "../../../../components/previouwing/actions/Actions";
import InfosRecap from "../../../../components/previouwing/infos-recap/InfosRecap";
import { User } from "../../../../models/user";
import ModalAcceptEstimate from "../../../../components/previouwing/modal-accept-estimate/ModalAcceptEstimate";
import ModalDeclineEstimate from "../../../../components/previouwing/modal-decline-estimate/ModalDeclineEstimate";
import ModalPdf from "../../../../components/previouwing/modal-pdf/ModalPdf";
import QuoteService from "../../../../services/quote-service";
import { Quote, RefusalCodes } from "../../../../models/quote";
import { toastr } from "react-redux-toastr";
import { ErrorFormData } from "../../../../models/interfaces/errorForm";
import { ErrorForm } from "../../../../components/ErrorForm";
import { FormLabel, MenuItem, Select } from "@material-ui/core";
import { documentType } from "../../../../models/utils/document-type";
import { loadPdf } from "../../../../utils/PdfReaderHelper";
import ModalCancel from "../../../../components/previouwing/modal-cancel/ModalCancel";
import PaymentService from "../../../../services/payment-service";
import { DataPaginate } from "../../../../models/utils/data-paginate";
import { Payment } from "../../../../models/payment";

interface IProps {
    quote: Quote;
    lines: LineEstimate[];
    user: User;
    setView: (e: any) => void;
    setPassengerMode: (e: any) => void;
    updateQuote: (quote: Quote) => void;
    view: string;
    passengerMode: string;
    updateLine: (line: LineEstimate) => void;
}
export default function PresviewingEstimate({
    quote,
    lines,
    user,
    setView,
    setPassengerMode,
    updateQuote,
    updateLine,
    view,
    passengerMode,
}: IProps) {
    const { t } = useTranslation();
    const [onEdit, setOnEdit] = useState<boolean>(false);
    const [plus, setPlus] = useState<any>({
        display: quote.datas?.display ? quote.datas.display : "",
        transfers: quote.datas?.transfers ? quote.datas.transfers : [],
        deposit: {
            currency: "money",
            amount:
                quote.datas && quote.datas.deposit?.amount
                    ? quote.datas?.deposit.amount
                    : 0,
        },
        reduction: {
            currency: "money",
            amount:
                quote.datas && quote.datas.reduction?.amount
                    ? quote.datas?.reduction.amount
                    : 0,
        },
    });

    const [pdf, setPdf] = useState<any>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showModalAccept, setShowModalAccept] = useState<boolean>(false);
    const [showModalDecline, setShowModalDecline] = useState<boolean>(false);
    const [showModalCancel, setShowModalCancel] = useState<boolean>(false);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [deadline, setDeadline] = useState(false);
    const [hasDepositsPayment, setHasDepositsPayment] = useState(null);

    const fetchPaymentsFolder = async () => {
        const paymentService = new PaymentService();
        const payments = await paymentService.all(
            null,
            null,
            null,
            quote.folder_id
        );
        setHasDepositsPayment(
            payments.data.filter((p: Payment) => p.deposit_id)
        );
    };
    useEffect(() => {
        fetchPaymentsFolder();
    }, []);
    const getDataForm = () => {
        let transfers: any = {}; //use objet to reduce datas size

        lines.map((line) => {
            if (line.isDetail === false) {
                transfers[line.id] = line.sellingPrice;
            }

            return line;
        });

        return {
            ...plus,
            transfers: transfers,
            display: view,
            passengerMode: passengerMode,
        };
    };

    const send = async () => {
        const quoteService = new QuoteService();
        setIsSending(true);
        try {
            await quoteService.send(quote.id, {
                deadline_at: deadline,
                datas: getDataForm(),
            });
            toastr.success(t("success"), t("success_bill_send"));
            setIsSending(false);
            setOnEdit(false);
            updateQuote(quote);
        } catch (error) {
            setIsSending(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    const cancel = async () => {
        const quoteService = new QuoteService();
        setIsSending(true);
        try {
            await quoteService.cancel(quote.id);
            toastr.success(t("success"), t("success_bill_cancel"));
            setIsSending(false);
            updateQuote(quote);
        } catch (error) {
            setIsSending(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    const openPdf = async () => {
        setPdfLoading(true);
        if (quote.pdf) {
            let fileURL = await loadPdf(quote);
            setPdf(fileURL);
            setIsOpen(true);
        } else if (user.isAdmin()) {
            const quoteService = new QuoteService();
            let file = new Blob(
                [
                    await quoteService.update(quote.id, true, {
                        deadline_at: deadline,
                        datas: getDataForm(),
                    }),
                ],
                {
                    type: "application/pdf",
                }
            );
            let fileURL = URL.createObjectURL(file);
            setPdf(fileURL);
            setIsOpen(true);
        }

        setPdfLoading(false);
    };

    const update = async () => {
        setPdfLoading(true);
        const quoteService = new QuoteService();
        await quoteService.update(quote.id, false, {
            deadline_at: deadline,
            datas: getDataForm(),
        })
        setOnEdit(false);
        toastr.success(t('success'), t('success_update_quote'));
        setPdfLoading(false);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const canEditEstimate = () => user.isAdmin();

    const updateSellingPrice = (transferId: number, price: number) => {
        const editedLine = lines.find(
            (line) => line.id === transferId && line.isDetail === false
        );
        if (editedLine) {
            editedLine?.setSellingPrice(price);
            updateLine(editedLine);
        }
    };

    const updateDeadline = (value: any) => {
        setDeadline(value);
        quote.deadline_at = new Date(value);
    };

    return (
        <div className="previewing-section">
            <Actions
                setOnEdit={(e: any) => setOnEdit(e)}
                onEdit={onEdit}
                setView={(e: any) => setView(e)}
                view={view}
                onClickSend={() => send()}
                onClickUpdate={() => update()}
                onClickPdf={() => openPdf()}
                onclickCancel={() => cancel()}
                hasDepositsPayment={hasDepositsPayment}
                setShowModalCancel={() => setShowModalCancel(true)}
                onClickAccept={() => setShowModalAccept(true)}
                onClickDecline={() => setShowModalDecline(true)}
                user={user}
                quote={quote}
                pdfLoading={pdfLoading}
                isSending={isSending}
            />

            {quote.isSent() || user.isAdmin() ? (
                <>
                    {quote.refusal && (
                        <div className="quote-refused">
                            <div className="field-refused">
                                <FormLabel component="legend">
                                    {t("reason_for_refusal")}
                                </FormLabel>
                                {quote.refusal ===
                                    RefusalCodes.FOLDER_CANCELLED && (
                                    <span>{t("request_cancelled")}</span>
                                )}
                                {quote.refusal === RefusalCodes.PRICE_ERROR && (
                                    <span>{t("quotation_too_high")}</span>
                                )}
                                {quote.refusal === RefusalCodes.OTHER && (
                                    <span>{t("others")}</span>
                                )}
                            </div>

                            <div className="field-refused">
                                <FormLabel component="legend">
                                    {t("comment")}
                                </FormLabel>
                                <span>
                                    {quote.comment ? quote.comment : "-"}
                                </span>
                            </div>
                        </div>
                    )}

                    {pdf && isOpen && (
                        <ModalPdf
                            closeDialog={() => closeModal()}
                            pdf={pdf}
                            isOpen={isOpen}
                            code={quote.code}
                            type={documentType.QUOTE}
                        />
                    )}

                    {showModalAccept && (
                        <ModalAcceptEstimate
                            isOpen={showModalAccept}
                            isAdmin={user.isAdmin()}
                            quote={quote}
                            closeDialog={() => setShowModalAccept(false)}
                            onAccept={(quote) => {
                                updateQuote(quote);
                                setShowModalAccept(false);
                            }}
                        />
                    )}
                    {showModalDecline && (
                        <ModalDeclineEstimate
                            isOpen={showModalDecline}
                            isAdmin={user.isAdmin()}
                            quote={quote}
                            closeDialog={() => setShowModalDecline(false)}
                            onDecline={(quote) => {
                                updateQuote(quote);
                                setShowModalDecline(false);
                            }}
                        />
                    )}
                    {showModalCancel && (
                        <ModalCancel
                            isOpen={showModalCancel}
                            closeDialog={() => setShowModalCancel(false)}
                            quote={quote}
                            handleCancel={(quote) => {
                                updateQuote(quote);
                                setShowModalCancel(false);
                            }}
                        />
                    )}

                    {quote?.folder.client && (
                        <InfosRecap
                            code={quote.code}
                            client={quote.folder.client}
                            quoteDate={
                                quote.sent_at ? quote.sent_at : new Date()
                            }
                            dueDate={quote.deadline_at}
                            onEdit={onEdit}
                            onDeadlineChange={updateDeadline}
                            address={quote.folder?.address}
                        />
                    )}

                    <div className="previewing-body">
                        <div className="header-previouwing">
                            <span className="designation">
                                {t("Designation")}
                                {onEdit &&
                                    <span style={{
                                        paddingLeft: 50,
                                    }}>
                                        <span>{t('passenger_display')} : &nbsp;  </span>
                                        <Select
                                            name="level"
                                            style={{
                                                background: '#FFF',
                                                borderRadius: 5,
                                                paddingLeft: 10,
                                                paddingRight: 5,
                                            }}
                                            onChange={(e:any) => {setPassengerMode(e.target.value)}}
                                            defaultValue={passengerMode}
                                            className="grant-selector"
                                        >
                                            <MenuItem value="none">{t('none')}</MenuItem>
                                            <MenuItem value="first">{t('first')}</MenuItem>
                                            <MenuItem value="all">{t('all')}</MenuItem>
                                        </Select>
                                    </span>
                                }
                            </span>
                            <div className="flex-row">
                                {canEditEstimate() ? (
                                    <>
                                        <span
                                            style={{
                                                width: 200,
                                                textAlign: "right",
                                            }}
                                            className="amount"
                                        >
                                            {t("provider_price")}
                                        </span>
                                        <span
                                            style={{
                                                width: 100,
                                                textAlign: "right",
                                            }}
                                            className="amount"
                                        >
                                            {t("selling_price")}
                                        </span>
                                        <span
                                            style={{
                                                width: 100,
                                                textAlign: "right",
                                            }}
                                            className="amount"
                                        >
                                            {t("margin")} (€)
                                        </span>
                                        <span
                                            style={{
                                                width: 100,
                                                textAlign: "right",
                                            }}
                                            className="amount"
                                        >
                                            {t("margin")} (%)
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span
                                            style={{
                                                width: 200,
                                                textAlign: "right",
                                            }}
                                            className="amount"
                                        >
                                            {t("selling_price")}
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>
                        {lines.map((line: LineEstimate, index: number) => (
                            <div key={`previewing-line-${index}`}>
                                <Line
                                    class_name="transfers-lines"
                                    transferId={line.id}
                                    index={line.indexList}
                                    label={line.designation}
                                    value={
                                        line?.prices
                                            ? line.prices[0]?.total?.toFixed(2)
                                            : 0
                                    }
                                    amountMargin={line.amountMargin}
                                    percentMargin={line.percentMargin}
                                    isDetail={line.isDetail}
                                    canEdit={canEditEstimate()}
                                    isEditing={onEdit}
                                    lineIsEditable={line.canEdit}
                                    sellingPrice={line.sellingPrice}
                                    setSellingPrice={(
                                        transferId: number,
                                        price: number
                                    ) => updateSellingPrice(transferId, price)}
                                />
                            </div>
                        ))}
                        <Recap
                            lines={lines}
                            onEdit={onEdit}
                            plus={plus}
                            setPlus={(e: any) => setPlus(e)}
                        />
                    </div>
                </>
            ) : (
                ""
            )}
            <ErrorForm errorApi={errorApi} />
        </div>
    );
}
