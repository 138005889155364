import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ActionTable from "../../../components/action-table/ActionTable";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import BodyTableRow from "../../../components/table/BodyTableRow";
import BodyTableCell from "../../../components/table/BodyTableCell";
import { Folder } from "../../../models/folder";
import FolderService from "../../../services/folder-service";
import { Button } from "@material-ui/core";
import { User, Roles } from "../../../models/user";
import ModalDelete from "../../../components/modal/ModalDelete";
import { format } from "../../../utils/AmountHelper";
import TransferService from "../../../services/transfer-service";
import { Transfer } from "../../../models/transfer";

interface IItemFolder {
    folder: Folder;
    user: User;
    onDeleteSuccess: () => void;
}

function ItemFolder({ folder, user, onDeleteSuccess }: IItemFolder) {
    const history = useHistory();
    const service = new FolderService();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const deleteClient = async () => {
        try {
            await service.delete(folder.id);
            if (folder.cancelled_at !== null) {
                toastr.success(t("success"), t("success_canceled_folder"));
            } else {
                toastr.success(t("success"), t("success_cancelled_folder"));
            }
            onDeleteSuccess();
        } catch (error) {
            console.log(error.response);
        }
    };

    const getTransfersCode = async () => {
        try {
            const service = new TransferService();
            let trans = await service.all(null, null, null, folder.id);
            trans =
                trans.data.length > 0
                    ? trans.data.map((t: Transfer) => t.code)
                    : null;
            console.log("test");
            return trans;
        } catch (error) {
            console.log(error.response);
        }
    };

    function renderItemAdmin() {
        return (
            <>
                <BodyTableRow>
                    <BodyTableCell align="center">
                        <div
                            className="link-table"
                            onClick={() =>
                                history.push(`folder/${folder.id}/edit`)
                            }
                        >
                            {folder.code}
                        </div>
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        <Button
                            style={{
                                backgroundColor: folder.getColorStatus(),
                                paddingLeft: 0,
                                paddingRight: 0,
                                minHeight: 0,
                            }}
                            className="btn-rounded mr-2"
                            variant="contained"
                            onClick={() =>
                                history.push(folder.getLinkByStatus())
                            }
                        >
                            {t("status." + folder.getStatus())}
                        </Button>
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {folder.client?.img_url ? (
                            <img
                                src={folder.client?.img_url}
                                alt=""
                                height="40"
                            />
                        ) : (
                            ""
                        )}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {folder.client?.company_name}
                    </BodyTableCell>
                    <BodyTableCell align="center">{folder.name}</BodyTableCell>
                    <BodyTableCell align="center">
                        {folder?.referent?.getFullName()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {folder?.client?.referent_name}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {folder.start_date?.toLocaleDateString()}
                        <br /> {folder.end_date?.toLocaleDateString()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {folder.transfers ? folder.transfers.length : "-"}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {folder.transfers ? folder.transfers.length : "-"}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {folder.getNumberPassengers()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {format(folder.getTotalTTC())}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {format(folder.getTotalHt())}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {format(folder.getPriceMargin())}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        <Button
                            className="btn-rounded mr-2"
                            variant="contained"
                            onClick={() =>
                                history.push(
                                    `folder/${folder.id}/edit?tab=transfers`
                                )
                            }
                        >
                            {t("view")}
                        </Button>
                    </BodyTableCell>
                    <BodyTableCell align="right">
                        <ActionTable
                            deleteAction={() => setIsOpen(true)}
                            updateAction={() =>
                                history.push(`folder/${folder.id}/edit`)
                            }
                        />
                    </BodyTableCell>
                </BodyTableRow>
                <ModalDelete
                    isOpen={isOpen}
                    closeDialog={() => setIsOpen(false)}
                    itemType="folder"
                    itemCode={folder.code}
                    itemName={folder.name}
                    handleDelete={() => deleteClient()}
                    requestBeforeDisplay={() => getTransfersCode()}
                    isDeleteAction={folder.cancelled_at !== null}
                />
            </>
        );
    }

    function renderItemClient() {
        return (
            <>
                <BodyTableRow>
                    <BodyTableCell align="center">
                        <div
                            className="link-table"
                            onClick={() =>
                                history.push(`folder/${folder.id}/edit`)
                            }
                        >
                            {folder.code}
                        </div>
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        <Button
                            style={{
                                backgroundColor: folder.getColorStatus(
                                    user.role
                                ),
                                paddingLeft: 0,
                                paddingRight: 0,
                                minHeight: 0,
                            }}
                            className="btn-rounded mr-2"
                            variant="contained"
                            onClick={() =>
                                history.push(folder.getLinkByStatus(user.role))
                            }
                        >
                            {t(`status.${user.role}.${folder.getStatus()}`)}
                        </Button>
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {folder.client?.img_url ? (
                            <img
                                src={folder.client?.img_url}
                                alt=""
                                height="40"
                            />
                        ) : (
                            folder.client?.company_name
                        )}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {folder.client?.referent_name}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {folder?.referent?.getFullName()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {folder.client?.division}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {folder.transfers ? folder.transfers.length : 0}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {folder.start_date?.toLocaleDateString()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {folder.end_date?.toLocaleDateString()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {format(folder.getTotalTTC())}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        <Button
                            className="btn-rounded mr-2"
                            variant="contained"
                            onClick={() =>
                                history.push(`folder/${folder.id}/edit`)
                            }
                        >
                            {t("view")}
                        </Button>
                    </BodyTableCell>
                </BodyTableRow>
            </>
        );
    }

    return user.role === Roles.CLIENT ? renderItemClient() : renderItemAdmin();
}

export default ItemFolder;
