import React from "react";
import { useTranslation } from "react-i18next";
import { findError } from "./helpers/ControllerHelper";
import MultiSelect from "react-multi-select-component";
import "./MultiSelectControlled.scss";

interface Iprops {
    list: any;
    errors: any;
    name: string;
    label: string;
    selected: any;
    onChange: (event: any) => void;
}

const MultiSelectControlled = ({
    list,
    errors,
    name,
    label,
    selected,
    onChange,
}: Iprops) => {
    const { t } = useTranslation();
    const options = list.map((language: any) => {
        return {
            label: language.label,
            value: language.id ? language.id : language.label,
            logo: language.iso_code
                ? `https://countryflagsapi.com/png/${language.iso_code}`
                : "../../../public/images/unknow-flag-icon.svg",
        };
    });
    let fieldError = findError(errors, name);
    return (
        <div className="multi-select-section">
            <label className="MuiFormLabel-root">{label}</label>
            <MultiSelect
                options={options}
                value={selected}
                onChange={(event: any) => onChange(event)}
                labelledBy={"Select"}
                className="multi-select"
                disableSearch
                overrideStrings={{
                    selectSomeItems: t("selectSomeItems"),
                    allItemsAreSelected: t("allItemsAreSelected"),
                    selectAll: t("selectAll"),
                    search: t("search"),
                }}
                ItemRenderer={(props: any) => {
                    console.log(props);
                    return (
                        <div
                            style={{
                                paddingBottom: 5,
                                paddingTop: 5,
                                backgroundColor: "white",
                            }}
                        >
                            <input
                                type="checkbox"
                                onChange={props.onClick}
                                checked={props.checked}
                                tabIndex={-1}
                                disabled={props.disabled}
                            />
                            <img
                                style={{
                                    width: 16,
                                    height: 11,
                                    marginTop: 0,
                                    marginBottom: 0,
                                    marginLeft: 20,
                                    marginRight: 20,
                                }}
                                src={props.option.logo}
                            />
                            <span>{props.option.label}</span>
                        </div>
                    );
                }}
            />
            {fieldError && <p>{fieldError.message}</p>}
        </div>
    );
};

export default MultiSelectControlled;
