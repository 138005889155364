import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import AuthService, { AuthModel } from "../../../services/auth-service";
import {
    login,
    loginError,
    loginSuccess,
    getUser,
} from "../../../redux/store/auth/actions";
import { ErrorForm } from "../../../components/ErrorForm";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import "./LoginForm.scss";
import { CircularProgress } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";

type Inputs = {
    email: string;
    password: string;
    interface: string;
};

interface loginProps {
    tab: string;
}

export default function LoginForm({ tab }: loginProps) {
    const { register, handleSubmit, errors } = useForm<Inputs>();
    const dispatch = useDispatch();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [redirection, setRedirection] = useState(false);
    const { t } = useTranslation();
    const [isSubmit, setIsSubmit] = useState(false);
    const [showLabelEmail, setShowLabelEmail] = useState(false);
    const [captchaIsValid, setCaptchaIsValid] = useState(false);
    const msgValidate = t("connect");
    const emailLabel = t("e-mail");
    const passwordLabel = t("password");
    const emailPlaceholder = t("e-mail");
    const emailRequired = t("errors.required", { field: t("email") });
    const passwordRequired = t("errors.required", { field: t("password") });

    const onSubmit = (data: any) => {
        if (captchaIsValid) {
            setIsSubmit(true);
            setErrorApi(new ErrorFormData());
            dispatch(login());
            data.interface = tab;
            const authService = new AuthService();
            authService
                .login(data)
                .then((data: AuthModel) => {
                    setIsSubmit(false);
                    dispatch(loginSuccess(data));
                    toastr.success(t("success"), t("success_connected"));
                    dispatch(getUser());
                    setRedirection(true);
                })
                .catch((err) => {
                    setIsSubmit(false);
                    err.response.status === 422
                        ? toastr.error(t("error"), t("error_wrong_interface"))
                        : setErrorApi(new ErrorFormData(err.response.data));

                    dispatch(loginError(err.response.data));
                });
        } else {
            toastr.error(t("error"), t("error_catpcha"));
        }
    };

    return (
        <form className="form flex_column" onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group email-group">
                <input
                    className="input email"
                    name="email"
                    ref={register({ required: true })}
                    placeholder={emailPlaceholder.toUpperCase()}
                    disabled={isSubmit}
                    onChange={() => {
                        setShowLabelEmail(true);
                    }}
                    autoFocus
                />
                {showLabelEmail ? (
                    <label htmlFor="password"> {emailLabel}</label>
                ) : (
                    ""
                )}
            </div>
            {errors.email && <span className="error">{emailRequired}</span>}
            <div className="input-group email-group">
                <input
                    className="input password"
                    type="password"
                    name="password"
                    ref={register({ required: true })}
                    autoFocus
                    disabled={isSubmit}
                />
                <label htmlFor="password"> {passwordLabel}</label>
            </div>
            {errors.password && (
                <span className="error">{passwordRequired}</span>
            )}
            <Link
                className="forgot_password to-upper"
                to="/account/resetting-password/reset/users"
            >
                {t("reset_password")}
            </Link>
            <ReCAPTCHA
                sitekey={
                    process.env.REACT_APP_RECAPTCHA_V2_KEY
                        ? process.env.REACT_APP_RECAPTCHA_V2_KEY
                        : "BAD_KEY"
                }
                onChange={(value) => setCaptchaIsValid(value !== null)}
            />
            <div className="submit-section">
                {!isSubmit ? (
                    <div className="submit flex_row">
                        <input type="submit" value={msgValidate} />
                        <img
                            className="logo"
                            src="/images/login/icons/login.png"
                            alt=""
                        />
                    </div>
                ) : (
                    <CircularProgress></CircularProgress>
                )}
            </div>
            <ErrorForm errorApi={errorApi} />
            {redirection ? <Redirect to="/app" /> : ""}
            {/* <ReCAPTCHA
                sitekey="Your client site key"
               onChange={onChange}
            /> */}
        </form>
    );
}
