import { FilterType } from "../../../components/filter-column/filterColumn";

export class FiltersEnabled {
    list: Array<FilterEnabled> = [];

    addFilter(key: string, value: any, type: FilterType) {
        if (type === FilterType.Unique) {
            return this.addUniqueFilter(key, value);
        } else if (type === FilterType.Multiple) {
            return this.addMultipleValuesFilter(key, value);
        }
    }

    constructor(filtersEnabled: Array<FilterEnabled> = []) {
        this.list = filtersEnabled;
    }

    private addUniqueFilter(key: string, value: string) {
        const index = this.list.findIndex(
            (item: FilterEnabled, index: number) =>
                item.key === key && item.type === FilterType.Unique
        );
        let newList = [...this.list];
        if (index !== -1) {
            if (value === null) {
                newList.splice(index, 1);
            } else {
                newList[index].value = value;
            }
        } else {
            newList.push(new FilterEnabled(key, value, FilterType.Unique));
        }

        return newList;
    }

    private addMultipleValuesFilter(key: string, values: any) {
        const listWithoutCurrentKey = [
            ...this.list.filter((item) => item.key !== key),
        ];
        const newFilters = values.map(
            (value: any) =>
                new FilterEnabled(key, value.value, FilterType.Multiple)
        );
        return [...listWithoutCurrentKey, ...newFilters];
    }
}

class FilterEnabled {
    public key: string;
    public value: any;
    public type: FilterType;

    constructor(key: string, values: any, type: FilterType) {
        this.key = key;
        this.value = values;
        this.type = type;
    }
}
