import React from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import ListFolders from "./list-folders/ListFolders";
import KpiFolders from "./kpi-folders/KpiFolders";
import { connect } from "react-redux";
import { User } from "../../models/user";

interface IPropsFolderPage {
    user: User;
}

function FolderPage({ user }: IPropsFolderPage) {
    const { t } = useTranslation();

    const canAdd = () => {
        if (user.isClient() && user.isOnlyReader()) {
            return false;
        }
    
        return true;
    }
    
    //Return the appropriate content
    return (
        <>
            {!user.isProvider() && <ContentSection title={t("nav.folders")}
                {... canAdd() ? { addAction: 'folder'} : {}}
            >
                <KpiFolders user={user} />
                <ListFolders user={user} />
            </ContentSection>}
            {user.isProvider() && <h1>Accès interdit !</h1>}
        </>
    );
}

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(FolderPage);
