import axios, { AxiosResponse } from "axios";
import { getBaseUrl, getParamsUrl } from "./setting-service";
import { Vehicle } from "../models/vehicle";
import { DataPaginate } from "../models/utils/data-paginate";
import { VehicleFilters } from "../models/utils/filters/vehicles/vehicle-filters";
import { FiltersEnabled } from "../models/utils/filters/filter-enabled";

export default class VehicleService {
    all(page: number, search?: string | null, filters?: FiltersEnabled) {
        let paramsUrl = "";
        if (filters) {
            paramsUrl = getParamsUrl([
                { key: "page", value: page },
                { key: "search", value: search },
                ...filters.list,
            ]);
        } else {
            paramsUrl = getParamsUrl([
                { key: "page", value: page },
                { key: "search", value: search },
            ]);
        }

        return axios
            .get(`${getBaseUrl()}vehicles${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map(
                    (vehicle: Object) => new Vehicle(vehicle)
                );

                return new DataPaginate(res);
            });
    }

    get(id: number) {
        return axios
            .get(`${getBaseUrl()}vehicles/${id}`)
            .then((response: AxiosResponse) => new Vehicle(response.data));
    }

    create(data: any) {
        return axios
            .post(`${getBaseUrl()}vehicles`, data)
            .then((response: AxiosResponse) => new Vehicle(response.data))
            .catch();
    }

    update(id: number, data: any) {
        return axios
            .put(`${getBaseUrl()}vehicles/${id}`, data)
            .then((response: AxiosResponse) => new Vehicle(response.data))
            .catch();
    }

    getFilter() {
        return axios
            .get(`${getBaseUrl()}vehicles/filters`)
            .then((response: AxiosResponse) => {
                return new VehicleFilters(response.data);
            });
    }

    delete(id: number) {
        return axios.delete(`${getBaseUrl()}vehicles/${id}`).then().catch();
    }

    getKpis(providerId?: number | string | null) {
        const params = providerId ? `?provider_id=${providerId}` : "";
        return axios
            .get(`${getBaseUrl()}vehicles/kpis${params}`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }
}
