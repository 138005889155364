import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {DataPaginate} from "../../../models/utils/data-paginate";
import AccordionBox from "../../../components/accordion-box/AccordionBox";
import {AppBar, Tab} from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Icon from "@material-ui/core/Icon";
import {Division} from "../../../models/client";
import DivisionService from "../../../services/division-service";
import FormDivision from "./form-division/FormDivision";
import ListDivisions from "./list-division/ListDivision";

const ClientDetails: React.FunctionComponent = () => {
    const {t} = useTranslation();
    const [dataPaginateDivisions, setDataPaginateDivisions] = useState(
        new DataPaginate()
    );
    const [pageDivisions, setPageDivisions] = useState(1);
    const [search, setSearch] = useState("");
    const divisionService = new DivisionService();
    const [value, setValue] = React.useState("1");

    useEffect(() => {
        const fetchAllDivisions = async () => {
            try {
                const divisionService = new DivisionService();
                setDataPaginateDivisions(await divisionService.list());
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchAllDivisions();
    }, []);

    async function refreshDivisions(newPage?: number) {
        if (newPage) {
            setPageDivisions(newPage);
        }
        try {
            setDataPaginateDivisions(
                await divisionService.list(
                    null,
                    newPage ? newPage : pageDivisions,
                    search
                )
            );
        } catch (error) {
            console.log("error", error);
        }
    }

    const searchOnEnter = (event: { key: string }) => {
        if (event.key === "Enter") {
            refreshDivisions(1);
        }
    };

    function renderSearch() {
        return (
            <div className="param-search">
                <input
                    placeholder={t("search")}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={searchOnEnter}
                />
                <button onClick={() => refreshDivisions(1)}>
                    <Icon>{"search"}</Icon>
                </button>
            </div>
        );
    }

    const updateDivision = (division: Division) => {
        const newDataPaginate = new DataPaginate(dataPaginateDivisions);
        const newData = dataPaginateDivisions.data.map((data: any) => {
            return data.id === division.id ? division : data;
        });
        newDataPaginate.setData(newData);
        setDataPaginateDivisions(newDataPaginate);
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <AccordionBox
                title={t("client_management").toUpperCase()}
                slotHeader={renderSearch()}
            >
                <div className="flex_row">
                    <div className="flex_column form_box_vehicle">
                        <FormDivision onAddSuccess={() => refreshDivisions(1)}/>
                    </div>

                    <div className="flex_column list_box_client">
                        <TabContext value={value}>
                            <AppBar position="static">
                                <TabList
                                    onChange={handleChange}
                                    aria-label="Client details"
                                >
                                    <Tab label={t("divisions_list")} value="1"/>
                                </TabList>
                            </AppBar>
                            <TabPanel value="1">
                                <ListDivisions
                                    loading={false}
                                    dataPaginate={dataPaginateDivisions}
                                    onPageChange={(page) => refreshDivisions(page)}
                                    onUpdateSuccess={(division) =>
                                        updateDivision(division)
                                    }
                                    onDeleteSuccess={() => refreshDivisions()}
                                />
                            </TabPanel>
                        </TabContext>
                    </div>
                </div>
            </AccordionBox>
        </>
    );
};

export default ClientDetails;
