import React from "react";
import { VehicleFilters } from "../../../models/utils/filters/vehicles/vehicle-filters";
import FilterColumn, {
    FilterType,
} from "../../../components/filter-column/filterColumn";
import { FiltersEnabled } from "../../../models/utils/filters/filter-enabled";
import { useTranslation } from "react-i18next";
import {TextField} from "@material-ui/core";

interface IPropsFiltersVehicles {
    filters?: VehicleFilters;
    filtersEnabled?: FiltersEnabled;
    company_name?:string,
    setFilters: (filters: FiltersEnabled) => void;
}

const FiltersVehicle: React.FunctionComponent<IPropsFiltersVehicles> = ({
    filters,
    filtersEnabled,
    setFilters,
    company_name
}) => {
    const { t } = useTranslation();

    const styles = {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row",
    } as React.CSSProperties;

    const changeValue = (name: string, value: any, type: FilterType) => {
        if (filters && filtersEnabled) {
            const newFiltersEnabled = filtersEnabled.addFilter(
                name,
                value,
                type
            );
            setFilters(new FiltersEnabled(newFiltersEnabled));
        }
    };

    return (
        <div className="filter-std-row" style={styles}>
            {filters ? (
                <>
                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.types}
                        label={t("vehicle_types")}
                        name="vehicle_type_id"
                        type={FilterType.Multiple}
                        hasHover={true}
                    />

                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.brands}
                        label={t("brand")}
                        name="brand_id"
                        type={FilterType.Multiple}
                        hasHover={true}
                    />

                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.models}
                        label={t("model")}
                        name="vehicle_model_id"
                        type={FilterType.Multiple}
                        hasHover={true}
                    />

                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.years}
                        label={t("year")}
                        name="year"
                        type={FilterType.Multiple}
                        width={130}
                        hasHover={true}
                    />

                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.exterior}
                        label={t("exterior_color")}
                        name="exterior_color_id"
                        type={FilterType.Multiple}
                        width={180}
                        hasHover={true}
                    />

                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.interior}
                        name="interior_color_id"
                        label={t("interior_color")}
                        type={FilterType.Multiple}
                        width={180}
                        hasHover={true}
                    />

                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.options}
                        label={"Option"}
                        name="vehicle_option_id"
                        type={FilterType.Multiple}
                        width={250}
                        hasHover={true}
                    />
                    <TextField
                        onChange={(e) =>
                            changeValue("provider", e.currentTarget.value, FilterType.Unique)
                        }
                        placeholder={t('owner')}
                        className="shadow-form-control"
                        type="text"
                        defaultValue={company_name?company_name:""}
                    />
                </>
            ) : (
                ""
            )}
        </div>
    );
};

export default FiltersVehicle;
