import { Deserializable } from "./interfaces/deserializable";
import { Folder } from "./folder";
import { Invoice } from "./invoice";

export class Payment implements Deserializable {
    public id: number;
    public folder_id: number;
    public deposit_id: number;
    public folder: Folder | null;
    public deposit: Deposit | null;
    public invoice_id: number;
    public invoice: Invoice | null;
    public type: string;
    public amount?: number;
    public date?: Date;
    public method?: string;
    public reference?: string;
    public comment?: string;
    public created_by?: number;
    public created_at?: Date;
    public updated_at?: Date;

    constructor(data: any) {
        this.id = data.id;
        this.folder_id = data.folder_id;
        this.deposit_id = data.deposit_id;
        this.folder = data.folder ? new Folder(data.folder) : null;
        this.deposit = data.deposit ? new Deposit(data.deposit) : null;
        this.invoice_id = data.invoice_id;
        this.invoice = data.invoice ? new Invoice(data.invoice) : null;
        this.type = data.type;
        this.amount = data.amount;
        this.date = new Date(data.date);
        this.method = data.method;
        this.reference = data.reference;
        this.comment = data.comment ? data.comment : "";
        this.created_by = data.created_by;
        this.updated_at = data.updated_at;
        this.created_at = data.created_at;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}

export class Deposit implements Deserializable {
    public id: number;
    public folder_id: number;
    public amount: number;
    public created_at: Date;
    public updated_at: Date;
    public pdf?: string;
    public pdf_en?: string;

    constructor(data: any) {
        this.id = data.id;
        this.folder_id = data.folder_id;
        this.amount = data.amount;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.pdf = data.pdf;
        this.pdf_en = data.pdf_en;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
