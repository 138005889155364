import {Filter} from "../filter";

interface IDictionary {
    [index: string]: string;
}

export class BeneficiaryFilters {
    public activated: Array<Filter> = [
        {
            id: 0,
            label: ''
        },
        {
            id: 1,
            label: ''
        }
    ];

    constructor(data: any, t: any) {
        this.activated = this.activated.map((itemActivated: Filter) =>
            new Filter({
                id: itemActivated.id,
                label: itemActivated.label = itemActivated.id === 0
                    ? t("no")
                    : t("yes")
            }));
    }
}
