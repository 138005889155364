import React, { useState, useEffect } from "react";
import Modal from "../../../modal/Modal";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { ExportType } from "../ExportAction";
import CsvService from "../../../../services/csv-service";
import { useTranslation } from "react-i18next";
import "./DialogExport.scss";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Button } from "@material-ui/core";
import SeparatorVertical from "../../../separator-vertical/separatorVertical";

interface IDialogExport {
    isOpen: boolean;
    exportType: ExportType;
    title?: string;
    closeDialog: () => void;
}

const DialogExport: React.FunctionComponent<IDialogExport> = ({
    isOpen,
    exportType,
    title = "Exporter",
    closeDialog,
}: IDialogExport) => {
    const { t } = useTranslation();
    const [data, setData] = useState<any>([]);
    const [error, setError] = useState<string>();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const csvService = new CsvService();
                const data = await csvService.getData(exportType, t);
                setData(data);
            } catch (error) {
                setError(t("unexpected_error"));
            }
        };
        fetchData();
    }, [exportType, t]);

    const exportExcel = () => {
        const ws = XLSX.utils.aoa_to_sheet(data);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, getFilename());
        XLSX.writeFile(wb, `${getFilename()}.xlsx`);
    };

    const getFilename = () => {
        let filename;
        switch (exportType) {
            case ExportType.Provider:
                filename = "providers";
                break;
            case ExportType.Client:
                filename = "clients";
                break;
            case ExportType.Ticket:
                filename = "tickets";
                break;
            default:
                filename = "export";
        }
        return filename.toLowerCase();
    };

    return (
        <Modal label={title} isOpen={isOpen} closeModal={() => closeDialog()}>
            <div className="content-modal-export">
                {data && data.length ? (
                    <>
                        <div className="flex-stretch-row">
                            <div className="flex-column item-export">
                                <div className="title-modal">
                                    {t("export_csv")}
                                </div>

                                <CSVLink
                                    filename={`${getFilename()}.csv`}
                                    data={data}
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        className="btn-form btn-icon mr-2"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        <span>{t("export_csv")}</span>{" "}
                                        <GetAppIcon />
                                    </Button>
                                </CSVLink>
                            </div>

                            <SeparatorVertical />

                            <div className="flex-column item-export">
                                <div className="title-modal">
                                    {t("export_excel")}
                                </div>
                                <Button
                                    onClick={() => exportExcel()}
                                    className="btn-form btn-icon mr-2"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                >
                                    <span>{t("export_excel")}</span>{" "}
                                    <GetAppIcon />
                                </Button>
                            </div>
                        </div>
                    </>
                ) : error ? (
                    error
                ) : (
                    t("loading")
                )}
            </div>
        </Modal>
    );
};

export default DialogExport;
