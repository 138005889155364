import React from "react";
import {useTranslation} from "react-i18next";
import AutocompleteControlled from "../../../../components/fields-controlled/AutocompleteControlled";
import { Delete } from "@material-ui/icons";
import { Beneficiary } from "../../../../models/beneficiary";
import RoundedImage from "../../../../components/rounded-image/RoundedImage";

interface Iprops {
    values: Beneficiary,
    beneficiariesList: Beneficiary[];
    index: number,
    disabled: boolean,
    control: any;
    errors: any;
    onUpdate: (index: number, beneficiary_id: number) => void;
    onDelete: (index: number) => void
}

const SubFormBeneficiary = ({values, beneficiariesList ,index, control, errors, disabled, onUpdate, onDelete}: Iprops) => {

    const { t } = useTranslation();
    const updateSelected = (value: Beneficiary) => {
        onUpdate(index, value.id);
    } 
    
    let fieldPrefix = 'beneficiaries[' + index + ']';

    return <>
        <div className='form-part-align-center'>
            <div className="field-form mgl4">
                <AutocompleteControlled 
                    label={t('form.beneficiary')}
                    name={fieldPrefix + '.beneficiary'}
                    disabled={disabled}
                    listItems={beneficiariesList}
                    value={values ? values : null}
                    required={true}
                    getOptionLabel={(option: any) => option.name}
                    control={control}
                    errors={errors}
                    onChange={(value) => updateSelected(value) }
                />
            </div>
            <div className="field-form mgl4">
                <RoundedImage image_url={values ? values.logo : undefined} />
            </div>
            <div className="field-form mgl4">
            {!disabled &&
                <Delete style={{cursor: 'pointer'}} onClick={() => onDelete(index)} />
            }
            </div>
        </div>
    </>;
}

export default SubFormBeneficiary;