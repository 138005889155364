import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import "./ActionTable.scss";
import { Check, FileCopy } from "@material-ui/icons";
import {ReactSVG} from "react-svg";

interface IPropsActionTable {
    updateAction?: () => void;
    deleteAction?: () => void;
    addAction?: () => void;
    duplicateAction?: () => void;
    validateAction?: () => void;
    replyAction?: () => void;
    alreadyOpen?: boolean;
}
const ActionTable: React.FunctionComponent<IPropsActionTable> = (props) => {
    const {
        updateAction,
        deleteAction,
        addAction,
        duplicateAction,
        validateAction,
        replyAction,
        alreadyOpen = false,
    } = props;
    const [isOpen, setIsOpen] = useState<boolean>(alreadyOpen);

    return (
        <div
            className="section-actions-table"
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(alreadyOpen ? true : false)}
        >
            {!isOpen && (
                <img src="/images/table/action-table.png" alt="Action" />
            )}
            {isOpen && (
                <div className="actions-table">
                    {deleteAction && (
                        <div className="icon delete">
                            <HighlightOffIcon
                                onClick={() => deleteAction()}
                                style={{ color: "#FFF" }}
                            />
                        </div>
                    )}
                    {addAction && (
                        <div className="icon edit">
                            <AddIcon
                                onClick={() => addAction()}
                                style={{ color: "#A2EFA2" }}
                            />
                        </div>
                    )}
                    {updateAction && (
                        <div className="icon edit">
                            <EditIcon
                                onClick={() => updateAction()}
                                style={{ color: "#A2EFA2" }}
                            />
                        </div>
                    )}
                    {validateAction && (
                        <div className="icon edit">
                            <Check
                                onClick={() => validateAction()}
                                style={{ color: "#A2EFA2" }}
                            />
                        </div>
                    )}
                    {duplicateAction && (
                        <div className="icon duplicate">
                            <FileCopy
                                onClick={() => duplicateAction()}
                                style={{ color: "white" }}
                            />
                        </div>
                    )}
                    {replyAction && (
                        <div className="icon reply">
                            <ReactSVG
                                src="/images/support/icons/reply.svg"
                                onClick={() => replyAction()}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ActionTable;
