import React, { useState, useEffect } from "react";

import {
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Paper from "@material-ui/core/Paper";
import { DataPaginate } from "../../../../models/utils/data-paginate";
import Pagination from "../../../../components/pagination/pagination";
import ClientService from "../../../../services/client-service";
import ItemAddress from "./ItemAddress";
import { Address } from "../../../../models/address";
import HeadTableCell from "../../../../components/table/HeadTableCell";
import { Client } from "../../../../models/client";

interface IFormProps {
    client: Client;
    refreshClient: () => void;
}

const ListAddresses = ({ client, refreshClient }: IFormProps) => {
    const { t } = useTranslation();
    const [dataPaginate, setDataPaginate] = useState(new DataPaginate());
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const dataService = new ClientService();
    useEffect(() => {
        const fetchDatas = async () => {
            try {
                setDataPaginate(
                    await dataService.addresses(client.id, currentPage)
                );
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log("error", error);
            }
        };

        fetchDatas();
    }, [currentPage, client]);

    function onPageChange(newPage?: number) {
        if (newPage) {
            setLoading(true);
            setCurrentPage(newPage);
        }
    }
    return (
        <>
            {dataPaginate?.data && dataPaginate.data.length !== 0 ? (
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <HeadTableCell align="center">
                                        {t("form.name")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("form.street")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("form.misc")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("form.post_code")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("form.city")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("form.country")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center"></HeadTableCell>
                                    <HeadTableCell align="center"></HeadTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataPaginate.data.map((address: Address) => (
                                    <ItemAddress
                                        refreshClient={() =>
                                            refreshClient
                                                ? refreshClient()
                                                : null
                                        }
                                        key={`table_address_id_${address.id}`}
                                        address={address}
                                        client={client}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        dataPaginate={dataPaginate}
                        onPageChange={(page) => onPageChange(page)}
                    />
                </>
            ) : loading ? (
                <CircularProgress />
            ) : (
                <div>{t("no_items")}</div>
            )}
        </>
    );
};

export default ListAddresses;
