import { Deserializable } from "./interfaces/deserializable";
import { Folder } from "./folder";

export enum RefusalCodes {
    PRICE_ERROR = "price_error",
    FOLDER_CANCELLED = "folder_cancelled",
    OTHER = "other",
}

export class Quote implements Deserializable {
    public id: number;
    public folder_id: number;
    public sent_at?: Date | null;
    public code: string;
    public comment?: string;
    public pdf?: string;
    public pdf_en?: string;
    public refusal: string;
    public status: string;
    public folder: Folder;
    public created_by: number;
    public created_at: Date;
    public updated_at: Date;
    public responded_at?: Date;
    public responded_by?: number;
    public deadline_at?: Date;
    public price_excl_tax?: number;
    public price_incl_tax?: number;
    public datas?: {
        deposit: {
            currency: "";
            amount: "";
        };
        display: "";
        passengerMode: "";
        reduction: {
            currency: "";
            amount: "";
        };
        transfers: any[];
    };

    constructor(data: any) {
        this.id = data.id;
        this.folder_id = data.folder_id;
        this.sent_at = (data.sent_at !== null) ? new Date(data.sent_at) : null;
        this.code = data.code;
        this.comment = data.comment;
        this.pdf = data.pdf;
        this.pdf_en = data.pdf_en;
        this.refusal = data.refusal;
        this.folder = new Folder(data.folder);
        this.status = data.status;
        this.created_by = data.created_by;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        if (data.responded_at) {
            this.responded_at = new Date(data.responded_at);
        }
        this.responded_by = data.responded_by;
        this.datas = data.datas;
        this.deadline_at = new Date(data.deadline_at);
        this.price_excl_tax = data.price_excl_tax;
        this.price_incl_tax = data.price_incl_tax;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    isDraft() {
        return this.status === 'draft';
    }

    waitingResponse() {
        return this.status === "sent";
    }

    isSent() {
        return this.sent_at !== null;
    }

    canBeCancelled() {
        return this.isSent()
            && this.status !== 'refused'
            && this.status !== 'cancelled'
            && this.folder.status !== 'in_progress'
            && this.folder.status !== 'to_invoice'
            && this.folder.status !== 'ended'
    }

    canCreateNew(){
        return this.status === 'cancelled'
            || this.status === "refused"
    }

    getColorByStatus() {
        if (
            this.status === "draft" ||
            this.status === "to_send" ||
            this.status === "sent"
        ) {
            return "#1abcb0";
        } else if (this.status === "refused") {
            return "#FF6106";
        } else if (this.status === "accepted") {
            return "#A1E89B";
        } else if (this.status === "cancelled") {
            return "#F36666";
        }

        return "#ccc";
    }
}
