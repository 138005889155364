import {TicketType} from "../../../models/ticket";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {ErrorFormData} from "../../../models/interfaces/errorForm";
import TicketService from "../../../services/ticket-service";
import FormSection from "../../../components/form-section/FormSection";
import './FormSubmitTicket.scss';
import CountryPhoneControlled from "../../../components/fields-controlled/CountryPhoneControlled";
import {Button, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import InputControlled from "../../../components/fields-controlled/InputControlled";
import SubmitComponent from "../../../components/submit-component/SubmitComponent";
import {ErrorForm} from "../../../components/ErrorForm";
import {Editor, RawDraftContentState} from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {toastr} from "react-redux-toastr";
import draftToHtml from 'draftjs-to-html';
import {connect} from "react-redux";
import {User} from "../../../models/user";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";


interface IPropsFormSubmitTicket {
    title: string;
    description: string;
    type: TicketType;
    phone_number: string;
    email: string;
    img_base64: any;
}

interface IProps {
    user: User;
}

function FormSubmitTicket({user}: IProps) {
    const {t} = useTranslation();
    const history = useHistory();
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        control,
    } = useForm<IPropsFormSubmitTicket>();
    const [errorApi, setErrorApi] = useState<ErrorFormData>(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');
    const [isActivateButton,setIsActivateButton]=useState(true)
    const [type, setType] = useState<TicketType>(TicketType.TECHNICAL);
    const [file, setFile] = useState<any>();

    const onSubmit = async (data: any, e: any) => {
        setLoadingSubmit(true);
        setDisabledForm(true);
        setIsActivateButton(false)
        const ticketService = new TicketService();
        // We add the type and desc to the data object
        const newData = {...data, 'type': type, 'description': description};
        try {
            await ticketService.post(newData);
            toastr.success(t("success"), t("success_add_ticket"));
            setLoadingSubmit(false);
            history.push(`/app/support`);
        } catch (e) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(e.response.data));
            setLoadingSubmit(false);
        }
        setIsActivateButton(true)
    }

    const onInternalChange = (currentContentState: RawDraftContentState) => {
        setDescription(draftToHtml(currentContentState));
    };

    const setImgBase64 = (files: any) => {
        if (files.length === 0) return;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        setFile(files[0]);
        reader.onload = (_event) =>
            reader.result ? setValue("img_base64", reader.result) : null;
    }

    const resetFile = () => {
        setValue("img_base64", null);
        setFile(undefined);
    }

    return (
        <>
            <form className="form-submit-ticket" onSubmit={handleSubmit(onSubmit)}>
                <div className="first-row-submit-problem">
                    <FormSection title={t("title_and_description_problem")} className="mgl2">
                        <div className="field-form mgl4 mgb1">
                            <InputControlled
                                label={`${t('form.title')} *`}
                                value=""
                                required={true}
                                disabled={disabledForm}
                                name="title"
                                errors={errors}
                                control={control}
                            />
                        </div>
                        <div className="field-form mgl4">
                            <FormLabel>{`${t("form.description")} *`} </FormLabel>
                            <Editor
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onChange={onInternalChange}
                            />
                        </div>
                    </FormSection>
                    <FormSection title={t("categories")}>
                        <div className="field-form provider-type mgt1 mgl2">
                            <span className="label-form">{t("form.type")}</span>
                            <div className="checkboxs-section">
                                <RadioGroup
                                    row
                                    defaultValue={TicketType.TECHNICAL}
                                >
                                    {Object.values(TicketType).map((type, index) =>
                                        <FormControlLabel
                                            key={index}
                                            value={type}
                                            control={
                                                <Radio onChange={() => setType(type)}/>
                                            } label={t(`ticket_type.${type}`)}/>
                                    )}
                                </RadioGroup>
                            </div>
                        </div>
                    </FormSection>
                </div>
                <FormSection title={""}>
                    <div className="field-form mgl2 col-2">
                        <FormLabel>{`${t("form.phone")} *`} </FormLabel>
                        <CountryPhoneControlled
                            name="phone_number"
                            label={t("form.phone")}
                            value={""}
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                            {...register('phone_number')}
                        />
                    </div>
                    <div className="field-form mgl2 col-2">
                        <InputControlled
                            name="email"
                            label={`${t("form.email")} *`}
                            value={user.email}
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>
                </FormSection>
                <FormSection title={t("various")}>
                    <div className="field-form">
                        <Button
                            variant="contained"
                            component="label"
                        >
                            {t('choose_a_file')}
                            <input type="hidden" name="img_base64" ref={register()}/>
                            <input
                                type="file"
                                hidden
                                onChange={(e) => setImgBase64(e.target.files)}
                            />
                        </Button>
                    </div>
                    {file && file.name && <div className="file-selected">
                        <label>{file.name}</label>
                        <ReactSVG className="icon-delete" src="/images/app_icons/icon-delete.svg" onClick={() => resetFile() } />
                    </div>}
                </FormSection>
                <SubmitComponent
                    loading={loadingSubmit}
                    text={t("form.validate")}
                    isActivate={isActivateButton}
                />
                <ErrorForm errorApi={errorApi}/>
            </form>
        </>
    )
}

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(FormSubmitTicket);
