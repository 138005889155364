import { Incident } from "../../incident";
import { Price, IPrice } from "./price";

export class LineEstimate {
    id: number;
    indexList?: number;
    designation: string;
    prices?: Price[];
    isDetail?: boolean;
    sellingPrice?: number;
    amountMargin?: number;
    percentMargin?: number;
    canEdit: boolean = true;
    rateTva?: number;
    incidents: Incident[] | null = null;

    constructor(id: number, indexList: number, designation: string, isDetail = true, prices: Price[] = [], sellingPrice?: number, canEdit?: boolean, rateTva?: number){
        this.id = id;
        this.indexList = indexList;
        this.designation = designation;
        this.isDetail = isDetail;
        this.prices = prices.map( (price:IPrice) => new Price(price));
        if(sellingPrice){
            this.setSellingPrice(sellingPrice);
        }
        if(canEdit !== undefined){
            this.canEdit = canEdit;
        }
        if(rateTva){
            this.rateTva = rateTva;
        } else {
            this.rateTva = isDetail === false ? 20 : undefined;
        }
    }

    setSellingPrice(sellingPrice: number){
        this.sellingPrice = sellingPrice;
        const price = this.prices && this.prices.length ? this.prices?.map(price => Number(price.total)).reduce((acc, cur) => acc + cur) : 0;
        this.amountMargin = sellingPrice - price;
        this.percentMargin = price === 0 ? 100 : (this.amountMargin / price) * 100;
    }

    setIncidents(incidents: Incident[]){
        this.incidents = incidents;
    }
}

