import React, { useEffect, useState } from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import ListTransfers from "./list-transfers/ListTransfers";
import KpiTransfers from "./kpi-transfers/KpiTransfers";
import { ExportType } from "../../components/content-section/export-action/ExportAction";
import { useHistory, useParams } from "react-router-dom";
import FolderService from "../../services/folder-service";
import { Folder } from "../../models/folder";
import { Provider } from "../../models/provider";
import WhiteButton from "../../components/white-button/WhiteButton";
import ModalAddTransfers from "./modal-add-transfers/ModalAddTransfers";
import CheckPermissions from "../../utils/CheckPermissions";
import { Roles, User } from "../../models/user";
import KpiTransfersProvider from "./kpi-transfers-provider/KpiTransfersProvider";
import { connect } from "react-redux";
import KpiTransfersClient from "./kpi-transfers-client/KpiTransfersClient";
import ProviderService from "../../services/provider-service";

interface IPropsTransferPagePage {
    user: User;
}

const TransferPage: React.FunctionComponent<IPropsTransferPagePage> = (
    props
) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { location } = useHistory();
    const [datas, setDatas] = useState<Folder>();
    const [provider, setProvider] = useState<Provider>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [nbImports, setNbImports] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                try {
                    // Check if we list the transfers of a folder or transfers of a provider
                    if (location.pathname.includes("provider")) {
                        const providerService = new ProviderService();
                        setProvider(await providerService.get(id));
                    } else {
                        const dataService = new FolderService();
                        setDatas(await dataService.get(id));
                    }
                } catch (error) {
                    console.log("error", error);
                }
            }
        };

        fetchData();
    }, [id, location.pathname]);

    const canAdd = () => {
        if (props.user.isProvider()) {
            return false;
        }
        if (props.user.isClient()) {
            if (!id && props.user.isOnlyReader()) {
                //global list
                return false;
            }
            if (id && datas?.client && props.user.isReader(datas.client)) {
                //specific folder
                return false;
            }
        }

        return true;
    };

    const renderActionHeader = () => {
        return !datas || !canAdd() ? (
            <></>
        ) : (
            <WhiteButton
                onClick={() => setShowModal(true)}
                className="btn-rounded"
                variant="contained"
                color="primary"
            >
                {t("add_a_transfer")}
            </WhiteButton>
        );
    };

    //Return the appropriate content
    return (
        <>
            {!id && location.pathname.includes("transfers/history") ? (
                <div className="transfer-list">
                    <ContentSection
                        title={t("transfers_history")}
                        suppContent={renderActionHeader()}
                    >
                        <CheckPermissions permissions={[Roles.PROVIDER]}>
                            <KpiTransfersProvider providerId={props.user.id} />
                        </CheckPermissions>
                        <ListTransfers
                            providerId={props.user.provider?.id}
                            addTransfer={() => null}
                            nbImports={nbImports}
                            user={props.user}
                        />
                    </ContentSection>
                </div>
            ) : id && location.pathname.includes("provider") ? (
                <div className="transfer-list">
                    <ContentSection
                        title={
                            t("provider_history") +
                            (provider ? " : " + provider?.company_name : "")
                        }
                        suppContent={renderActionHeader()}
                    >
                        <CheckPermissions permissions={[Roles.ADMIN]}>
                            <KpiTransfers providerId={id} />
                        </CheckPermissions>{" "}
                        <ListTransfers
                            providerId={id}
                            addTransfer={() => null}
                            nbImports={nbImports}
                            user={props.user}
                        />
                    </ContentSection>
                </div>
            ) : id ? (
                <div className="transfer-list">
                    <ContentSection
                        title={
                            t("transfer_disposition") +
                            (datas ? " : " + datas?.code : "")
                        }
                        suppContent={renderActionHeader()}
                    >
                        <KpiTransfers folderId={id} />
                        <ListTransfers
                            folderId={id}
                            addTransfer={() => setShowModal(true)}
                            nbImports={nbImports}
                            user={props.user}
                        />
                    </ContentSection>
                </div>
            ) : (
                <div className="transfer-list">
                    <ContentSection
                        title={t("transfer_disposition")}
                        exportType={ExportType.Transfer}
                        addAction={!canAdd() ? undefined : "transfer"}
                    >
                        <CheckPermissions permissions={[Roles.ADMIN]}>
                            <KpiTransfers />
                        </CheckPermissions>
                        <CheckPermissions permissions={[Roles.PROVIDER]}>
                            <KpiTransfersProvider />
                        </CheckPermissions>
                        <CheckPermissions permissions={[Roles.CLIENT]}>
                            <KpiTransfersClient />
                        </CheckPermissions>
                        <ListTransfers
                            addTransfer={() => null}
                            nbImports={nbImports}
                            user={props.user}
                        />
                    </ContentSection>
                </div>
            )}
            <ModalAddTransfers
                folderId={id}
                isOpen={showModal}
                closeModal={() => setShowModal(false)}
                importSuccess={() => setNbImports(nbImports + 1)}
            />
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(TransferPage);
