import React from "react";
import {useTranslation} from "react-i18next";
import FilterColumn, {
    FilterType,
} from "../../../components/filter-column/filterColumn";
import {FiltersEnabled} from "../../../models/utils/filters/filter-enabled";
import {BeneficiaryFilters} from "../../../models/utils/filters/beneficiaries/beneficiary-filters";

interface IPropsFiltersBeneficiaries {
    filters?: BeneficiaryFilters;
    filtersEnabled?: FiltersEnabled;
    setFilters: (filters: FiltersEnabled) => void;
}

const FiltersBeneficiaries = ({
                            filters,
                            filtersEnabled,
                            setFilters,
                        }: IPropsFiltersBeneficiaries) => {
    const {t} = useTranslation();
    const styles = {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row",
    } as React.CSSProperties;

    const changeValue = (name: string, value: any, type: FilterType) => {
        if (filters && filtersEnabled) {
            const newFiltersEnabled = filtersEnabled.addFilter(
                name,
                value === t("no") ? '0' : value,
                type
            );
            setFilters(new FiltersEnabled(newFiltersEnabled));
        }
    };
    return (
        <div className="filter-std-row" style={styles}>
            {filters && (
                <>
                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.activated}
                        label={t("activated")}
                        name="activated"
                        type={FilterType.Unique}
                    />
                </>
            )}
        </div>
    );
};

export default FiltersBeneficiaries;
