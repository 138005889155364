import { Driver } from "./driver";
import { Deserializable } from "./interfaces/deserializable";
import { Provider } from "./provider";
import { TransferResponse } from "./transfer_response";

export class Assignment implements Deserializable {
    public id: number;
    public provider_id: number;
    public response_date: Date;
    public status: string;
    public driver_id: number;
    public transfer_id: number;
    public created_at: Date;
    public updated_at: Date;
    public response?: TransferResponse;
    public provider?: Provider;
    public driver?: Driver;

    constructor(data: any) {
        this.id = data.id;
        this.provider_id = data.provider_id;
        this.response_date = new Date(data.response_date);
        this.status = data.status;
        this.driver_id = data.driver_id;
        this.transfer_id = data.transfer_id;
        if (data.response) {
            this.response = new TransferResponse(data?.response);
        }
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);

        if (data.provider) {
            this.provider = new Provider(data.provider);
        }
        if (data.driver) {
            this.driver = new Driver(data.driver);
        }
    }

    isPending() {
        return this.status === "pending";
    }

    isAccepted() {
        return this.status === "accepted";
    }

    deserialize(input: any): this {
        const assignment = Object.assign(this, input);
        if (input.response) {
            assignment.response = new TransferResponse(input.response);
        }

        return assignment;
    }

    getColorStatus(): string {
        let color = "transparent";
        switch (this.status) {
            case "pending":
                color = "#00c6fb";
                break;
            case "accepted":
                color = "#EEAF13";
                break;
            case "refused":
            case "":
                color = "#FF6106";
        }
        return color;
    }
}
