import React from "react";
import { Dialog, Slide } from "@material-ui/core";
import "./Modal.scss";
import { TransitionProps } from "@material-ui/core/transitions/transition";

interface IModal {
    label: string;
    isOpen: boolean;
    closeModal: () => void;
    children?: any;
    maxWidth?: any;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const Modal = (props: IModal) => {
    const { label, isOpen, closeModal, children, maxWidth = "md" } = props;

    return (
        <Dialog
            onClose={closeModal}
            TransitionComponent={Transition}
            aria-labelledby="export-dialog"
            open={isOpen}
            className="modal"
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth={maxWidth}
        >
            <div className="header-modal">
                <h2>{label}</h2>

                <span onClick={() => closeModal()}>
                    <span className="material-icons">close</span>
                </span>
            </div>
            {children}
        </Dialog>
    );
};

export default Modal;
