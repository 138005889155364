import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AccordionBox from "../../../components/accordion-box/AccordionBox";
import FormTitle from "../../../components/form-title/FormTitle";
import "./Languages.scss";
import Paper from "@material-ui/core/Paper";
import {
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import HeadTableCell from "../../../components/table/HeadTableCell";
import Table from "@material-ui/core/Table";
import BodyTableCell from "../../../components/table/BodyTableCell";
import IconClear from "@material-ui/icons/Delete";
import { toastr } from "react-redux-toastr";
import FormLanguages from "./form-langauges/FormLanguages";
import LanguageService from "../../../services/language-service";
import { Language } from "../../../models/language";

const Languages: React.FunctionComponent = (props) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);

    const languageService = new LanguageService();
    const fetchGetLanguages = async () => {
        try {
            setData(await languageService.all());
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        fetchGetLanguages();
    }, []);

    async function deleteLanguage(id: number) {
        try {
            await languageService.delete(id);
            toastr.success(t("success"), t("success_deleted_language"));
            fetchGetLanguages();
        } catch (error) {
            toastr.success(t("error"), t("unexpected_error"));
        }
    }

    function renderLanguages() {
        return data ? (
            <TableContainer
                component={Paper}
                style={{ width: "100%" }}
                className="small-table-scroll"
            >
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <HeadTableCell className="center" align="center">
                                {t("code")}
                            </HeadTableCell>
                            <HeadTableCell className="testTh" align="center">
                                {t("language")}
                            </HeadTableCell>
                            <HeadTableCell
                                className="testTh"
                                align="center"
                            ></HeadTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((language: Language) => {
                            return (
                                <TableRow key={`language_id_${language.id}`}>
                                    <BodyTableCell
                                        align="center"
                                        className="to-upper"
                                    >
                                        {language.iso_code}
                                    </BodyTableCell>
                                    <BodyTableCell align="center">
                                        {language.label}
                                    </BodyTableCell>
                                    <BodyTableCell
                                        align="center"
                                        className="to-upper"
                                    >
                                        <IconClear
                                            onClick={() =>
                                                deleteLanguage(language.id)
                                            }
                                            className="delete-action"
                                        />
                                    </BodyTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        ) : (
            ""
        );
    }

    return (
        <>
            <AccordionBox title={t("nav.languages").toUpperCase()}>
                <div className="flex_row">
                    <div className="flex_column big_box">
                        <FormLanguages
                            onAddLanguageSuccess={() => fetchGetLanguages()}
                        />
                    </div>

                    <div className="flex_column small_box no-pad">
                        <FormTitle title={t("language_list")} />
                        {renderLanguages()}
                    </div>
                </div>
            </AccordionBox>
        </>
    );
};

export default Languages;
