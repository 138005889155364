import React from "react";
import { useTranslation } from "react-i18next";
import { IndicatorType } from "../../../components/indicator/Indicator";
import UseStyles from "../../design-dependencies/UseStyles";
import NewIndicator from "../../../components/indicator/NewIndicator";

interface PropsKpisProvider {
  nbProviders: number;
  nbTenders: string;
}

const KpiProviders = ({ nbProviders, nbTenders }: PropsKpisProvider) => {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
        <NewIndicator
          className={UseStyles().blueGradient}
          index={0}
          text={t("providers")}
          type={IndicatorType.Provider}
          total={nbProviders}
        />
      </div>
      <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
        <NewIndicator
          className={UseStyles().greenBg}
          index={1}
          text={t("is_tender")}
          type={IndicatorType.Provider}
          total={nbTenders}
        />
      </div>
    </div>
  );
};

export default KpiProviders;
