import React from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import { User } from "../../models/user";
import { connect } from "react-redux";
import ListCredits from "./list-credits/ListCredits";

interface IPropsInvoicePage {
    user: User;
}

const CreditPage = ({ user }: IPropsInvoicePage) => {
    const { t } = useTranslation();

    //Return the appropriate content
    return (
        <>
            <div className="invoice-list">
                <ContentSection title={t("nav.credits")}>
                    <ListCredits />
                </ContentSection>
            </div>
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(CreditPage);
