import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../modal/Modal";

interface IPropsModalWantCancelled {
    isOpen: boolean;
    goBack: () => void;
    closeModal: () => void;
}

const ModalWantCancelled: React.FunctionComponent<IPropsModalWantCancelled> = (
    props
) => {
    const { t } = useTranslation();
    const { isOpen, goBack } = props;

    return (
        <Modal
            label={t("status.cancelled")}
            isOpen={isOpen}
            closeModal={() => props.closeModal()}
        >
            <div className="container-modal-back">
                <div className="text-back">
                    <p className="title">{t("lost_input")}</p>
                    <p className="content">{t("sure_to_back")}</p>
                </div>
            </div>
            <button className="delete-modale-action" onClick={() => goBack()}>
                {t("back")}
            </button>
        </Modal>
    );
};

export default ModalWantCancelled;
