import { Button, CircularProgress, Icon } from "@material-ui/core";
import { AttachMoney, MoneyOff } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import ContentSection from "../../../components/content-section/ContentSection";
import PaymentModal from "../../../components/payment-modal/PaymentModal";
import { Folder } from "../../../models/folder";
import { Deposit, Payment } from "../../../models/payment";
import FolderService from "../../../services/folder-service";
import { format } from "../../../utils/AmountHelper";
import "./DepositPage.scss";
import ModalPdf from "../../../components/previouwing/modal-pdf/ModalPdf";
import { documentType } from "../../../models/utils/document-type";
import { loadPdf } from "../../../utils/PdfReaderHelper";

interface IPropsDeposit {
    folder: Folder;
    isAdmin: boolean;
    refreshPayments: () => void;
    afterRemove: () => void;
}

const DepositPage = ({ folder, isAdmin, refreshPayments, afterRemove }: IPropsDeposit) => {
    const { t } = useTranslation();
    const deposit = folder?.deposit && folder?.deposit;
    const service = new FolderService();

    const [openPayment, setOpenPayment] = useState<boolean>(false);

    const [pdf, setPdf] = useState<any>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [pdfLoading, setPdfLoading] = useState(false);

    const deleteDeposit = async (item: Deposit) => {
        try {
            await service.removeDeposit(folder.id, item.id);
            toastr.success(t("success"), t("success_updated_folder"));
            afterRemove();
        } catch (error) {
            console.log(error.response);
        }
    };

    const unpaidAmount = () : number => {
        let amount = 0;

        if (deposit) {
            amount = deposit.amount;
            if (folder.total_deposit_payments) {
                amount -= folder.total_deposit_payments;
            }
            if (amount < 0) {
                amount = 0;
            }
        }

        return amount;
    }

    const hasPayments = () : boolean => {
        return (folder.total_deposit_payments) ? true : false;
    }

    const openPdf = async () => {
        setPdfLoading(true);
        if (deposit && deposit.pdf) {
            let fileURL = await loadPdf(deposit);
            setPdf(fileURL);
            setIsOpen(true);
        }

        setPdfLoading(false);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return deposit ? (
        <ContentSection title={unpaidAmount() ? t("to_pay") : t("paid")}>
            <div className="deposit-card">
                <div className="card">
                    {deposit?.pdf && <Button
                        className="pdf preview-button"
                        style={{ minWidth: "25px" }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => openPdf()}
                    >
                        {!pdfLoading ? (
                            <span>{t("pdf")}</span>
                        ) : (
                            <CircularProgress />
                        )}
                    </Button>}
                    <div className="amount">
                        <span className="label">{`${t("amount")}: `}</span>
                        <span className="value">
                            {format(deposit ? deposit.amount : 0)}
                        </span>
                    </div>
                    <div className="amount">
                        <span className="label">{`${t("paid")}: `}</span>
                        <span className="value">
                            {format(folder.total_deposit_payments ? folder.total_deposit_payments : 0)}
                        </span>
                    </div>
                    <div className="amount">  
                        <span className="label">{`${t("to_pay")}: `}</span>
                        <span className="value">
                            {format(unpaidAmount())}
                        </span>
                    </div>
                    {isAdmin ? 
                        <>
                            <div className="add_payment">
                                {unpaidAmount() === 0 ? (
                                    <AttachMoney
                                        style={{ color: "rgb(117 255 106)" }}
                                    />
                                ) : (
                                    <MoneyOff color="secondary" />
                                )}
                            </div>
                            {(unpaidAmount() > 0) && <div className="add_payment">
                                <Button
                                    className="btn-form btn-icon"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => setOpenPayment(true)}
                                >
                                    <span>{t("add_payment")}</span> <Icon>payment</Icon>
                                </Button>
                            </div>}
                            {!hasPayments() && <div className="delete">
                                <Button
                                    className="btn-form btn-icon"
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={() => deleteDeposit(deposit)}
                                >
                                    <span>{t("delete")}</span> <Icon>delete</Icon>
                                </Button>
                            </div>}
                        </> : ''
                    }
                    
                </div>
            </div>
            {isAdmin && <PaymentModal
                isOpen={openPayment}
                closeDialog={() => setOpenPayment(false)}
                refreshPayments={() => refreshPayments()}
                payment={new Payment({
                    folder: folder,
                    deposit: deposit,
                    amount: unpaidAmount(),
                    date: new Date(), //default today
                })}
            />}

            {pdf && isOpen && (
                <ModalPdf
                    closeDialog={() => closeModal()}
                    pdf={pdf}
                    isOpen={isOpen}
                    code={folder.code}
                    type={documentType.DEPOSIT}
                />
            )}

        </ContentSection>


    ) : (
        <></>
    );
};

export default DepositPage;
