import axios, { AxiosResponse } from "axios";
import { User } from "../models/user";
import { history } from "../containers/app/App";
import { getBaseUrl } from "./setting-service";
import { Driver } from "../models/driver";

export interface AuthModel {
    access_token: string;
    expires_in: number;
    token_type: string;
}

export default class AuthService {
    login(body: any) {
        return axios
            .post(`${getBaseUrl()}users/login`, body)
            .then((response: AxiosResponse) => {
                let data = response.data;
                this.storeAuthInformations(data);
                this.updateAxiosAuthorization();
                return data;
            });
    }

    register(body: any) {
        return axios
            .post(`${getBaseUrl()}administrators/register`, body)
            .then((response: AxiosResponse) => new User(response.data))
            .catch();
    }

    logout() {
        return axios
            .post(`${getBaseUrl()}users/logout`, [])
            .then((response: AxiosResponse) => {
                this.removeAuthInformations();
            })
            .catch();
    }

    getUserByTokenAccount(relationName: string, token: string) {
        return axios
            .get(
                `${getBaseUrl()}users/validate-account/${relationName}/${token}`
            )
            .then((response: AxiosResponse) => {
                if (relationName === "users") {
                    return new User(response.data);
                } else {
                    return new Driver(response.data);
                }
            })
            .catch();
    }

    getUserByTokenResettingPassword(relationName: string, token: string) {
        return axios
            .get(
                `${getBaseUrl()}users/token-resetting-password/${relationName}/${token}`
            )
            .then((response: AxiosResponse) => {
                return new User(response.data);
            })
            .catch();
    }

    validateAccount(relationName: string, data: any) {
        return axios.post(
            `${getBaseUrl()}users/validate-account/${relationName}`,
            data
        );
    }

    demandPassword(relationName: string, data: any) {
        return axios.post(
            `${getBaseUrl()}users/resetting-password/send/${relationName}`,
            data
        );
    }

    resettingPassword(relationName: string, data: any) {
        return axios.post(
            `${getBaseUrl()}users/resetting-password/${relationName}`,
            data
        );
    }

    getUser() {
        return axios
            .get(`${getBaseUrl()}users`)
            .then((response: AxiosResponse) => {
                return new User(response.data);
            })
            .catch();
    }

    updateAxiosAuthorization() {
        axios.interceptors.request.use((config) => {
            config.headers.Authorization = "Bearer " + this.getAuthToken();

            return config;
        });
    }

    static updateAxiosInterceptors() {
        axios.interceptors.response.use(undefined, (error) => {
            const { status } = error.response;
            if (status === 401) {
                history.push("/login");
            }
            return Promise.reject(error);
        });
    }

    storeAuthInformations(data: AuthModel) {
        localStorage.setItem("auth", JSON.stringify(data));
    }

    removeAuthInformations() {
        localStorage.removeItem("auth");
    }

    getAuthToken() {
        let auth = localStorage.getItem("auth");
        return auth ? JSON.parse(auth).access_token : null;
    }
}
