import axios, { AxiosResponse } from "axios";
import { getBaseUrl } from "./setting-service";
import { ProviderType } from "../models/provider-type";

export default class ProviderTypeService {
  all() {
    return axios
      .get(`${getBaseUrl()}providersType`)
      .then((response: AxiosResponse) => {
        return response.data.map((providerType: Object) => new ProviderType(providerType));
      });
  }
}
