import axios, {AxiosResponse} from "axios";
import {Driver} from "../models/driver";
import {getBaseUrl, getParamsUrl} from "./setting-service";
import {DataPaginate} from "../models/utils/data-paginate";
import {FiltersEnabled} from "../models/utils/filters/filter-enabled";
import {DriverFilters} from "../models/utils/filters/drivers/driver-filters";

export default class DriverService {
    private t: any;

    constructor(t: any = null) {
        this.t = t;
    }

    all(page?: number | null, search?: string | null, filter?: string | null, filters?: FiltersEnabled,provider_id?:any) {
        let paramsUrl = "";

        if (filters)
            paramsUrl = getParamsUrl([
                {key: "page", value: page},
                {key: "search", value: search},
                {key: "filter", value: filter},
                {key: "provider_id", value: provider_id},
                ...filters.list,
            ]);
        else {
            paramsUrl = getParamsUrl([
                {key: "page", value: page},
                {key: "search", value: search},
                {key: "filter", value: filter},
                {key: "provider_id", value: provider_id}
            ]);
        }
        return axios
            .get(`${getBaseUrl()}drivers${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map((driver: Object) => new Driver(driver));

                return new DataPaginate(res);
            });
    }

    get(id: number) {
        return axios
            .get(`${getBaseUrl()}drivers/${id}`)
            .then((response: AxiosResponse) => new Driver(response.data))
            .catch();
    }

    getFilter() {
        return axios
            .get(`${getBaseUrl()}drivers/filters`)
            .then((response: AxiosResponse) => new DriverFilters(response.data, this.t));
    }

    put(body: any, id: number) {
        return axios
            .put(`${getBaseUrl()}drivers/${id}`, body)
            .then((response: AxiosResponse) => new Driver(response.data))
            .catch();
    }

    post(body: any, id: number) {
        return axios
            .post(`${getBaseUrl()}providers/${id}/drivers`, body)
            .then((response: AxiosResponse) => new Driver(response.data))
            .catch();
    }

    patch(body: any, id: number) {
        return axios
            .patch(`${getBaseUrl()}drivers/${id}`, body)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    getKpis() {
        return axios
            .get(`${getBaseUrl()}drivers/kpis`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    delete(id: number) {
        return axios
            .delete(`${getBaseUrl()}drivers/${id}`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    sendDemandResetPassword(email: string) {
        const body = {
            email: email
        };
        return axios.post(`${getBaseUrl()}users/resetting-password/send/drivers`, body)
    }
}
