import React from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import { User } from "../../models/user";
import { connect } from "react-redux";
import KpiQuote from "./kpi-quotes/KpiQuote";
import ListQuotes from "./list-quotes/ListQuotes";

interface IPropsQuotePage {
    user: User;
}

const QuotePage = ({ user }: IPropsQuotePage) => {
    const { t } = useTranslation();

    //Return the appropriate content
    return (
        <>
            <div className="transfer-list">
                <ContentSection title={t("entity.quote")}>
                    <KpiQuote />
                    <ListQuotes user={user} />
                </ContentSection>
            </div>
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(QuotePage);
