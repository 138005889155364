import axios, {AxiosResponse} from "axios";

import {getBaseUrl, getParamsUrl} from "./setting-service";
import {DataPaginate} from "../models/utils/data-paginate";
import {Invoice} from "../models/invoice";
import {InvoiceFilters} from "../models/utils/filters/invoices/invoice-filters";
import {FiltersEnabled} from "../models/utils/filters/filter-enabled";

export default class InvoiceService {
    get(id: number | string) {
        return axios
            .get(`${getBaseUrl()}invoices/${id}`)
            .then((response: AxiosResponse) => new Invoice(response.data))
            .catch();
    }

    getFilter() {
        return axios
            .get(`${getBaseUrl()}invoices/filters`)
            .then((response: AxiosResponse) => {
                return new InvoiceFilters(response.data);
            });
    }

    all(page?: number | null,
        search?: string | null,
        limit?: string | null,
        filters?: FiltersEnabled | null,
        start?: string | null,
        end?: string | null,
        clients?: string | null,
        status?: string | null,
        order?: string | null,
        dir?: string | null,
    ) {
        let paramsUrl = "";

        if (filters)
            paramsUrl = getParamsUrl([
                {key: "page", value: page},
                {key: "search", value: search},
                {key: "limit", value: limit},
                {key: "start", value: start},
                {key: "end", value: end},
                {key: "client_ids", value: clients},
                {key: "status", value: status},
                {key: "order", value: order},
                {key: "dir", value: dir},
                ...filters.list
            ]);
        else {
            paramsUrl = getParamsUrl([
                {key: "page", value: page},
                {key: "search", value: search},
                {key: "limit", value: limit},
                {key: "start", value: start},
                {key: "end", value: end},
                {key: "client_ids", value: clients},
                {key: "status", value: status},
                {key: "order", value: order},
                {key: "dir", value: dir},
            ]);
        }
        return axios
            .get(`${getBaseUrl()}invoices${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map(
                    (invoice: Object) => new Invoice(invoice)
                );

                return new DataPaginate(res);
            });
    }

    update(invoiceId: number | string, preview = false, $data: any) {
        return axios
            .put(`${getBaseUrl()}invoices/${invoiceId}?preview=${preview}`, $data, {
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    send(id: number | string, $data: any) {
        return axios
            .post(`${getBaseUrl()}invoices/${id}/send`, $data)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    cancel(id: number | string) {
        return axios
            .delete(`${getBaseUrl()}invoices/${id}`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    getKpis() {
        return axios
            .get(`${getBaseUrl()}invoices/kpis`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    penality(id: number | string, $data: any) {
        return axios
            .post(`${getBaseUrl()}invoices/${id}/penalities`, $data)
            .then((response: AxiosResponse) => new Invoice(response.data))
            .catch();
    }
}
