import { Deserializable } from "./interfaces/deserializable";

export enum IncidentType {
  Lateness="lateness",
  Technical="technical",
  Other="other"
}

export class Incident implements Deserializable {
  public id: number;
  public code: IncidentType;
  public comment: string;
  public driver_id: number;
  public transfer_id: number;
  public created_at: Date;
  public updated_at: Date;

  constructor(data: any) {
    this.id = data.id;
    this.code = data.code;
    this.comment = data.comment;
    this.driver_id = data.driver_id;
    this.transfer_id = data.transfer_id;
    this.created_at = new Date(data.created_at);
    this.updated_at = new Date(data.updated_at);
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
