import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import NewIndicator, {
    IndicatorType,
} from "../../../components/indicator/NewIndicator";
import TransferService from "../../../services/transfer-service";
import UseStyles from "../../design-dependencies/UseStyles";

interface IKpiTransfer {
    pending: number;
    validated: number;
    to_assign: number;
}

interface IListParams {
    folderId?: number | null;
}
const KpiTransfersClient = ({ folderId }: IListParams) => {
    const [kpis, setKpis] = useState<IKpiTransfer>();
    const { t } = useTranslation();

    useEffect(() => {
        const getKpis = async () => {
            try {
                const service = new TransferService();
                let res = await service.getKpis(folderId ? folderId : null);
                setKpis(res);
            } catch (error) {
                console.log("error", error);
            }
        };

        getKpis();
    }, [folderId]);

    return (
        <div className="row">
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().mintBg}
                    index={0}
                    text={t("status.client.to_cipher")}
                    type={IndicatorType.TransferClient}
                    total={kpis?.to_assign ? kpis.to_assign : 0}
                />
            </div>
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().cyanBg}
                    index={1}
                    text={t("provider_status.pending")}
                    type={IndicatorType.TransferClient}
                    total={kpis?.pending ? kpis.pending : 0}
                />
            </div>
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().yellowBg}
                    index={2}
                    text={t("status.running")}
                    type={IndicatorType.TransferClient}
                    total={kpis?.pending ? kpis.pending : 0}
                />
            </div>
        </div>
    );
};

export default KpiTransfersClient;
