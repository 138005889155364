
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { findError } from "./helpers/ControllerHelper";

interface Iprops {
  label: string;
  listItems: any[];
  value: any;
  disabled: boolean | false;
  name: string;
  required?: boolean | false;
  getOptionLabel: any;
  renderOption?:any;
  control: any;
  errors: any;
  width?: number;
  onChange?: (selected: any) => void;
}

const AutocompleteControlled = (props: Iprops) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(props.value ? props.value : null);
  let fieldError = findError(props.errors, props.name);
  return (
    <>
      <input
        type="hidden"
        name={props.name + "_id"}
        value={selected ? selected.id : ""}
        onChange={() => {}}
        ref={props.control.register}
      />
      <Controller
        name={props.name}
        defaultValue={selected}
        value={selected}
        disabled={props.disabled}
        control={props.control}
        rules={{ required: props.required }}
        as={
          <Autocomplete
            id={props.name + "_autocomplete"}
            options={props.listItems}
            noOptionsText={t("no_items")}
            getOptionLabel={props.getOptionLabel}
            renderOption={props.renderOption}
            disableClearable={props.required}
            style={{ width: props.width ? props.width : 200 }}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                label={props.label}
                {...params}
                error={!!fieldError}
                helperText={
                  fieldError ? t("errors.required", { field: props.label }) : ""
                }
              />
            )}
          />
        }
        onChange={([event, data]) => {
          setSelected(data);
          if (props.onChange) {
            props.onChange(data);
          }
          return data;
        }}
      />
      {fieldError && <p>{fieldError.message}</p>}
    </>
  );
};

export default AutocompleteControlled;
