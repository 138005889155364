import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DataPaginate } from "../../../models/utils/data-paginate";
import BrandService from "../../../services/brand-service";
import FormBrand from "./form-brand/FormBrand";
import ListBrands from "./list-brands/ListBrands";
import ListModels from "./list-models/ListModels";
import AccordionBox from "../../../components/accordion-box/AccordionBox";
import { VehicleBrand } from "../../../models/vehicle-brand";
import FormModel from "./form-model/FormModel";
import ModelService from "../../../services/model-service";
import { CircularProgress, AppBar, Tab } from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import "./VehicleDetails.scss";
import { VehicleModel } from "../../../models/vehicle-model";
import Icon from "@material-ui/core/Icon";
import VehicleColorService from "../../../services/vehicle-color-service";
import { VehicleColor } from "../../../models/vehicle-color";
import FormColor from "./form-color/FormColor";
import ListColors from "./list-colors/ListColors";

const BrandsAndModels: React.FunctionComponent = (props) => {
    const { t } = useTranslation();
    const [dataPaginateColors, setDataPaginateColors] = useState(
        new DataPaginate()
    );
    const [dataPaginateBrands, setDataPaginateBrands] = useState(
        new DataPaginate()
    );
    const [dataPaginateModels, setDataPaginateModels] = useState(
        new DataPaginate()
    );
    const [allBrandsPaginate, setAllBrandsPaginate] = useState(
        new DataPaginate()
    );
    const [pageBrands, setPageBrands] = useState(1);
    const [pageModels, setPageModels] = useState(1);
    const [pageColors, setPageColors] = useState(1);
    const [search, setSearch] = useState("");
    const brandService = new BrandService();
    const modelService = new ModelService();
    const vehicleColorService = new VehicleColorService();
    const [value, setValue] = React.useState("1");
    const [selectedBrandId, setSelectedBrandId] = React.useState<any>(null);

    useEffect(() => {
        const fetchAllBrands = async () => {
            try {
                const brandService = new BrandService();
                setDataPaginateBrands(await brandService.list());
                setAllBrandsPaginate(await brandService.list("all"));
            } catch (error) {
                console.log("error", error);
            }
        };

        const fetchAllModels = async () => {
            try {
                const modelService = new ModelService();
                setDataPaginateModels(await modelService.list());
            } catch (error) {
                console.log("error", error);
            }
        };

        const fetchAllColors = async () => {
            const vehicleColorService = new VehicleColorService();
            try {
                setDataPaginateColors(await vehicleColorService.list());
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchAllBrands();
        fetchAllModels();
        fetchAllColors();
    }, []);

    async function refreshBrands(newPage?: number) {
        if (newPage) {
            setPageBrands(newPage);
        }
        try {
            setDataPaginateBrands(
                await brandService.list(
                    null,
                    newPage ? newPage : pageBrands,
                    search
                )
            );
            setAllBrandsPaginate(await brandService.list("all"));

            refreshModels();
        } catch (error) {
            console.log("error", error);
        }
    }

    async function refreshModels(newPage?: number, brandId?: number) {
        if (newPage) {
            setPageModels(newPage);
        }
        if (brandId) {
            setSelectedBrandId(brandId);
        } else if (brandId === 0) {
            setSelectedBrandId(null);
        }
        try {
            setDataPaginateModels(
                await modelService.list(
                    newPage ? newPage : pageModels,
                    brandId ? brandId : brandId === 0 ? null : selectedBrandId,
                    search
                )
            );
        } catch (error) {
            console.log("error", error);
        }
    }

    async function refreshColors(newPage?: number) {
        if (newPage) {
            setPageColors(newPage);
        }
        try {
            setDataPaginateColors(
                await vehicleColorService.list(
                    null,
                    newPage ? newPage : pageColors,
                    search
                )
            );
        } catch (error) {
            console.log("error", error);
        }
    }

    const searchOnEnter = (event: { key: string }) => {
        if (event.key === "Enter") {
            refreshBrands(1);
            refreshModels(1);
            refreshColors(1);
        }
    };

    function renderSearch() {
        return (
            <div className="param-search">
                <input
                    placeholder={t("search")}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={searchOnEnter}
                />
                <button onClick={() => refreshBrands(1)}>
                    <Icon>{"search"}</Icon>
                </button>
            </div>
        );
    }

    const updateVehicleBrand = (vehicleBrand: VehicleBrand) => {
        const newDataPaginate = new DataPaginate(dataPaginateBrands);
        const newData = dataPaginateBrands.data.map((data: any) => {
            return data.id === vehicleBrand.id ? vehicleBrand : data;
        });
        newDataPaginate.setData(newData);
        setDataPaginateBrands(newDataPaginate);
    };

    const updateVehicleModel = (vehicleModel: VehicleModel) => {
        const newDataPaginate = new DataPaginate(dataPaginateModels);
        const newData = dataPaginateModels.data.map((data: any) => {
            return data.id === vehicleModel.id ? vehicleModel : data;
        });
        newDataPaginate.setData(newData);
        setDataPaginateModels(newDataPaginate);
    };

    const updateVehicleColor = (vehicleColor: VehicleColor) => {
        const newDataPaginate = new DataPaginate(dataPaginateColors);
        const newData = dataPaginateColors.data.map((data: any) => {
            return data.id === vehicleColor.id ? vehicleColor : data;
        });
        newDataPaginate.setData(newData);
        setDataPaginateColors(newDataPaginate);
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <AccordionBox
                title={t("vehicle_management").toUpperCase()}
                slotHeader={renderSearch()}
            >
                <div className="flex_row">
                    <div className="flex_column form_box_vehicle">
                        <FormBrand onAddSuccess={() => refreshBrands(1)} />

                        {allBrandsPaginate.data &&
                        allBrandsPaginate.data.length ? (
                            <FormModel
                                allBrandsPaginate={allBrandsPaginate}
                                onAddSuccess={() => refreshModels(1)}
                            />
                        ) : (
                            <CircularProgress />
                        )}
                        <FormColor onAddSuccess={() => refreshColors(1)} />
                    </div>

                    <div className="flex_column list_box_vehicle">
                        <TabContext value={value}>
                            <AppBar position="static">
                                <TabList
                                    onChange={handleChange}
                                    aria-label="Brands and Models"
                                >
                                    <Tab label={t("brands_list")} value="1" />
                                    <Tab label={t("models_list")} value="2" />
                                    <Tab label={t("colors_list")} value="3" />
                                </TabList>
                            </AppBar>
                            <TabPanel value="1">
                                <ListBrands
                                    loading={false}
                                    dataPaginate={dataPaginateBrands}
                                    onPageChange={(page) => refreshBrands(page)}
                                    onUpdateSuccess={(vehicleBrand) =>
                                        updateVehicleBrand(vehicleBrand)
                                    }
                                    onDeleteSuccess={() => refreshBrands()}
                                />
                            </TabPanel>
                            <TabPanel value="2">
                                <ListModels
                                    loading={false}
                                    dataPaginate={dataPaginateModels}
                                    brands={dataPaginateBrands.data}
                                    onPageChange={(page) => refreshModels(page)}
                                    updateListModels={(brandId) =>
                                        refreshModels(undefined, brandId)
                                    }
                                    onUpdateSuccess={(vehicleModel) =>
                                        updateVehicleModel(vehicleModel)
                                    }
                                    onDeleteSuccess={() => refreshModels()}
                                />
                            </TabPanel>
                            <TabPanel value="3">
                                <ListColors
                                    loading={false}
                                    dataPaginate={dataPaginateColors}
                                    onPageChange={(page) => refreshColors(page)}
                                    onUpdateSuccess={(vehicleColor) =>
                                        updateVehicleColor(vehicleColor)
                                    }
                                    onDeleteSuccess={() => refreshColors()}
                                />
                            </TabPanel>
                        </TabContext>
                    </div>
                </div>
            </AccordionBox>
        </>
    );
};

export default BrandsAndModels;
