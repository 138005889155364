import React from "react";
import {useTranslation} from "react-i18next";
import './FormTitle.scss'

interface IPropsFormTitle {
    title: string;
    margin?: boolean;
    children?: any;
}

const FormTitle: React.FunctionComponent<IPropsFormTitle> = props => {
    
    const { t } = useTranslation();
    
    return (
        <>
            <div className={`section-title ${ props.margin ? 'mgt4' : 'mgt2'}`}>{t(props.title)} {props.children}</div>
            <div className="section-border"/>
        </>
    );
};

export default FormTitle;
