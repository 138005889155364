import React, { useEffect, useState } from "react";
import NewIndicator from "../../../components/indicator/NewIndicator";
import UseStyles from "../../design-dependencies/UseStyles";
import { IndicatorType } from "../../../components/indicator/NewIndicator";
import { useTranslation } from "react-i18next";
import BeneficiaryService from "../../../services/beneficiary-service";

interface PropsKpisBeneficiary {
  changeKpis: number;
}

const KpiBeneficiary: React.FunctionComponent<PropsKpisBeneficiary> = (
  changeKpis
) => {
  const { t } = useTranslation();
  const [kpis, setKpis] = useState<any>();
  //Fetch kpis
  useEffect(() => {
    const getKpis = async () => {
      try {
        const beneficiaryService = new BeneficiaryService();
        const data = await beneficiaryService.getKpis();
        setKpis(data);
      } catch (e) {
        console.log(e);
      }
    };
    getKpis();
  }, [changeKpis]);

  return (
    <div className="row">
      <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
        <NewIndicator
          className={UseStyles().blueGradient}
          index={0}
          text={t("activated")}
          type={IndicatorType.Beneficiary}
          total={kpis ? kpis.nbActifs : 0}
        />
      </div>
      <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
        <NewIndicator
          className={UseStyles().pinkGradient}
          index={1}
          text={t("deactivated")}
          type={IndicatorType.Beneficiary}
          total={kpis ? kpis.nbInactifs : 0}
        />
      </div>
    </div>
  );
};

export default KpiBeneficiary;
