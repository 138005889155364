import { Deserializable } from "./interfaces/deserializable";
import { Folder } from "./folder";

export class Credit implements Deserializable {
    public id: number;
    public folder_id: number;
    public invoice_id: number;
    public code: string;
    public closed: boolean;
    public value: number;
    public amount: number;
    public expiration: Date|null = null;
    public comment: string;
    public pdf?: string;
    public pdf_en?: string;

    public folder?: Folder;
    public created_by: number;
    public updated_by: number;
    public created_at: Date;
    public updated_at: Date;

    constructor(data: any) {
        this.id = data.id;
        this.folder_id = data.folder_id;
        this.invoice_id = data.invoice_id;
      
        this.code = data.code;
        this.value = data.value;
        this.amount = data.amount;
        this.comment = data.comment;

        if (data.folder) {
            this.folder = new Folder(data.folder);
        }
        this.closed = data.closed ? true : false;

        this.created_by = data.created_by;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.updated_by = data.updated_by;

        if(data.expiration){
            this.expiration = new Date(data.expiration);
        }
        if (data.pdf) {
            this.pdf = data.pdf;
            this.pdf_en = data.pdf_en;
        }
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

}
