import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import AuthService from "../../../services/auth-service";
import { ErrorForm } from "../../../components/ErrorForm";
import { useLocation, useParams } from "react-router-dom";
import "./DemandPassword.scss";

interface FormRegister {
    email: string;
}

const FormPassword: React.FunctionComponent = (props) => {
    const { relationName } = useParams();
    const { register, handleSubmit, errors } = useForm<FormRegister>();
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [successMsg, setSuccessMsg] = useState(null);
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let tab = useQuery().get("tab");

    const emailRequired = t("errors.required", { field: t("email") });

    const onSubmit = async (data: any) => {
        try {
            setErrorApi(new ErrorFormData());
            const authService = new AuthService();
            await authService.demandPassword(relationName, data);
            setSuccessMsg(t("success_demand_password"));
        } catch (error) {
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    return (
        <div className="container-login-page demand-reset flex_center">
            <div className="login-page">
                <div className="container-img">
                    <img src="/images/login/images/image-login.png" alt="" />
                </div>
                <div className="container-login">
                    <div className="container-title">
                        <h1 className="to-upper title">
                            {t("demand_password")}
                        </h1>
                    </div>
                    <div className="login flex_center">
                        <div className="form-container">
                            <div className="admin-logo-section">
                                <img
                                    className="logo-spark"
                                    src="/images/login/images/logo.png"
                                    alt=""
                                />
                            </div>
                            <div className="form-content">
                                <form
                                    className="form form-demand-password"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    {successMsg && (
                                        <p
                                            style={{
                                                textAlign: "center",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {successMsg}
                                        </p>
                                    )}

                                    <input
                                        className="input"
                                        placeholder="Email"
                                        name="email"
                                        ref={register({
                                            required: emailRequired,
                                        })}
                                    />

                                    <div className="submit-section">
                                        <div className="submit flex_row">
                                            <input
                                                type="submit"
                                                value="Envoyer"
                                            />
                                            <img
                                                className="logo"
                                                src="/images/login/icons/login.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>

                                    {errors.email && (
                                        <p>{errors.email.message}</p>
                                    )}
                                    <ErrorForm errorApi={errorApi} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormPassword;
