import React, { useState, useEffect, useCallback } from "react";
import { Provider } from "../../../models/provider";
import ProviderService from "../../../services/provider-service";
import { useTranslation } from "react-i18next";
import { DataPaginate } from "../../../models/utils/data-paginate";
import Pagination from "../../../components/pagination/pagination";
import ItemProvider from "./ItemProvider";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import {
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import ActionsSection from "../../../components/actions-section/ActionsSection";
import GlobalSearch from "../../../components/global-search/globalSearch";
import { handleCheckBoxChange } from "../../../utils/HandleCheckboxs";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { ErrorForm } from "../../../components/ErrorForm";
import HeadTableCell from "../../../components/table/HeadTableCell";
import KpiContentSeparator from "../../../components/kpi-content-separator/KpiContentSeparator";
import { FiltersEnabled } from "../../../models/utils/filters/filter-enabled";
import FiltersProviders from "./FiltersProviders";
import { ProviderFilters } from "../../../models/utils/filters/providers/provider-filters";
import { debounce } from "lodash";

interface IPropsListProviders {
    fetchKpis: () => void;
}

const ListProviders = ({ fetchKpis }: IPropsListProviders) => {
    let history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState<ProviderFilters>();
    const [dataPaginateProviders, setDataPaginateProviders] = useState(
        new DataPaginate()
    );
    const [pageProviders, setPageProviders] = useState(1);
    const [filtersEnabled, setFiltersEnabled] = useState<FiltersEnabled>(
        new FiltersEnabled()
    );
    const [action, setAction] = useState("car");
    const [search, setSearch] = useState<string | null>(null);
    const [date, setDate] = useState(new Date());
    const [checkboxs, setCheckboxs] = useState<any>({
        checkAll: false,
        selections: [],
    });
    const [errorApi, setErrorApi] = useState(new ErrorFormData());

    const fetchAllInfo = async () => {
        if (!loading) setLoading(true);
        try {
            const providerService = new ProviderService();
            setDataPaginateProviders(
                await providerService.all(
                    pageProviders,
                    search,
                    null,
                    filtersEnabled
                )
            );
        } catch (error) {
            console.log(error);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setLoading(false);
    };

    const debounceQuery = useCallback(debounce(fetchAllInfo, 500), [
        pageProviders,
        search,
        filtersEnabled,
        date,
    ]);

    useEffect(() => {
        debounceQuery();
        return debounceQuery.cancel;
    }, [pageProviders, search, filtersEnabled, date, debounceQuery]);

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const providerService = new ProviderService(t);
                setFilters(await providerService.getFilter());
            } catch (e) {
                console.log(e);
            }
        };
        fetchFilters();
    }, [t]);

    //Change provider actions
    const handleActionChange = (event: any) => {
        setAction(event.target.value);
    };

    //Handle validate provider action
    const validateAction = () => {
        if (checkboxs.selections.length === 1) {
            switch (action) {
                case "car":
                    history.push(
                        `providers/${checkboxs.selections[0]}/vehicles/add`
                    );
                    break;
                case "driver":
                    history.push(
                        `providers/${checkboxs.selections[0]}/drivers/add`
                    );
            }
        } else {
            toastr.warning(t("warning"), t("warning_add_provider_action"));
        }
    };

    //Handle checkboxs
    const handleCheckboxs = (event: any) => {
        handleCheckBoxChange(
            event,
            checkboxs,
            setCheckboxs,
            dataPaginateProviders
        );
    };

    const handleFiltersChange = (filters: FiltersEnabled) => {
        setFiltersEnabled(filters);
        setPageProviders(1);
    };

    return (
        <>
            {/* SEARCHBAR */}
            <GlobalSearch
                onSearch={(value: string) => {
                    setPageProviders(1);
                    setSearch(value);
                }}
                onResetFilter={() => setDate(new Date())}
            />
            <FiltersProviders
                filtersEnabled={filtersEnabled}
                filters={filters}
                setFilters={(filters) => handleFiltersChange(filters)}
            />
            <KpiContentSeparator />
            {dataPaginateProviders && dataPaginateProviders.data && !loading ? (
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            {/* HEAD */}
                            <TableHead>
                                <TableRow>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {/* Checkbox to check all items */}
                                        {dataPaginateProviders.data &&
                                        dataPaginateProviders.data.length !==
                                            0 &&
                                        !loading ? (
                                            <Checkbox
                                                onChange={(e) =>
                                                    handleCheckboxs(e)
                                                }
                                                id="checkAll"
                                                checked={
                                                    checkboxs.checkAll || false
                                                }
                                                inputProps={{
                                                    "aria-label":
                                                        "checkbox with default color",
                                                }}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("logo")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("business_name")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("referent_name")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("email")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("city")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("phone")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("number_vehicles")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("number_drivers")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("type")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("adjucication")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("show_history")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    />
                                </TableRow>
                            </TableHead>
                            {/* BODY */}
                            <TableBody>
                                {dataPaginateProviders.data.map(
                                    (provider: Provider) => (
                                        <ItemProvider
                                            key={`table-provider-${provider.id}`}
                                            provider={provider}
                                            checkboxs={checkboxs}
                                            fetchKpis={() => fetchKpis()}
                                            handleCheckbox={(e) =>
                                                handleCheckboxs(e)
                                            }
                                            setErrorApi={(error) =>
                                                setErrorApi(error)
                                            }
                                            onDeleteSuccess={() => fetchAllInfo()}
                                        />
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* PAGINATION */}
                    <Pagination
                        dataPaginate={dataPaginateProviders}
                        onPageChange={(page: number) => setPageProviders(page)}
                    />
                    {/* DropdownListe actions + validate button */}
                    <ActionsSection
                        onChangeDropdown={(e) => handleActionChange(e)}
                        valueDropdown={action}
                        labelDropdown={t("actions")}
                        onClickButton={validateAction}
                    >
                        <option value="car">{t("add_vehicle")}</option>
                        <option value="driver">{t("driver_add")}</option>
                    </ActionsSection>
                </>
            ) : loading ? (
                <CircularProgress />
            ) : !errorApi || dataPaginateProviders.data?.length === 0 ? (
                <div>{t("no_items")}</div>
            ) : (
                ""
            )}
            <ErrorForm errorApi={errorApi} />
        </>
    );
};
export default ListProviders;
