import React, { useState } from "react";
import {Ticket} from "../../../models/ticket";
import BodyTableRow from "../../../components/table/BodyTableRow";
import BodyTableCell from "../../../components/table/BodyTableCell";
import TicketService from "../../../services/ticket-service";
import {toastr} from "react-redux-toastr";
import {useTranslation} from "react-i18next";
import ActionTable from "../../../components/action-table/ActionTable";
import {useHistory} from "react-router-dom";
import { formatDateDDMMYYYYAndHoursAndMinutes } from "../../../utils/DateHelper";
import ModalDelete from "../../../components/modal/ModalDelete";

interface IItemTicket {
    ticket: Ticket,
    onDeleteSuccess: () => void;
}

const ItemTicket: React.FunctionComponent<IItemTicket> = ({
                                                              ticket,
                                                              onDeleteSuccess
                                                          }: IItemTicket) => {
    const history = useHistory();
    const service = new TicketService();
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    // Delete a ticket
    const deleteTicket = async () => {
        try {
            await service.delete(ticket.id);
            setIsOpen(false)
            toastr.success(t("success"), t("success_deleted_ticket"));
            onDeleteSuccess();
        } catch (error) {
            toastr.error(t("warning"), t("error_deleted_ticket"));
        }
    };

    return (
        <>
            <BodyTableRow>
                <BodyTableCell align="center">
                    {t(`ticket_type.${ticket.type}`)}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {ticket.title}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {ticket.email}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {formatDateDDMMYYYYAndHoursAndMinutes(ticket.created_at)}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {t(`ticket_state.${ticket.state}`)}
                </BodyTableCell>
                <BodyTableCell align="right">
                    <ActionTable
                        deleteAction={() => setIsOpen(true)}
                        replyAction={() =>
                            history.push(`support/${ticket.id}/reply`)
                        }
                    />
                </BodyTableCell>
                <ModalDelete
                    isOpen={isOpen}
                    closeDialog={() => setIsOpen(false)}
                    itemType="ticket"
                    itemCode={ticket.id.toString()}
                    itemName={ticket.title}
                    handleDelete={() => deleteTicket()}
                />
            </BodyTableRow>
        </>
    )
}

export default ItemTicket