import { combineReducers } from "redux";
import { authReducer } from "./auth/reducer";
import {reducer as toastrReducer} from 'react-redux-toastr'
import { notificationsReducer } from "./notifications/reducer";


export const rootReducer = combineReducers({
    auth: authReducer,
    toastr: toastrReducer,
    notifications: notificationsReducer
})

export type RootState = ReturnType<typeof rootReducer>