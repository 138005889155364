import { Deserializable } from "./interfaces/deserializable";
import { ProviderType } from "./provider-type";
import { Address } from "./address";
import { User } from "./user";
import { Transfer } from "./transfer";

export class Provider implements Deserializable {
    public id: number;
    public logo_key: string;
    public img_url: string;
    public company_name: string;
    public email: string;
    public phone: string;
    public address_id: number;
    public tva_number: string;
    public is_exonerated: boolean;
    public tva_rate?: number;
    public is_tender: boolean;
    public provider_type_id: number;
    public provider_type?: ProviderType | null;
    public address?: Address | null;
    public coordinator_id: number;
    public user_id: number;
    public user?: User | null;
    public transfers: Array<Transfer>;
    public licence_number: string;
    public perimeter: string[];
    public nb_drivers: number;
    public nb_vehicles: number;
    public nb_assignments: number;
    public nb_transfers: number;
    public created_at: Date;
    public updated_at: Date;
    public iban: string;
    public bic: string;
    public has_notif_email?: boolean;

    constructor(data: any) {
        this.id = data.id;
        this.logo_key = data.logo_key;
        this.img_url = data.img_url;
        this.company_name = data.company_name;
        this.email = data.email;
        this.phone = data.phone;
        this.address_id = data.address_id;
        this.tva_number = data.tva_number;
        this.is_exonerated = data.is_exonerated;
        this.tva_rate = data.tva_rate;
        this.is_tender = Boolean(data.is_tender);
        this.provider_type_id = data.provider_type_id;
        this.provider_type = data.provider_type
            ? new ProviderType(data.provider_type)
            : null;
        this.address = data.address ? new Address(data.address) : null;
        this.coordinator_id = data.coordinator_id;
        this.user_id = data.user_id;
        this.user = data.user ? new User(data.user) : null;
        this.transfers = data.transfers
            ? data.transfers.map((transfer: Transfer) => new Transfer(transfer))
            : null;
        this.licence_number = data.licence_number;
        this.perimeter = data.perimeter;
        this.nb_drivers = data.nb_drivers;
        this.nb_vehicles = data.nb_vehicles;
        this.nb_assignments = data.nb_assignments;
        this.nb_transfers = data.nb_transfers;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.iban = data.iban;
        this.bic = data.bic;
        this.has_notif_email = data.has_notif_email;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    getDeliveryAddress = () => {
        return (
            this.address?.street ||
            "" + this.address?.misc ||
            "" + this.address?.post_code + this.address?.city
        );
    };

    canBeDeleted = () => {
        return this.nb_assignments <= 0;
    };
}
