import { Client } from "./client";
import { Deserializable } from "./interfaces/deserializable";

export class Beneficiary implements Deserializable {
    public id: number;
    public logo: string;
    public img_url: string;
    public name: string;
    public coordinator_id: number;
    public client_id: number;
    public client?: Client;
    public activated: boolean;
    public created_at: Date;
    public updated_at: Date;

    constructor(data: any) {
        this.id = data.id;
        this.logo = data.logo;
        this.img_url = data.img_url;
        this.name = data.name;
        this.coordinator_id = data.coordinator_id;
        this.client_id = data.client_id;
        this.client = data.client ? new Client(data.client) : undefined;
        this.activated = data.activated;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}