import React from 'react';
import './ClientDashboard.scss';
import { useTranslation } from 'react-i18next';
import { User } from '../../models/user';
import EuroSvg from '../design-dependencies/svgs/EuroSvg';
import Colors from '../design-dependencies/Colors';
import UseStyles from '../design-dependencies/UseStyles';
import CarSvg from '../design-dependencies/svgs/CarSvg';
import QuoteSvg from '../design-dependencies/svgs/QuoteSvg';
import { useHistory, useRouteMatch } from 'react-router-dom';
import CurrentFolders from './boards/CurrentFolders';
import UpcomingTransfers from './boards/UpcomingTransfers';
import QuotesBoard from './boards/QuotesBoard';
import InvoicesBoard from './boards/InvoicesBoard';

interface IProps {
    user: User;
}
const ClientDashboard = ({
    user
} : IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  let { url } = useRouteMatch();
  
  return (
      <>
        <h1><span className="welcome">{t("board.welcome")}</span> {user.firstname} {user.lastname} !</h1>
        <div className="row links">
            {getLink(
                t("board.my_folders"),
                `${url}/folders`,
                UseStyles().greenBg,
                <EuroSvg fill={Colors.green} />
            )}
            {getLink(
                t("board.my_transfers"),
                `${url}/transfers`,
                UseStyles().greenLightBg,
                <CarSvg fill={Colors.greenLight} />
            )}
            {getLink(
                t("board.my_quotes"),
                `${url}/folders`,
                UseStyles().yellowBg,
                <QuoteSvg fill={Colors.yellow} />
            )}
            {getLink(
                t("board.my_invoices"),
                `${url}/invoices`,
                UseStyles().cyanBg,
                <QuoteSvg fill={Colors.cyan} />
            )}
        </div>
        <div className="row">
            <div className="col-12">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <CurrentFolders />
                    </div>
                    <div className="col-12 col-lg-6">
                        <UpcomingTransfers />
                    </div>
                    <div className="col-12 col-lg-6">
                        <QuotesBoard />
                    </div>
                    <div className="col-12 col-lg-6">
                        <InvoicesBoard />
                    </div>
                </div>
            </div>
        </div>
      </>
  );

  function getLink(title: string, url:string, style:string, svg: any) {
      return (
        <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
            <div className={"media-icon-box mic-sm media " + style}
                 onClick={() => history.push(url)}
            >
                <div className={"mib-media-left "}>
                    <div className="mib-round">{svg}</div>
                </div>
                <div className="mib-body media-body">
                    <div className="mibb-content row">
                        <span className="col">{title}</span>
                    </div>
                </div>
            </div>
        </div>
      );
  }
}

export default ClientDashboard;
