import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ModalUnassignment.scss";
import { ReactSVG } from "react-svg";
import {
    Button,
    FormControl,
    FormLabel,
    Icon,
    TextareaAutosize,
} from "@material-ui/core";
import { toastr } from "react-redux-toastr";
import { Transfer } from "../../../../models/transfer";
import Modal from "../../../../components/modal/Modal";
import Colors from "../../../design-dependencies/Colors";
import TransferService from "../../../../services/transfer-service";

interface IProps {
    transfer: Transfer;
    isOpen: boolean;
    closeDialog: () => void;
    providerId: number;
    onRefresh: () => void;
    from?: string;
}

export default function ModalUnassignment({
    isOpen,
    transfer,
    closeDialog,
    providerId,
    onRefresh,
    from = "provider",
}: IProps) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState("");

    async function accept() {
        setLoading(true);
        if (!comment) {
            toastr.error(t("error"), t("error_quotation_not_commented"));
            setLoading(false);
            return;
        }
        try {
            const transferService = new TransferService();
            await transferService.unassign(transfer.id, {
                provider_id: providerId,
                comment: comment,
            });

            setLoading(false);
            from === "provider"
                ? toastr.success(t("success"), t("success_unassign"))
                : toastr.success(t("success"), t("success_unassign_admin"));

            onRefresh();
            closeDialog();
        } catch (e) {
            setLoading(false);
            toastr.error(t("error"), t("unexpected_error"));
        }
    }
    return (
        <Modal
            label={
                transfer.isTender()
                    ? t("unasign_tender")
                    : t("unasign_transfer")
            }
            isOpen={isOpen}
            closeModal={() => closeDialog()}
        >
            <div className="content-modal-unasign">
                <FormControl component="fieldset" className="comment-unnasign">
                    <FormLabel component="legend">{t("comment")}</FormLabel>
                    <TextareaAutosize
                        disabled={loading}
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                        className="textarea"
                        aria-label="empty textarea"
                        rowsMin={4}
                        placeholder={t("comment")}
                    />
                </FormControl>
                <div className="actions">
                    <Button
                        disabled={loading}
                        onClick={() => closeDialog()}
                        className="btn-form btn-icon mr-2 submit-input"
                        variant="contained"
                        style={{ backgroundColor: Colors.red }}
                        size="large"
                        type="button"
                    >
                        {t("cancel")}{" "}
                        <ReactSVG src="/images/app_icons/icon-delete.svg" />
                    </Button>

                    <Button
                        disabled={loading}
                        onClick={() => accept()}
                        className="btn-form btn-icon mr-2 submit-input"
                        variant="contained"
                        color="primary"
                        size="large"
                        type="button"
                    >
                        {t("validate")} <Icon>{"done"}</Icon>
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
