import { NotificationUser } from "../models/notificationUser";
import { updateNotification } from "../redux/store/notifications/actions";
import NotificationService from "../services/notification-service";
import { Dispatch } from "redux";
import { Checkboxes } from "../models/utils/checkboxes";
import { DataPaginate } from "../models/utils/data-paginate";

export function editNotificationsByCheckBoxes(
    notifications: DataPaginate,
    notifCheckBoxes: Checkboxes,
    dispatch: Dispatch,
    state: boolean
) {
    const body = { is_active: state };
    const notificationService = new NotificationService();
    try {
        notifications.data.forEach(async (notification: NotificationUser) => {
            if (
                notifCheckBoxes.selections.find(
                    (id) => Number(id) === notification.id
                )
            ) {
                await notificationService
                    .setVisibility(notification, body)
                    .then((notif) => {
                        console.log(notif);
                        dispatch(updateNotification(notif));
                    });
            }
        });
        return true;
    } catch (e) {
        return false;
    }
}

export function showNotification(
    notif: NotificationUser,
    dispatch: Dispatch,
    history: any
) {
    if (notif.is_active) {
        const body = { is_active: false };
        const notificationService = new NotificationService();
        notificationService.setVisibility(notif, body).then((notif) => {
            dispatch(updateNotification(notif));
            redirectToNotifUrl(notif, history);
        });
    } else {
        redirectToNotifUrl(notif, history);
    }
}

function redirectToNotifUrl(notif: NotificationUser, history: any) {
    const link = notif.getUrl();
    if (link) {
        history.push(link);
    }
}
