import { Folder } from "./folder";
import { Deserializable } from "./interfaces/deserializable";

const TRANSFER_TYPE_STANDARD = "standard";
const TRANSFER_TYPE_PROVISION = "provision";

export class HistoryTransfer implements Deserializable {
    public id: number;
    public code: string;
    public folder_id: number;
    public folder: Folder | null;
    public type: string;
    public nb_passenger: number;
    public departure_location: string;
    public departure_datetime?: Date | null;
    public arrival_location: string;
    public arrival_datetime?: Date | null;
    public infos: string;
    public status?: string;
    public provider_id: number;
    public vehicle_type_id: number;
    public created_at: Date;
    public updated_at: Date;
    public passengers?: Array<any> | null;
    public route_points?: Array<any> | null;
    public options?: Array<any> | null;
    public is_tender: boolean;
    public tender_price?: number | null;
    public min_estimate?: number | null;
    public max_estimate?: number | null;
    public tender_status?: string;

    constructor(data: any) {
        this.id = data.id;
        this.code = data.code;
        this.folder_id = data.folder_id;
        this.folder = data.folder ? new Folder(data.folder) : null;
        this.status = data.status;
        this.tender_status = data.tender_status;
        this.type = data.type;
        this.nb_passenger = data.nb_passenger;
        this.departure_location = data.departure_location;
        this.departure_datetime = data.departure_datetime
            ? new Date(data.departure_datetime)
            : null;
        this.arrival_location = data.arrival_location;
        this.arrival_datetime = data.arrival_datetime
            ? new Date(data.arrival_datetime)
            : null;
        this.infos = data.infos;
        this.provider_id = data.provider_id;
        this.vehicle_type_id = data.vehicle_type_id;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);

        this.passengers = data.passengers ? data.passengers : null;
        this.route_points = data.route_points ? data.route_points : null;
        this.options = data.options ? data.options : null;
        this.is_tender = data.is_tender;
        this.tender_price = data.tender_price;
        this.min_estimate = data.min_estimate;
        this.max_estimate = data.max_estimate;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    isCancelled(): boolean {
        return this.status === "cancelled";
    }

    getHourDeparture() {
        return this.departure_datetime?.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        });
    }

    getHourArrival() {
        return this.arrival_datetime?.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        });
    }

    getDateDeparture() {
        return this.departure_datetime?.toLocaleDateString();
    }

    getDateArrival() {
        return this.arrival_datetime?.toLocaleDateString();
    }

    getHourUpdated() {
        return this.updated_at?.toLocaleDateString([], {
            hour: "2-digit",
            minute: "2-digit",
        });
    }

    getRateTva() {
        return this.type === TRANSFER_TYPE_STANDARD
            ? 10
            : this.type === TRANSFER_TYPE_PROVISION
            ? 20
            : 0;
    }

    canReassignDriver(): boolean {
        return (
            this.status === "assigned" ||
            this.status === "to_validate" ||
            this.status === "validated"
        );
    }

    getTypeTender(): string {
        if (this.isTender()) {
            if (this.tender_price) {
                return "fixed_price";
            } else if (this.min_estimate) {
                return "standard";
            }
        }
        return "basic";
    }

    isTender(): boolean {
        return this.is_tender;
    }

    getColorTenderStatus(): string {
        let color = "transparent";
        switch (this.tender_status) {
            case "to_assign":
                color = "#00C6FB";
                break;
            case "accepted":
                color = "#ffa50099";
                break;
            case "refused":
                color = "#F36666";
                break;
            case "already_assigned":
                color = "#A6A9B7";
                break;
            case "validated":
                color = "#A1E89B";
                break;
        }

        return color;
    }

    getColorStatus(from: string): string {
        let color = "transparent";
        switch (from) {
            case "provider":
                switch (this.status) {
                    case "pending":
                        color = "#1ABCB0";
                        break;
                    case "refused":
                        color = "#FF6106";
                        break;
                    case "validated":
                        color = "#A1E89B";
                        break;
                    case "ended":
                        color = "#707070";
                        break;
                    case "cancelled":
                        color = "#F36666";
                        break;
                    case "running":
                        color = "#EEAF13";
                        break;
                    case "accepted":
                        color = "#00C6FB";
                        break;
                    case "to_assign":
                        color = "#a6a4a4";
                        break;
                }
                break;
            case "admin":
                switch (this.status) {
                    case "to_assign":
                        color = "rgb(0, 195, 255)";
                        break;
                    case "assigned":
                        color = "#1ABCB0";
                        break;
                    case "pending":
                        color = "#0003b8";
                        break;
                    case "refused":
                        color = "#FF6106";
                        break;
                    case "accepted":
                        color = "#A1E89B";
                        break;
                    case "ended":
                        color = "#222222";
                        break;
                    case "cancelled":
                        color = "#a6a9b7";
                        break;
                    case "running":
                        color = "#a1e89b";
                        break;
                    case "validated":
                        color = "rgb(71, 241, 204)";
                        break;
                    case "in_place":
                        color = "#00c6fb";
                        break;
                    case "to_validate":
                        color = "#00A07D";
                        break;
                }
        }
        return color;
    }
}
