import axios, { AxiosResponse } from "axios";
import { Provider } from "../models/provider";
import { getBaseUrl, getParamsUrl } from "./setting-service";
import { DataPaginate } from "../models/utils/data-paginate";
import { Driver } from "../models/driver";
import { FiltersEnabled } from "../models/utils/filters/filter-enabled";
import { ProviderFilters } from "../models/utils/filters/providers/provider-filters";

export default class ProviderService {
    private t: any;

    constructor(t: any = null) {
        this.t = t;
    }

    all(
        page?: number | null,
        search?: string | null,
        limit?: string | null,
        filters?: FiltersEnabled | null
    ) {
        let paramsUrl = "";
        if (filters)
            paramsUrl = getParamsUrl([
                { key: "page", value: page },
                { key: "search", value: search },
                { key: "limit", value: limit },
                ...filters.list,
            ]);
        else {
            paramsUrl = getParamsUrl([
                { key: "page", value: page },
                { key: "search", value: search },
                { key: "limit", value: limit },
            ]);
        }
        return axios
            .get(`${getBaseUrl()}providers${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map(
                    (provider: Object) => new Provider(provider)
                );
                return new DataPaginate(res);
            });
    }

    add(body: any) {
        return axios
            .post(`${getBaseUrl()}providers`, body)
            .then((response: AxiosResponse) => {
                return new Provider(response.data);
            })
            .catch();
    }

    get(id: number) {
        return axios
            .get(`${getBaseUrl()}providers/${id}`)
            .then((response: AxiosResponse) => new Provider(response.data))
            .catch();
    }

    getFilter() {
        return axios
            .get(`${getBaseUrl()}providers/filters`)
            .then(
                (response: AxiosResponse) =>
                    new ProviderFilters(response.data, this.t)
            );
    }

    put(body: any, id: number) {
        return axios
            .put(`${getBaseUrl()}providers/${id}`, body)
            .then((response: AxiosResponse) => new Provider(response.data))
            .catch();
    }

    patch(body: any, id: number) {
        return axios
            .patch(`${getBaseUrl()}providers/${id}`, body)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    patchPerimeter(body: any, id: number) {
        return axios
            .patch(`${getBaseUrl()}providers/${id}/perimeter`, body)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    delete(id: number) {
        return axios
            .delete(`${getBaseUrl()}providers/${id}`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    getKpis() {
        return axios
            .get(`${getBaseUrl()}providers/kpis`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    getDrivers(id: number) {
        return axios
            .get(`${getBaseUrl()}providers/${id}/drivers`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                return res.map((driver: Object) => new Driver(driver));
            })
            .catch();
    }

    getDriversTenders(id: number) {
        return axios
            .get(`${getBaseUrl()}providers/tenders/${id}/drivers`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                return res.map((driver: Object) => new Driver(driver));
            })
            .catch();
    }
}
