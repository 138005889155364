import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const GreyButton = withStyles((theme) => ({
    root: {
        color: "#fff",
        backgroundColor: "#2f2f2f",
        fontSize: "13px",
        borderRadius: "30px",
        padding: "6px 20px",
        "&:hover": {
            backgroundColor: "#6f6f6f",
            color: "#fff",
        },
    },
}))(Button);

export default GreyButton;
