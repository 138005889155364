import React from "react";
import './DetailTicket.scss';
import {useTranslation} from "react-i18next";
import {Ticket} from "../../../models/ticket";
import {Button} from "@material-ui/core";

interface IPropsDetailTicket {
    ticket?: Ticket;
}

const DetailTicket = ({ ticket }: IPropsDetailTicket) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="detail-ticket-reply">
                <div>
                    <span>{t("form.email")} : </span>
                    <p>{ticket?.email}</p>
                </div>

                <div>
                    <span>{t("form.category")} : </span>
                    <p>{t(`ticket_type.${ticket?.type}`)}</p>
                </div>

                <div>
                    <span>{t("form.title")} : </span>
                    <p>{ticket?.title}</p>
                </div>

                <div>
                    <span>{t("form.description")} : </span>
                    {ticket &&
                    <div dangerouslySetInnerHTML={{__html: ticket?.description}}/>
                    }
                </div>

                <div>
                    <span>{t("form.attachment")} : </span>
                    {ticket?.img_url ?
                        <div>
                            <Button className="btn-form btn-icon mr-2 submit-input" variant="contained" color="primary"
                                    size="large" target="_blank" href={ticket.img_url} download={true}>
                                <span>{t('download')}</span>
                                <img src="/images/app_icons/icon-download.png" alt="download"/>
                            </Button>
                        </div>

                        : <div>{t('none')}</div>
                    }
                </div>
            </div>
        </>
    );
};

export default DetailTicket;
