import React, { useState, useEffect } from "react";
import Modal from "../../../modal/Modal";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import CsvService from "../../../../services/csv-service";
import { useTranslation } from "react-i18next";
import "./DialogExportInvoice.scss";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
    Button,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from "@material-ui/core";
import SeparatorVertical from "../../../separator-vertical/separatorVertical";
import GlobalSearch from "../../../global-search/globalSearch";
import { dateAddMonth, formatDatePicker } from "../../../../utils/DateHelper";
import { Client } from "../../../../models/client";
import { User } from "../../../../models/user";
import ClientService from "../../../../services/client-service";
import MultiSelect from "react-multi-select-component";

interface IDialogExport {
    isOpen: boolean;
    title?: string;
    closeDialog: () => void;
}

const DialogExportInvoice: React.FunctionComponent<IDialogExport> = ({
    isOpen,
    title = "Exporter",
    closeDialog,
}: IDialogExport) => {
    const { t } = useTranslation();
    const [data, setData] = useState<any>([]);
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const filters = [
        { value: "sent_at", label: t("bill_date") },
        { value: "deadline_at", label: t("due_date") },
        { value: "price_incl_tax", label: t("price_ttc") },
        { value: "price_margin", label: t("margin") },
    ];

    const statuses = [
        { value: "all", label: t("all") },
        { value: "paid", label: t("invoice_status.paid") },
        { value: "to_pay", label: t("invoice_status.to_pay") },
        { value: "lateness", label: t("invoice_status.lateness") },
    ];

    const [clientList, setClientList] = useState<any[]>([]);

    const [search, setSearch] = useState<string>("");
    const [endDate, setEndDate] = useState(formatDatePicker(new Date()));
    const [startDate, setStartDate] = useState(
        formatDatePicker(dateAddMonth(new Date(), -1))
    );

    const [clients, setClients] = useState<any[]>([]);
    const [status, setStatus] = useState<string>(statuses[0].value);
    const [sort, setSort] = useState<string>(filters[0].value);
    const [dir, setDir] = useState<string>("asc");

    useEffect(() => {
        const fetchAll = async () => {
            const clientService = new ClientService();
            const clients = await clientService.getClientsForForm(new User({}));
            setClientList(clients);
        };
        if (isOpen) {
            fetchAll();
        }
    }, [isOpen]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setData([]); // remove previous datas
            try {
                const csvService = new CsvService();

                let client_ids: number[] = [];

                if (clients.length < clientList.length) {
                    clients.map((client) => client_ids.push(client.value));
                    console.log(client_ids);
                }

                const data = await csvService.getInvoices(
                    t,
                    search,
                    startDate,
                    endDate,
                    client_ids.join(","),
                    status,
                    sort,
                    dir
                );
                setData(data);
            } catch (error) {
                setError(t("unexpected_error"));
            }
            setLoading(false);
        };
        if (isOpen) {
            fetchData();
        }
    }, [
        t,
        isOpen,
        search,
        startDate,
        endDate,
        sort,
        dir,
        clients,
        status,
        clientList.length,
    ]);

    const exportExcel = () => {
        const ws = XLSX.utils.aoa_to_sheet(data);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, getFilename());
        XLSX.writeFile(wb, `${getFilename()}.xlsx`);
    };

    const getFilename = () => {
        let filename = "invoices";
        return filename.toLowerCase();
    };

    return (
        <Modal label={title} isOpen={isOpen} closeModal={() => closeDialog()}>
            <div className="content-modal-export-controls">
                <GlobalSearch
                    onSearch={(value: string) => {
                        setSearch(value);
                    }}
                    searchColWidth="col-lg-8"
                />
                <div className="provider-form-part">
                    <div className="field-form mgl4">
                        <TextField
                            type={"date"}
                            label={t("form.start_date")}
                            value={startDate}
                            onChange={(event) => {
                                setStartDate(event.target.value as string);
                            }}
                        />
                    </div>
                    <div className="field-form mgl4">
                        <TextField
                            type={"date"}
                            label={t("form.end_date")}
                            value={endDate}
                            onChange={(event) => {
                                setEndDate(event.target.value as string);
                            }}
                        />
                    </div>
                </div>
                <div className="provider-form-part">
                    <div className="field-form mgl4">
                        <b className="label">{t("nav.clients")} </b>
                        <MultiSelect
                            options={clientList.map((client: Client) => {
                                return {
                                    label: client.company_name,
                                    value: client.id,
                                };
                            })}
                            value={clients}
                            onChange={setClients}
                            labelledBy={"Select"}
                            className="multi-select"
                            overrideStrings={{
                                selectSomeItems: " ",
                                allItemsAreSelected: t("allItemsAreSelected"),
                                selectAll: t("selectAll"),
                                search: t("search"),
                            }}
                        />
                    </div>
                    <div className="field-form mgl4">
                        <b className="label">{t("status_table")} </b>
                        <Select
                            value={status}
                            onChange={(event) => {
                                setStatus(event.target.value as string);
                            }}
                        >
                            {statuses.map((status: any) => (
                                <MenuItem
                                    value={status.value}
                                    key={`status-invoice-${status.value}`}
                                >
                                    {status.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <hr />
                <div className="provider-form-part">
                    <div className="field-form mgl4">
                        <b className="label">Tri par </b>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sort}
                            onChange={(event) => {
                                setSort(event.target.value as string);
                            }}
                        >
                            {filters.map((filter: any) => (
                                <MenuItem
                                    value={filter.value}
                                    key={`filter-invoice-${filter.value}`}
                                >
                                    {filter.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="field-form mgl4 radio-group">
                        <RadioGroup
                            style={{ flexDirection: "row", flexWrap: "nowrap" }}
                            value={dir}
                            onChange={(event) => {
                                setDir(event.target.value as string);
                            }}
                        >
                            <FormControlLabel
                                value="asc"
                                control={<Radio />}
                                label={t("asc")}
                            />
                            <FormControlLabel
                                value="desc"
                                control={<Radio />}
                                label={t("desc")}
                            />
                        </RadioGroup>
                    </div>
                </div>
            </div>
            <div className="content-modal-export">
                {data && data.length > 1 ? (
                    <>
                        <div className="flex-stretch-row">
                            <div className="flex-column item-export">
                                <div className="title-modal">
                                    {t("export_csv")}
                                </div>

                                <CSVLink
                                    filename={`${getFilename()}.csv`}
                                    data={data}
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        className="btn-form btn-icon mr-2"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        <span>{t("export_csv")}</span>{" "}
                                        <GetAppIcon />
                                    </Button>
                                </CSVLink>
                            </div>

                            <SeparatorVertical />

                            <div className="flex-column item-export">
                                <div className="title-modal">
                                    {t("export_excel")}
                                </div>
                                <Button
                                    onClick={() => exportExcel()}
                                    className="btn-form btn-icon mr-2"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                >
                                    <span>{t("export_excel")}</span>{" "}
                                    <GetAppIcon />
                                </Button>
                            </div>
                        </div>
                    </>
                ) : error ? (
                    error
                ) : (
                    <div>{loading ? t("loading") : t("no_items")}</div>
                )}
            </div>
        </Modal>
    );
};

export default DialogExportInvoice;
