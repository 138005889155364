interface IDataPaginate {
    current_page?: number;
    data: any;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string | null
    next_page_url: string | null 
    path: string
    per_page: number
    prev_page_url: string | null
    to: number
    total: number
}

export class DataPaginate {
    public current_page!: number
    public data!: any
    public first_page_url!: string
    public from!: number
    public last_page!: number;
    public last_page_url!: string 
    public next_page_url!: string
    public path!: string
    public per_page!: number
    public prev_page_url!: string
    public to!: number
    public total!: number

    constructor(params?: IDataPaginate | null){
        if(params){
            Object.assign(this, params);
        }
    }

    getData(){
        return this.data;
    }

    setData(data: any){
        this.data = data;
    }
}