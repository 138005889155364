import React from "react";
import "./ContentSection.scss";
import ExportAction, { ExportType } from "./export-action/ExportAction";
import AddAction from "./add-action/AddAction";
import ReturnAction from "./return-action/ReturnAction";
import { connect } from "react-redux";
import { User } from "../../models/user";
import { getPrimaryColor } from "../../containers/design-dependencies/ColorHelper";

interface IPropsContentSection {
    title: string;
    exportType?: ExportType;
    returnAction?: boolean;
    addAction?: string;
    suppContent?: any;
    user?: User;
    children?: any;
    searchBar?: any;
    style?: any;
    alertCancelled?: boolean;
}

const ContentSection: React.FunctionComponent<IPropsContentSection> = ({
    title,
    exportType,
    returnAction,
    addAction,
    suppContent,
    user,
    children,
    searchBar,
    style,
    alertCancelled = false,
}: IPropsContentSection) => {
    const styleBackgroundColor = {
        backgroundColor: getPrimaryColor(user?.role),
    };

    return (
        <div className="main-box">
            <div
                className={`main-box-title ${
                    !addAction ? "without-action" : ""
                } `}
                style={
                    style
                        ? { ...style, ...styleBackgroundColor }
                        : styleBackgroundColor
                }
            >
                <div className="row align-items-center">
                    <div className="col">{title}</div>
                    <div className="col-auto ml-auto">
                        {exportType ? (
                            <ExportAction exportType={exportType} />
                        ) : (
                            ""
                        )}
                        {addAction ? <AddAction model={addAction} /> : ""}
                        {searchBar ? "searchbar" : ""}
                        {returnAction ? (
                            <ReturnAction alertCancelled={alertCancelled} />
                        ) : (
                            ""
                        )}
                        {suppContent ? suppContent : ""}
                        {}
                    </div>
                </div>
            </div>
            <div className="main-box-content">{children}</div>
        </div>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(ContentSection);
