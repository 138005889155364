import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import BodyTableRow from "../../../components/table/BodyTableRow";
import BodyTableCell from "../../../components/table/BodyTableCell";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { format } from "../../../utils/AmountHelper";
import { connect } from "react-redux";
import { User } from "../../../models/user";
import { Invoice } from "../../../models/invoice";

interface ItemInvoiceProps {
    invoice: Invoice;
    setErrorApi: (error: ErrorFormData) => void;
    user: User;
}

const ItemInvoice: React.FunctionComponent<ItemInvoiceProps> = ({
    invoice,
    setErrorApi,
    user,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <>
            <BodyTableRow key={invoice.id}>
                <BodyTableCell className="img-index-driver ">
                    {invoice.code}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {invoice.folder?.client &&
                        invoice.folder.client.company_name}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {invoice.folder?.code}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {invoice.folder?.transfers
                        ? invoice.folder.transfers.length
                        : 0}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {format(
                        invoice.price_excl_tax ? invoice?.price_excl_tax : 0
                    )}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {format(
                        invoice.price_incl_tax ? invoice?.price_incl_tax : 0
                    )}
                </BodyTableCell>
            {user.isAdmin() &&
                <BodyTableCell align="center">
                    {format(
                        invoice.price_margin ? invoice?.price_margin : 0
                    )}
                </BodyTableCell>
            }
                <BodyTableCell align="center">
                    {invoice.deposit_paid_ratio !== null ? invoice.deposit_paid_ratio + ' %' : '-'}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {invoice.send_count
                        ? invoice.deadline_at?.toLocaleDateString()
                        : "-"}
                </BodyTableCell>
                <BodyTableCell align="center">
                    <Button
                        className="btn-rounded mr-2"
                        variant="contained"
                        onClick={() => history.push(`invoices/${invoice.id}`)}
                    >
                        {t("view_invoice")}
                    </Button>
                </BodyTableCell>

                <BodyTableCell align="center">
                    {invoice.send_count}
                </BodyTableCell>
                <BodyTableCell align="center">
                    <div className="tag-table-section">
                        <div className="tag-table-content">
                            <div
                                className="tag-table"
                                style={{
                                    backgroundColor: invoice.getColorByStatus(),
                                }}
                            >
                                <div className="tag-table">
                                    <span className="to-upper">
                                        {t(`invoice_status.${invoice.status}`)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </BodyTableCell>
            </BodyTableRow>
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(ItemInvoice);
