import {Deserializable} from "./interfaces/deserializable";
import {User} from "./user";
import {Reply} from "./reply";

export enum TicketType {
    TECHNICAL = "technical",
    INTERFACE = "interface",
    USER = "user"
}

export enum TicketState {
    UNPROCESS = "unprocess",
    RUNNING = "running",
    RESOLVE = "resolve"
}

export class Ticket implements Deserializable {
    public id: number;
    public title: string;
    public description: string;
    public from: User;
    public to: User;
    public type: TicketType;
    public state: TicketState;
    public phone_number: string;
    public email: string;
    public attachment: File;
    public img_url: string;
    public created_at: Date;
    public updated_at: Date;
    public replies: Reply[];

    constructor(data: any) {
        this.id = data.id
        this.title = data.title;
        this.description = data.description;
        this.from = data.from;
        this.to = data.to;
        this.type = data.type;
        this.state = data.state;
        this.phone_number = data.phone_number;
        this.email = data.email;
        this.created_at = new Date(data.created_at);
        this.updated_at = data.updated_at ? new Date(data.updated_at) : data.updated_at;
        this.attachment = data.attachment;
        this.img_url = data.img_url;
        this.replies = data.replies
            ? data.replies.map((reply: any) => {
                return new Reply(reply);
            })
            : [];
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}