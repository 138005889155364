import { Deserializable } from "./interfaces/deserializable";

export class VehicleColor implements Deserializable {
    public id: number;
    public color: string;
    public type: number;
    public hex_color?: string;
    public img_url?: string;
    public created_at: Date;
    public updated_at: Date;

    constructor(data:any) {
        this.id = data.id;
        this.color = data.color;
        this.type = data.type;
        this.hex_color = data.hex_color ? data.hex_color : null;
        this.img_url = data.img_url ? data.img_url : null;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}