import React from "react";
import { useTranslation } from "react-i18next";
import "./InfoCard.scss";
import { TransferResponseOption } from "../../../../models/transfer_response";
import { Icon } from "@material-ui/core";

interface Iprops {
    item: TransferResponseOption;
    remove: (e: any) => void;
}

const InfoCardOptions = ({ item, remove }: Iprops) => {
    const { t } = useTranslation();

    return (
        <div className="transfer_amount_card">
            <span className="label">{item?.label}</span>
            <div className="price-section">
                <span className="price">
                    {item.price} {t("money_symbol")}
                </span>
            </div>
            <div className="tva-section">
                <span className="tva">
                    {item.tva} {" %"}
                </span>
            </div>

            <div className="close_item" onClick={() => remove(item)}>
                <Icon className="close_icon">close</Icon>
            </div>
        </div>
    );
};

export default InfoCardOptions;
