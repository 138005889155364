import {
    CircularProgress,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorForm } from "../../../components/ErrorForm";
import GlobalSearch from "../../../components/global-search/globalSearch";
import KpiContentSeparator from "../../../components/kpi-content-separator/KpiContentSeparator";
import HeadTableCell from "../../../components/table/HeadTableCell";
import { Driver } from "../../../models/driver";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { DataPaginate } from "../../../models/utils/data-paginate";
import DriverService from "../../../services/driver-service";
import Paper from "@material-ui/core/Paper";
import ItemDriver from "./ItemDrivers";
import Pagination from "../../../components/pagination/pagination";
import "./ListDrivers.scss";
import { FiltersEnabled } from "../../../models/utils/filters/filter-enabled";
import FiltersDrivers from "./FiltersDrivers";
import { DriverFilters } from "../../../models/utils/filters/drivers/driver-filters";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";

interface IPropsListDrivers {
    fetchKpis: () => void;
}

const ListDrivers: React.FunctionComponent<IPropsListDrivers> = ({
    fetchKpis,
}: IPropsListDrivers) => {
    const { t } = useTranslation();
    const [dataPaginateDrivers, setDataPaginateDrivers] = useState(
        new DataPaginate()
    );
    const [pageDrivers, setPageDrivers] = useState(1);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState<string | null>(null);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [filters, setFilters] = useState<DriverFilters>();
    const [filtersEnabled, setFiltersEnabled] = useState<FiltersEnabled>(
        new FiltersEnabled()
    );
    const [isDeleted, setIsDeleted] = useState<number>(0);
    const [date, setDate] = useState(new Date());

    const query = new URLSearchParams(useLocation().search);
    const provider_id= query.get("providerId");
    const fetchAllDrivers = async () => {
        if (!loading) setLoading(true);
        try {
            const driverService = new DriverService();
            setDataPaginateDrivers(
                await driverService.all(
                    pageDrivers,
                    search,
                    null,
                    filtersEnabled,
                    provider_id
                )
            );
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const debounceQuery = useCallback(debounce(fetchAllDrivers, 500), [
        pageDrivers,
        search,
        filtersEnabled,
        date,
    ]);

    useEffect(() => {
        debounceQuery();
        return debounceQuery.cancel;
    }, [pageDrivers, search, filtersEnabled, date, debounceQuery]);

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const driverService = new DriverService(t);
                setFilters(await driverService.getFilter());
            } catch (e) {
                console.log(e);
            }
        };
        fetchFilters();
    }, [t]);

    const handleDeleted = () => {
        fetchAllDrivers()
        setIsDeleted(isDeleted + 1);
    };

    const handleFiltersChange = (filters: FiltersEnabled) => {
        setFiltersEnabled(filters);
        setPageDrivers(1);
    };

    return (
        <>
            {/* SEARCHBAR */}
            <GlobalSearch
                onSearch={(value: string) => {
                    setPageDrivers(1);
                    setSearch(value);
                }}
                onResetFilter={() => setDate(new Date())}
            />
            <FiltersDrivers
                filtersEnabled={filtersEnabled}
                filters={filters}
                setFilters={(filters) => handleFiltersChange(filters)}
            />
            <KpiContentSeparator />
            {dataPaginateDrivers &&
            dataPaginateDrivers.data?.length !== 0 &&
            !loading ? (
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("picture")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("last_name")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("first_name")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("phone")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("email")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("company_name")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("language")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh reset-password"
                                        align="center"
                                    >
                                        {t("reset_password")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("note")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("is_tenders")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="left"
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataPaginateDrivers.data && dataPaginateDrivers.data
                                .map(
                                    (driver: Driver) => (
                                        <ItemDriver
                                            key={`table-benefeciary-${driver.id}`}
                                            driver={driver}
                                            setErrorApi={(error) =>
                                                setErrorApi(error)
                                            }
                                            fetchKpis={() => fetchKpis()}
                                            handleDeleted={() =>
                                                handleDeleted()
                                            }
                                        />
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        dataPaginate={dataPaginateDrivers}
                        onPageChange={(page: number) => setPageDrivers(page)}
                    />
                </>
            ) : loading ? (
                <CircularProgress />
            ) : !errorApi || dataPaginateDrivers.data?.length === 0 ? (
                <div>{t("no_items")}</div>
            ) : (
                ""
            )}
            <ErrorForm errorApi={errorApi} />
        </>
    );
};

export default ListDrivers;
