import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ModalRedirectProvider.scss";
import { Button, CircularProgress, Icon } from "@material-ui/core";
import Modal from "../../../components/modal/Modal";
import { Provider } from "../../../models/provider";
import { useHistory } from "react-router-dom";

interface IPopupAccept {
    isOpen: boolean;
    closeDialog: () => void;
    provider: Provider;
    requestBeforeDisplay?: any;
    isDeleteAction?: boolean;
}

const ModalRedirectProvider = ({
    isOpen,
    closeDialog,
    provider,
}: IPopupAccept) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Modal
            label={t("impossible_delete_provider")}
            isOpen={isOpen}
            closeModal={() => closeDialog()}
        >
            <div className="modal-redirect-provider">
                <div className="title-section">
                    <h2 className="title-content to-upper">
                        {!provider.canBeDeleted() 
                        ? t(`delete_impossible_assigned`)
                        :provider.nb_vehicles !== 0
                        ? t(`delete_vehicles_before`)
                        : provider.nb_drivers !== 0 && t(`delete_drivers_before`)
                        }
                    </h2>
                </div>
                <div className="buttons-section">
                    {provider.canBeDeleted() &&
                        <Button
                            className="btn-form btn-icon"
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => {
                                if (provider.nb_vehicles !== 0) {
                                    history.push(
                                        `/app/vehicles?providerName=${provider.company_name}&providerId=${provider.id}`
                                    );
                                } else if (provider.nb_drivers !== 0) {
                                    history.push(
                                        `/app/drivers?providerId=${provider.id}`
                                    );
                                } else closeDialog();
                            }}
                        >
                            <span>
                                {provider.nb_vehicles !== 0
                                    ? t(`go_to_vehicles`)
                                    : provider.nb_drivers !== 0 &&
                                    t(`go_to_drivers`)}
                            </span>
                        </Button>
                    }
                </div>
            </div>
        </Modal>
    );
};

export default ModalRedirectProvider;
