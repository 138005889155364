import Modal from "../../modal/Modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ModalDeclineEstimate.scss";
import { Quote, RefusalCodes } from "../../../models/quote";
import { Button, FormControl, FormControlLabel, FormLabel, Icon, Radio, RadioGroup, TextareaAutosize } from "@material-ui/core";
import Colors from "../../../containers/design-dependencies/Colors";
import { ReactSVG } from "react-svg";
import { toastr } from "react-redux-toastr";
import QuoteService from "../../../services/quote-service";

interface IProps {
    isOpen: boolean,
    isAdmin?: boolean | false;
    quote: Quote,
    closeDialog: () => void,
    onDecline: (quote: Quote) => void,
}

export default function ModalDeclineEstimate({isOpen, isAdmin, quote, closeDialog, onDecline}: IProps) {
    const { t } = useTranslation();
    const [refusal, setRefusal] = useState<RefusalCodes>();
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);

    function changeValueRefusal(e:any) {
        setRefusal(e.target.value);
    }

    async function declineQuote() {
        if(!refusal){
            toastr.error(t('error'), t('error_decline_quotation'));
        }else {
            try{
                setLoading(true);
                const quoteService = new QuoteService();
                const quoteUpdated = await quoteService.decline(quote.id, refusal, comment);
                toastr.success(t('success'), t('sucess_quotation_canceled'));
                onDecline(quoteUpdated)
                setLoading(false);
            } catch(e){
                toastr.error(t("error"), t("unexpected_error"));
                setLoading(false);
            }
        }
    }
    
    return (
        <Modal
            label="Refuser le devis"
            isOpen={isOpen}
            closeModal={() => closeDialog()}
        >
            <div className="container-decline-quotation">
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t('reason_for_refusal')}</FormLabel>
                    <RadioGroup aria-label="gender" name="gender1" value={refusal} onChange={changeValueRefusal}>
                        <FormControlLabel value={RefusalCodes.FOLDER_CANCELLED} control={<Radio  disabled={loading} />} label={t('request_cancelled')} />
                        <FormControlLabel value={RefusalCodes.PRICE_ERROR} control={<Radio  disabled={loading} />} label={t('quotation_too_high')} />
                        <FormControlLabel value={RefusalCodes.OTHER} control={<Radio  disabled={loading} />} label={t('others')} />
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset">
                    <FormLabel component="legend">{t('comment')}</FormLabel>
                    <TextareaAutosize disabled={loading} onChange={(e) => setComment(e.target.value)} value={comment} className="textarea w-100" aria-label="empty textarea" rowsMin={4} placeholder={t('comment')} />
                </FormControl>

                <div className="actions actions-declined">
                    <Button disabled={loading} onClick={() => closeDialog()} className="btn-form btn-icon mr-2 submit-input" variant="contained" style={{backgroundColor: Colors.red}} size="large" type="button">
                        {t('cancel')} <ReactSVG src="/images/app_icons/icon-delete.svg" />
                    </Button>

                    <Button disabled={loading} onClick={() => declineQuote()} className="btn-form btn-icon mr-2 submit-input" variant="contained" color="primary" size="large" type="button">
                        {t('validate')}  <Icon>{ 'done'}</Icon>
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
