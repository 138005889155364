import React from "react";
import Dropdown from "./dropdown/Dropdown";
import "./ActionsSection.scss";
import ActionButton from "../action-button/ActionButton";
import { useTranslation } from "react-i18next";

interface IDropdownProps {
    children: any;
    labelDropdown?: any;
    valueDropdown: any;
    onChangeDropdown: (e: any) => void;
    onClickButton: () => void;
    labelButton?: any;
}

const ActionsSection = ({
    children,
    labelDropdown,
    valueDropdown,
    onChangeDropdown,
    onClickButton,
    labelButton = "validate",
}: IDropdownProps) => {
    const { t } = useTranslation();
    return (
        <div className="actions-section">
            <Dropdown
                onChange={(e) => onChangeDropdown(e)}
                value={valueDropdown}
                label={labelDropdown}
            >
                {children}
            </Dropdown>
            <ActionButton onClickAction={onClickButton}>
                {t(labelButton)}
            </ActionButton>
        </div>
    );
};

export default ActionsSection;
