import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AuthService from "../../../services/auth-service";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { User } from "../../../models/user";
import { ErrorForm } from "../../../components/ErrorForm";
import FormPassword, {
    RESETTING_PASSWORD,
} from "../form-password/FormPassword";
import { useTranslation } from "react-i18next";
import "./ResetPassword.scss";

const Register: React.FunctionComponent = (props) => {
    const { relationName, token } = useParams();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [user, setUser] = useState<null | User>(null);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const [userInterface, setUserInterface] = useState<string>();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const authService = new AuthService();
                const u = await authService.getUserByTokenResettingPassword(
                    relationName,
                    token
                );
                setUser(u);
                if (u instanceof User) {
                    setUserInterface(u.role);
                }
                setLoading(false);
            } catch (error) {
                setErrorApi(new ErrorFormData(error.response.data));
                setLoading(false);
            }
        };
        fetchUser();
    }, [token, relationName]);

    return (
        <div className="container-login-page reset-password-page flex_center">
            <div className="login-page">
                <div className="container-img">
                    <img src="/images/login/images/image-login.png" alt="" />
                </div>
                <div className="container-login">
                    <div className="container-title">
                        <h1 className="to-upper title">
                            {t("reset_password")}
                        </h1>
                    </div>
                    <div className="login flex_center">
                        <div className="form-container">
                            <div className="admin-logo-section">
                                <img
                                    className="logo-spark"
                                    src={`/images/login/images/logo.png`}
                                    alt=""
                                />
                            </div>
                            <div className="form-content">
                                {loading ? <span>{t("loading")}</span> : ""}
                                <FormPassword
                                    user={user}
                                    token={token}
                                    type={RESETTING_PASSWORD}
                                    relationName={relationName}
                                ></FormPassword>
                                <ErrorForm errorApi={errorApi} />
                                {errorApi && errorApi.error && (
                                    <h2
                                        style={{
                                            color: "red",
                                            textAlign: "center",
                                            marginTop: "30px",
                                        }}
                                    >
                                        {errorApi.error}
                                    </h2>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
