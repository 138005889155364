import { createStyles, TableCell, Theme, withStyles } from "@material-ui/core";
import React from "react";

interface IBodyTableCell {
  className?: string;
  align?: any;
  children?: any;
}

const BodyTableCell = ({
  className = "testTh",
  align = "center",
  children,
}: IBodyTableCell) => {
  return (
    <StyledTableCell className={className} align={align}>
      {children}
    </StyledTableCell>
  );
};

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontWeight: 700,
      border: "none",
      fontSize: "12px",
    },
  })
)(TableCell);

export default BodyTableCell;
