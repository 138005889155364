import axios, { AxiosResponse } from "axios";
import { User } from "../models/user";
import { getBaseUrl } from "./setting-service";


export default class UserService {

  update(body: any) {
    return axios
      .put(`${getBaseUrl()}users/me`,body)
      .then((response: AxiosResponse) => new User(response.data))
  }

  updateLanguage() {
    return axios
      .patch(`${getBaseUrl()}users/me/language`, {})
      .then((response: AxiosResponse) => new User(response.data))
  } 
}
