import axios, { AxiosResponse } from "axios";

import { getBaseUrl, getParamsUrl } from "./setting-service";
import { DataPaginate } from "../models/utils/data-paginate";
import { Credit } from "../models/credit";

export default class CreditService {
    get(id: number | string) {
        return axios
            .get(`${getBaseUrl()}credits/${id}`)
            .then((response: AxiosResponse) => new Credit(response.data))
            .catch();
    }

    all(
        page?: number | null,
        search?: string | null,
        folder?: number | null
    ) {
        let paramsUrl = getParamsUrl([
            { key: "page", value: page },
            { key: "search", value: search },
            { key: "folder", value: folder },
        ]);

        return axios
            .get(`${getBaseUrl()}credits${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;

                res.data = res.data.map(
                    (client: Object) => new Credit(client)
                );
                return new DataPaginate(res);
            });
    }

    add(body: any) {
        return axios
            .post(`${getBaseUrl()}credits`, body)
            .then((response: AxiosResponse) => new Credit(response.data))
            .catch();
    }
}
