import React, { useState } from 'react';
import './ExportAction.scss';
import { useTranslation } from "react-i18next";
import DialogExport from './dialog-export/DialogExport';
import WhiteButton from "../../white-button/WhiteButton";
import DialogExportInvoice from './dialog-export/DialogExportInvoice';

export enum ExportType {
    Provider = 1,
    Client = 2,
    ClientForClient = '2b',
    Transfer = 3,
    Invoice = 4,
    Ticket = 5,
}

interface IExportAction {
    exportType: ExportType
}

const ExportAction: React.FunctionComponent<IExportAction> = props => {
    const { exportType } = props; 
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <WhiteButton onClick={() => setIsOpen(true)} className="btn-rounded mr-2" variant="contained"
                color="primary">
                { (t('export').toUpperCase()) }
            </WhiteButton>

            { exportType === ExportType.Invoice
                ? <DialogExportInvoice
                    isOpen={isOpen} 
                    closeDialog={() => setIsOpen(false)} 
                />
                : <DialogExport 
                    exportType={exportType} 
                    isOpen={isOpen} 
                    closeDialog={() => setIsOpen(false)} 
                />
            }
        </>
    )
}

export default ExportAction;