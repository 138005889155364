import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from './setting-service';
import { VehicleOption } from '../models/vehicle-option';

export default class VehicleOptionService{

    all() {
        return axios.get(`${getBaseUrl()}vehicle-options`)
            .then( (response:AxiosResponse) => response.data.map( (model:Object)=> new VehicleOption(model)));
    }
}