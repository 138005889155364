import Modal from "../../components/modal/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ModalDelete.scss";
import { Button, CircularProgress, Icon } from "@material-ui/core";

interface IPopupAccept {
    isOpen: boolean;
    closeDialog: () => void;
    itemType: string;
    itemCode: string;
    itemName?: string;
    handleDelete: () => void;
    requestBeforeDisplay?: any;
    isDeleteAction?: boolean;
}

const ModalDelete = ({
    isOpen,
    closeDialog,
    itemType,
    itemCode,
    itemName,
    handleDelete,
    requestBeforeDisplay,
    isDeleteAction = false,
}: IPopupAccept) => {
    const { t } = useTranslation();
    const [codes, setCodes] = useState<Array<any>>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (isOpen) {
                if (requestBeforeDisplay) {
                    setIsLoading(true);
                    setCodes(await requestBeforeDisplay());
                    setIsLoading(false);
                }
            }
        })();
    }, [isOpen]);

    return (
        <Modal
            label={
                !isDeleteAction
                    ? t(`${itemType}_cancel`)
                    : t(`${itemType}_delete`)
            }
            isOpen={isOpen}
            closeModal={() => closeDialog()}
        >
            <div className="modal-delete">
                {!isLoading ? (
                    <>
                        <div className="title-section">
                            <h2 className="title-content to-upper">
                                {!isDeleteAction
                                    ? t(`sure_to_cancel_${itemType}`)
                                    : t(`sure_to_delete_${itemType}`)}
                            </h2>
                            {itemName ? (
                                <span className="name">{`${itemCode}: ${itemName}`}</span>
                            ) : (
                                <span className="name">{`${itemCode}`}</span>
                            )}
                            {codes && !isDeleteAction && (
                                <p style={{ color: "indianred" }}>
                                    {t("warning_transfers_folder_cancel")} :{" "}
                                    {codes.join(",")}
                                </p>
                            )}
                        </div>

                        <div className="buttons-section">
                            <div className="buttons-content">
                                <Button
                                    className="btn-form btn-icon"
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={() => closeDialog()}
                                >
                                    <span>{t("cancel")}</span>{" "}
                                    <Icon>close</Icon>
                                </Button>
                                <Button
                                    className="btn-form btn-icon"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => handleDelete()}
                                >
                                    <span>{t("validate")}</span>{" "}
                                    <Icon>check</Icon>
                                </Button>
                            </div>
                        </div>
                    </>
                ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default ModalDelete;
