import React, { useState } from 'react';
import './AccordionBox.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

interface IPropsAccordionBox{
    title: string,
    slotHeader?: any
}

const AccordionBox: React.FunctionComponent<IPropsAccordionBox> = props => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <div className="accordion_box">
            <div className="header_box">
                <span className="title">{props.title}</span>
                <div className="flex_row search-row">
                    {props.slotHeader && isOpen ? props.slotHeader : ''}
                    {isOpen ? <ExpandLessIcon onClick={ () => setIsOpen(false)} /> : ''}
                </div>
               
                { !isOpen ? <ExpandMoreIcon onClick={ () => setIsOpen(true)} /> : ''}
            </div>
            { isOpen ? (
                <div className="content_box">
                    {props.children}
                </div>
                ) : '' 
            }
        </div>
    )
};

export default AccordionBox;
