import { Deserializable } from "./interfaces/deserializable";

export class NotificationUser implements Deserializable {
    public id: number;
    public code: string;
    public type: string;
    public content: string;
    public is_active: boolean;
    public payload: any;
    public from: number;
    public to: number;
    public created_at: Date;
    public updated_at: Date;

    constructor(data: any) {
        this.id = data.id;
        this.code = data.code;
        this.type = data.type;
        this.content = data.content;
        this.is_active = data.is_active;
        this.payload = data.payload ? JSON.parse(data.payload) : null;
        this.from = data.from;
        this.to = data.to;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    getUrl() {
        switch (this.code) {
            case "driver_create": // Création d'un driver
                return `/app/driver/${this.payload.driver_id}`;
            case "transfer_assign_to_provider": // Assignation d'un transfert à un prestataire
                return `/app/transfer/${this.payload.transfer_id}/amount`;
            case "transfer_provider_unassign": // Unassignment d'un transfert à un prestataire
                return `/app/folder/${this.payload.folder_id}/edit?tab=transfers`;
            case "transfer_provider_accept": // Transfert accepté par le prestataire
                return `/app/folder/${this.payload.folder_id}/edit?tab=transfers`;
            case "transfer_provider_decline": // Transfert refusé par le prestataire
                return `/app/folder/${this.payload.folder_id}/edit?tab=transfers`;
            case "transfer_updated_to_provider": // Transfert attribué puis modifié
                return `/app/transfer/${this.payload.transfer_id}/amount/${this.payload.assignment_id}`;
            case "transfer_provider_choose":
                return `/app/transfers`;
            case "transfer_last_ended":
                return `/app/invoice/${this.payload.invoice_id}`;
            case "transfer_incomplete":
                return `/app/folder/${this.payload.folder_id}/edit`;
            case "folder_payment_update":
                return `/app/folder/${this.payload.folder_id}/edit`;
            case "quote_client_sent":
                return `/app/folder/${this.payload.folder_id}/edit?tab=quote`;
            case "quote_client_decline":
                return `/app/folder/${this.payload.folder_id}/edit`;
            case "quote_is_ready":
                return `/app/folder/${this.payload.folder_id}/edit&tab=quote`;
            case "quote_invalid":
                return `/app/folder/${this.payload.folder_id}/edit`;
            case "invoice_client_sent":
                return `/app/folder/${this.payload.folder_id}/edit`;
            case "create_ticket":
                return `/app/support/${this.payload.ticket_id}/reply`;
            case "close_ticket":
                return `/app/support/${this.payload.ticket_id}/reply`;
            case "reopen_ticket":
                return `/app/support/${this.payload.ticket_id}/reply`;
            case "reply_ticket_sent":
                return `/app/support/${this.payload.ticket_id}/reply`;
        }

        return "";
    }
}
