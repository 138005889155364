import React, { useEffect, useState } from "react";
import Echo from "laravel-echo";
import { Badge, IconButton } from "@material-ui/core";
import { Notifications } from "@material-ui/icons";
import { Roles, User } from "../../../models/user";
import { toastr } from "react-redux-toastr";
import NotificationService from "../../../services/notification-service";
import { NotificationUser } from "../../../models/notificationUser";
import { connect, useDispatch } from "react-redux";
import {
    addNotification,
    getNotifications,
    getNotificationsError,
    getNotificationsSuccess,
} from "../../../redux/store/notifications/actions";
import { useHistory } from "react-router-dom";
import ListNotification from "./ListNotifications/ListNotifications";
import AuthService from "../../../services/auth-service";
import { FiltersEnabled } from "../../../models/utils/filters/filter-enabled";

interface IProps {
    user?: User;
    notifications?: NotificationUser[];
}
function MenuNotification({ user, notifications }: IProps) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [echo, setEcho] = useState<Echo>();
    const [filtersEnabled, setFiltersEnabled] = useState<FiltersEnabled>(
        new FiltersEnabled()
    );

    useEffect(() => {
        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
        } else {
            Notification.requestPermission();
        }
    }, []);

    useEffect(() => {
        const getMessages = async () => {
            if (user && loading === true) {
                const authService = new AuthService();
                const token = await authService.getAuthToken();
                setEcho(
                    new Echo({
                        broadcaster: "socket.io",
                        host: process.env.REACT_APP_SOCKET_URL,
                        auth: { headers: { Authorization: `Bearer ${token}` } },
                    })
                );
            }
        };
        getMessages();
    }, [dispatch, user, loading]);

    useEffect(() => {
        if (echo && user) {
            echo.private(`private-channel-notif.${user.id}`).listen(
                ".notify",
                (e: any) => {
                    const notifUser = new NotificationUser(e.notif);
                    dispatch(addNotification(notifUser));
                    if (Notification.permission === "granted") {
                        let options: NotificationOptions = {
                            body: e.notif.content,
                            icon: `${window.location.protocol}//${window.location.host}/images/logo-sparklanes-white.png`,
                            dir: "ltr",
                            vibrate: 300,
                        };
                        const audio = new Audio(
                            `${window.location.protocol}//${window.location.host}/images/sound.mp3`
                        );
                        audio.play();
                        const notification = new Notification(
                            "Sparklanes",
                            options
                        );
                        notification.onclick = function () {
                            const link = notifUser.getUrl();
                            if (link) {
                                history.push(link);
                            }
                        };
                    } else {
                        toastr.info("Info", e.notif.content);
                    }
                    setLoading(false);
                }
            );
        }
    }, [echo, user, dispatch, history]);

    useEffect(() => {
        const loadNotifications = async () => {
            try {
                const notificationService = new NotificationService();
                dispatch(getNotifications());
                notificationService
                    .list(null, filtersEnabled)
                    .then((notifications) =>
                        dispatch(getNotificationsSuccess(notifications))
                    )
                    .catch(() => dispatch(getNotificationsError()));
            } catch (e) {
                console.log(e);
            }
        };
        loadNotifications();
    }, [dispatch, filtersEnabled]);

    useEffect(() => {
        return () => {
            if (user && echo) {
                echo.leave(`private-channel-notif.${user.id}`);
            }
        };
    }, [echo, user]);

    const isClient = () => {
        return user && user.role === Roles.CLIENT;
    };

    const getNbNotificationsActive = () => {
        return notifications
            ? notifications.filter((notif) => notif.is_active).length
            : 0;
    };

    return (
        <>
            <IconButton
                className="aua-notifications"
                color="inherit"
                onClick={() => setShowDetails(!showDetails)}
            >
                <Badge
                    badgeContent={getNbNotificationsActive()}
                    color="secondary"
                >
                    <Notifications color={isClient() ? "primary" : "inherit"} />
                </Badge>
            </IconButton>
            <ListNotification
                show={showDetails}
                notifications={notifications}
                filtersEnabled={filtersEnabled}
                setFilters={setFiltersEnabled}
                close={() => setShowDetails(false)}
            />
        </>
    );
}

function mapStateToProps(state: any) {
    return {
        notifications: state.notifications.list,
        loading: state.notifications.loading,
    };
}

export default connect(mapStateToProps)(MenuNotification);
