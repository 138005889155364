import React from "react";
import { Incident } from "../../../models/incident";
import { format } from "../../../utils/AmountHelper";
import "./Line.scss";
import IconIncident from "../../icon-incident/IconIncident";

interface IProps {
    transferId: number;
    class_name: string;
    index: any;
    label: string;
    value?: any;
    amountMargin?: any;
    percentMargin?: any;
    isDetail?: boolean;
    canEdit?: boolean;
    isEditing?: boolean;
    sellingPrice?: number;
    lineIsEditable?: boolean;
    incidents?: Incident[] | null;
    setSellingPrice?: (transferId: number, sellingPrice: number) => void;
}
export default function Line({
    transferId,
    class_name,
    index,
    label,
    value,
    amountMargin,
    percentMargin,
    isDetail = true,
    canEdit = true,
    isEditing = false,
    lineIsEditable = true,
    sellingPrice = 0,
    incidents = null,
    setSellingPrice
}: IProps) {
    return (
        <>
            {!isDetail ? (
                <div className={`${class_name} line`}>
                    <div className="label">
                        <div className="num">
                            <img src="/images/num.png" alt="" />
                            <span>{index}</span>
                        </div>
                        <span className="text">{label}</span>
                        {incidents && incidents.map(incident => 
                            <IconIncident incident={incident} />
                        )}
                    </div>
                    <div style={{display: 'flex'}}>
                      
                        {canEdit ?
                            <>
                                <div style={{width: 200, textAlign: 'right'}}>
                                    <span className="value">{lineIsEditable ? format(value) : '-'}</span>
                                </div>
                                <div style={{width: 100, textAlign: 'right'}}>
                                    {isEditing && lineIsEditable ? 
                                        <input
                                            value={sellingPrice}
                                            type="number"
                                            onChange={(e: any) => setSellingPrice ? setSellingPrice(transferId, parseFloat(e.target.value)) : null }
                                        /> : 
                                        <div style={{width: 100, textAlign: 'right'}}>
                                            <span className="value">{format(sellingPrice)}</span>
                                        </div> 
                                    }
                                </div>
                                {lineIsEditable ? <div style={{width: 100, textAlign: 'right'}}>{amountMargin ? amountMargin.toFixed(2) : 0} €</div> :<div style={{width: 100, textAlign: 'right'}}>-</div> } 
                                {lineIsEditable ? <div style={{width: 100, textAlign: 'right'}}>{percentMargin ? percentMargin.toFixed(2) : 0} %</div> : <div style={{width: 100, textAlign: 'right'}}>-</div> }
                            </> :
                            <>
                                <div style={{width: 100, textAlign: 'right'}}>
                                    <span className="value">{format(sellingPrice)}</span>
                                </div> 
                            </>
                        }
                    </div>
                </div>
            ) : (
                <div className="line detail">
                    <div className="label">
                        <span className="text">{label}</span>
                    </div>
                </div>
            )}
            {label.includes("Drop off") && (
                <span className="separator" style={{ paddingLeft: 53 }}>
                    ----------------------------------------------------------------------------------
                </span>
            )}
        </>
    );
}
