import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ModalCancel.scss";
import { Button, FormControl, FormLabel, Icon, Input } from "@material-ui/core";
import Colors from "../../../../containers/design-dependencies/Colors";
import { ReactSVG } from "react-svg";
import { toastr } from "react-redux-toastr";
import { Invoice } from "../../../../models/invoice";
import Modal from "../../../../components/modal/Modal";
import InvoiceService from "../../../../services/invoice-service";
import { ErrorForm } from "../../../../components/ErrorForm";
import { ErrorFormData } from "../../../../models/interfaces/errorForm";

interface IProps {
    isOpen: boolean;
    invoice: Invoice;
    closeDialog: () => void;
    afterAdd: (updated: Invoice) => void;
}

export default function ModalPenality({
    isOpen,
    invoice,
    closeDialog,
    afterAdd,
}: IProps) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [amount, setAmount] = useState("");
    const [comment, setComment] = useState("");
    const [errorApi, setErrorApi] = useState(new ErrorFormData());

    async function createPenality() {
        try {
            setLoading(true);
            const service = new InvoiceService();
            const updated = await service.penality(invoice.id, {
                amount: amount,
                comment: comment,
            });
            toastr.success(t("success"), t("success_penality_created"));
            afterAdd(updated);
            setLoading(false);

            closeDialog();
        } catch (e) {
            setErrorApi(new ErrorFormData(e.response.data));
            toastr.error(t("error"), t("unexpected_error"));
            setLoading(false);
        }
    }

    return (
        <Modal
            label={t("add_penality")}
            isOpen={isOpen}
            closeModal={() => closeDialog()}
        >
            <div className="container-decline-quotation">
                <div className="row">
                    <div className="col-3">
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                {t("amount")}
                            </FormLabel>
                            <Input
                                onChange={(e) => setAmount(e.target.value)}
                                value={amount}
                                type="number"
                                placeholder={t("amount")}
                            />
                        </FormControl>
                    </div>
                    {/*<div className="col-9">
                        <FormControl component="fieldset">
                            <FormLabel component="legend">{t('comment')}</FormLabel>
                            <TextareaAutosize onChange={(e) => setComment(e.target.value)} value={comment} className="textarea w-100" aria-label="empty textarea" rowsMin={4} placeholder={t('comment')} />
                        </FormControl>
                    </div>*/}
                </div>
                <div className="actions actions-declined">
                    <Button
                        disabled={loading}
                        onClick={() => closeDialog()}
                        className="btn-form btn-icon mr-2 submit-input"
                        variant="contained"
                        style={{ backgroundColor: Colors.red }}
                        size="large"
                        type="button"
                    >
                        {t("cancel")}{" "}
                        <ReactSVG src="/images/app_icons/icon-delete.svg" />
                    </Button>

                    <Button
                        disabled={loading}
                        onClick={() => createPenality()}
                        className="btn-form btn-icon mr-2 submit-input"
                        variant="contained"
                        color="primary"
                        size="large"
                        type="button"
                    >
                        {t("validate")} <Icon>{"done"}</Icon>
                    </Button>
                </div>
            </div>
            <ErrorForm errorApi={errorApi} />
        </Modal>
    );
}
