import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { User } from '../../models/user';

interface IProps{
  user: User
}
export function LeftSideNavClient({user}: IProps){
    const { t } = useTranslation();
    let { url } = useRouteMatch();
    
    return (
        <>
          <List className="asm-list asml-top">

            <Link className="side-nav-link" to={`${url}`}> 
              <ListItem button>
                <ListItemIcon>
                  <img src={require("../menu-icons/home.svg")} alt="Clients" />
                </ListItemIcon>
                <ListItemText primary={t("nav.home")}/>
              </ListItem>
            </Link>

            <Link className="side-nav-link" to={`${url}/clients`}>
              <ListItem button>
                <ListItemIcon>
                  <img src={require("../menu-icons/users.svg")} alt="Clients" />
                </ListItemIcon>
                <ListItemText primary={t("nav.clients")}/>
              </ListItem>
            </Link>

            <Link className="side-nav-link" to={`${url}/folders`}>
              <ListItem button>
                <ListItemIcon>
                  <img
                    src={require("../menu-icons/devis.svg")}
                    alt={t("nav.estimate")}
                  />
                </ListItemIcon>
                <ListItemText primary={t("nav.estimate_and_folders")} />
              </ListItem>
            </Link>

            <Link className="side-nav-link" to={`${url}/transfers`}> 
              <ListItem button>
                <ListItemIcon>
                  <Link className="side-nav-link" to={`${url}/transfers`}>
                    <img src={require("../menu-icons/bxl-bitcoin.svg")} alt="Dossiers" />
                  </Link>
                </ListItemIcon>
                <ListItemText primary={t("nav.transfers")}/>
              </ListItem>
            </Link>

            <Link className="side-nav-link" to={`${url}/invoices`}>
              <ListItem button>
                <ListItemIcon>
                  <img
                    src={require("../menu-icons/devis.svg")}
                    alt= {t("nav.billing")}
                  />
                </ListItemIcon>
                <ListItemText primary={t("nav.billings")}/>
              </ListItem>
            </Link>

            <Link className="side-nav-link" to={`${url}/support`}>
              <ListItem button>
                <ListItemIcon>
                  <img src={require("../menu-icons/help.svg")} alt="Support" />
                </ListItemIcon>
                <ListItemText primary={t("nav.support")}/>
              </ListItem>
            </Link>

            <Link className="side-nav-link" to={`${url}/trackings`}>
              <ListItem button>
                <ListItemIcon>
                    <img
                      src={require("../menu-icons/tracking.svg")}
                      alt= {t("nav.tracking")}
                    />
                </ListItemIcon>
                <ListItemText primary={t("nav.tracking")}/>
              </ListItem>
            </Link>
            
          </List>
        </>
    );
}