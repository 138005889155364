import React, { useState, useEffect } from "react";
import ContentSection from "../../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import AddressService from "../../../services/address-service";
import { Address } from "../../../models/address";
import { CircularProgress } from "@material-ui/core";
import CountryService from "../../../services/country-service";
import { Country } from "../../../models/country";
import FormAddress from "./form-address/FormAddress";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { ErrorForm } from "../../../components/ErrorForm";
import { Client } from "../../../models/client";
import ClientService from "../../../services/client-service";

const AddressFormPage = () => {
    const { t } = useTranslation();
    const { address_id, client_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [address, setAddress] = useState<Address>();
    const [client, setClient] = useState<Client>();
    const [countries, setCountries] = useState<Array<Country>>([]);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    let query = new URLSearchParams(useLocation().search);
    let defaultAddress = Boolean(query.get("default_address"));

    useEffect(() => {
        const fetchAccount = async () => {
            const countryService = new CountryService();
            setCountries(await countryService.all());
            if (client_id) {
                try {
                    const clientService = new ClientService();
                    setClient(await clientService.get(client_id));
                } catch (error) {
                    setLoading(false);
                    setErrorApi(new ErrorFormData(error.response.data));
                }
            }
            if (address_id) {
                try {
                    setLoading(true);
                    const addressService = new AddressService();
                    setAddress(await addressService.get(address_id));
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    setErrorApi(new ErrorFormData(error.response.data));
                }
            }
            setLoading(false);
        };

        fetchAccount();
    }, [address_id, client_id]);
    return (
        <>
            <ContentSection
                title={(address ? t("address_edit") : t("address_add")) + (client ?  ' - ' + client.company_name : '') }
                returnAction={!defaultAddress}
            >
                {loading ? (
                    <CircularProgress />
                ) : address && client ? (
                    <FormAddress
                        client={client}
                        countries={countries}
                        address={address}
                        defaultAddress={defaultAddress}
                    />
                ) : client ? (
                    <FormAddress
                        client={client}
                        countries={countries}
                        defaultAddress={defaultAddress}
                    />
                ) : (
                    <CircularProgress />
                )}
                <ErrorForm errorApi={errorApi} />
            </ContentSection>
        </>
    );
};

export default AddressFormPage;
