import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Step, Transfer } from "../../../models/transfer";
import TrackingService from "../../../services/tracking-service";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { AppBar, Button, CircularProgress, Icon, Tab, Tabs } from "@material-ui/core";
import "./DetailTracking.scss";
import { ErrorForm } from "../../../components/ErrorForm";
import Map from "../../../components/map/Map";

interface IProps {
    id: number;
}

enum StepEnum {
    started = 0,
    in_place = 1,
    pickup = 2,
    dropoff = 3,
}

const DetailTracking: React.FunctionComponent<IProps> = ({
    id,
}: IProps) => {
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [transfer, setTransfer] = useState<Transfer | null>(null);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const dataService = new TrackingService();
                setTransfer(await dataService.get(id));
            } catch (error) {
                setErrorApi(new ErrorFormData());
            }
            setLoading(false);
            setReload(false);
        };
        if (reload) {
            fetchData();
        }
    }, [id, reload]);

    function getInitialTab() {
        return StepEnum.started;
    }
    const [currentTab, setCurrentTab] = useState(getInitialTab());

    function getStep(status: string) : Step | null{
        let matches = transfer?.steps?.filter((step: Step) => (step.status === status));

        //last matching step
        return matches?.length ? matches.slice(-1)[0] : null;
    }

    function getUpdateDate() : string{
        let date = '';

        let lastStep = (transfer && transfer?.steps?.length) ? transfer.steps.slice(-1)[0] : null;
        
        if (lastStep) {
            date = lastStep.updated_at.toLocaleTimeString();

            if (lastStep.updated_at !== new Date()) {
                date += ' (' + lastStep.updated_at.toLocaleDateString() + ')';
            }
        }

        return date;
    }

    function formatStepTime(status: string){
        let time = '';
        let step = getStep(status);

        if (step) {
           time += step.updated_at.getHours() + 'h ';
           time += step.updated_at.getMinutes() + 'm ';
           time += step.updated_at.getSeconds() + 's ';
        }

        return time

    }

    function getEnumStepCodes() {
        return Object.keys(StepEnum).filter((key: any) => isNaN(key));
    }
  
    return (
        <>
           {transfer && !reload
            ? <>
                <div className="row">
                    <div className="detail col-12 col-lg-5">
                        {renderDetail()}
                    </div>
                    <div className="maps col-12 col-lg-7">
                        {renderTabMaps()}
                    </div>
                </div>
            </>
            : loading ? <CircularProgress /> : ''
           }
           <ErrorForm errorApi={errorApi} />
        </>
    );

    function renderDetail() {
        return (
            transfer ? <>
                <div>
                    <h2 className="title">{t('tracking_detail.mission')}</h2>
                    <div className="row mission">
                        <div className="col-8">
                            <div className="date">
                               {
                                   transfer.departure_datetime?.toLocaleDateString(undefined, {
                                        weekday: 'long',
                                        //year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })
                               }
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    {t("tracking_detail.steps.pickup")} :
                                    <div className="time">
                                        {transfer.departure_datetime?.toLocaleTimeString().slice(0, -3)}
                                    </div>
                                </div>
                                <div className="col-6">
                                    {t("tracking_detail.steps.dropoff")} :
                                    <div className="time">
                                        {transfer.arrival_datetime?.toLocaleTimeString().slice(0, -3)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="point">
                                <Icon>place</Icon>{transfer.departure_location}
                            </div>
                            <div className="point">
                                <Icon>flag</Icon>{transfer.arrival_location}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 className="title">{t('tracking_detail.position')}</h2>
                    <div className="row position">
                        {getEnumStepCodes().map((code: string) => (
                            <div className="col-3 status" key={"pos-"+code}>
                                <div className={"box"  + (getStep(code) ? ' active ' : ' ') + code }>
                                    <span>{t("tracking_detail.status."+code+"_at")}</span>
                                </div>
                                <div className="hour">
                                    { formatStepTime(code)}
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
                <div>
                    <h2 className="title">{t('tracking_detail.update')}</h2>
                    <div className="row ml-1">
                        <div className="col-6">
                            <div className="label">{t('tracking_detail.updated_at')}</div>
                            <div className="ml-1">{getUpdateDate()}</div>
                        </div>
                        <div className="col-6">
                            <Button
                                className="btn-form btn-icon refresh"
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={() => setReload(true)}
                            >
                                <span>{t("refresh")}</span> <Icon>refresh</Icon>
                            </Button>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 className="title">{t('incident')}</h2>
                </div>
            </> : <></>
        )
    }

    function renderTabMaps() {
        return (
            <>
                <AppBar position="static">
                    <Tabs
                        value={currentTab}
                        onChange={(event, newValue) => setCurrentTab(newValue)}
                    >
                        {getEnumStepCodes().map((code: string) => (
                            <Tab
                                key={"tab_"+code}
                                label={t("tracking_detail.steps." + code)}
                                disabled={!getStep(code)}
                            />
                        ))}
                    </Tabs>
                </AppBar>
                {getEnumStepCodes().map((code: string) => (
                    currentTab === StepEnum[code as keyof typeof StepEnum] && (
                        <div className="map" key={"map-"+code}>
                            {renderMap(code)}
                        </div>
                    )
                ))}
            </>
        )
    }

    function renderMap(status: string) {
        const step: Step|null= getStep(status);

        return step?.geo_lat && step?.geo_long
            ?(
                <Map 
                    lat={step.geo_lat}
                    long={step.geo_long}
                    zoom={16}
                    height={500} 
                />
            )
            : (
                <>{t("tracking_detail.gps_unavailable")}</>
            )
    }
};


export default (DetailTracking);

