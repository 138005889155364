import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import NewIndicator, {
    IndicatorType,
} from "../../../components/indicator/NewIndicator";
import TransferService from "../../../services/transfer-service";
import UseStyles from "../../design-dependencies/UseStyles";

interface IKpiTransfer {
    pending: number;
    accepted: number;
    refused: number;
    validated: number;
    ended: number;
}

interface IListParams {
    folderId?: number | null;
    providerId?: number | null;
}
const KpiTransfersProvider = ({ folderId, providerId }: IListParams) => {
    const [kpis, setKpis] = useState<IKpiTransfer>();
    const { t } = useTranslation();

    useEffect(() => {
        const getKpis = async () => {
            try {
                const service = new TransferService();
                let res = providerId
                    ? await service.getKpisTransfersOfProvider(providerId)
                    : await service.getKpis(folderId ? folderId : null);
                setKpis(res);
            } catch (error) {
                console.log("error", error);
            }
        };

        getKpis();
    }, [folderId, providerId]);

    return (
        <div className="row">
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().greenBg}
                    index={0}
                    text={t("transfer_kpis_status.requested_price")}
                    type={IndicatorType.TransferProvider}
                    total={kpis?.pending ? kpis.pending : 0}
                />
            </div>
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().mintBg}
                    index={1}
                    text={t("transfer_kpis_status.accepted")}
                    type={IndicatorType.TransferProvider}
                    total={
                        (kpis?.accepted ? kpis.accepted : 0) +
                        (kpis?.validated ? kpis.validated : 0)
                    }
                />
            </div>
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().orangeBg}
                    index={2}
                    text={t("transfer_kpis_status.refused")}
                    type={IndicatorType.TransferProvider}
                    total={kpis?.refused ? kpis.refused : 0}
                />
            </div>
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().greyBg}
                    index={3}
                    text={t("transfer_kpis_status.finished")}
                    type={IndicatorType.TransferProvider}
                    total={kpis?.ended ? kpis.ended : 0}
                />
            </div>
        </div>
    );
};

export default KpiTransfersProvider;
