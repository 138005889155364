import React from "react";
import KpiClients from "./kpi-clients/KpiClients";
import ContentSection from "../../components/content-section/ContentSection";
import {useTranslation} from "react-i18next";
import { ExportType } from "../../components/content-section/export-action/ExportAction";
import { User } from "../../models/user";
import { connect } from "react-redux";
import ListClients from "./list-clients/ListClients";

interface IPropsClientPage {
    user:User
}

function ClientPage({ user }: IPropsClientPage)  {
    const { t } = useTranslation();

    const canAdd = () => {
        if (!user) {
            return false;
        }
        if (user.isAdmin()) {
            return true;
        }
        if (user.isClient()) {
            //can only add subaccount if main account onwer
            return false
            // return user.getMainClientOwned().length > 0;
        }
    }

    //Return the appropriate content
    return (
        <>
            <ContentSection title={t('nav.clients')}
                exportType={user.isClient() ? ExportType.ClientForClient : ExportType.Client}
                {... canAdd() ? { addAction: 'client'} : {}}
            >
                <KpiClients />
                <ListClients />
            </ContentSection>
        </>
    )
}

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(ClientPage);