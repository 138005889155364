import React from "react";
import "./LinkArrayNumber.scss";
import { useHistory } from "react-router-dom";

interface IProps {
    number?: number;
    link?: string;
    onChange?: () => void;
}
export default function LinkArrayNumber({
    number = 0,
    link = "",
    onChange = () => console.log("test"),
}: IProps) {
    const history = useHistory();

    return (
        <div
            className="link-item"
            onClick={() => (link !== "" && number !== 0 ? history.push(`${link}`) : onChange())}
            style={number !== 0 ? {cursor: 'pointer'} : {}}
        >
            <span className="title">Voir</span>
            <div className="rounded">
                <span>{number}</span>
            </div>
        </div>
    );
}
