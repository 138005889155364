import React, { useState } from "react";
import { Address } from "../../../../models/address";
import ActionTable from "../../../../components/action-table/ActionTable";
import { useHistory } from "react-router-dom";
import BodyTableCell from "../../../../components/table/BodyTableCell";
import BodyTableRow from "../../../../components/table/BodyTableRow";
import { connect } from "react-redux";
import { User } from "../../../../models/user";
import { Client } from "../../../../models/client";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import ClientService from "../../../../services/client-service";
import { ErrorFormData } from "../../../../models/interfaces/errorForm";
import { ErrorForm } from "../../../../components/ErrorForm";
import { toastr } from "react-redux-toastr";
import ModalDelete from "../../../../components/modal/ModalDelete";
import AddressService from "../../../../services/address-service";

interface IItemAddress {
    address: Address;
    user: User;
    client: Client;
    refreshClient: () => void;
}

const ItemAddress: React.FunctionComponent<IItemAddress> = ({
    address,
    user,
    client,
    refreshClient,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());

    const editAccount = () => {
        history.push(`/app/client/${address.client_id}/address/${address.id}`);
    };

    const [isOpen, setIsOpen] = useState(false);

    const handleDeleted = async () => {
        try {
            const addressService = new AddressService();
            await addressService.delete(address.id);
            setIsOpen(false);
            toastr.success(t("success"), t("success_delete_address"));
            refreshClient();
        } catch (error) {
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    const updateDefaultAddress = async () => {
        try {
            const clientService = new ClientService();
            await clientService.updateDefaultAddress(client.id, address.id);
            toastr.success(t("success"), t("success_update_default_address"));
            refreshClient();
        } catch (error) {
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    return (
        <>
            <BodyTableRow>
                <BodyTableCell align="center">{address.name}</BodyTableCell>
                <BodyTableCell align="center">{address.street}</BodyTableCell>
                <BodyTableCell align="center">{address.misc}</BodyTableCell>
                <BodyTableCell align="center">
                    {address.post_code}
                </BodyTableCell>
                <BodyTableCell align="center">{address.city}</BodyTableCell>
                <BodyTableCell align="center">
                    {address.country?.label}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {client.address && client.address.id === address.id ? (
                        t("default_address")
                    ) : (
                        <Button
                            onClick={() => updateDefaultAddress()}
                            className="btn-form"
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                        >
                            {t("set_default_address")}
                        </Button>
                    )}
                    <ErrorForm errorApi={errorApi} />
                </BodyTableCell>
                <BodyTableCell align="center">
                    {
                        // !user.isClient() &&
                        address.client && !user.isReader(address.client) && (
                            <>
                                <ActionTable
                                    deleteAction={() => setIsOpen(true)}
                                    updateAction={() => editAccount()}
                                />
                                <ModalDelete
                                    isOpen={isOpen}
                                    closeDialog={() => setIsOpen(false)}
                                    itemType="address"
                                    itemCode={address.post_code}
                                    itemName={address.name}
                                    handleDelete={() => handleDeleted()}
                                    isDeleteAction
                                />
                            </>
                        )
                    }
                </BodyTableCell>
            </BodyTableRow>
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(ItemAddress);
