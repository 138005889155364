import React from "react";
import './FormTitleMultiple.scss';

interface IPropsFormSection {
    title: string;
    field: string | React.ReactNode;
    children?: any;
    className?: string;
    marginTitle?: boolean;
    sectionFlex?: boolean;
    clickable?: boolean;
    onClick?: () => void;
}

const FormTitleMultiple = ({
    title,
    field,
    children,
    className,
    marginTitle = false,
    sectionFlex = false,
    clickable = false,
    onClick,
}: IPropsFormSection) => {
    return (
        <div className="form-section">
            <div
                className={`section-title${clickable ? "-clickable" : ""} ${
                    marginTitle ? "mgt4" : "mgt2"
                }`}
                onClick={onClick}
            >
                <span>{title}</span>
                <span className="head-state">{field}</span>
            </div>
            <div className="section-border" />
            <div
                className={`content-form-section${
                    sectionFlex ? "-multiple" : ""
                } ${className ? className : ""}`}
            >
                {children}
            </div>
        </div>
    );
};

export default FormTitleMultiple;
