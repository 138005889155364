
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ModalCancel.scss";
import { Button, Icon } from "@material-ui/core";
import Colors from "../../../../containers/design-dependencies/Colors";
import { ReactSVG } from "react-svg";
import { toastr } from "react-redux-toastr";
import { Invoice } from "../../../../models/invoice";
import Modal from "../../../../components/modal/Modal";
import InvoiceService from "../../../../services/invoice-service";

interface IProps {
    isOpen: boolean,
    invoice: Invoice,
    closeDialog: () => void,
    afterCancel: () => void,
}

export default function ModalCancel({isOpen, invoice, closeDialog, afterCancel}: IProps) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    async function cancelInvoice() {

        try{
            setLoading(true);
            const service = new InvoiceService();
            await service.cancel(invoice.id);
            toastr.success(t('success'), t('sucess_quotation_canceled'));
            afterCancel()
            setLoading(false);

            closeDialog()
        } catch(e){
            toastr.error(t("error"), t("unexpected_error"));
            setLoading(false);
        }
    }

    return (
        <Modal
            label={t("confirm_invoice_cancelling")}
            isOpen={isOpen}
            closeModal={() => closeDialog()}
        >
            <div className="container-decline-quotation">
                <div className="actions actions-declined">
                    <Button disabled={loading} onClick={() => closeDialog()} className="btn-form btn-icon mr-2 submit-input" variant="contained" style={{backgroundColor: Colors.red}} size="large" type="button">
                        {t('no')} <ReactSVG src="/images/app_icons/icon-delete.svg" />
                    </Button>

                    <Button disabled={loading} onClick={() => cancelInvoice()} className="btn-form btn-icon mr-2 submit-input" variant="contained" color="primary" size="large" type="button">
                        {t('yes')}  <Icon>{ 'done'}</Icon>
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
