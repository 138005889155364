import React, { useState, useRef } from "react";
import { User } from "../../../models/user";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import AuthService from "../../../services/auth-service";
import { history } from "../../app/App";
import { ErrorForm } from "../../../components/ErrorForm";
import { Driver } from "../../../models/driver";
import "./FormPassword.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { toastr } from "react-redux-toastr";

interface FormRegister {
    password: string;
    password_repeat: string;
}

interface iProps {
    user: User | Driver | null;
    token: string;
    type: string;
    relationName: string;
}

export const VALIDATE_ACCOUNT = "VALIDATE_ACCOUNT";
export const RESETTING_PASSWORD = "RESETTING_PASSWORD";

const FormPassword: React.FunctionComponent<iProps> = (props) => {
    const { register, handleSubmit, errors, watch } = useForm<FormRegister>();
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [successMessage, setSuccessMessage] = useState();
    const [captchaIsValid, setCaptchaIsValid] = useState(false);
    const password = useRef({});
    password.current = watch("password", "");
    const passwordRequired = t("errors.required", { field: t("password") });
    const passwordMin = t("errors.min", { field: t("password"), number: 8 });
    const passwordNotMatch = t("password_not_watch");

    const onSubmit = async (data: any) => {
        if (captchaIsValid) {
            setErrorApi(new ErrorFormData());
            const authService = new AuthService();

            if (props.type === VALIDATE_ACCOUNT) {
                try {
                    data["token_activation"] = props.token;
                    await authService.validateAccount(props.relationName, data);

                    props.user instanceof User
                        ? history.push(`/login?tab=${props.user.role}`)
                        : history.push("/login");
                } catch (error) {
                    setErrorApi(new ErrorFormData(error.response.data));
                }
            } else if (props.type === RESETTING_PASSWORD) {
                try {
                    data["token_resetting_password"] = props.token;
                    await authService.resettingPassword(
                        props.relationName,
                        data
                    );
                    if (props.relationName === "users") {
                        props.user instanceof User
                            ? history.push(`/login?tab=${props.user.role}`)
                            : history.push("/login");
                    } else {
                        setSuccessMessage(
                            t("success_msg_reset_password_drivers")
                        );
                    }
                } catch (error) {
                    setErrorApi(new ErrorFormData(error.response.data));
                }
            }
        } else {
            toastr.error(t("error"), t("error_catpcha"));
        }
    };

    if (props.user) {
        return (
            <form
                className="form flex_column"
                onSubmit={handleSubmit(onSubmit)}
            >
                {errors.password && <p>{errors.password.message}</p>}
                {errors.password_repeat && (
                    <p>{errors.password_repeat.message}</p>
                )}
                {successMessage && <p>{successMessage}</p>}
                <ErrorForm errorApi={errorApi} />
                {errorApi && errorApi.error && <h1>{errorApi.error}</h1>}
                <div className="input-group email-group">
                    <input
                        className="input"
                        type="password"
                        name="password"
                        ref={register({
                            required: passwordRequired,
                            minLength: {
                                value: 8,
                                message: passwordMin,
                            },
                        })}
                    />
                    <label htmlFor="password">Mot de passe</label>
                </div>

                <div className="input-group email-group">
                    <input
                        className="input"
                        type="password"
                        name="password_repeat"
                        ref={register({
                            validate: (value) =>
                                value === password.current || passwordNotMatch,
                        })}
                    />
                    <label htmlFor="password_repeat">
                        Confirmer le mot de passe
                    </label>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <ReCAPTCHA
                        sitekey={
                            process.env.REACT_APP_RECAPTCHA_V2_KEY
                                ? process.env.REACT_APP_RECAPTCHA_V2_KEY
                                : "BAD_KEY"
                        }
                        onChange={(value) => setCaptchaIsValid(value !== null)}
                    />
                </div>

                <div className="submit-section">
                    <div className="submit flex_row">
                        <input type="submit" value="Envoyer" />
                        <img
                            className="logo"
                            src="/images/login/icons/login.png"
                            alt=""
                        />
                    </div>
                </div>
            </form>
        );
    }

    return <></>;
};

export default FormPassword;
