import React from "react";
import FormTitle from "../form-title/FormTitle";
import "./FormSection.scss";

interface IPropsFormSection {
  title: string;
  children?: any;
  className?: string;
  marginTitle?: boolean;
}

const FormSection = ({
  title,
  children,
  className,
  marginTitle = false,
}: IPropsFormSection) => {
  return (
    <div className="form-section">
      <FormTitle title={title} margin={marginTitle} />
      <div className={`content-form-section ${className ? className : ""}`}>
        {children}
      </div>
    </div>
  );
};

export default FormSection;
