
import React from 'react';
import { User, Roles } from '../models/user';
import { connect } from 'react-redux';

interface IHeaderProps{
    permissions: Roles[];
    user?: User;
    children?: any
} 

const CheckPermissions = (props:IHeaderProps) => {
    let { user, permissions } = props;

    if(user && user.role) { 
        const hasPermission = permissions.filter( (role: Roles) => user?.role === role ).length > 0;

        if(hasPermission) {
            return (
                <>
                    {props.children}
                </>
            );
        }
    }
   
    return null;
}

function mapStateToProps(state:any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(CheckPermissions);