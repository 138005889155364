import axios, {AxiosResponse} from "axios";
import {Beneficiary} from "../models/beneficiary";
import {getBaseUrl, getParamsUrl} from "./setting-service";
import {DataPaginate} from "../models/utils/data-paginate";
import {BeneficiaryFilters} from "../models/utils/filters/beneficiaries/beneficiary-filters";
import {FiltersEnabled} from "../models/utils/filters/filter-enabled";

export default class BeneficiaryService {
    private t: any;

    constructor(t: any = null) {
        this.t = t;
    }

    all(page?: number | null, search?: string | null, filter?: string | null, clientId?: number | null, filters?: FiltersEnabled | null) {
        let paramsUrl = "";

        if (filters)
            paramsUrl = getParamsUrl([
                {key: "page", value: page},
                {key: "search", value: search},
                {key: "filter", value: filter},
                {key: "client_id", value: clientId},
                ...filters.list
            ]);
        else {
            paramsUrl = getParamsUrl([
                {key: "page", value: page},
                {key: "search", value: search},
                {key: "filter", value: filter},
                {key: "client_id", value: clientId},
            ]);
        }
        return axios
            .get(`${getBaseUrl()}beneficiaries${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map(
                    (beneficiary: Object) => new Beneficiary(beneficiary)
                );

                return new DataPaginate(res);
            });
    }

    get(id: number) {
        return axios
            .get(`${getBaseUrl()}beneficiaries/${id}`)
            .then((response: AxiosResponse) => new Beneficiary(response.data))
            .catch();
    }

    getFilter() {
        // No filters yet except a boolean for the "activated" field
        /*return axios
            .get(`${getBaseUrl()}beneficiaries/filters`)
            .then((response: AxiosResponse) => new BeneficiaryFilters(response.data, this.t));*/
        return new BeneficiaryFilters(null, this.t)
    }

    put(body: any, id: number) {
        return axios
            .put(`${getBaseUrl()}beneficiaries/${id}`, body)
            .then((response: AxiosResponse) => new Beneficiary(response.data))
            .catch();
    }

    post(body: any) {
        return axios
            .post(`${getBaseUrl()}beneficiaries`, body)
            .then((response: AxiosResponse) => new Beneficiary(response.data))
            .catch();
    }

    patch(body: any, id: number) {
        return axios
            .patch(`${getBaseUrl()}beneficiaries/${id}`, body)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    getKpis() {
        return axios
            .get(`${getBaseUrl()}beneficiaries/kpis`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    delete(id: number) {
        return axios
            .delete(`${getBaseUrl()}beneficiaries/${id}`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }
}
