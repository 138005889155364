import Modal from "../../modal/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "../../../models/provider";
import "./ModalPdf.scss";
import PreviewPdf from "../../preview-pdf/PreviewPdf";
import { documentType } from "../../../models/utils/document-type";

interface IModalProps {
    isOpen: boolean;
    type?: documentType;
    closeDialog: () => void;
    pdf: Provider;
    code: string;
}

const ModalPdf = ({ isOpen, type = documentType.QUOTE, closeDialog, pdf, code }: IModalProps) => {
    const { t } = useTranslation();
    return (
        <Modal
            label={ type === documentType.QUOTE ? `${t("preview_bill")} ${code} :` : `${t("preview_pdf")} ${code} :`}
            isOpen={isOpen}
            closeModal={() => closeDialog()}
        >
            <div className="modal-preview-pdf">
                <div className="content-section" style={{minWidth: '1050px', padding: '50px 25px'}}>
                    <div className="bill-pdf">
                        <PreviewPdf bytes={pdf} />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ModalPdf;
