import React from "react";
import { useTranslation } from "react-i18next";
import BodyTableCell from "../../components/table/BodyTableCell";
import BodyTableRow from "../../components/table/BodyTableRow";
import { Payment } from "../../models/payment";
import { format } from "../../utils/AmountHelper";

interface IPropsItem {
    payment: Payment;
}

const ItemPayment = ({ payment }: IPropsItem) => {
    const { t } = useTranslation();
    return (
        <BodyTableRow>
            <BodyTableCell align="center">
                <span className="to-upper">
                    {payment.deposit_id ? t("deposit") : t("invoice")}
                </span>
            </BodyTableCell>
            <BodyTableCell align="center">
                {format(payment.amount ? payment.amount : 0)}
            </BodyTableCell>

            <BodyTableCell align="center">
                {t("" + payment.method)}
            </BodyTableCell>
            <BodyTableCell align="center">{payment.reference}</BodyTableCell>
            <BodyTableCell align="center">
                {payment.date?.toLocaleDateString()}
            </BodyTableCell>
            <BodyTableCell align="center">{payment.comment}</BodyTableCell>
            <BodyTableCell align="center" className="provider-actions-row">
                {/* <ActionTable
                    deleteAction={() => deleteProvider()}
                    updateAction={() => editProvider()}
                />
                <DialogDelete
                    isOpen={isOpen}
                    closeDialog={() => setIsOpen(false)}
                    provider={provider}
                /> */}
            </BodyTableCell>
        </BodyTableRow>
    );
};

export default ItemPayment;
