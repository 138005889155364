import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NewIndicator, {
    IndicatorType,
} from "../../../components/indicator/NewIndicator";
import UseStyles from "../../design-dependencies/UseStyles";
import QuoteService from "../../../services/quote-service";

interface IKpiQuote {
    accepted: number;
    refused: number;
    draft: number;
}

interface IListParams {
    quoteId?: number | null;
}
const KpiQuote: React.FunctionComponent<IListParams> = (props) => {
    const [kpis, setKpis] = useState<IKpiQuote>();
    const { t } = useTranslation();

    useEffect(() => {
        const getKpis = async () => {
            try {
                const service = new QuoteService();
                let res = await service.getKpis();
                setKpis(res);
            } catch (error) {
                console.log("error", error);
            }
        };

        getKpis();
    }, []);

    return (
        <div className="row">
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().greenBg}
                    index={0}
                    text={t("quote_status.accepted")}
                    type={IndicatorType.Quote}
                    total={kpis?.accepted ? kpis.accepted : 0}
                    isMoney
                />
            </div>
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().redBg}
                    index={1}
                    text={t("quote_status.refused")}
                    type={IndicatorType.Quote}
                    total={kpis?.refused ? kpis.refused : 0}
                    isMoney
                />
            </div>
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().yellowGradient}
                    index={2}
                    text={t("quote_status.draft")}
                    type={IndicatorType.Quote}
                    total={kpis?.draft ? kpis.draft : 0}
                    isMoney
                />
            </div>
        </div>
    );
};

export default KpiQuote;
