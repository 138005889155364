import React, { useState, useEffect } from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FormClient from "./form-client/FormClient";
import ClientService from "../../services/client-service";
import { Client } from "../../models/client";

const ClientFormPage: React.FunctionComponent = (props) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [datas, setDatas] = useState<Client>();

    useEffect(() => {
        const fetchAllClients = async () => {
            if (id) {
                try {
                    setLoading(true);
                    const dataService = new ClientService();
                    setDatas(await dataService.get(id));
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    console.log("error", error);
                }
            }
        };

        fetchAllClients();
    }, [id]);

    return (
        <>
            <ContentSection
                title={
                    id
                        ? t("edit") + " " + t("entity.client")
                        : t("add") + " " + t("entity.client")
                }
                returnAction={true}
            >
                {!id || !loading ? (
                    <FormClient client={datas} />
                ) : (
                    <div>{t("loading")}</div>
                )}
            </ContentSection>
        </>
    );
};

export default ClientFormPage;
