import Modal from "../../../components/modal/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "../../../models/provider";
import "./ModalAcceptProvider.scss";
import { Button, CircularProgress, Icon } from "@material-ui/core";

interface IPopupAccept {
    isOpen: boolean;
    closeDialog: () => void;
    provider: Provider;
    handleAccept: () => void;
    driverName?: () => void;
    loading: boolean;
}

const ModalAcceptProvider = ({
    isOpen,
    closeDialog,
    provider,
    handleAccept,
    driverName,
    loading,
}: IPopupAccept) => {
    const { t } = useTranslation();
    return (
        <Modal
            label={t("provider_choice")}
            isOpen={isOpen}
            closeModal={() => closeDialog()}
        >
            <div className="modal-accept-provider">
                <div className="title-section">
                    <h2 className="title-content to-upper">
                        {t("sure_to_accept_provider")}
                    </h2>
                    <p className="provider-name">
                        <span>{t("name")} :</span> {provider.company_name}
                    </p>
                    {driverName && (
                        <p className="provider-name">
                            <span>{t("driver")}: </span>
                            {driverName()}
                        </p>
                    )}
                </div>

                <div className="buttons-section">
                    <div className="buttons-content">
                        {!loading ? (
                            <>
                                <Button
                                    className="btn-form btn-icon"
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={() => closeDialog()}
                                    disabled={loading}
                                >
                                    <span>{t("cancel")}</span>{" "}
                                    <Icon>close</Icon>
                                </Button>
                                <Button
                                    className="btn-form btn-icon"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => handleAccept()}
                                    disabled={loading}
                                >
                                    <span>{t("validate")}</span>{" "}
                                    <Icon>check</Icon>
                                </Button>
                            </>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                }}
                            >
                                <CircularProgress />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ModalAcceptProvider;
