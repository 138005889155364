import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ContentSection from "../../../components/content-section/ContentSection";
import { Quote } from "../../../models/quote";
import QuoteService from "../../../services/quote-service";
import PresviewingEstimate from "./previewing-estimate/PreviewingEstimate";
import "./EstimatePage.scss";
import { LineEstimate } from "../../../models/utils/line-estimate/line-estimate";
import { Passenger, Transfer } from "../../../models/transfer";
import { Price } from "../../../models/utils/line-estimate/price";
import { connect } from "react-redux";
import { User } from "../../../models/user";
import WhiteButton from "../../../components/white-button/WhiteButton";

interface IProps {
    user: User;
    archive?: boolean | false;
    quoteId?: number | false;
    afterUpdate?: () => void;
}
function EstimatePage({ user, archive, quoteId, afterUpdate }: IProps) {
    const { t } = useTranslation();
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [quote, setQuote] = useState<Quote>();
    const [linesEstimate, setLinesEstimate] = useState<LineEstimate[]>([]);
    const [view, setView] = useState("detail");
    const [passengerMode, setPassengerMode] = useState("none");

    useEffect(() => {
        const createOrGetCurrentQuote = async () => {
            const quoteService = new QuoteService();
            if (archive) {
                //get specified quote
                setQuote(await quoteService.get(id));
            } else if (quoteId) {
                setQuote(await quoteService.get(quoteId));
            }
            setLoading(false);
        };

        createOrGetCurrentQuote();
    }, [id, archive, quoteId]);

    useEffect(() => {
        if (quote) {
            //init stored passenger display mode
            if (quote.datas?.passengerMode && quote.datas?.passengerMode != passengerMode) {
                setPassengerMode(quote.datas?.passengerMode);
            }
        }
    }, [quote]);

    useEffect(() => {
        const getSellingPrice = (transferId: number) => {
            let initialPrice = quote?.folder?.getTransfer(transferId)?.getTotalCost();

            return quote?.datas?.transfers &&
                quote?.datas?.transfers[transferId]
                ? parseFloat(quote?.datas?.transfers[transferId])
                : initialPrice;
        };

        if (quote) {
            setLinesEstimate([]);
            quote.folder.transfers?.map((transfer: Transfer, index: number) => {
                view === "detail"
                    ? setLinesEstimate((arr) => [
                          ...arr,
                          new LineEstimate(
                              transfer.id,
                              index + 1,
                              `${t("transfer")} N° ${transfer.code}`,
                              false,
                              [
                                  new Price({
                                      total: transfer.getTotalCost(),
                                  }),
                              ],
                              getSellingPrice(transfer.id),
                              true,
                              transfer.getRateTva()
                          ),
                          new LineEstimate(
                              transfer.id,
                              index + 1,
                              `${t("passengers")}: ${formatPassenger(transfer)}` 
                          ),
                          new LineEstimate(
                              transfer.id,
                              index + 1,
                              `${t(
                                  "departure_location"
                              )} \n ${transfer.getHourDeparture()} | ${transfer.getDateDeparture()} | ${
                                  transfer.departure_location
                              }`
                          ),
                          new LineEstimate(
                              transfer.id,
                              index + 1,
                              `${t(
                                  "drop_off"
                              )} \n ${transfer.getHourArrival()} | ${transfer.getDateArrival()} | ${
                                  transfer.arrival_location
                              }`
                          ),
                      ])
                    : view === "global"
                    ? setLinesEstimate((arr) => [
                          ...arr,
                          new LineEstimate(
                              transfer.id,
                              index + 1,
                              `${t("transfer")} N° ${transfer.code}`,
                              false,
                              [
                                  new Price({
                                      total: transfer.getTotalCost(),
                                  }),
                              ],
                              getSellingPrice(transfer.id),
                              true,
                              transfer.getRateTva()
                          ),
                      ])
                    : setLinesEstimate([
                          new LineEstimate(
                              transfer.id,
                              1,
                              `x${quote.folder.transfers?.length} ${t(
                                  "transfer"
                              )}`,
                              false,
                              [
                                  new Price({
                                      total: quote?.folder.getPriceTransfersTTC(),
                                  }),
                              ]
                          ),
                      ]);
            });

            quote.folder.extras.forEach((extra) => {
                setLinesEstimate((arr) => [
                    ...arr,
                    new LineEstimate(
                        0,
                        2,
                        extra.type,
                        false,
                        [],
                        extra.price,
                        false,
                        extra.tva
                    ),
                ]);
            });
        }
    }, [quote, t, view, passengerMode]);

    function formatPassenger(transfer: Transfer) : string {
        let output: string = '' + transfer.nb_passenger;

        if (passengerMode && passengerMode!= 'none') {

            let passengers = transfer.passengers;

            if (passengers?.length === 0) {
                return output + ' | - ' + t('no_name_for_transfer') +' - '
            }

            if (passengerMode === 'first') {
                passengers = passengers?.slice(0,1); //only keep first
            }

            passengers?.map((passenger: Passenger, idx) => {
                output += (idx === 0 ? ' | ' : ', ') + passenger.getFullName();
            })
        }

        return output;
    }

    function updateLine(line: LineEstimate) {
        const newLines = [...linesEstimate];
        const index = newLines.findIndex(
            (oldLine) => oldLine.id === line.id && oldLine.isDetail === false
        );
        newLines[index] = line;
        if (quote && quote.datas && quote.datas?.transfers) {
            quote.datas.transfers[line.id] = line.sellingPrice;
        }

        setLinesEstimate(newLines);
    }

    const createQuote = async () => {
        const quoteService = new QuoteService();
        setQuote(await quoteService.create(id));
        if(afterUpdate){
            afterUpdate();
        }
    };

    const renderButtonNewQuote = () => {
        return user.isAdmin() &&
            !loading &&
            !archive &&
            (!quote || quote.canCreateNew()) ? (
            <WhiteButton
                onClick={() => createQuote()}
                className="btn-rounded"
                variant="contained"
                color="primary"
            >
                {t("new_quote")}
            </WhiteButton>
        ) : (
            <></>
        );
    };

    return (
        <ContentSection
            title={t("nav.estimate")}
            suppContent={renderButtonNewQuote()}
        >
            {quote ? (
                <div className="row">
                    <div className="big col-12">
                        {quote && linesEstimate ? (
                            <PresviewingEstimate
                                quote={quote}
                                lines={linesEstimate}
                                user={user}
                                setView={(e: any) => setView(e)}
                                setPassengerMode={(e: any) => setPassengerMode(e)}
                                passengerMode={passengerMode}
                                updateQuote={async (quote) => {
                                    const quoteService = new QuoteService();
                                    setQuote(await quoteService.get(quote.id));
                                    if (afterUpdate) {
                                        afterUpdate();
                                    }
                                }}
                                view={view}
                                updateLine={(line: LineEstimate) =>
                                    updateLine(line)
                                }
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            ) : (
                <span>{loading ? t("loading") : t("none")}</span>
            )}
        </ContentSection>
    );
}

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(EstimatePage);
