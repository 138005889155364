import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FilterType} from "../../../../components/filter-column/filterColumn";
import {FiltersEnabled} from "../../../../models/utils/filters/filter-enabled";
import {Button} from "@material-ui/core";
import "./FiltersNotifications.scss";

interface IPropsFiltersNotifications {
    colorFilters?: string;
    filtersEnabled?: FiltersEnabled;
    setFilters: (filters: FiltersEnabled) => void;
}

const FiltersNotifications = ({
                                  colorFilters,
                                  filtersEnabled,
                                  setFilters,
                              }: IPropsFiltersNotifications) => {
    const {t} = useTranslation();
    const [filterBy, setFilterBy] = useState("is_sort");

    const changeValue = (name: string, value: any, type: FilterType) => {
        if (filtersEnabled) {
            const newFiltersEnabled = filtersEnabled.addFilter(
                name,
                value,
                type
            );
            setFilters(new FiltersEnabled(newFiltersEnabled));
        }
    };
    return (
        <div className={`tab-section ${colorFilters}`}>
            <>
                <Button
                    onClick={(e) => {
                        setFilterBy("is_sort");
                        changeValue("is_sort", 1, FilterType.Unique)
                    }}
                    className={filterBy === "is_sort" ? "active" : ""}
                >
                    {t('recent')}
                </Button>
                <Button
                    onClick={(e) => {
                        setFilterBy("unread")
                        changeValue("is_active", 1, FilterType.Unique)
                    }}
                    className={filterBy === "unread" ? "active" : ""}
                >
                    {t('unread')}
                </Button>
                <Button
                    onClick={(e) => {
                        setFilterBy("read")
                        changeValue("is_active", 0, FilterType.Unique)
                    }}
                    className={filterBy === "read" ? "active" : ""}
                >
                    {t('read')}
                </Button>
            </>
        </div>
    );
};

export default FiltersNotifications;
