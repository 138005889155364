import React from "react";
import Administrators from "./administrators/Administrators";
import CheckPermissions from "../../utils/CheckPermissions";
import Languages from "./languages/Languages"
import {Roles} from "../../models/user";
import VehicleDetails from "./vehicle-details/VehicleDetails";
import ClientDetails from "./client-details/ClientDetails";

const ParameterPage: React.FunctionComponent = props => {
    return (
        <>  <CheckPermissions permissions={[Roles.ADMIN]}>
            <VehicleDetails/>
            <ClientDetails/>
            <Administrators/>
            <Languages/>
        </CheckPermissions>
        </>
    )
}

export default ParameterPage;
