import React from "react";
import "./TransferNote.scss";
import { useTranslation } from "react-i18next";
import { Transfer } from "../../../models/transfer";
import { format } from "../../../utils/AmountHelper";

interface IPopupTransfer {
    transfer: Transfer;
}

const TransferNote = ({ transfer }: IPopupTransfer) => {
    const { t } = useTranslation();
    return (
        <div className="transfer_note_popup">
            <div className="top-info section">
                <div className="info_part">
                    <span className="label">{t("note")}</span>
                    <span>{transfer.free_note ? transfer.free_note : '-'}</span>
                </div>
            </div>
            <div className="bottom-info section">
                {transfer.provider_price && (
                    <div className="info_part">
                        <span className="label">{t("provider_price")}</span>
                        <span>{format(transfer.provider_price)}</span>
                    </div>
                )}
                {transfer.client_price && (
                    <div className="info_part">
                        <span className="label">{t("selling_price")}</span>
                        <span>{format(transfer.client_price)}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TransferNote;
