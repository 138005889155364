import { NotificationUser } from "../../../models/notificationUser";

export interface NotificationsState {
  list: NotificationUser[],
  loading: boolean
}

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR'
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'

interface GetNotificationAction {
  type: typeof GET_NOTIFICATIONS
}

interface GetNotificationSuccessAction {
  type: typeof GET_NOTIFICATIONS_SUCCESS,
  payload: NotificationUser[]
}

interface GetNotificationErrorAction {
  type: typeof GET_NOTIFICATIONS_ERROR,
}

interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION,
  payload: NotificationUser
}

interface UpdateNotificationAction {
  type: typeof UPDATE_NOTIFICATION,
  payload: NotificationUser
}

export type NotificationActionTypes = GetNotificationAction | GetNotificationSuccessAction | 
  GetNotificationErrorAction | AddNotificationAction | UpdateNotificationAction;