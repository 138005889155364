import axios, { AxiosResponse } from 'axios';

import { getBaseUrl } from './setting-service';
import { Folder } from '../models/folder';
import { Transfer } from '../models/transfer';
import { Quote } from '../models/quote';
import { Invoice } from '../models/invoice';

export default class DashboardService {

    folders() {
        return axios.get(`${getBaseUrl()}dashboard/folders`)
            .then( (response:AxiosResponse) => {
                let res = response.data.map(
                    (item: Object) => new Folder(item)
                );
                return res;
            });
    }

    transfers() {
        return axios.get(`${getBaseUrl()}dashboard/transfers`)
            .then( (response:AxiosResponse) => {
                let res = response.data.map(
                    (item: Object) => new Transfer(item)
                );
                return res;
            });
    }

    quotes() {
        return axios.get(`${getBaseUrl()}dashboard/quotes`)
            .then( (response:AxiosResponse) => {
                let res = response.data.map(
                    (item: Object) => new Quote(item)
                );
                return res;
            });
    }

    invoices() {
        return axios.get(`${getBaseUrl()}dashboard/invoices`)
            .then( (response:AxiosResponse) => {
                let res = response.data.map(
                    (item: Object) => new Invoice(item)
                );
                return res;
            });
    }


}