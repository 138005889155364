import { Client } from "./client";
import { Country } from "./country";
import { Deserializable } from "./interfaces/deserializable";

export class Address implements Deserializable {
    public id: number;
    public name: string;
    public street: string;
    public misc: string;
    public post_code: string;
    public city: string;
    public intention_of: string;
    public tva_number: string;
    public country_id: number;
    public country?: Country;
    public client_id?: number;
    public client?: Client;
    public created_at: Date;
    public updated_at: Date;

    constructor(data:any) {
        this.id = data.id;
        this.name = data.name;
        this.street = data.street;
        this.misc = data.misc;
        this.post_code = data.post_code;
        this.city = data.city;
        this.intention_of = data.intention_of;
        this.tva_number = data.tva_number;
        this.country_id = data.country_id;
        this.country = data.country ? new Country(data.country) : undefined;
        this.client_id = data.client_id;
        this.client = data.client ? new Client(data.client) : undefined;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }

    toString(): string{

        return this.street + (this.misc ? '\n ' + this.misc : ' ') + '\n ' + this.post_code + ' ' + this.city;
    }
}
