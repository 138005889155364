import React, { useEffect, useState } from "react";
import { Filter } from "../../models/utils/filters/filter";
import MultiSelect from "react-multi-select-component";
import { useTranslation } from "react-i18next";
import "./filterColumn.scss";
import {
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
} from "@material-ui/core";
import { Language } from "../../models/language";
import LanguageService from "../../services/language-service";

export enum FilterType {
    Unique,
    Multiple,
    Languages,
}

interface IFilterColumn {
    label?: string;
    filters: Array<Filter>;
    name: string;
    type: FilterType;
    width?: number;
    hasHover?: boolean;

    changeValue: (name: string, value: any, type: FilterType) => void;
}

const FilterColumn = (props: IFilterColumn) => {
    const setValueSelect = (value: any) => {
        props.changeValue(props.name, value, props.type);
    };

    return (
        <div className="filter">
            {props.type === FilterType.Multiple ? (
                <FilterMultiple {...props} updateSearch={setValueSelect} />
            ) : (
                <></>
            )}
            {props.type === FilterType.Languages ? (
                <FilterMultipleLanguages
                    {...props}
                    updateSearch={setValueSelect}
                />
            ) : (
                <></>
            )}
            {props.type === FilterType.Unique ? (
                <FilterSimple {...props} setValue={setValueSelect} />
            ) : (
                <></>
            )}
        </div>
    );
};

interface IFilterSimple {
    label?: string;
    filters: Array<Filter>;
    name: string;
    type: FilterType;
    width?: number;
    setValue: (option: any) => void;
}

const FilterSimple = (props: IFilterSimple) => {
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: "0 8px",
            minWidth: props.width || 160,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));

    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const { t } = useTranslation();

    const updateSelect = (value: any) => {
        setSelected(value);
        props.setValue(value);
    };

    return (
        <>
            <FormControl className={classes.formControl}>
                <InputLabel id="filter-unique-label" className="pl-10 fw-400">
                    {props.label}
                </InputLabel>
                <Select
                    name="filter_id"
                    labelId="filter-unique-label"
                    id="filter-unique-select"
                    label={selected}
                    className="shadow-form-control"
                    displayEmpty
                    onChange={(e) =>
                        updateSelect(
                            e.target.value === "-1" ? null : e.target.value
                        )
                    }
                >
                    <MenuItem aria-label="None" value="-1">
                        {t("none")}
                    </MenuItem>
                    {props.filters.map((filter: Filter, index: number) => {
                        return (
                            <MenuItem
                                key={`form_filter_${props.name}_${index}`}
                                value={filter.id ? filter.id : filter.label}
                            >
                                {filter.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </>
    );
};

interface IFilterMultiple {
    label?: string;
    filters: Array<Filter>;
    name: string;
    type: FilterType;
    width?: number;
    updateSearch: (option: any) => void;
    hasHover?: boolean;
}
const FilterMultiple = (props: IFilterMultiple) => {
    const useStyles = makeStyles((theme) => ({
        formControl: {
            minWidth: props.width || 160,
        },
    }));
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const { t } = useTranslation();

    const setValuesMultiSelect = (values: any) => {
        setSelected(values);
        props.updateSearch(
            values.length === props.filters.length ? [] : values
        );
    };

    const options = props.filters.map((filter: Filter) => {
        return {
            label: filter.label,
            value: filter.id ? filter.id : filter.label,
        };
    });

    return (
        <MultiSelect
            options={options}
            value={selected}
            onChange={setValuesMultiSelect}
            labelledBy={"Select"}
            className={classes.formControl + " multi-select"}
            shouldToggleOnHover={props.hasHover}
            overrideStrings={{
                selectSomeItems: props.label
                    ? props.label
                    : t("selectSomeItems"),
                allItemsAreSelected: t("allItemsAreSelected"),
                selectAll: t("selectAll"),
                search: t("search"),
            }}
        />
    );
};

const FilterMultipleLanguages = (props: IFilterMultiple) => {
    const [languages, setLanguages] = useState<Array<Language>>([]);
    const [options, setOptions] = useState<any[]>([]);

    useEffect(() => {
        const fetchLang = async () => {
            try {
                const languageService = new LanguageService();
                setLanguages(await languageService.all());
            } catch (e) {
                console.log(e);
            }
        };
        fetchLang();
    }, []);

    useEffect(() => {
        setOptions(
            props.filters.map((filter: Filter) => {
                let flag = languages.find(
                    (lang) => lang.label == filter.label
                )?.iso_code;
                return {
                    label: filter.label,
                    value: filter.id ? filter.id : filter.label,
                    logo: flag
                        ? `https://countryflagsapi.com/png/${flag}`
                        : "../../../public/images/unknow-flag-icon.svg",
                };
            })
        );
    }, [languages]);

    const useStyles = makeStyles((theme) => ({
        formControl: {
            minWidth: props.width || 160,
        },
    }));
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const { t } = useTranslation();

    const setValuesMultiSelect = (values: any) => {
        setSelected(values);
        props.updateSearch(
            values.length === props.filters.length ? [] : values
        );
    };

    return (
        <>
            {options.length !== 0 && (
                <MultiSelect
                    options={options}
                    value={selected}
                    onChange={setValuesMultiSelect}
                    labelledBy={"Select"}
                    className={classes.formControl + " multi-select"}
                    shouldToggleOnHover={props.hasHover}
                    disableSearch
                    overrideStrings={{
                        selectSomeItems: props.label
                            ? props.label
                            : t("selectSomeItems"),
                        allItemsAreSelected: t("allItemsAreSelected"),
                        selectAll: t("selectAll"),
                        search: t("search"),
                    }}
                    ItemRenderer={(props: any) => {
                        console.log(props);
                        return (
                            <div
                                style={{
                                    paddingBottom: 5,
                                    paddingTop: 5,
                                    backgroundColor: "white",
                                }}
                            >
                                <input
                                    type="checkbox"
                                    onChange={props.onClick}
                                    checked={props.checked}
                                    tabIndex={-1}
                                    disabled={props.disabled}
                                />
                                <img
                                    style={{
                                        width: 16,
                                        height: 11,
                                        marginTop: 0,
                                        marginBottom: 0,
                                        marginLeft: 20,
                                        marginRight: 20,
                                    }}
                                    src={props.option.logo}
                                />
                                <span>{props.option.label}</span>
                            </div>
                        );
                    }}
                />
            )}
        </>
    );
};

export default FilterColumn;
