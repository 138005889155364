export interface IPrice{
    amount?: number; 
    tva?: number;
    total?: number;
}
export class Price{
    amount?: number;
    tva?: number;
    total?: number;

    constructor(data: IPrice){
        this.amount = data.amount;
        this.tva = data.tva;
        this.total = data.total;
    }
}