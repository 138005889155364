import React, { useEffect, useState } from "react";
import { User } from "../../../models/user";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { Department } from "../../../models/department";
import GeoApiService from "../../../services/geo-api-service";
import ProviderService from "../../../services/provider-service";
import AutocompleteControlled from "../../../components/fields-controlled/AutocompleteControlled";
import Modal from "../../../components/modal/Modal";
import SubmitComponent from "../../../components/submit-component/SubmitComponent";
import { ErrorForm } from "../../../components/ErrorForm";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import "./ModalConfigPerimeters.scss";
import { toastr } from "react-redux-toastr";
import GreyButton from "../../../components/grey-button/GreyButton";

interface IProps {
    user: User;
    isOpen: boolean;
    closeModal: () => void;
}

const ModalConfigPerimeters = ({ user, isOpen, closeModal }: IProps) => {
    const { t } = useTranslation();
    const { handleSubmit, errors, control } = useForm();
    const [loading, setLoading] = useState(true);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState(false);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [providerDepartments, setProviderDepartments] = useState<
        string[] | undefined
    >(user?.provider?.perimeter);

    useEffect(() => {
        const fetchDepartments = async () => {
            if (!loading) setLoading(true);
            try {
                const geoApiService = new GeoApiService();
                setDepartments(await geoApiService.getAllDepartments());
            } catch (error) {
                console.log(error);
                setErrorApi(new ErrorFormData(error.response.data));
            }
            setLoading(false);
        };
        fetchDepartments();
    }, []);

    const addProviderPerimeter = (codeDepartment?: string | null) => {
        if (codeDepartment && !providerDepartments?.includes(codeDepartment)) {
            const departments = providerDepartments
                ? [...providerDepartments, codeDepartment]
                : [codeDepartment];
            setProviderDepartments(departments);
        }
    };

    const removeProviderPerimeter = (codeDepartment: string) => {
        if (providerDepartments?.includes(codeDepartment)) {
            const departments = providerDepartments.filter(
                (item) => item !== codeDepartment
            );
            setProviderDepartments(departments);
        }
    };

    const removeAllProviderPerimeter = () => {
        setProviderDepartments([]);
    };

    const onSubmit = async (data: any, e: any) => {
        setLoading(true);
        setDisabledForm(true);
        try {
            if (user.provider && providerDepartments !== user.provider?.perimeter) {
                const providerService = new ProviderService();
                await providerService.patchPerimeter(
                    { perimeters: providerDepartments },
                    user.provider.id
                );
                toastr.success(t("success"), t("success_config_perimeters"));
                setLoading(false);
                setDisabledForm(false);
                closeModal();
            }
        } catch (error) {
            setLoading(true);
            setDisabledForm(true);
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    return (
        <Modal
            label={t("config_perimeters")}
            isOpen={isOpen}
            closeModal={() => closeModal()}
        >
            <div className="config-provider-perimeters">
                <GreyButton onClick={removeAllProviderPerimeter}>
                    {t("remove_list")}
                </GreyButton>
                <form
                    className="form-submit-ticket mt-3"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="field-form mgl2 col-2">
                        <AutocompleteControlled
                            label={t("choose_departments")}
                            name="departments"
                            disabled={disabledForm}
                            listItems={departments ? departments : []}
                            value={null}
                            required={false}
                            getOptionLabel={(option: any) => option.nom}
                            control={control}
                            errors={errors}
                            onChange={(department: any) =>
                                addProviderPerimeter(
                                    department ? department.code : null
                                )
                            }
                        />
                    </div>
                    <hr />
                    <div>
                        <ul>
                            {departments &&
                                departments.map(
                                    (department: Department) =>
                                        providerDepartments &&
                                        providerDepartments?.includes(
                                            department.code
                                        ) && (
                                            <li
                                                className="provider-perimeter"
                                                key={department.id}
                                            >
                                                <span>{department.nom}</span>
                                                <div className="icon delete">
                                                    <HighlightOffIcon
                                                        className="delete-provider-perimeter"
                                                        onClick={() =>
                                                            removeProviderPerimeter(
                                                                department.code
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </li>
                                        )
                                )}
                        </ul>
                    </div>
                    <SubmitComponent
                        loading={loading}
                        text={t("form.validate")}
                        isActivate={!disabledForm}
                    />
                    <ErrorForm errorApi={errorApi} />
                </form>
            </div>
        </Modal>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(ModalConfigPerimeters);
