import axios, { AxiosResponse } from 'axios';
import { getBaseUrl} from './setting-service';
import { ClientType } from '../models/client-type';

export default class ClientTypeService {

    all() {
        return axios.get(`${getBaseUrl()}client-types`)
            .then( (response:AxiosResponse) => {
                let res = response;
                res.data = res.data.map( (client:Object)=> new ClientType(client));

                return res.data;
            });
    }
}