import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";
import { findError } from "./helpers/ControllerHelper";

interface Iprops {
    label: string;
    value: any;
    disabled: boolean | false;
    name: string;
    required?: boolean | false;
    control: any;
    errors: any;
    decorator?: any | null;
    multiline?: boolean | false;
    onChange?: (value: any) => void;
}

const InputDateTimeControlled = (props: Iprops) => {

    const { t } = useTranslation();
    let fieldError = findError(props.errors, props.name);

    return (
        <>
            <Controller
                as={TextField}
                error={!!fieldError}
                helperText={
                    fieldError
                        ? t("errors.required", { field: props.label })
                        : ""
                }
                type={"datetime-local"}
                InputLabelProps={{
                    shrink: true,
                }}
                label={props.label}
                disabled={props.disabled}
                name={props.name}
                control={props.control}
                defaultValue={props.value}
                placeholder={props.label}
                rules={{ required: props.required }}
                multiline={props.multiline}
                style={{ width: "230px" }}
                onChange={(e: any) => {
                    const value = e[0].target.value;
                    if (props.onChange) {
                        props.onChange(value);
                    }

                    return value;
                }}
            />
            {props.decorator ? props.decorator : ""}
            {/* {fieldError && <p>{fieldError.message}</p>} */}
            {fieldError && <p>{fieldError.message}</p>}
        </>
    );
};

export default InputDateTimeControlled;
