import axios, { AxiosResponse } from "axios";
import { getBaseUrl, getParamsUrl } from "./setting-service";
import { DataPaginate } from "../models/utils/data-paginate";
import { Payment } from "../models/payment";

export default class PaymentService {
    all(
        page?: number | null,
        search?: string | null,
        filter?: string | null,
        folder?: number | null
    ) {
        let paramsUrl = getParamsUrl([
            { key: "page", value: page },
            { key: "search", value: search },
            { key: "filter", value: filter },
            { key: "folder", value: folder },
        ]);
        return axios
            .get(`${getBaseUrl()}payments${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map(
                    (payment: Object) => new Payment(payment)
                );

                return new DataPaginate(res);
            });
    }

    get(id: number) {
        return axios
            .get(`${getBaseUrl()}payments/${id}`)
            .then((response: AxiosResponse) => new Payment(response.data))
            .catch();
    }

    add(body: any) {
        return axios
            .post(`${getBaseUrl()}payments`, body)
            .then((response: AxiosResponse) => new Payment(response.data))
            .catch();
    }

    update(id: number, body: any) {
        return axios
            .put(`${getBaseUrl()}payments/${id}`, body)
            .then((response: AxiosResponse) => new Payment(response.data))
            .catch();
    }

    delete(id: number) {
        return axios
            .delete(`${getBaseUrl()}payments/${id}`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }
}
