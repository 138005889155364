import React from "react";
import './pagination.scss';
import { DataPaginate } from "../../models/utils/data-paginate";
import { useTranslation } from "react-i18next";

interface IPaginationProps{
    dataPaginate?: DataPaginate;
    onPageChange: (page: number) => void
}

const Pagination: React.FunctionComponent<IPaginationProps> = ({ dataPaginate, onPageChange }) => {
    const { t } = useTranslation();
    const currentPage = dataPaginate ? dataPaginate.current_page : 0;

    function updatePage(page: number, currentPage: number) {
        if(page !== currentPage) {
           onPageChange(page);
        }
    }

    function returnPages(lastPage:number, currentPage:number) {
        let listPages = [];
        while(lastPage !== 0){
            listPages.push(lastPage);
            lastPage = lastPage - 1;
        }
        listPages.reverse();
    
        return (
            <div className="list_pages">
                {listPages && listPages.map( (page, index) => {
                    return <span onClick={() => updatePage(page, currentPage)} className={page === currentPage ? 'current_page' : 'other_page'} key={index}>{page}</span>
                } )}
            </div>
        )
    }

    if(dataPaginate?.data?.length && dataPaginate.total !== 0){
        return (
            <div className="row_pagination">
                <span>{t('pagination.information_recording', {from: dataPaginate.from, to:dataPaginate.to, total: dataPaginate.total})}</span>
                <div className="navigation_pages">
                    { dataPaginate.current_page !== 1 ? <span onClick={() => updatePage(currentPage - 1, currentPage)}  className="action">{t('pagination.previous')}</span> : '' }

                    { dataPaginate.last_page > 1 ? returnPages(dataPaginate.last_page, dataPaginate.current_page) : '' }

                    { dataPaginate.current_page !== dataPaginate.last_page ? <span onClick={() => updatePage(currentPage + 1, currentPage)} className="action">{t('pagination.next')}</span> : '' }
                </div>
            </div>
        )
    }

    return <></>;
}

export default Pagination;