import React from "react";
import { DataPaginate } from "../../../../models/utils/data-paginate";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../components/pagination/pagination";
import {Division} from "../../../../models/client";
import ItemDivision from "./ItemDivision";

interface IListDivisionsProps {
    dataPaginate: DataPaginate;
    loading?: boolean;
    onPageChange: (page: number) => void;
    onUpdateSuccess: (division: Division) => void;
    onDeleteSuccess: () => void;
}

const ListDivisions: React.FunctionComponent<IListDivisionsProps> = ({
                                                                   dataPaginate,
                                                                   loading,
                                                                   onPageChange,
                                                                   onUpdateSuccess,
                                                                   onDeleteSuccess,
                                                               }) => {
    const { t } = useTranslation();
    return (
        <>
            {dataPaginate && dataPaginate.data ? (
                <>
                    <table>
                        <thead>
                        <tr>
                            <th>{t("label")}</th>
                            <th />
                        </tr>
                        </thead>
                        <tbody>
                        {dataPaginate.data.map(
                            (division: Division, index: number) => {
                                return (
                                    <tr
                                        key={`division-${division.id}-${index}`}
                                    >
                                        <ItemDivision
                                            onUpdateSuccess={(
                                                division
                                            ) =>
                                                onUpdateSuccess(
                                                    division
                                                )
                                            }
                                            onDeleteSuccess={() =>
                                                onDeleteSuccess()
                                            }
                                            division={division}
                                        />
                                    </tr>
                                );
                            }
                        )}
                        </tbody>
                    </table>
                    <Pagination
                        dataPaginate={dataPaginate}
                        onPageChange={(page) => onPageChange(page)}
                    />
                </>
            ) : loading ? (
                <div>{t("loading")}</div>
            ) : (
                <div>{t("no_items")}</div>
            )}
        </>
    );
};

export default ListDivisions;
