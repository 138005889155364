import axios, { AxiosResponse } from "axios";
import { Address } from "../models/address";
import { getBaseUrl } from "./setting-service";

export default class AddressService {
    get(id: number) {
        return axios
            .get(`${getBaseUrl()}addresses/${id}`)
            .then((response: AxiosResponse) => new Address(response.data))
            .catch();
    }

    put(id: number, body: any) {
        return axios
            .put(`${getBaseUrl()}addresses/${id}`, body)
            .then((response: AxiosResponse) => {
                new Address(response.data);
            });
    }

    delete(id: number) {
        return axios
            .delete(`${getBaseUrl()}addresses/${id}`)
            .then((response: AxiosResponse) => response.data);
    }
}
