import React, { useEffect, useState } from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import FormProvider from "./form-provider/FormProvider";
import { Provider } from "../../models/provider";
import ProviderService from "../../services/provider-service";
import { useParams } from "react-router-dom";
import ProviderTypeService from "../../services/provider-type-service";
import { ProviderType } from "../../models/provider-type";
import { CircularProgress } from "@material-ui/core";
import CountryService from "../../services/country-service";
import { ErrorFormData } from "../../models/interfaces/errorForm";
import { Country } from "../../models/country";
import { ErrorForm } from "../../components/ErrorForm";

interface IPropsProviderFormPage {
  id?: number;
}

const ProviderFormPage: React.FunctionComponent<IPropsProviderFormPage> = (
  props
) => {
  const { t } = useTranslation();
  const [provider, setProvider] = useState<Provider>();
  const [loading, setLoading] = useState<boolean>(true);
  const [providerTypes, setProviderTypes] = useState<Array<ProviderType>>([]);
  const { id } = useParams();
  const [errorApi, setErrorApi] = useState(new ErrorFormData());
  const [countries, setCountries] = useState<Array<Country>>([]);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        const countryService = new CountryService();
        setCountries(await countryService.all());
        const providerTypeService = new ProviderTypeService();
        setProviderTypes(await providerTypeService.all());
        if (id) {
          const providerService = new ProviderService();
          setProvider(new Provider(await providerService.get(id)));
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorApi(new ErrorFormData(error.response.data));
      }
    };
    fetchAll();
  }, [id]);

  return (
    <>
      <ContentSection
        title={provider ? t("provider_update") : t("provider_add")}
        returnAction={true}
      >
        {loading ? (
          <CircularProgress />
        ) : id ? (
          <FormProvider
            countries={countries}
            provider={provider}
            providerTypes={providerTypes}
          />
        ) : (
          <FormProvider countries={countries} providerTypes={providerTypes} />
        )}
        <ErrorForm errorApi={errorApi} />
      </ContentSection>
    </>
  );
};

export default ProviderFormPage;
