import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/app/App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import { rootReducer } from './redux/store';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import './i18n';


require('dotenv').config();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

ReactDOM.render(
  <React.Fragment>
    <Suspense fallback="loading">
      <App store={store}/>
    </Suspense>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
