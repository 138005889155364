import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/pagination/pagination";
import HeadTableCell from "../../components/table/HeadTableCell";
import { Payment } from "../../models/payment";
import { DataPaginate } from "../../models/utils/data-paginate";
import ItemPayment from "./ItemPayment";

interface IPropsPayments {
    payments: DataPaginate;
    setCurrentPage: (e: any) => void;
}

const ListPayments = ({ payments, setCurrentPage }: IPropsPayments) => {
    const { t } = useTranslation();

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    {/* HEAD */}
                    <TableHead>
                        <TableRow>
                            <HeadTableCell
                                className="testTh"
                                align="center"
                            ></HeadTableCell>
                            <HeadTableCell className="testTh" align="center">
                                {t("form.amount")}
                            </HeadTableCell>
                            {/*<HeadTableCell className="testTh" align="center">
                                {t("form.type")}
                            </HeadTableCell>*/}
                            <HeadTableCell className="testTh" align="center">
                                {t("form.method")}
                            </HeadTableCell>
                            <HeadTableCell className="testTh" align="center">
                                {t("form.reference")}
                            </HeadTableCell>
                            <HeadTableCell className="testTh" align="center">
                                {t("date")}
                            </HeadTableCell>
                            <HeadTableCell className="testTh" align="center">
                                {t("comment")}
                            </HeadTableCell>
                            <HeadTableCell className="testTh" align="center" />
                        </TableRow>
                    </TableHead>
                    {/* BODY */}
                    <TableBody>
                        {payments.data.map((payment: Payment) => (
                            <ItemPayment
                                key={`table-payment-${payment.id}`}
                                payment={payment}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                dataPaginate={payments}
                onPageChange={(page: number) => setCurrentPage(page)}
            />
        </>
    );
};

export default ListPayments;
