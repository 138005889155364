import { Checkbox } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { documentType, documentTypeIsQuote } from "../../../../models/utils/document-type";
import { format } from "../../../../utils/AmountHelper";

import "./FormEdit.scss";

interface IProps {
    isEditing?: boolean;
    plus: any;
    setPlus: (e: any) => void;
    deposit: any;
    reduction: any;
    type?: documentType
}
export default function FormEdit({
    isEditing = false,
    plus,
    setPlus,
    deposit,
    reduction,
    type
}: IProps) {
    const { t } = useTranslation();

    const handleCurrencyDeposit = (e: any) => {
        let value = e?.target.name;

        setPlus &&
            setPlus({
                ...plus,
                deposit: { ...plus.deposit, currency: value },
            });
    };

    const handleCurrencyReduction = (e: any) => {
        let value = e?.target.name;

        setPlus &&
            setPlus({
                ...plus,
                reduction: { ...plus.reduction, currency: value },
            });
    };

    const handleAmountDeposit = (e: any) => {
        let value = e?.target.value;

        setPlus &&
            setPlus({
                ...plus,
                deposit: { ...plus.deposit, amount: value },
            });
    };

    const handleAmountReduction = (e: any) => {
        let value = e?.target.value;

        setPlus &&
            setPlus({
                ...plus,
                reduction: { ...plus.reduction, amount: value },
            });
    };

    return isEditing ?  <> 
            <div className="reduction line-recap">
                <div className="label-section">
                    <span className="label">{t("reduction")}</span>
                    <span className="value_plus">{format(reduction)}</span>
                </div>
                <div className="form-section-preview">
                    <div className="input reduction">
                        <label htmlFor="">{`${t("reduction")} :`}</label>
                        <input
                            type="text"
                            value={plus.reduction.amount}
                            onChange={(e: any) => handleAmountReduction(e)}
                        />
                    </div>
                    <div className="checkboxs-section">
                        <div className="checkbox-section">
                            <Checkbox
                                onChange={(e) => handleCurrencyReduction(e)}
                                name="money"
                                id="money_reduction"
                                checked={
                                    plus.reduction.currency === "money" || false
                                }
                                inputProps={{
                                    "aria-label": "checkbox with default color",
                                }}
                            />
                            <span>{t("money_symbol")}</span>
                        </div>
                        <div className="checkbox-section">
                            <Checkbox
                                onChange={(e) => handleCurrencyReduction(e)}
                                name="pourcent"
                                id="pourcent_reduction"
                                checked={
                                    plus.reduction.currency === "pourcent" ||
                                    false
                                }
                                inputProps={{
                                    "aria-label": "checkbox with default color",
                                }}
                            />
                            <span>%</span>
                        </div>
                    </div>
                   
                </div>
            </div>
            { type && documentTypeIsQuote(type) ? 
            <div className="deposit line-recap">
                <div className="label-section">
                    <span className="label">{t("deposit")}</span>
                    <span className="value_plus">{format(deposit)}</span>
                </div>
                <div className="form-section-preview">
                    <div className="input deposit field-form">
                        <label htmlFor="">{`${t("deposit")} :`}</label>
                        <input
                            type="text"
                            value={plus.deposit.amount}
                            onChange={(e: any) => handleAmountDeposit(e)}
                        />
                    </div>
                    <div className="checkboxs-section">
                        <div className="checkbox-section">
                            <Checkbox
                                onChange={(e) => handleCurrencyDeposit(e)}
                                name="money"
                                id="money_deposit"
                                checked={
                                    plus.deposit.currency === "money" || false
                                }
                                inputProps={{
                                    "aria-label": "checkbox with default color",
                                }}
                            />
                            <span>{t("money_symbol")}</span>
                        </div>

                        <div className="checkbox-section">
                            <Checkbox
                                onChange={(e) => handleCurrencyDeposit(e)}
                                name="pourcent"
                                id="pourcent_deposit"
                                checked={
                                    plus.deposit.currency === "pourcent" ||
                                    false
                                }
                                inputProps={{
                                    "aria-label": "checkbox with default color",
                                }}
                            />
                            <span>%</span>
                        </div>
                    </div>
                </div>
            </div> : 
             <div className="deposit line-recap">
                <div className="label-section">
                    <span className="label">{t("deposit")}</span>
                </div>
                <span className="value">{format(deposit)}</span>
            </div> 
            }
        </>
    : <>
        <div className="reduction line-recap">
            <div className="label-section">
                <span className="label">{t("reduction")}
                    {reduction && plus.reduction.currency === "pourcent" ? ' ('+ plus.reduction.amount + '%)' : ''}
                </span>
            </div>
            <span className="value">{format(reduction)}</span>
        </div>
        <div className="deposit line-recap">
            <div className="label-section">
                <span className="label">{t("deposit")}
                    {deposit && plus.deposit.currency === "pourcent" ? ' ('+ plus.deposit.amount + '%)' : ''}
                </span>
            </div>
            <span className="value">{format(deposit)}</span>
        </div>
    </>
}
