import React from "react";

const BusSvg = (props: any) => {
  return (
    <svg
      id="bus"
      xmlns="http://www.w3.org/2000/svg"
      width="26.744"
      height="26.744"
      viewBox="0 0 26.744 26.744"
    >
      <g id="Groupe_4754" data-name="Groupe 4754" transform="translate(0 7.8)">
        <g id="Groupe_4753" data-name="Groupe 4753">
          <path
            id="Tracé_23793"
            data-name="Tracé 23793"
            d="M2.786,153.79v-3.343a.557.557,0,0,0,0-1.114A2.789,2.789,0,0,0,0,152.119v1.114A1.674,1.674,0,0,0,1.672,154.9H2.786a.557.557,0,1,0,0-1.114Z"
            transform="translate(0 -149.333)"
            fill={props.fill}
          />
        </g>
      </g>
      <g
        id="Groupe_4756"
        data-name="Groupe 4756"
        transform="translate(23.401 7.8)"
      >
        <g id="Groupe_4755" data-name="Groupe 4755">
          <path
            id="Tracé_23794"
            data-name="Tracé 23794"
            d="M448.557,149.333a.557.557,0,1,0,0,1.114v3.343a.557.557,0,1,0,0,1.114h1.114a1.674,1.674,0,0,0,1.672-1.672v-1.114A2.789,2.789,0,0,0,448.557,149.333Z"
            transform="translate(-448 -149.333)"
            fill={props.fill}
          />
        </g>
      </g>
      <g
        id="Groupe_4758"
        data-name="Groupe 4758"
        transform="translate(4.457 23.401)"
      >
        <g id="Groupe_4757" data-name="Groupe 4757">
          <path
            id="Tracé_23795"
            data-name="Tracé 23795"
            d="M90.347,448a.557.557,0,0,0-.557.557H86.446a.557.557,0,0,0-1.114,0v1.114A1.674,1.674,0,0,0,87,451.343h2.229a1.674,1.674,0,0,0,1.672-1.672v-1.114A.557.557,0,0,0,90.347,448Z"
            transform="translate(-85.332 -448)"
            fill={props.fill}
          />
        </g>
      </g>
      <g
        id="Groupe_4760"
        data-name="Groupe 4760"
        transform="translate(16.715 23.401)"
      >
        <g id="Groupe_4759" data-name="Groupe 4759">
          <path
            id="Tracé_23796"
            data-name="Tracé 23796"
            d="M325.015,448a.557.557,0,0,0-.557.557h-3.343a.557.557,0,1,0-1.114,0v1.114a1.674,1.674,0,0,0,1.672,1.672H323.9a1.674,1.674,0,0,0,1.672-1.672v-1.114A.557.557,0,0,0,325.015,448Z"
            transform="translate(-320 -448)"
            fill={props.fill}
          />
        </g>
      </g>
      <g id="Groupe_4762" data-name="Groupe 4762" transform="translate(2.229)">
        <g id="Groupe_4761" data-name="Groupe 4761">
          <path
            id="Tracé_23797"
            data-name="Tracé 23797"
            d="M62.168,0H45.453a2.789,2.789,0,0,0-2.786,2.786V21.73a2.789,2.789,0,0,0,2.786,2.786H62.168a2.789,2.789,0,0,0,2.786-2.786V2.786A2.789,2.789,0,0,0,62.168,0ZM47.682,2.229H59.939a1.672,1.672,0,1,1,0,3.343H47.682a1.672,1.672,0,1,1,0-3.343Zm0,18.944A1.672,1.672,0,1,1,49.353,19.5,1.674,1.674,0,0,1,47.682,21.172Zm12.258,0A1.672,1.672,0,1,1,61.611,19.5,1.674,1.674,0,0,1,59.939,21.172Zm2.786-7.243A1.674,1.674,0,0,1,61.054,15.6H46.567A1.674,1.674,0,0,1,44.9,13.929V8.358a1.674,1.674,0,0,1,1.672-1.672H61.054a1.674,1.674,0,0,1,1.672,1.672v5.572Z"
            transform="translate(-42.667 0)"
            fill={props.fill}
          />
        </g>
      </g>
    </svg>
  );
};

export default BusSvg;
