import React from "react";
import { Button, CircularProgress, Icon } from "@material-ui/core";
import "./Actions.scss";
import { useTranslation } from "react-i18next";
import { Invoice } from "../../../../models/invoice";
import { ReactSVG } from "react-svg";
import { AttachMoney } from "@material-ui/icons";
import { User } from "../../../../models/user";

interface IProps {
    invoice: Invoice;
    user: User;
    onEdit: any;
    pdfLoading: boolean;
    isSending: boolean;
    setOnEdit: (e: any) => void;
    onClickPdf: () => void;
    send: () => void;
    setOpenPayment: () => void;
    onClickCancel: () => void;
    onClickPenality: () => void;
}
export default function Actions({
    invoice,
    user,
    onEdit,
    pdfLoading,
    isSending,
    setOnEdit,
    onClickPdf,
    send,
    setOpenPayment,
    onClickCancel,
    onClickPenality,
}: IProps) {
    const { t } = useTranslation();

    const showEdit = () => {
        return user.isAdmin() && !invoice.isSent();
    }
    const showPdf = () => {
        return user.isAdmin() || invoice.isSent();
    }
    const showPayment = () => {
        return user.isAdmin() && invoice.isSent() && !invoice.isCancelled();
    }
    const showSend = () => {
        return showEdit();
    }
    const showCancel = () => {
        return user.isAdmin() && invoice.can_be_cancelled;
    }
    const showPenality = () => {
        return user.isAdmin() && invoice.isLate();
    }

    return (
        <div className="actions-section-previouwing">
            <div
                className="label-status to-upper"
                style={{ backgroundColor: invoice.getColorByStatus() }}
            >
                {t(`invoice_status.${invoice.status}`)}
            </div>
            <div className="actions">
                {showEdit() && (
                    <Button
                        className="edit preview-button"
                        style={{ minWidth: "15px" }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => setOnEdit(!onEdit)}
                    >
                        <Icon>edit</Icon>
                    </Button>
                )}

                {showCancel() && (
                    <Button
                        className="btn-form btn-icon"
                        variant="text"
                        style={{ minWidth: "50px" }}
                        color="secondary"
                        size="small"
                        onClick={() => onClickCancel()}
                    >
                        <span>{t("cancel")}</span> <Icon>delete</Icon>
                    </Button>
                )}

                {showPdf() && <Button
                    className="pdf preview-button"
                    style={{ minWidth: "15px" }}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => onClickPdf()}
                >
                    {!pdfLoading ? (
                        <span>{t("pdf")}</span>
                    ) : (
                        <CircularProgress />
                    )}
                </Button>}

                {showPayment() && (
                    invoice.folder?.status !== "ended" ? (
                        <Button
                            className="btn-form btn-icon"
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => setOpenPayment()}
                        >
                            <span>{t("add_payment")}</span> <Icon>payment</Icon>
                        </Button>
                    ) : (
                        <AttachMoney style={{ color: "rgb(117 255 106)" }} />
                    )
                )}

                {showPenality() && (
                    <Button
                        className="btn-form btn-icon"
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => onClickPenality()}
                    >
                        <span>{t("add_penality")}</span> <Icon>payment</Icon>
                    </Button>
                )}

                {showSend() && (
                    <Button
                        style={{
                            minWidth: "15px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => send()}
                    >
                        {!isSending ? (
                            <>
                                <ReactSVG
                                    style={{ marginTop: "5px" }}
                                    src="/images/app_icons/envelope.svg"
                                />
                                <span style={{ marginLeft: "10px" }}>
                                    {t("sending")}
                                </span>
                            </>
                        ) : (
                            <CircularProgress />
                        )}
                    </Button>
                )}
            </div>
        </div>
    );
}
