import React, { useEffect, useState } from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TransferService from "../../services/transfer-service";
import { Transfer } from "../../models/transfer";
import TransferRecap from "./providers/TransferRecap";
import ProviderChoice from "./providers/ProviderChoice";

interface IPropsPage {}

const TransferProvidersPage: React.FunctionComponent<IPropsPage> = (props) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [datas, setDatas] = useState<Transfer>();

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                try {
                    const dataService = new TransferService();
                    setDatas(await dataService.get(id));
                } catch (error) {
                    console.log("error", error);
                }
            }
        };

        fetchData();
    }, [id]);

    //Return the appropriate content
    return (
        <>
            {id ? (
                <>
                    <ContentSection
                        title={
                            t("entity.transfer") +
                            (datas
                                ? " : " +
                                  datas?.folder?.code +
                                  " " +
                                  datas?.code
                                : "")
                        }
                        returnAction={true}
                    >
                        {datas ? (
                            <>
                                <TransferRecap transfer={datas} />
                                <ProviderChoice transfer={datas} />
                            </>
                        ) : (
                            ""
                        )}
                    </ContentSection>
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default TransferProvidersPage;
