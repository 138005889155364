import { Filter } from "../filter";

interface IDictionary {
    [index: string]: string;
}

export class VehicleFilters {
    public brands: Array<Filter>;
    public exterior: Array<Filter>;
    public interior: Array<Filter>;
    public models: Array<Filter>;
    public options: Array<Filter>;
    public types: Array<Filter>;
    public years: Array<Filter>;

    constructor(data: any){
        this.brands = data.brands.map( (brand: Object) => new Filter(brand));
        this.exterior = data.exterior.map( (exterior: Object) => new Filter(exterior));
        this.interior = data.interior.map( (interior: Object) => new Filter(interior));
        this.models = data.models.map( (model: Object) => new Filter(model));
        this.options = data.options.map( (option: Object) => new Filter(option));
        this.types = data.types.map( (type: Object) => new Filter(type));
        this.years = data.years.map( (year: Object) => new Filter(year));
    }
}