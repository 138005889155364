import React, { useEffect } from 'react';
import './PreviewPdf.scss';

interface IProps{
    bytes?: any;
}
export default function PreviewPdf({bytes}: IProps){

    useEffect(() => {
        if(bytes){
            const elems = document.getElementsByClassName('content-pdf');
            if(elems.length){
                const iframe = document.createElement('iframe');
                iframe.className = 'sample-iframe';
                iframe.src = bytes;
                elems[0].innerHTML = ''; //empty previous display
                elems[0].appendChild(iframe);
            }
        }
    }, [bytes]) 

    return <div className="content-pdf"></div>
}
