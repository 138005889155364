import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BeneficiaryService from "../../services/beneficiary-service";
import { Beneficiary } from "../../models/beneficiary";
import ContentSection from "../../components/content-section/ContentSection";
import FormBeneficiary from "./form-beneficiary/FormBeneficiary";
import { CircularProgress } from "@material-ui/core";
import ClientService from "../../services/client-service";
import { Client } from "../../models/client";
import { User } from "../../models/user";
import { connect } from "react-redux";

interface PropsFormPage {
  id?: number;
  user: User;
}

const BeneficiaryFormPage: React.FunctionComponent<PropsFormPage> = (props) => {
  const { t } = useTranslation();
  const [benef, setBenef] = useState<Beneficiary>();
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { id, client_id } = useParams();

  useEffect(() => {
    const fetchAll = async () => {
      const clientService = new ClientService();
      const clients = await clientService.getClientsForForm(props.user);
      setClients(clients);
      if (id) {
        const benefService = new BeneficiaryService();
        setBenef(new Beneficiary(await benefService.get(id)));
      }
      setLoading(false);
    };
    fetchAll();
  }, [id, props.user]);

  return (
    <>
      <ContentSection
        title={benef ? t("beneficiary_update") : t("beneficiary_add")}
        returnAction={true}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <FormBeneficiary beneficiary={benef} clients={clients} client_id={client_id} />
        )}
      </ContentSection>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
      user: state.auth.user,
  };
}

export default connect(mapStateToProps)(BeneficiaryFormPage);
