import { Deserializable } from "./interfaces/deserializable";

export class ClientType implements Deserializable {
    public id: number;
    public label: string;

    constructor(data:any) {
        this.id = data.id;
        this.label = data.label;
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}