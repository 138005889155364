import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ContentSection from "../../components/content-section/ContentSection";
import FormVehicle from "./form-vehicle/FormVehicle";
import BrandService from "../../services/brand-service";
import {VehicleBrand} from "../../models/vehicle-brand";
import VehicleTypeService from "../../services/vehicle-type-service";
import {VehicleType} from "../../models/vehicle-type";
import {VehicleModel} from "../../models/vehicle-model";
import ModelService from "../../services/model-service";
import VehicleColorService from "../../services/vehicle-color-service";
import {VehicleOption} from "../../models/vehicle-option";
import VehicleOptionService from "../../services/vehicle-option-service";
import {useParams} from "react-router-dom";
import ProviderService from "../../services/provider-service";
import {Provider} from "../../models/provider";
import VehicleService from "../../services/vehicle-service";
import {Vehicle} from "../../models/vehicle";
import {InteriorColor} from "../../models/interior-color";
import {ExteriorColor} from "../../models/exterior-color";
import {CircularProgress} from "@material-ui/core";
import {connect} from "react-redux";
import {User} from "../../models/user";

interface PropsForm {
    user: User;
}

const AddOrEditVehiclePage = ({user}: PropsForm) => {
    const {providerId, vehicleId} = useParams();
    const {t} = useTranslation();
    const [provider, setProvider] = useState<Provider>();
    const [vehicle, setVehicle] = useState<Vehicle>();
    const [brands, setBrands] = useState<VehicleBrand[]>();
    const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>();
    const [vehicleModels, setVehicleModels] = useState<VehicleModel[]>();
    const [vehicleOptions, setVehicleOptions] = useState<VehicleOption[]>();
    const [interiorColors, setInteriorColors] = useState<InteriorColor[]>([]);
    const [exteriorColors, setExteriorColors] = useState<ExteriorColor[]>([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAll = async () => {
            try {
                const vehicleService = new VehicleService();
                let vehicleData = null;
                if (vehicleId) {
                    vehicleData = await vehicleService.get(vehicleId);
                    setVehicle(vehicleData);
                }

                const brandService = new BrandService();
                setBrands(await brandService.all());

                const vehicleTypeService = new VehicleTypeService();
                setVehicleTypes(await vehicleTypeService.all());

                const modelService = new ModelService();
                setVehicleModels(await modelService.all());

                const vehicleColorService = new VehicleColorService();
                const vehicleColors = await vehicleColorService.all();
                setInteriorColors(
                    vehicleColorService.getInteriorColors(vehicleColors)
                );
                setExteriorColors(
                    vehicleColorService.getExteriorColors(vehicleColors)
                );

                const vehicleOptionService = new VehicleOptionService();
                setVehicleOptions(await vehicleOptionService.all());

                if (user.provider) {
                    setProvider(user.provider);
                } else {
                    if (providerId || vehicleData) {
                        const providerService = new ProviderService();
                        setProvider(await providerService.get(providerId ? providerId : vehicleData?.provider_id));
                    }
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log("error", error);
            }
        };

        fetchAll();
    }, [providerId, vehicleId, user]);

    return (
        <>
            <ContentSection
                title={
                    loading
                        ? t("loading")
                        : vehicle
                        ? t("edit_vehicle")
                        : t("add_vehicle")
                }
                returnAction={true}
            >
                {brands &&
                vehicleTypes &&
                vehicleModels &&
                interiorColors &&
                exteriorColors &&
                vehicleOptions &&
                provider &&
                !loading ? (
                    <FormVehicle
                        brands={brands}
                        vehicleTypes={vehicleTypes}
                        vehicleModels={vehicleModels}
                        interiorColors={interiorColors}
                        exteriorColors={exteriorColors}
                        vehicleOptions={vehicleOptions}
                        vehicle={vehicle}
                        provider={provider}
                    />
                ) : loading ? (
                    <CircularProgress/>
                ) : (
                    ""
                )}
            </ContentSection>
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(AddOrEditVehiclePage);
