import React from "react";
import {useTranslation} from "react-i18next";
import FilterColumn, {
    FilterType,
} from "../../../components/filter-column/filterColumn";
import {FiltersEnabled} from "../../../models/utils/filters/filter-enabled";
import {DriverFilters} from "../../../models/utils/filters/drivers/driver-filters";

interface IPropsFiltersDrivers {
    filters?: DriverFilters;
    filtersEnabled?: FiltersEnabled;
    setFilters: (filters: FiltersEnabled) => void;
}

const FiltersDrivers = ({
                            filters,
                            filtersEnabled,
                            setFilters,
                        }: IPropsFiltersDrivers) => {
    const {t} = useTranslation();
    const styles = {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row",
    } as React.CSSProperties;

    const changeValue = (name: string, value: any, type: FilterType) => {
        if (filters && filtersEnabled) {
            const newFiltersEnabled = filtersEnabled.addFilter(
                name,
                value === t("no") ? '0' : value,
                type
            );
            setFilters(new FiltersEnabled(newFiltersEnabled));
        }
    };
    return (
        <div className="filter-std-row" style={styles}>
            {filters && (
                <>
                {console.log(filters.languages)}
                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.languages}
                        label={t("language")}
                        name="languages"
                        type={FilterType.Languages}
                    />
                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.is_tenders}
                        label={t("is_tender")}
                        name="is_tender"
                        type={FilterType.Unique}
                    />
                </>
            )}
        </div>
    );
};

export default FiltersDrivers;
