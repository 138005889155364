import { Button, FormControl, FormLabel, Icon, Input, TextareaAutosize } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { Folder } from "../../../models/folder";
import { format } from "../../../utils/AmountHelper";
import "./CreditCreate.scss";
import CreditService from "../../../services/credit-service";
import { ErrorForm } from "../../../components/ErrorForm";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import Modal from "../../../components/modal/Modal";
import { ReactSVG } from "react-svg";
import Colors from "../../design-dependencies/Colors";
import { User } from "../../../models/user";

interface IPropsDeposit {
    folder: Folder;
    user: User;
}

const CreditCreate = ({ folder, user }: IPropsDeposit) => {
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());

    const service = new CreditService();

    const [amount, setAmount] = useState('');
    const [comment, setComment] = useState('');

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        setIsOpen(false);
    };

    const submit = async () => {

        try{
            setLoading(true);

            const credit = await service.add({
                folder_id: folder.id,
                value: amount,
                comment: comment ? comment : '',
            });
            toastr.success(t('success'), t('sucess_credit_created'));

            if (credit.folder) {
                folder.total_credits = credit.folder?.total_credits;
            }

            closeModal()
        } catch(e){
            toastr.error(t("error"), t("unexpected_error"));
            setErrorApi(new ErrorFormData(e.response.data));
        }
        setLoading(false);
    };

    return (
        <>
            { user.isAdmin() &&  <div className="credit-container">
                <span>
                    {t("credits_total")} : {format(folder.total_credits)}
                </span>
                <Button
                    className="btn-form btn-icon"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => setIsOpen(true) }
                >
                    <span>{t("create_credit")}</span> <Icon>money</Icon>
                </Button>
            </div> }
            {isOpen &&
            
            <Modal
                label={t("create_credit")}
                isOpen={isOpen}
                closeModal={() => closeModal()}
            >
                <div className="content-modal-admin modal-credit">
                    <div className="row">
                        <div className="col-3">
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t('amount')}</FormLabel>
                                <Input onChange={(e) => setAmount(e.target.value)} value={amount} type="number" placeholder={t('amount')} />
                            </FormControl>
                        </div>
                        <div className="col-9">
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t('comment')}</FormLabel>
                                <TextareaAutosize onChange={(e) => setComment(e.target.value)} value={comment} className="textarea w-100" aria-label="empty textarea" rowsMin={4} placeholder={t('comment')} />
                            </FormControl>
                        </div>
                    </div>
                    <div className="actions">
                        <Button
                            disabled={loading}
                            onClick={() => closeModal()}
                            className="btn-form btn-icon mr-2 submit-input"
                            variant="contained"
                            style={{ backgroundColor: Colors.red }}
                            size="large"
                            type="button"
                        >
                            {t("cancel")}{" "}
                            <ReactSVG src="/images/app_icons/icon-delete.svg" />
                        </Button>
                        <Button
                            disabled={loading}
                            onClick={() => submit()}
                            className="btn-form btn-icon mr-2 submit-input"
                            variant="contained"
                            color="primary"
                            size="large"
                            type="button"
                        >
                            {t("validate")} <Icon>{"done"}</Icon>
                        </Button>
                    </div>
                </div>
                <ErrorForm errorApi={errorApi} />
            </Modal>
            }
        </>
    )

};

export default CreditCreate;
