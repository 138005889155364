import { AuthState, AuthActionTypes, LOGIN_USER, LOGOUT_USER, LOGIN_SUCESS_USER, LOGIN_ERROR_USER, GET_USER_FETCH_ACTION, GET_USER_SUCCESS_ACTION, GET_USER_FAIL_ACTION } from "./types";


const initialState:  AuthState = {
    loading: false,
    access_token: null,
    token_type: null,
    expires_in: null,
    is_authentificated: null,
    error: null,
    errors: null,
    user: null,
}

export function authReducer(
    state = initialState,
    action: AuthActionTypes
): AuthState{
    switch(action.type) {
        case LOGIN_USER: {
            return {
                ...state,
                loading: true
            }
        }
        case LOGIN_SUCESS_USER: {
            return {
                ...state,
                ...action.payload,
                loading: false, 
                error: null, 
                errors: null,
                user: null,
                is_authentificated: true
            }
        }
        case LOGIN_ERROR_USER: {
            return {
                ...state,
                ...{error: action.payload.error, errors: null},
                ...{loading: false, user: null, access_token: null, token_type: null, expires_in: null}
            }
        }
        case LOGOUT_USER: {
            return initialState;
        }
        
        case GET_USER_FETCH_ACTION: {
            return {
                ...state,
                loading: true
            }
        }

        case GET_USER_SUCCESS_ACTION: {
            return {
                ...state,
                loading: false,
                user: action.payload,
                is_authentificated: true
            }
        }

        case GET_USER_FAIL_ACTION: {
            return {
                ...state,
                loading: false,
                is_authentificated: false
            }
        }

        default:
            return state;
    }
}