import { Deserializable } from "./interfaces/deserializable";
import { ClientType } from "./client-type";
import { User } from "./user";
import { Address } from "./address";

export class Client implements Deserializable {
    public id: number;
    public parent_id: number;
    public parent?: Client | null;
    public company_name: string;
    public client_type_id?: number | null;
    public client_type?: ClientType | null;
    public user_id?: number | null;
    public user?: User | null;
    public img_url: string | null;
    public referent_name: string;
    public email: string;
    public phone_number: string;
    public mobile_number: string;
    public tva_number: string;
    public tva_exoneration: boolean;
    public tva_rate?: string | null;
    public division_id?: number | null;
    public is_tender?: boolean | false;
    public nbAddresses?: number | 0;
    public nbAccounts?: number | 0;
    public nbFolders?: number | 0;
    public is_active?: boolean | false;
    public created_at: Date;
    public updated_at: Date;
    public subAccounts?: Client[];
    public address?: Address | null;
    public addresses?: Address[] | null;
    public created_by?: number;
    public division?: Division | null;

    constructor(data: any) {
        this.id = data.id;
        this.parent_id = data.parent_id;
        if (data.parent) {
            this.parent = new Client(data.parent);
        }

        this.company_name = data.company_name;
        this.client_type_id = data.client_type_id;
        this.client_type = data.client_type
            ? new ClientType(data.client_type)
            : null;
        this.user_id = data.user_id;
        this.user = data.user ? new User(data.user) : null;
        this.referent_name = data.referent_name;
        this.img_url = data.img_url;
        this.email = data.email;
        this.phone_number = data.phone_number;
        this.mobile_number = data.mobile_number;

        this.tva_number = data.tva_number;
        this.tva_exoneration = data.tva_exoneration === 1;
        this.tva_rate = data.tva_rate;

        this.nbAddresses = data.nbAddresses;
        this.nbAccounts = data.nbAccounts;

        this.nbFolders = data.nbFolders;

        this.division_id = data.division_id;
        this.is_active = data.is_active;
        this.is_tender = data.is_tender;

        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.subAccounts = data.subAccounts
            ? data.subAccounts.map((a: Client) => new Client(a))
            : null;
        this.address = data.address ? new Address(data.address) : null;

        if(data.addresses && data.addresses.length){
            this.addresses = data.addresses.map( (a: any) => new Address(a));
        }

        this.created_by = data.created_by;
        this.division = data.division ? new Division(data.division) : null;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    getPhoneForList(): string {
        return this.mobile_number ? this.mobile_number : this.phone_number;
    }
}
export class Division implements Deserializable {
    public id: number;
    public label: string;

    constructor(data: any) {
        this.id = data.id;
        this.label = data.label;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}

export enum Grants {
    OWNER = "owner",
    MANAGER = "manager",
    READER = "reader",
}

export class Grant implements Deserializable {
    public id: number;
    public client_id: number;
    public client?: Client;
    public user_id: number;
    public user?: User;
    public level: string;

    constructor(data: any) {
        this.id = data.id;
        this.level = data.level;
        this.client_id = data.client_id;
        if (data.client) {
            this.client = new Client(data.client);
        }
        this.user_id = data.user_id;
        if (data.user) {
            this.user = new User(data.user);
        }
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
