import React, {useState, useEffect, useCallback} from "react";

import {
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Icon,
    Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DataPaginate } from "../../../models/utils/data-paginate";
import Pagination from "../../../components/pagination/pagination";
import GlobalSearch from "../../../components/global-search/globalSearch";
import Paper from "@material-ui/core/Paper";
import KpiContentSeparator from "../../../components/kpi-content-separator/KpiContentSeparator";
import { Transfer } from "../../../models/transfer";
//import "./ListTransfers.scss";
import HeadTableCell from "../../../components/table/HeadTableCell";
import { ErrorForm } from "../../../components/ErrorForm";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import TrackingService from "../../../services/tracking-service";
import ItemTracking from "./ItemTracking";
import {debounce} from "lodash";

const ListTrackings: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [dataPaginate, setDataPaginate] = useState(new DataPaginate());
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(true);
    const [date, setDate] = useState(new Date());

    const refresh = async () => {
        setReload(true);
    };

    const fetchAll = async () => {
        try {
            setLoading(true);
            const dataService = new TrackingService();
            setDataPaginate(
                await dataService.all(
                    currentPage,
                    search
                )
            );
        } catch (error) {
            setErrorApi(new ErrorFormData());
        }
        setLoading(false);
        setReload(false);
    };

    const debounceQuery = useCallback(
        debounce(fetchAll, 500),
        [currentPage, search, reload, date]
    );

    useEffect(() => {
        debounceQuery();
        return debounceQuery.cancel;
    }, [currentPage, search, reload, date, debounceQuery]);

    useEffect(() => {
        //trigger reload
        refresh();
    }, [currentPage, search]);

    function onPageChange(newPage?: number) {
        if (newPage) {
            setCurrentPage(newPage);
        }
    }

    return (
        <>
            <GlobalSearch
                onSearch={(value: string) => {
                    setCurrentPage(1)
                    setSearch(value)
                }}
                onResetFilter={() => setDate(new Date())}
            />
            <KpiContentSeparator />
            {!loading && dataPaginate && dataPaginate.data ? (
                <>
                    <TableContainer component={Paper} className="table-scroll">
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <HeadTableCell align="center">
                                        {t("ref")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("driver")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        align="center"
                                        className="client_logo_table"
                                    >
                                        {t("client")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("nb_passengers")}
                                    </HeadTableCell>

                                    <HeadTableCell align="center">
                                        {t("departure_date")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("departure_time")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("departure_location")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("map")}
                                    </HeadTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataPaginate.data.map((transfer: Transfer) => (
                                    <ItemTracking
                                        key={`table_transfer_id_${transfer.id}`}
                                        transfer={transfer}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        dataPaginate={dataPaginate}
                        onPageChange={(page) => onPageChange(page)}
                    />
                    <div className="refresh-transfers">
                        <Button
                            className="btn-form btn-icon"
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={() => refresh()}
                        >
                            <span>{t("refresh")}</span> <Icon>refresh</Icon>
                        </Button>
                    </div>
                </>
            ) : loading ? (
                <CircularProgress />
            ) : (
                <div>{t("no_items")}</div>
            )}

            <ErrorForm errorApi={errorApi} />
        </>
    );
};

export default ListTrackings;
