import React from "react";
import { DataPaginate } from "../../../../models/utils/data-paginate";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../components/pagination/pagination";
import { VehicleModel } from "../../../../models/vehicle-model";
import ItemModel from "./ItemModel";
import { VehicleBrand } from "../../../../models/vehicle-brand";

interface IListModelsProps{
    dataPaginate:  DataPaginate,
    brands: Array<VehicleBrand>,
    loading?: boolean,
    onPageChange: (page: number) => void,
    updateListModels: (brandId: number) => void,
    onUpdateSuccess: (vehicleModel: VehicleModel) => void
    onDeleteSuccess: () => void
}

const ListBrands: React.FunctionComponent<IListModelsProps> = ({dataPaginate, brands, loading, onPageChange, updateListModels, onUpdateSuccess, onDeleteSuccess}) => {
    const { t } = useTranslation();

    return (
        <>
            { dataPaginate && dataPaginate.data ? (
                <>
                    <select name="brand_id" onChange={(e) => updateListModels(parseInt(e.target.value))}
                        className="models-brand-filter"
                    >
                        <option value={0}>{t('all')}</option>
                        { brands.map( (brand: VehicleBrand) => {
                            return (
                                <option key={`list_models_brand_id_${brand.id}`} value={brand.id}>
                                    {brand.name}
                                </option>
                            )
                        } ) }
                    </select>

                    <table>
                        <thead>
                            <tr>
                                <th>{t('name')}</th>           
                            </tr>
                        </thead>
                        <tbody>
                            { dataPaginate.data.map( ( model: VehicleModel, index: number) => {
                                return (
                                    <tr key={`vehicle-model-${model.id}-${index}`}>
                                        <ItemModel 
                                            onUpdateSuccess={(vehicleModel => onUpdateSuccess(vehicleModel))}
                                            onDeleteSuccess={( () => onDeleteSuccess())} 
                                            vehicleModel={model} />
                                    </tr>
                                )
                            }) }
                        
                        </tbody>
                    </table>
                    <Pagination dataPaginate={dataPaginate} onPageChange={(page) => onPageChange(page)} />
                </>
                ) : loading ? <div>Loading...</div> : <div>Aucun élément !</div>
            }
        </>
    );
}

export default ListBrands;
