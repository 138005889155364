import { Deserializable } from "./interfaces/deserializable";

export class VehicleOption implements Deserializable {
    public id: number;
    public label: string;
    public description: string;
    public created_at: Date;
    public updated_at: Date;
    public current_time: number;

    constructor(data:any = null) {
        this.id = data.id;
        this.label = data.label;
        this.description = data.description;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.current_time = new Date().getTime() + Math.floor(Math.random() * Math.floor(9999));
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}