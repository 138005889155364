import { Deserializable } from "./interfaces/deserializable";

export class ExteriorColor implements Deserializable {
    public id: number;
    public color: string;
    public type: number;
    public hex_color?: string;
    public img_url?: string;

    constructor(data:any){
        this.id = data.id;
        this.color = data.color;
        this.type = data.type;
        this.hex_color = data.hex_color;
        this.img_url = data.img_url;
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}