export enum documentType{
    INVOICE="invoice",
    QUOTE="quote",
    DEPOSIT="deposit"
}

export function documentTypeIsInvoice(type: documentType){
    return type === documentType.INVOICE;
}

export function documentTypeIsQuote(type: documentType){
    return type === documentType.QUOTE;
}

export function documentTypeIsDeposit(type: documentType){
    return type === documentType.DEPOSIT;
}