
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import React from "react";
import { useParams } from "react-router-dom";
import ListTrackings from "./list-trackings/ListTrackings";
import DetailTracking from "./detail-tracking/DetailTracking";


const TrackingsPage: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    //Return the appropriate content
    return (
        <>
                <div className="transfer-list">
                    <ContentSection
                        title={t("tracking")}
                        returnAction={id ? true : false}
                    >
                        {id
                            ? <DetailTracking id={id} />
                            : <ListTrackings />
                        }                        
                    </ContentSection>
                </div>

        </>
    );
};

export default (TrackingsPage);
