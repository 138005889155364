import React, { useEffect, useState } from "react";

import { IndicatorType } from "../../../components/indicator/Indicator";
import { useTranslation } from "react-i18next";
import ClientService from "../../../services/client-service";
import NewIndicator from "../../../components/indicator/NewIndicator";
import UseStyles from "../../design-dependencies/UseStyles";
import { connect } from "react-redux";
import { Roles, User } from "../../../models/user";

interface IKpiClient {
    active: number;
    outstanding: number;
    accounts: number;
}

interface IProps {
    user: User;
}

function KpiClient({ user }: IProps) {
    const [kpis, setKpis] = useState<IKpiClient>();
    const { t } = useTranslation();

    useEffect(() => {
        const getKpis = async () => {
            try {
                const service = new ClientService();
                setKpis(await service.getKpis());
            } catch (error) {
                console.log("error", error);
            }
        };

        getKpis();
    }, []);

    return (
        <>
        {user?.role === Roles.CLIENT
            ? <>
                {/* TODO ADD Specific Client KPI */}
            </>
            :
            <div className="row">
                <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                    <NewIndicator
                        className={UseStyles().blueGradient}
                        index={0}
                        text={t("clients.active_clients")}
                        type={IndicatorType.Client}
                        total={kpis?.active ? kpis.active : 0}
                    />
                </div>
                <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                    <NewIndicator
                        className={UseStyles().orangeGradient}
                        index={1}
                        text={t("clients.outstanding")}
                        type={IndicatorType.Client}
                        total={kpis?.outstanding ? kpis.outstanding : 0}
                    />
                </div>
                <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                    <NewIndicator
                        className={UseStyles().yellowGradient}
                        index={2}
                        text={t("clients.accounts")}
                        type={IndicatorType.Client}
                        total={kpis?.accounts ? kpis.accounts : 0}
                    />
                </div>
            </div>
        }
        </>);
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(KpiClient);
