import React from "react";
import "./Indicator.scss";
import { ReactSVG } from "react-svg";
import { css } from "glamor";

interface IPropsIndicator {
  index: number;
  type: IndicatorType;
  icon: string;
  title: string;
  total?: number;
}

export enum IndicatorType {
  Vehicle,
  Provider,
  Client,
  Beneficiary,
}

const getColor = (indicatorType: IndicatorType, index: number) => {
  switch (indicatorType) {
    case IndicatorType.Vehicle:
      if (index === 0) {
        return "#0268CC";
      } else if (index === 1) {
        return "#FE885B";
      } else if (index === 2) {
        return "#FFD727";
      } else {
        return "#0268CC";
      }

    case IndicatorType.Provider:
      if (index === 0) {
        return "#0F7AE4";
      } else {
        return "#1DBAAD";
      }

    case IndicatorType.Client:
      if (index === 0) {
        return "#0268CC";
      } else if (index === 1) {
        return "#FE885B";
      } else if (index === 2) {
        return "#FFD727";
      } else {
        return "#0268CC";
      }

    default:
      if (index === 0) {
        return "#0268CC";
      } else if (index === 1) {
        return "#FE885B";
      } else if (index === 2) {
        return "#FFD727";
      } else {
        return "#0268CC";
      }
  }
};

const Indicator: React.FunctionComponent<IPropsIndicator> = (props) => {
  const color = getColor(props.type, props.index);
  const indicatorIconStyle = {
    backgroundColor: color,
  };

  const totalStyle = {
    color: color,
  };

  const styles = css({
    " path": {
      fill: color,
    },
  });

  return (
    <div className="indicator">
      <div className="indicator-icon" style={indicatorIconStyle}>
        <div className="indicator-round">
          <ReactSVG {...styles} src={props.icon} />
        </div>
      </div>

      <div className="indicator-content">
        <span className="title">{props.title}</span>
        <span className="total" style={totalStyle}>
          {props.total ? props.total : "-"}
        </span>
      </div>
    </div>
  );
};

export default Indicator;
