import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ContentSection from "../../components/content-section/ContentSection";
import FormSubmitTicket from "./form-submit-ticket/FormSubmitTicket";
import {useParams} from "react-router-dom";
import TicketService from "../../services/ticket-service";
import {Ticket} from "../../models/ticket";
import FormAnswerReply from "./form-reply-ticket/FormAnswerReply";
import {Roles} from "../../models/user";

const TicketFormPage: React.FunctionComponent = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [ticket, setTicket] = useState<Ticket>();

    useEffect(() => {
        const fetchAllClients = async () => {
            if (id) {
                try {
                    setLoading(true);
                    const ticketService = new TicketService();
                    setTicket(await ticketService.get(id));
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    console.log("error", error);
                }
            }
        };

        fetchAllClients();
    }, [id]);

    const refresh = async () => {
        try {
            setLoading(true);
            const ticketService = new TicketService();
            setTicket(await ticketService.get(id));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("error", error);
        }
    }

    return (
        <>
            <ContentSection
                title={
                    id
                        ? t("answer_problem")
                        : t("submit_problem")
                }
                addAction={Roles.ADMIN ? "ticket" : ""}
                returnAction={true}
            >
                <div className="content-ticket-form">
                    {!id && !loading ? (
                        <FormSubmitTicket/>
                    ) : id && !loading ? (
                        <FormAnswerReply ticket={ticket} refresh={() => refresh() }/>
                    ) : (
                        <div>{t("loading")}</div>
                    )}
                </div>
            </ContentSection>
        </>
    )
}

export default TicketFormPage;