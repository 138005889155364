import {Deserializable} from "./interfaces/deserializable";
import {User} from "./user";
import {Ticket} from "./ticket";

export class Reply implements Deserializable {
    public id: number;
    public answer: string;
    public ticket: Ticket;
    public from_user: User;
    public attachment: File;
    public img_url?: string;
    public created_at: Date;
    public updated_at: Date;

    constructor(data: any) {
        this.id = data.id;
        this.answer = data.answer;
        this.ticket = data.ticket;
        this.from_user = data.from_user;
        this.attachment = data.attachment;
        this.img_url = data.img_url;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}