import axios, { AxiosResponse } from "axios";

import { getBaseUrl, getParamsUrl } from "./setting-service";
import { DataPaginate } from "../models/utils/data-paginate";
import { Transfer } from "../models/transfer";

export default class TrackingService {

    all(
        page?: number | null,
        search?: string | null,
    ) {
        let paramsUrl = getParamsUrl([
            { key: "page", value: page },
            { key: "search", value: search },
        ]);

        return axios
            .get(`${getBaseUrl()}trackings${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;

                res.data = res.data.map(
                    (client: Object) => new Transfer(client)
                );
                return new DataPaginate(res);
            });
    }

    get(id: number) {
        return axios
            .get(`${getBaseUrl()}trackings/${id}`)
            .then((response: AxiosResponse) => new Transfer(response.data))
            .catch();
    }
}
