import {Department} from "../models/department";
import {getParamsUrl} from "./setting-service";
import axios, {AxiosResponse} from "axios";

export default class GeoApiService {
    // Get list of departments from api-gouv.fr
    getAllDepartments(search?: string | null) {

        const paramsUrl = getParamsUrl([
            {key: "search", value: search},
        ]);

        return axios
            .get(`https://geo.api.gouv.fr/departements${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response;
                res.data = res.data.map(
                    (department: Department, index: number = 1) =>
                        new Department({...department, id: index})
                );
                return res.data;
            })
            .catch();
    }
}
