import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import BodyTableRow from "../../../components/table/BodyTableRow";
import BodyTableCell from "../../../components/table/BodyTableCell";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { Quote } from "../../../models/quote";
import { format } from "../../../utils/AmountHelper";
import { connect } from "react-redux";
import { User } from "../../../models/user";

interface ItemQuoteProps {
    quote: Quote;
    setErrorApi: (error: ErrorFormData) => void;
    handleDeleted: () => void;
    user: User;
}

const ItemQuote: React.FunctionComponent<ItemQuoteProps> = ({
    quote,
    setErrorApi,
    handleDeleted,
    user,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    // const idQuote = quote.id.toString();
    return (
        <>
            <BodyTableRow key={quote.id}>
                <BodyTableCell className="img-index-driver ">
                    <div
                        className="link-table"
                        onClick={() => history.push(`quote/${quote.id}`)}
                    >
                        {quote.code}
                    </div>
                </BodyTableCell>
                <BodyTableCell align="center">
                    {quote.folder?.client && quote.folder.client.company_name}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {quote.folder.code}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {quote.folder.transfers ? quote.folder.transfers.length : 0}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {format(quote.price_excl_tax ? quote?.price_excl_tax : 0)}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {format(quote.price_incl_tax ? quote?.price_incl_tax : 0)}
                </BodyTableCell>
                <BodyTableCell align="center">{format(0)}</BodyTableCell>
                <BodyTableCell align="center">
                    {quote.deadline_at
                        ? quote.deadline_at?.toLocaleDateString()
                        : ""}
                </BodyTableCell>
                <BodyTableCell align="center">
                    <Button
                        className="btn-rounded mr-2"
                        variant="contained"
                        onClick={() => history.push(`quote/${quote.id}`)}
                    >
                        {t("view_quote")}
                    </Button>
                </BodyTableCell>
                <BodyTableCell align="center">
                    <div className="tag-table-section">
                        <div className="tag-table-content">
                            <div
                                className="tag-table"
                                style={{
                                    backgroundColor: quote.getColorByStatus(),
                                }}
                            >
                                <div className="tag-table">
                                    <span className="to-upper">
                                        {t(
                                            `quote_status_${user.role}.${quote.status}`
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </BodyTableCell>
                <BodyTableCell align="right" className="driver-actions-row">
                    {/*<ActionTable
                        deleteAction={() => {}}
                        updateAction={() => history.push(`quote/${quote.id}`)}
                    />*/}
                </BodyTableCell>
            </BodyTableRow>
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(ItemQuote);
