import React from "react";
import { useTranslation } from "react-i18next";
import "./SubmitComponent.scss";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";

interface IPropsSubmitComponent {
    text: string;
    loading?: boolean;
    icon?: string;
    mainClass?: string;
    isActivate: boolean;
}

const SubmitComponent = ({
    text,
    loading = false,
    icon,
    mainClass,
    isActivate,
}: IPropsSubmitComponent) => {
    const { t } = useTranslation();
    return (
        <div className={!mainClass ? "submit-container" : mainClass}>
            <Button
                className="btn-form btn-icon mr-2 submit-input"
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={!isActivate}
            >
                {!loading ? (
                    <>
                        <span>{t(text)}</span>{" "}
                        <Icon>{!icon ? "check" : icon}</Icon>{" "}
                    </>
                ) : (
                    <div className="loader">
                        {" "}
                        <CircularProgress />{" "}
                    </div>
                )}
            </Button>
        </div>
    );
};

export default SubmitComponent;
