import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from './setting-service';
import { VehicleType } from '../models/vehicle-type';

export default class VehicleTypeService{

    all() {
        return axios.get(`${getBaseUrl()}vehicle-types`)
            .then( (res:AxiosResponse) =>  res.data.map( (vehicleType:Object)=> new VehicleType(vehicleType)));
    }

    getDetailed() {
        return axios.get(`${getBaseUrl()}vehicle-types/detailed`)
            .then( (res:AxiosResponse) =>  res.data.map( (vehicleType:Object)=> new VehicleType(vehicleType)));
    }
}