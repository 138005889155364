import Modal from "../../modal/Modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ModalCancel.scss";
import { Button, CircularProgress, Icon } from "@material-ui/core";
import { Quote } from "../../../models/quote";
import QuoteService from "../../../services/quote-service";
import { toastr } from "react-redux-toastr";

interface IPopupAccept {
    isOpen: boolean;
    closeDialog: () => void;
    handleCancel: (quote: Quote) => void;
    quote: Quote;
}

const ModalCancel = ({
    isOpen,
    closeDialog,
    handleCancel,
    quote,
}: IPopupAccept) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const cancel = async () => {
        const quoteService = new QuoteService();
        setLoading(true);
        try {
            await quoteService.cancel(quote.id);
            toastr.success(t("success"), t("success_bill_cancel"));
            handleCancel(quote);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastr.error(t("error"), t("unexpected_error"));
        }
    };

    return (
        <Modal
            label={t("cancel_quote")}
            isOpen={isOpen}
            closeModal={() => closeDialog()}
        >
            <div className="modal-cancel-quote">
                <div className="title-section">
                    <h2 className="title-content to-upper">{t("warning")}</h2>
                    <p className="infos">
                        <span>{t("cancel_quote_warning")}</span>
                    </p>
                </div>

                <div className="buttons-section">
                    <div className="buttons-content">
                        {!loading ? (
                            <>
                                <Button
                                    className="btn-form btn-icon"
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={() => closeDialog()}
                                    disabled={loading}
                                >
                                    <span>{t("cancel")}</span>{" "}
                                    <Icon>close</Icon>
                                </Button>
                                <Button
                                    className="btn-form btn-icon"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => cancel()}
                                    disabled={loading}
                                >
                                    <span>{t("validate")}</span>{" "}
                                    <Icon>check</Icon>
                                </Button>
                            </>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                }}
                            >
                                <CircularProgress />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ModalCancel;
