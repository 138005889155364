import React from 'react';

export default function ConfidentialityApp(){
    return  <div className="container-login-page">
                <div className="login-page">
                    <div className="container-login">
                        <div className="container-text">
                            <h1 className="to-upper text">CONDITIONS GENERALES DE VENTE</h1>
                        </div>
                        <div className="login flex_center">
                            <div className="form-container" style={{width: '100%', height: '600px', overflow: 'scroll'}}>
                                <div className="admin-logo-section">
                                    <img
                                        style={{width: '500px', marginBottom: '30px'}}
                                        className="logo"
                                        src={`/images/login/images/admin-logo.png`}
                                        alt=""
                                    />
                                </div>
                                <div className="form-content">
                                    <div className="lorem">
                                        <h2>1 – ACTIVITE – REGLEMENTATION - ASSURANCE</h2>
                                        <h2>2 – SECTEUR GEOGRAPHIQUE - DISPONIBILITE</h2>
                                        <h2>3 – MATERIEL MIS A DISPOSITION - CHAUFFEUR</h2>
                                        <h2>4 – DETAIL DES PRESTATIONS</h2>
                                        <h2>5 –DOMMAGES OCCASIONNES AU(X) VEHICULE(S)</h2>
                                        <h2>6 – SECURITE</h2>
                                        <h2>7 – TARIF – FACTURATION – REGLEMENT</h2>
                                        <h2>8 – ANNULATION OU RETARD DU FAIT DU CLIENT</h2>
                                        <h2>9 – ANNULATION OU RETARD DU FAIT DE SPARK LANES - INDISPONIBILITE DU VEHICULE</h2>
                                        <h2>10 - RESPONSABILITES – LITIGES - JURIDICTION</h2>

                                        <p>Toute transaction effectuée avec la société SPARK LANES entraîne l'acceptation pure et simple, sans contestation possible, des présentes Conditions Générales de Vente.</p>

                                        <h2>1  – ACTIVITE – REGLEMENTATION - ASSURANCE</h2>

                                        <p>L’activité de transport de personnes à titre onéreux est régie par différents textes officiels. La société SPARK LANES s’attache à respecter les conditions imposées par la législation pour l’exercice de cette profession.</p>
                                        <p>SPARK LANES est titulaire d’une  licence d’exploitation de véhicules de tourisme avec chauffeur délivrée par la Préfecture de l’Oise et a régularisé son inscription dans les registres VTC du ministère de la transition écologique et de la cohésion des territoires mer. Les chauffeurs sont titulaires du certificat d’aptitude à la conduite et du certificat de premier secours civique.</p>
                                        <p>Nos véhicules, adaptés aux normes actuelles, sont conçus pour la détente ou le travail des passagers.</p>
                                        <p>Les risques liés à l’activité sont couverts par la compagnie MAT – Mutuelle d’Assurance de l’Artisanat et des Transports. Les garanties incluent la Responsabilité Civile et l’assurance au bénéfice des personnes transportées.</p>

                                        <h2>2 – SECTEUR GEOGRAPHIQUE - DISPONIBILITE</h2>

                                        <p>SPARK LANES organise et assure le transfert de personnes dans le monde entier selon les besoins et exigences de ses clients. </p>
                                        <p>Sauf cas exceptionnels ou cas de force majeure, SPARK LANES assure ses prestations tous les jours de la semaine, tout au long de l’année.</p>

                                        <h2>3 – MATERIEL MIS A DISPOSITION - CHAUFFEUR</h2>

                                        <p>Le véhicule haut de gamme mis à disposition pour effectuer la prestation est en excellent état (mécanique, carrosserie et habitacle). Il est entretenu pour assurer le confort, le bien être et la sécurité des passagers. </p>
                                        <p>Le chauffeur, fera preuve de courtoisie, de discrétion et d’éthique. Il mettra tout en œuvre pour satisfaire son client et/ou le(s) passager(s), dans la limite des engagements légaux et/ou autorisés, en rapport avec la prestation.</p>
                                        
                                        <h2>4 – DETAIL DES PRESTATIONS</h2>

                                        <p>Le forfait inclut la fourniture d’un véhicule haut de gamme conforme aux normes de la règlementation du code de la route et de l’activité, un chauffeur titulaire des autorisations et certificats obligatoires, toutes les assurances, y compris celle souscrite au bénéfice des personnes transportées et la Responsabilité Civile, le carburant, le kilométrage, les péages, les frais de parking, le temps calculé du départ garage au retour garage, sauf point de départ et point de retour spécifiques définis avant la prise en charge et mentionnés sur le bon de transport, toutes les options convenues avec le client et également mentionnées sur le bon de transport.</p>
                                        <p>Toutes les prestations non conventionnelles et non préétablies, tous frais supplémentaires non prévus, sont facturés en supplément après notification au client (Circonstances exceptionnelles, imprévus, impératifs, urgences, sécurité ou autres situations nécessitant un changement de la prestation d’origine pour un motif particulier et exceptionnel). Ces prestations sont facturées selon les tarifs en vigueur et/ou selon les frais réels engagés.</p>
                                        
                                        <h2>5 – DOMMAGES OCCASIONNES AU(X) VEHICULE(S)</h2>
                                        <p>En cas de dégradation de l'équipement intérieur ou extérieur du véhicule, du fait ou par le fait du client et/ou des passagers et pour quelque cause ou motif que ce soit, les réparations destinées à remettre le véhicule en état seront à la charge du client (signataire du bon de transport, commanditaire ou passager en l’absence de commanditaire). Cette mesure s’applique également pour les dégradations et dommages subis par des véhicules tiers, du fait ou par le fait du ou des passagers, avant, pendant et après la prestation.</p>
                        
                                        <h2>6 – SECURITE</h2>
                                        <p>En aucun cas, la réalisation de la prestation ne saurait donner lieu à une dérogation à la législation du code de la route.</p>
                                        <p>Le véhicule ne pourra être engagé sur la chaussée que dès lors que tous les passagers seront équipés de leur ceinture de sécurité.</p>
                                        <p>Le véhicule est pourvu d’un extincteur révisé selon les préconisations du fabricant, d’une trousse de premiers soins et de tous les équipements requis par la législation en matière de sécurité routière.</p>
                                        <p>Pour assurer la sécurité des passagers et du chauffeur, les portes pourront être verrouillées sur la seule initiative du chauffeur. </p>
                                        <p>A la prise en charge et au moment de la dépose, seul le chauffeur est autorisé à manœuvrer l’ouverture et la fermeture des portières. </p>
                                        <p>Il est strictement interdit de fumer à l'intérieur du véhicule.</p>
                                        <p>Les enfants mineurs voyageant à bord sont gardés sous l’entière responsabilité de leurs parents.</p>
                                        <p>Les bagages et effets personnels sont sous l’entière responsabilité de leur propriétaire, à l’intérieur du véhicule où à l’extérieur du véhicule avant, pendant ou à la fin de la prestation. Le poids moyen accepté par bagage et par personne est de 20 Kg. En aucun cas, le poids total du chargement ne sera supérieur aux capacités préconisées par le constructeur du véhicule.</p>
                                        <p>Les animaux ne sont pas acceptés dans le véhicule. Une dérogation pourra éventuellement être accordée mais, en tout état de cause,  les animaux devront voyager dans une cage et, en ce qui concerne les chiens, être muselés.</p>
                                        
                                        <h2>7 – TARIF – FACTURATION – REGLEMENT</h2>
                                        <p>Nos prix sont exprimés en Euros, selon barème en vigueur et stipulés Toutes Taxes Comprises (TTC). Le détail de la TVA est mentionné, le cas échéant, sur nos factures. Le taux de TVA varie selon la prestation, conformément à la règlementation fiscale française.</p>
                                        <p>Pour des motifs portant sur l’évolution brusque et/ou imprévisible des coûts ou des frais de gestion, nos prix pourront faire l’objet de modifications, sans préavis. Toute augmentation importante sera justifiée et communiquée au client si une réservation a été effectuée préalablement à la modification de prix.  Le contrat de réservation pourra, dans cas, être éventuellement révisé, au mieux des intérêts des deux parties.</p>
                                        <p>Toute unité de tarification commencée (heure et/ou kilomètre) est due. Toute unité de tarification supplémentaire est due.</p>
                                        <p>Le bon de transport devra être visé par le client, commanditaire ou passager.</p>
                                        <p>Quelle que soit la nature de la prestation, le commanditaire ou le client-passager, signataire du bon de commande et/ou du bon de transport est seul responsable du paiement de la prestation en cas de défaillance du - ou des – co-passager(s).</p>
                                        <p>Le règlement des factures peut être effectué en espèces, par carte bancaire, chèque ou virement bancaire selon l’accord établit entre SPARK LANES et le client au moment de la signature du devis et confirmation de commande</p>
                                        <p>Les modalités de règlements : Acompte et solde de factures seront décidés et mis en place au cas par cas selon les accords commerciaux établis avec chacun des clients.</p>
                                        
                                        <h2>8 – ANNULATION OU RETARD DU FAIT DU CLIENT</h2>

                                        <p>Le terme «   client »  est employé pour désigner le commanditaire ou, à défaut, le ou les passagers, en l’absence de commanditaire.</p>
                                        <p>L’absence du client à la prise en charge, est considérée comme une annulation de la prestation n’ayant pas été notifiée dans les délais requis. L’absence sera considérée comme effective au-delà de 20 minutes d’attente sans que le client ne se soit manifesté à l’égard de SPARK LANES ou du chauffeur.</p>
                                        <p>En cas de retard, du fait du client, au moment de la prise en charge ou pendant la prestation, SPARK LANES se réserve le droit d’annuler tout ou partie de la prestation commandée.</p>
                                        <p>Délais d’annulation calculés à partir du jour et de l’heure prévus pour le début de la première prestation. Une journée = 24 heures.</p>
                                        <p>Des frais d’annulation spécifiques pourront être appliqués en fonction de l’heure et de la date de l’annulation, selon le barême suivant :</p>
                                        <ul>
                                            <li>Annulation à plus de 72H : Pas de retenue sauf cas particuliers et dossiers conséquents ayant nécessités des services payables d’avance, 25% forfaitaire sur cas particuliers.</li>
                                            <li>Annulation entre 72H et 48H : 50% de retenue facturable</li>
                                            <li>Annulation entre 48H et 24H : 75% de retenue facturable</li>
                                            <li>Annulation à moins de 24H : 100% de retenue facturable</li>
                                        </ul>

                                        <p>Le retard, du fait du client, au moment de la prise en charge, entraîne l’application d’une indemnité forfaitaire calculée sur la base du taux horaire de mise à disposition négocié au préalable par tranche de 15 minutes de retard et ce à partir de 20 minutes de retard client.</p>
                                        <p>Les annulations doivent être effectuées :
                                        Par téléphone auprès de votre agent reponsable de votre dossier ou en cas d’urgence au 06.46.30.24.05
                                        ou
                                        Par mail à contact@sparklanes.com</p>
                                        <p>NB Le système des indemnités forfaitaires pourra ne pas être appliqué en cas de motif portant sur la sécurité ou sur la santé du - ou des – passager(s) prévu(s). Dans ces cas et après étude de la situation, une partie ou l’intégralité des sommes versées par le client pourront être  restituées,  SPARK LANES pouvant exiger pour leur restitution un justificatif ou une d’attestation. Aucune autre indemnité, aucun dommage ou intérêt ne seront versés par SPARK LANES.</p>
                                        
                                        <h2>9 – ANNULATION OU RETARD DU FAIT DE SPARK LANES - INDISPONIBILITE DU VEHICULE</h2>
                                        <p>Si SPARK LANES ne peut assurer la prestation commandée, pour une raison indépendante de sa bonne volonté, les acomptes versés seront intégralement remboursés.</p>
                                        <p>SPARK LANES se réserve le droit, en cas d’immobilisation du véhicule habituel, d’assurer sa prestation avec un véhicule de remplacement de gamme similaire, avec notification préalable au client (commanditaire et/ou passager(s)).</p>
                                        <p>Si le véhicule n’est pas mis à la disposition à l’heure convenue mais si la prestation est néanmoins effectuée, sans préjudice majeur pour le client, le règlement de la prestation sera exigible en totalité.</p>
                                        <p>Tout retard entrainé dans la réalisation d’une prestation, du fait des conditions de circulation, du fait d’une force majeure ou d’un incident imprévisible et particulier, ne peut, en aucun cas, être opposé à SPARK LANES en tant que cause d’un préjudice quelconque subi par le client, commanditaire ou passager(s).</p>
                                        <p>En cas de panne, d’accident ou d’incident, de nature à immobiliser le véhicule, survenant au cours de la prestation,  SPARK LANES prendra toutes initiatives pour mener à bien sa prestation, dans la mesure du réalisable et en fonction des possibilités propices à la situation. Si la prestation est néanmoins réalisée en totalité par recours ou non à un véhicule de substitution, sans préjudicie majeur pour le client, le règlement de la prestation sera exigible en totalité.</p>
                                        <p>Quel que soit l’incident survenu, et dès lors que la prestation a été réalisée sans préjudice majeur pour le client, la facturation ne saurait donner lieu à une remise ou une réduction.</p>
                                        <p>SPARK LANES se réserve le droit d’annuler toute prestation, sans aucun délai ni préavis en cas de fraude, d’omission ou de dissimulation d’informations, ou pour tout motif susceptible de rendre difficile, voir impossible, la réalisation de la prestation et/ou pouvant entrainer un danger ou un risque quelconque.</p>
                                        
                                        <h2>10 - RESPONSABILITE – LITIGE – JURIDICTION</h2>

                                        <p>Toute réclamation sera effectuée à l’adresse du siège social dans les huit jours suivant la fin de la prestation, par courrier recommandé. Le délai de huit jours expiré, aucune réclamation ne sera prise en considération.</p>
                                        <p>En aucun cas la responsabilité de SPARK LANES ne pourra être engagée en cas de perte d’effets personnels ou de bagages dans le véhicule.</p>
                                        <p>Toutes situations confondues, quelle que soit la nature et l’origine des préjudices, directs ou indirects, subis par le client et/ou le(s) passager(s) à la suite d’évènements imprévisibles, de retard survenu au cours de la prestation, d’annulation de la prestation, d’incidents exceptionnels ainsi qu’en cas de force majeure, aucun recours ne sera opposable à SPARK LANES et aucune indemnité, dommage ou intérêt ne pourra être réclamé.</p>
                                        <p>Le présent contrat est régi par la loi française. En cas de contestation ou de litige, quelle qu’en soit la nature ou l’origine, seuls les Tribunaux dont dépend le siège social de SPARK LANES sont compétents.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
}