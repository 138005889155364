import React, { useEffect, useState } from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import ListProviders from "./list-providers/ListProviders";
import { ExportType } from "../../components/content-section/export-action/ExportAction";
import KpiProviders from "./kpi-providers/KpiProviders";
import { ErrorFormData } from "../../models/interfaces/errorForm";
import ProviderService from "../../services/provider-service";
import { ErrorForm } from "../../components/ErrorForm";

interface IPropsProviderPage {}

const ProviderPage: React.FunctionComponent<IPropsProviderPage> = (props) => {
  const { t } = useTranslation();
  const [dataKpis, setDataKpis] = useState<any>({
    nbTenders: "-",
    nbDrivers: "-",
  });
  const [errorApi, setErrorApi] = useState(new ErrorFormData());

  const fetchKpis = async () => {
    try {
      const providerService = new ProviderService();
      setDataKpis(await providerService.getKpis());
    } catch (e) {
      setErrorApi(new ErrorFormData(e.response.data));
    }
  };

  useEffect(() => {
    fetchKpis();
  }, []);

  //Return the appropriate content
  return (
    <>
      <ContentSection
        title={t("nav.providers")}
        exportType={ExportType.Provider}
        addAction={"provider"}
      >
        <KpiProviders
          nbProviders={dataKpis.nbProviders}
          nbTenders={dataKpis.nbTenders}
        />
        <ListProviders fetchKpis={fetchKpis} />
      </ContentSection>
      <ErrorForm errorApi={errorApi} />
    </>
  );
};

export default ProviderPage;
