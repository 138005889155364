import axios, { AxiosResponse } from "axios";
import { Client } from "../models/client";
import { getBaseUrl, getParamsUrl } from "./setting-service";
import { DataPaginate } from "../models/utils/data-paginate";
import { Address } from "../models/address";
import { User } from "../models/user";

export default class ClientService {
    all(page?: number | null, search?: string | null, limit?: string | null) {
        let paramsUrl = getParamsUrl([
            { key: "page", value: page },
            { key: "search", value: search },
            { key: "limit", value: limit },
        ]);
    
        return axios
            .get(`${getBaseUrl()}clients${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map((client: Object) => new Client(client));
                return new DataPaginate(res);
            });
    }


    getClientsForForm(user: User) {
        return this.all(null,null,'all').then((res: any) => {
            let list: Client[] = [];
            res?.data.map((client: Client) => {
                if (!user.isClient() || !user.isReader(client)) {
                    list.push(client);
                }
                if (user.isAdmin()) {
                    client.subAccounts?.map((account: Client) => {
                        list.push(account);
                    });
                }
            });
            return list;
        });
    }

    get(id: number) {
        return axios
            .get(`${getBaseUrl()}clients/${id}`)
            .then((response: AxiosResponse) => new Client(response.data))
            .catch();
    }

    add(body: any) {
        return axios
            .post(`${getBaseUrl()}clients`, body)
            .then((response: AxiosResponse) => new Client(response.data))
            .catch();
    }

    update(id: number, body: any) {
        return axios
            .put(`${getBaseUrl()}clients/${id}`, body)
            .then((response: AxiosResponse) => new Client(response.data))
            .catch();
    }

    delete(id: number) {
        return axios
            .delete(`${getBaseUrl()}clients/${id}`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    getKpis() {
        return axios
            .get(`${getBaseUrl()}clients/kpis`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    addresses(id: number, page?: number | null) {
        let paramsUrl = getParamsUrl([{ key: "page", value: page }]);

        return axios
            .get(`${getBaseUrl()}clients/${id}/addresses${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map(
                    (address: Object) => new Address(address)
                );

                return new DataPaginate(res);
            });
    }

    addAddress(id: number, body: any, defaultAddress = false) {
        const params = defaultAddress
            ? `?default_address=${defaultAddress}`
            : "";
        return axios
            .post(`${getBaseUrl()}clients/${id}/addresses${params}`, body)
            .then((response: AxiosResponse) => {
                new Address(response.data);
            });
    }

    updateDefaultAddress(id: number, addressId:number){
        return axios.patch(`${getBaseUrl()}clients/${id}/default-address/${addressId}`, null)
            .then(response => response);
    }
}
