import React, { useState } from "react";
import "./ReturnAction.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import WhiteButton from "../../white-button/WhiteButton";
import ModalWantCancelled from "./modal-want-cancelled/ModalWantCancelled";

interface IPropsReturnAction {
    back?: string;
    alertCancelled: boolean;
}

const ReturnAction: React.FunctionComponent<IPropsReturnAction> = ({
    back,
    alertCancelled = false,
}: IPropsReturnAction) => {
    const { t } = useTranslation();
    let history = useHistory();
    const [wantCancelled, setWantCancelled] = useState(false);
    return (
        <>
            {!alertCancelled ? (
                <WhiteButton
                    onClick={() => history.goBack()}
                    className="btn-rounded"
                    variant="contained"
                    color="primary"
                >
                    {t("back").toUpperCase()}
                </WhiteButton>
            ) : (
                <>
                    <WhiteButton
                        onClick={() => setWantCancelled(true)}
                        className="btn-rounded"
                        variant="contained"
                        color="primary"
                    >
                        {t("back").toUpperCase()}
                    </WhiteButton>
                    {
                        <ModalWantCancelled
                            isOpen={wantCancelled}
                            closeModal={() => setWantCancelled(false)}
                            goBack={() => history.goBack()}
                        />
                    }
                </>
            )}
        </>
    );
};

export default ReturnAction;
