import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ExpandLess, ExpandMore, Notifications, AccountCircle, ArrowDropDown } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import { TextField, FormControl, InputLabel, Select, Checkbox } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import FormGroup from '@material-ui/core/FormGroup';

import './style.scss';
import Modal from '../../components/modal/Modal';

const Colors = {
    blue: '#107DE8', 
    blueLight: 'rgba(17, 126, 234, 0.12)', 
    cyan: '#00C6FB',
    red: '#F36666',
    orange: '#FE8456',
    green: '#1ABCB0',
    mint: '#A1E89B',
    yellow: '#FED128',
};

const useStyles = makeStyles((theme) => ({      
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },    
    blueGradient: {
        backgroundImage: 'linear-gradient(to bottom, #1482EF 0%, #0065C9 100%)',        
    },
    orangeGradient: {
        backgroundImage: 'linear-gradient(to bottom, #FF8F63 0%, #FF6F37 100%)',        
    },
    yellowGradient: {
        backgroundImage: 'linear-gradient(to bottom, #FFDD26 0%, #FFBC31 100%)',        
    },
    cyanGradient: {
        backgroundImage: 'linear-gradient(to bottom, #31D0FF 0%, #07A9D9 100%)',        
    },
    blueBg: {
        backgroundColor: Colors.blue,       
    },
    blueBgLight: {
        backgroundColor: Colors.blueLight,       
    },
    blueTextColor: {
        color: '#1757FB',
    },
    cyanBg: {
        backgroundColor: Colors.cyan,        
    },
    redBg: {
        backgroundColor: Colors.red,        
    },
    orangeBg: {
        backgroundColor: Colors.orange,        
    },
    yellowBg: {
        backgroundColor: Colors.yellow,   
    },
    greenBg: {
        backgroundColor: Colors.green,        
    },
    greenLightBg: {
        backgroundColor: '#A1E89B'
    },
    mintBg: {
        backgroundColor: Colors.mint,        
    },
}));

const EuroSvg = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.33" height="25.335" viewBox="0 0 25.33 25.335"><path d="M12.333,8.819l-.693,2.775c.784.2,3.2.993,3.6-.578C15.644,9.38,13.116,9.015,12.333,8.819Zm-1.041,4.176-.764,3.06c.941.234,3.848,1.167,4.277-.559C15.255,13.694,12.235,13.229,11.292,12.995Z" transform="translate(0.268 -0.189)" fill="#a6a9b7"/><path d="M17.735,2.379a12.665,12.665,0,1,0,9.222,15.352A12.662,12.662,0,0,0,17.735,2.379Z" transform="translate(-2.007 -2)" fill={props.fill}/><path d="M11.232,6.839a3.083,3.083,0,0,0-.7-2.824,2.957,2.957,0,0,0-2.491-.747c-.044,0-.089.012-.133.018l1.023,1.04A1.312,1.312,0,1,1,7.066,6.166L5.375,4.447a6.291,6.291,0,0,0-.473.426,6.188,6.188,0,0,0-.423.469L6.166,7A1.312,1.312,0,1,1,4.327,8.871l-1.01-.993c-.007.046-.014.093-.019.139a2.958,2.958,0,0,0,.747,2.491,3.069,3.069,0,0,0,2.772.705,1.312,1.312,0,1,1,.469,2.581,6.749,6.749,0,0,1-1.2.11A5.421,5.421,0,0,1,2.19,12.363a5.522,5.522,0,0,1-1.5-4.644,7.335,7.335,0,0,1,.5-1.931L.392,5A1.312,1.312,0,1,1,2.231,3.131L2.6,3.5q.212-.246.444-.479t.482-.447L3.2,2.231A1.312,1.312,0,0,1,5.067.392l.755.767a7.339,7.339,0,0,1,1.926-.5,5.523,5.523,0,0,1,4.644,1.5,5.654,5.654,0,0,1,1.416,5.179,1.312,1.312,0,1,1-2.575-.5Z" transform="matrix(0.891, -0.454, 0.454, 0.891, 2.814, 9.14)" fill="#fff"/></svg>
    );
};
const UserSvg = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.261" height="24.734" viewBox="0 0 22.261 24.734"><path d="M22.788,2H5.473A2.474,2.474,0,0,0,3,4.473V20.551a2.474,2.474,0,0,0,2.473,2.473H10.42l3.71,3.71,3.71-3.71h4.947a2.474,2.474,0,0,0,2.473-2.473V4.473A2.474,2.474,0,0,0,22.788,2ZM14.131,5.71a3.6,3.6,0,0,1,3.71,3.71,3.6,3.6,0,0,1-3.71,3.71,3.6,3.6,0,0,1-3.71-3.71A3.6,3.6,0,0,1,14.131,5.71Zm-5.965,13.6c.69-2.131,3.087-3.71,5.965-3.71s5.276,1.579,5.965,3.71Z" transform="translate(-3 -2)" fill={props.fill}/></svg>
    );
};
const CarSvg = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="37.642" height="27.42" viewBox="0 0 37.642 27.42"><g transform="translate(-0.021 -1)"><g transform="translate(0.021 1)"><path d="M36.826,66.759H34.066a.816.816,0,0,0-.564.227l-.589.565a29.373,29.373,0,0,0-4.343-6.925,5.435,5.435,0,0,0-4.222-2H13.294a5.433,5.433,0,0,0-4.222,2,28.7,28.7,0,0,0-4.352,6.916l-.581-.556a.816.816,0,0,0-.564-.227H.815A.815.815,0,0,0,0,67.574v1.887a.815.815,0,0,0,.764.814l2.837.179a18.094,18.094,0,0,0-.763,5.752c0,2.284.456,3.786,1.25,4.758v4.4a.683.683,0,0,0,.683.683H7.912a.683.683,0,0,0,.683-.683V82.631H29.046v2.731a.683.683,0,0,0,.683.683H32.87a.683.683,0,0,0,.683-.683v-4.4c.794-.972,1.25-2.473,1.25-4.758a18.093,18.093,0,0,0-.763-5.752l2.837-.179a.815.815,0,0,0,.764-.814V67.574A.815.815,0,0,0,36.826,66.759ZM11.4,62.532a2.435,2.435,0,0,1,1.891-.9H24.347a2.435,2.435,0,0,1,1.891.9,28.412,28.412,0,0,1,3.232,4.791l-21.318,0A28.593,28.593,0,0,1,11.4,62.532ZM9.08,79.292c-1.563,0-2.831-.511-2.831-1.749S6.709,75.3,8.272,75.3s3.638,1,3.638,2.243S10.643,79.292,9.08,79.292Zm12.708-.258H15.854a1.624,1.624,0,0,1-1.622-1.622.454.454,0,0,1,.455-.454h8.27a.454.454,0,0,1,.454.454A1.624,1.624,0,0,1,21.788,79.033Zm6.774.258c-1.563,0-2.831-.511-2.831-1.749S27.806,75.3,29.369,75.3s2.023,1,2.023,2.243S30.125,79.292,28.562,79.292Z" transform="translate(0 -58.625)" fill={props.fill}/></g></g></svg>
    );
};
const CustomerSvg = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35.281" height="35.281" viewBox="0 0 35.281 35.281"><g transform="translate(0.001)"><path d="M190.825,9.647a4.824,4.824,0,1,1,4.824-4.824A4.829,4.829,0,0,1,190.825,9.647Z" transform="translate(-173.185)" fill={props.fill}/><path d="M153.207,148.282a6.876,6.876,0,0,1-8.565,0A7.925,7.925,0,0,0,141,154.947v2.067a1.034,1.034,0,0,0,1.034,1.034h13.782a1.034,1.034,0,0,0,1.034-1.034v-2.067A7.925,7.925,0,0,0,153.207,148.282Z" transform="translate(-131.285 -138.064)" fill={props.fill}/><path d="M344.352,284.79a1.034,1.034,0,0,0-.834-.7l-3.033-.441-1.356-2.749a1.034,1.034,0,0,0-1.854,0l-1.356,2.749-3.033.441a1.034,1.034,0,0,0-.573,1.763l2.195,2.139-.518,3.021a1.034,1.034,0,0,0,1.5,1.09l2.713-1.426,2.713,1.426a1.034,1.034,0,0,0,1.5-1.09l-.518-3.021,2.195-2.139A1.033,1.033,0,0,0,344.352,284.79Z" transform="translate(-309.122 -261.005)" fill={props.fill}/><path d="M9.9,287.99l2.195-2.139a1.034,1.034,0,0,0-.573-1.763l-3.033-.441L7.128,280.9a1.034,1.034,0,0,0-1.854,0l-1.357,2.749-3.033.441a1.034,1.034,0,0,0-.573,1.763l2.195,2.139-.518,3.021a1.034,1.034,0,0,0,1.5,1.09L6.2,290.674,8.914,292.1a1.034,1.034,0,0,0,1.5-1.09Z" transform="translate(0 -261.005)" fill={props.fill}/><path d="M177.518,343.108l-3.033-.441-1.357-2.749a1.034,1.034,0,0,0-1.854,0l-1.357,2.749-3.033.441a1.034,1.034,0,0,0-.573,1.763l2.195,2.14-.518,3.021a1.034,1.034,0,0,0,1.5,1.09l2.713-1.426,2.713,1.426a1.034,1.034,0,0,0,1.5-1.09l-.518-3.021,2.195-2.14a1.034,1.034,0,0,0-.573-1.763Z" transform="translate(-154.561 -315.959)" fill={props.fill}/></g></svg>    
    );
};
const ClientSvg = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27.34" height="27.34" viewBox="0 0 27.34 27.34"><g transform="translate(0 -0.004)"><path d="M312.152,54.977a.854.854,0,0,0-1.18.258,2.434,2.434,0,0,1-1.844,1.315,2.382,2.382,0,0,1-1.994-1.282h1.14a.854.854,0,0,0,0-1.709h-1.688a4.419,4.419,0,0,1,0-.854h1.688a.854.854,0,0,0,0-1.709h-1.14a2.382,2.382,0,0,1,1.994-1.282,2.434,2.434,0,0,1,1.844,1.315.854.854,0,1,0,1.438-.922c-2.208-3.445-5.956-2.3-7.17.888h-.385a.854.854,0,1,0,.016,1.709,5.9,5.9,0,0,0,0,.854.854.854,0,1,0-.016,1.709h.385c1.214,3.193,4.961,4.334,7.17.888A.854.854,0,0,0,312.152,54.977Z" transform="translate(-287.769 -45.44)" fill={props.fill}/><path d="M9.825,19.654h-.4a.872.872,0,0,1-.838-.6A.854.854,0,0,1,9.091,18L15.5,15.538a.855.855,0,0,0,.481-1.129A52.434,52.434,0,0,1,12.813.789.855.855,0,0,0,11.25.384L10.015,2.236,8.163,1a.855.855,0,0,0-1.3.5L6.3,3.746,4.746,2.711a.855.855,0,0,0-1.3.5L2.882,5.455,1.328,4.419A.855.855,0,0,0,0,5.2,58.307,58.307,0,0,0,2.044,16.054a24.79,24.79,0,0,0,3.507,7.607,4.876,4.876,0,0,0,3.93,1.974h.3a2.99,2.99,0,1,0,.045-5.981ZM4.333,10.573a.855.855,0,0,1,.476-1.111L9.081,7.754a.855.855,0,1,1,.634,1.587c-4.626,1.85-4.353,1.77-4.589,1.77A.855.855,0,0,1,4.333,10.573ZM6.3,14.467a.855.855,0,0,1-.634-1.587l4.272-1.709a.855.855,0,0,1,.634,1.587Zm16.77.061c-.223,0,.218-.145-5.959,2.231-.008-.012-4.132,1.58-4.125,1.577a.427.427,0,0,0-.121.726,4.7,4.7,0,0,1-1.016,7.824.241.241,0,0,0,.1.458,4.278,4.278,0,0,0,1.986-.483S25.99,21.925,26.16,21.748a4.28,4.28,0,0,0-3.092-7.219Z" transform="translate(0 0)" fill={props.fill}/></g></svg>    
    );
};
const ChatSvg = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28.631" height="28.632" viewBox="0 0 28.631 28.632"><g transform="translate(-0.01)"><g transform="translate(0.01)"><g transform="translate(0)"><path d="M17.68,0H4.2A4.247,4.247,0,0,0,.01,4.25V9.283a4.2,4.2,0,0,0,3.355,4.11V17.67a.84.84,0,0,0,1.432.593l3.9-3.842a5.863,5.863,0,0,1,5.631-4.3h7.549V4.25A4.247,4.247,0,0,0,17.68,0ZM12.648,8.444H5.882a.839.839,0,1,1,0-1.678h6.766a.839.839,0,0,1,0,1.678ZM16,5.089H5.882a.839.839,0,1,1,0-1.678H16a.839.839,0,0,1,0,1.678Z" transform="translate(-0.01)" fill={props.fill}/></g></g><g transform="translate(10.131 11.799)"><path d="M195.318,210.992H185.2A4.2,4.2,0,0,0,181,215.186v3.355a4.2,4.2,0,0,0,4.194,4.194h4.685l4.787,4.843a.878.878,0,0,0,1.488-.593v-4.334a4.2,4.2,0,0,0,3.355-4.11v-3.355A4.2,4.2,0,0,0,195.318,210.992Zm-7.605,2.516a.839.839,0,1,1-.839.839A.839.839,0,0,1,187.713,213.508Zm5.482,5.482a4.192,4.192,0,0,1-5.93,0,.839.839,0,0,1,1.186-1.186,2.577,2.577,0,0,0,3.558,0,.839.839,0,1,1,1.186,1.186Zm-.449-3.8a.839.839,0,1,1,.839-.839A.839.839,0,0,1,192.746,215.186Z" transform="translate(-181.003 -210.992)" fill={props.fill}/></g></g></svg>
    );
};
const WhiteButton = withStyles((theme) => ({
    root: {
        color: '#707070',
        backgroundColor: '#fff',

        '&:hover': {
            backgroundColor: '#707070',
            color: '#fff',
        },
    },
}))(Button);

export default function ClippedDrawer() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = () => {
        setOpen(!open);
    };
    /*const handleMenuClick = (event: { currentTarget: React.SetStateAction<null>; }) => {
        setAnchorEl(event.currentTarget);
    };*/
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [age, setAge] = React.useState('');

    const handleChange = (event: any) => {
        setAge(event.target.value);
    }; 

    return (
        <div className="d-flex">
            <AppBar id="header" className={classes.appBar}>
                <Toolbar>
                    <img className="header-logo" src="/images/sparklanes-administration.png" alt="sparklanes administration" />                        
                    <div className="appbar-user-actions d-flex ml-auto">
                        <IconButton className="aua-notifications" aria-label="show 17 new notifications" color="inherit">
                            <Badge badgeContent={17} color="secondary">
                                <Notifications />
                            </Badge>
                        </IconButton>
                        {/* <Button aria-controls="simple-menu-icons" aria-haspopup="true" onClick={handleMenuClick}> */}
                        <Button className="appbar-user-dropdown" aria-controls="simple-menu" aria-haspopup="true">
                            <div className="aud-placeholder"> 
                                <AccountCircle />
                            </div>                              
                            Marc Sparklane
                            <ArrowDropDown className="aud-caret" />
                        </Button>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose}>Profile</MenuItem>
                            <MenuItem onClick={handleClose}>My account</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer className="app-drawer" variant="permanent">
                <Toolbar />
                <div className="list-baseline">Bonjour, Spark lanes</div>
                <div className="app-aside-menu d-flex align-items-start flex-column">
                    <List className="asm-list asml-top">
                        <ListItem button onClick={handleClick}>
                            <ListItemIcon>
                                <img src="/images/menu/prestataires.svg" alt="prestataires" />
                            </ListItemIcon>
                            <ListItemText primary="Prestataires" />
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button>
                                    <ListItemIcon>
                                        <img src="/images/menu/car.svg" alt="Véhicules" />
                                    </ListItemIcon>
                                    <ListItemText primary="Véhicules" />
                                </ListItem>
                                <ListItem button>
                                    <ListItemIcon>
                                        <img src="/images/menu/bxs-user-pin.svg" alt="les chauffeurs" />
                                    </ListItemIcon>
                                    <ListItemText primary="Les chauffeurs" />
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button onClick={handleClick}>
                            <ListItemIcon>
                                <img src="/images/menu/users.svg" alt="Clients" />
                            </ListItemIcon>
                            <ListItemText primary="Clients" />
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button>
                                    <ListItemIcon>
                                        <img src="/images/menu/customer.svg" alt="Bénéficiaires" />
                                    </ListItemIcon>
                                    <ListItemText primary="Bénéficiaires" />
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button onClick={handleClick}>
                            <ListItemIcon>
                                <img src="/images/menu/bxl-bitcoin.svg" alt="Dossiers" />
                            </ListItemIcon>
                            <ListItemText primary="Dossiers" />
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button>
                                    <ListItemIcon>
                                        <img src="/images/menu/placeholder.svg" alt="Transferts" />
                                    </ListItemIcon>
                                    <ListItemText primary="Transferts" />
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button>
                            <ListItemIcon>
                                <img src="/images/menu/devis.svg" alt="Devis & facturation" />
                            </ListItemIcon>
                            <ListItemText primary="Devis & facturation" />
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon>
                                <img src="/images/menu/help.svg" alt="Support" />
                            </ListItemIcon>
                            <ListItemText primary="Support" />
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon>
                                <img src="/images/menu/person.svg" alt="Commanditaires" />
                            </ListItemIcon>
                            <ListItemText primary="Commanditaires" />
                        </ListItem>
                    </List>
                    <List className="asm-list asml-bottom mt-auto">
                        <ListItem button>
                            <ListItemIcon>
                                <img src="/images/menu/settings.svg" alt="Paramètres" />
                            </ListItemIcon>
                            <ListItemText primary="Paramètres" />
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon>
                                <img src="/images/menu/logout.svg" alt="Déconnexion" />
                            </ListItemIcon>
                            <ListItemText primary="Déconnexion" />
                        </ListItem>
                    </List>
                </div>
            </Drawer>
            <main className="main-content flex-grow-1">
                <div className="main-box">
                    <div className="main-box-title"> 
                        Media icon boxes
                    </div>  
                    <div className="main-box-content">
                        <div className="row">
                            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                                <div className={'media-icon-box media ' + classes.blueBgLight}>
                                    <div className={'mib-media-left ' + classes.blueGradient}>
                                        <div className="mib-round">
                                            <EuroSvg fill={Colors.blue} />
                                        </div>
                                    </div>
                                    <div className="mib-body media-body">
                                        <div className="mibb-content row">
                                            <span className="col">Dossiers</span>
                                            <strong className={'col-auto ml-auto ' + classes.blueTextColor}>500</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                                <div className="media-icon-box media">
                                    <div className={'mib-media-left ' + classes.orangeGradient}>
                                        <div className="mib-round">
                                            <UserSvg fill={Colors.orange} />
                                        </div>
                                    </div>
                                    <div className="mib-body media-body">
                                        <div className="mibb-content row">
                                            <span className="col">Chauffeurs</span>
                                            <strong className="col-auto ml-auto">856</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                                <div className="media-icon-box media">
                                    <div className={'mib-media-left ' + classes.yellowGradient}>
                                        <div className="mib-round">
                                            <CarSvg fill={Colors.yellow} />
                                        </div>
                                    </div>
                                    <div className="mib-body media-body">
                                        <div className="mibb-content row">
                                            <span className="col">Véhicules</span>
                                            <strong className="col-auto ml-auto">500</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                                <div className="media-icon-box media">
                                    <div className={'mib-media-left ' + classes.cyanGradient}>
                                        <div className="mib-round">
                                            <CustomerSvg fill={Colors.cyan} />
                                        </div>
                                    </div>
                                    <div className="mib-body media-body">
                                        <div className="mibb-content row">
                                            <span className="col">Clients</span>
                                            <strong className="col-auto ml-auto">850</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                                <div className={'media-icon-box mic-sm media ' + classes.blueBgLight}>
                                    <div className={'mib-media-left ' + classes.blueGradient}>
                                        <div className="mib-round">
                                            <CustomerSvg fill={Colors.blue} />
                                        </div>
                                    </div>
                                    <div className="mib-body media-body">
                                        <div className="mibb-content row">
                                            <span className="col">Clients Actifs</span>
                                            <strong className={'col-auto ml-auto ' + classes.blueTextColor}>540</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                                <div className="media-icon-box mic-sm media">
                                    <div className={'mib-media-left ' + classes.orangeGradient}>
                                        <div className="mib-round">
                                            <ClientSvg fill={Colors.orange} />
                                        </div>
                                    </div>
                                    <div className="mib-body media-body">
                                        <div className="mibb-content row">
                                            <span className="col">Encours client</span>
                                            <strong className="col-auto ml-auto">5k €</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                                <div className="media-icon-box mic-sm media">
                                    <div className={'mib-media-left ' + classes.yellowGradient}>
                                        <div className="mib-round">
                                            <ChatSvg fill={Colors.yellow} />
                                        </div>
                                    </div>
                                    <div className="mib-body media-body">
                                        <div className="mibb-content row">
                                            <span className="col">Compte</span>
                                            <strong className="col-auto ml-auto">40</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                                <div className="media-icon-box mic-sm media">
                                    <div className={'mib-media-left ' + classes.cyanBg}>
                                        <div className="mib-round">
                                            <EuroSvg fill={Colors.cyan} />
                                        </div>
                                    </div>
                                    <div className="mib-body media-body">
                                        <div className="mibb-content row">
                                            <span className="col">Brouillons</span>
                                            <strong className="col-auto ml-auto">12</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                                <div className="media-icon-box mic-sm media">
                                    <div className={'mib-media-left ' + classes.redBg}>
                                        <div className="mib-round">
                                            <EuroSvg fill={Colors.red} />
                                        </div>
                                    </div>
                                    <div className="mib-body media-body">
                                        <div className="mibb-content row">
                                            <span className="col">A expédier</span>
                                            <strong className="col-auto ml-auto">24</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                                <div className="media-icon-box mic-sm media">
                                    <div className={'mib-media-left ' + classes.greenBg}>
                                        <div className="mib-round">
                                            <EuroSvg fill={Colors.green} />
                                        </div>
                                    </div>
                                    <div className="mib-body media-body">
                                        <div className="mibb-content row">
                                            <span className="col">Expédié</span>
                                            <strong className="col-auto ml-auto">02</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                                <div className="media-icon-box mic-sm media">
                                    <div className={'mib-media-left ' + classes.mintBg}>
                                        <div className="mib-round">
                                            <EuroSvg fill={Colors.mint} />
                                        </div>
                                    </div>
                                    <div className="mib-body media-body">
                                        <div className="mibb-content row">
                                            <span className="col">Confirmé</span>
                                            <strong className="col-auto ml-auto">15</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
                <div className="main-box no-bg">
                    <div className="main-box-title"> 
                        No background and no box-shadow
                    </div>
                    <div className="main-box-content">
                        Bow with no background and no box shadow
                    </div>
                </div>
                <div className="main-box">
                    <div className="main-box-title">                                                  
                        <div className="row align-items-center">
                            <div className="col">
                                Right buttons
                            </div>
                            <div className="col-auto ml-auto">
                                <WhiteButton className="btn-rounded mr-2" variant="contained" color="primary">
                                    Exporter
                                </WhiteButton>
                                <WhiteButton className="btn-rounded" variant="contained" color="primary">
                                    Retour
                                </WhiteButton>
                            </div>
                        </div>
                    </div>
                    <div className="main-box-content">
                        <WhiteButton className="btn-rounded mr-2" variant="contained" color="primary">
                            rounded - White
                        </WhiteButton>
                        <Button className="btn-rounded mr-2" variant="contained">Default</Button>
                        <Button className="btn-rounded mr-2" variant="contained" color="primary">
                            Primary
                        </Button>
                        <Button className="btn-rounded mr-2" variant="contained" color="secondary">
                            Secondary
                        </Button>
                        <Button className="btn-rounded" variant="contained" disabled>
                            Disabled
                        </Button>
                        <hr className="mt-3 mb-3" />
                        <Button className="mr-2" variant="contained" size="large">Default</Button>
                        <Button className="mr-2" variant="contained" color="primary" size="large">
                            Primary
                        </Button>
                        <Button variant="contained" color="secondary" size="large">
                            Secondary
                        </Button>
                        <hr className="mt-3 mb-3" />
                        <Button className="btn-form btn-icon mr-2" variant="contained" color="secondary" size="large">
                            <span>Annuler</span> <Icon>close</Icon>
                        </Button>
                        <Button className="btn-form btn-icon mr-2" variant="contained" color="primary" size="large">
                            <span>Valider</span> <Icon>check</Icon>
                        </Button>
                    </div>
                </div>
                <div className="main-box">
                    <div className="main-box-title"> 
                        Typography & helpers
                    </div>
                    <div className="main-box-content">
                        <section className="section">
                            <h3 className="text-500 mb-4">Section title with border</h3>
                            <div className="section-title">Section title</div>
                            <div className="section-border"></div>
                            <h3 className="text-500 mb-4">Standalone section border (no title)</h3>
                            <div className="section-border"></div>                                    
                        </section>      
                        <div className="row mt-2">
                            <div className="col-xl-6 mb-3">
                                <h1 className="mt-0">Typography h1</h1>
                                <h2>Typography h2</h2>
                                <h3>Typography h3</h3>
                                <h4>Typography h4</h4>
                                <h5>Typography h5</h5>
                                <h6>Typography h6</h6>
                                <hr />
                                <div className="text-uppercase">Typography uppercase</div>
                                <del>Typography del</del>
                                <h3 className="mt-4 text-300">Font weight 300</h3>
                                <h3 className="text-400">Font weight 400</h3>
                                <h3 className="text-500">Font weight 500</h3>
                                <h3 className="text-700">Font weight 700</h3>
                                <h3 className="text-900">Font weight 900</h3>
                            </div>
                            <div className="col-xl-6">                                
                                <section className="section">
                                    <ul>
                                        <li>list 1</li>
                                        <li>list 2</li>
                                        <li>list 3</li>
                                    </ul>
                                    <ul className="list-unstyled">
                                        <li>list unstyled 1</li>
                                        <li>list unstyled 2</li>
                                        <li>list unstyled 3</li>
                                    </ul>    
                                </section>                       
                            </div>
                        </div>                        
                    </div>
                </div>
                <div className="main-box">
                    <div className="main-box-title">
                        <div className="row align-items-center">
                            <div className="col">
                                Ajouter client
                            </div>
                            <div className="col-auto ml-auto">                                
                                <WhiteButton className="btn-rounded" variant="contained" color="primary">
                                    Retour
                                </WhiteButton>
                            </div>
                        </div>
                    </div>
                    <div className="main-box-content">
                        <section className="section">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="section-title">Identité</div>
                                    <div className="section-border"></div>
                                    <div className="row">
                                        <div className="col-xl-6 col-xxl-3">
                                            <TextField label="Raison sociale" value="Wholehelp" fullWidth></TextField>
                                        </div>
                                        <div className="col-xl-6 col-xxl-3">     
                                            <FormControl fullWidth>
                                                <InputLabel shrink>
                                                    Type de société
                                                </InputLabel>
                                                <Select
                                                    value={age}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    autoWidth
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={10}>Ten</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-xl-6 col-xxl-3">     
                                            <TextField label="Nom référent" value="Mr Lorem ipsum" fullWidth></TextField>
                                        </div>
                                        <div className="col-xl-6 col-xxl-3">
                                            <div className="user-image-upload d-flex">
                                                <div className="label MuiFormLabel-root align-self-start">Photo</div>  
                                                <input accept="image/*" className="d-none" id="icon-button-file" type="file" />
                                                <label htmlFor="icon-button-file">
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                        <span className="material-icons">add</span>
                                                    </IconButton>
                                                </label>
                                            </div> 
                                        </div>    
                                    </div>                                
                                </div>
                                <div className="col-xl-auto ml-xl-auto">                                
                                    <div className="user-card">
                                        <div className="uc-title">Aperçu</div>
                                        <div className="uc-media media align-items-center">
                                            <div className="uc-avatar mr-3">
                                                <img src="https://via.placeholder.com/66.png/09f/fff" alt="" />
                                            </div>        
                                            <div className="media-body">
                                                Mr Lorem Ipsum <br/>
                                                contact@whoelehelp.fr <br/>
                                                06.00.00.00.00
                                            </div>
                                        </div>
                                        <div className="uc-meta">
                                            <span>Societe</span>
                                            <strong>WHOLEHELP</strong>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </section>
                        <div className="section-title">Divers</div>
                        <div className="section-border"></div>
                        <div className="row">
                            <div className="col-xl-6 col-xxl-3">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        className="switch-label-top mx-0"
                                        value="top"
                                        control={<Switch className="switch-ios" color="primary" />}
                                        label="Top"
                                        labelPlacement="top"
                                    />
                                </FormGroup>
                            </div> 
                            <div className="col-xl-6 col-xxl-3">
                                <div className="label MuiFormLabel-root align-self-start">Inline Checkboxes</div>
                                <FormControlLabel
                                    control={
                                      <Checkbox  
                                        className="checkbox"                                 
                                        name="checkedB"
                                        color="primary"
                                      />
                                    }
                                    label="Checkbox"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox  
                                        className="checkbox"                                 
                                        name="checkedB"
                                        color="primary"
                                      />
                                    }
                                    label="Checkbox"
                                  />
                            </div>
                            <div className="col-xl-6 col-xxl-3">
                                <div className="label MuiFormLabel-root align-self-start">Checkboxes</div>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                          <Checkbox  
                                            className="checkbox"                                 
                                            name="checkedB"
                                            color="primary"
                                          />
                                        }
                                        label="Checkbox"
                                    />
                                    <FormControlLabel
                                        control={
                                          <Checkbox  
                                            className="checkbox"                                 
                                            name="checkedB"
                                            color="primary"
                                          />
                                        }
                                        label="Checkbox"
                                    />
                                </FormGroup>
                            </div>
                        </div>                
                    </div>
                </div>
                <Modal label="Test" isOpen={false} closeModal={() =>null}>
                    <div className="content-modal">
                        <div className="row">
                            <div className="col-lg-6">
                                <TextField label="Email" value="Email" fullWidth></TextField>
                            </div>
                            <div className="col-lg-6">
                                <TextField label="Catégorie" value="Catégorie" fullWidth></TextField>
                            </div>
                        </div>
                        <div className="label MuiFormLabel-root">Message</div>
                        <TextareaAutosize className="textarea w-100" aria-label="empty textarea" rowsMin={4} placeholder="Empty" />
                        <hr className="hr-gray my-4 hr-2" />
                        <div className="message-history-list">
                            <div className="message-history">  
                                <div className="message-history d-flex">  
                                    <div className="mi-col">
                                        <span className="checkbox-bg"></span>  
                                        <Checkbox  
                                            className="checkbox pt-0 pl-0"                                 
                                            name="checkedB"
                                            color="primary"
                                        />
                                    </div>
                                    <div className="mi-messsage ml-auto">  
                                        <div className="mi-user">  
                                            Sparklane
                                        </div>
                                        <div className="mi-header d-flex justify-content-between">  
                                            <div className="mi-title">  
                                                Lorem ipsum
                                            </div>
                                            <div className="mi-date">  
                                                22/04/2020 à 14h36
                                            </div>
                                        </div>
                                        <div className="mi-content">  
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, 
                                            sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                                        </div>
                                        <hr className="my-4" /> 
                                    </div>                                    
                                </div>
                                <div className="message-history d-flex">  
                                    <div className="mi-col">
                                        <span className="checkbox-bg"></span>  
                                        <Checkbox  
                                            className="checkbox pt-0 pl-0"                                 
                                            name="checkedB"
                                            color="primary"
                                        />
                                    </div>
                                    <div className="mi-messsage ml-auto">  
                                        <div className="mi-user">  
                                            Sparklane
                                        </div>
                                        <div className="mi-header d-flex justify-content-between">  
                                            <div className="mi-title">  
                                                Lorem ipsum
                                            </div>
                                            <div className="mi-date">  
                                                22/04/2020 à 14h36
                                            </div>
                                        </div>
                                        <div className="mi-content">  
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, 
                                            sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                                        </div>
                                        <hr className="my-4" /> 
                                    </div>                                    
                                </div>
                                <div className="message-history d-flex">  
                                    <div className="mi-col">
                                        <span className="checkbox-bg"></span>  
                                        <Checkbox  
                                            className="checkbox pt-0 pl-0"                                 
                                            name="checkedB"
                                            color="primary"
                                        />
                                    </div>
                                    <div className="mi-messsage ml-auto">  
                                        <div className="mi-user">  
                                            Sparklane
                                        </div>
                                        <div className="mi-header d-flex justify-content-between">  
                                            <div className="mi-title">  
                                                Lorem ipsum
                                            </div>
                                            <div className="mi-date">  
                                                22/04/2020 à 14h36
                                            </div>
                                        </div>
                                        <div className="mi-content">  
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, 
                                            sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                                        </div>
                                        <hr className="my-4" /> 
                                    </div>                                    
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-5 mb-2">
                                <WhiteButton className="btn-rounded btn-shadow mr-2 pl-5 pr-5" variant="contained" color="primary">
                                    Fermer
                                </WhiteButton>
                            </div>    
                        </div> 
                    </div>
                </Modal>
            </main>
        </div>
    );
}

/* Modal vertical + horizontal content center
<div className="content-modal d-flex flex-row align-items-center justify-content-center">
*/

// interface IPropsDavidInte {

// }

// const DavidInte: React.FunctionComponent<IPropsDavidInte> = props => {
//     return(
//         <>
//             <h1>Hello David</h1>
//             <i className="fas fa-arrow-down"></i>
//         </>
//     )
// }

// export default DavidInte;