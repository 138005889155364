import { Refresh } from "@material-ui/icons";
import React, { useState } from "react";
import "./globalSearch.scss";

interface ISearchProps {
    onSearch: (value: string) => void;
    onResetFilter?: () => void;
    searchColWidth?: string;
}

const GlobalSearch = ({ onSearch, onResetFilter, searchColWidth = 'col-lg-6 col-xl-3' }: ISearchProps) => {
    const [value, setValue] = useState("");

    return (
        <div className="row search-section">
            <div className={"media-icon-box-col col-12 global-search " + searchColWidth}>
                <div className="input-container">
                    <input
                        value={value}
                        placeholder="Rechercher"
                        onChange={(event) => setValue(event.target.value)}
                        onKeyUp={() => onSearch(value)}
                    />
                    <img
                        src="/images/icon_search.png"
                        alt="search"
                        onClick={() => onSearch(value)}
                    />
                </div>
            </div>
            {onResetFilter && (
                <div className="refresh-filter">
                    <Refresh
                        className="refresh"
                        onClick={() => {
                            onResetFilter();
                            //setValue("");
                        }}
                        style={{ color: "white" }}
                    />
                </div>
            )}
        </div>
    );
};

export default GlobalSearch;
