import React from "react";
import { useTranslation } from "react-i18next";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import { Delete } from "@material-ui/icons";
import { RoutePoint } from "../../../../models/transfer";
import "./SubFormPoint.scss";

interface Iprops {
    route_point: RoutePoint;
    index: number;
    disabled: boolean;
    control: any;
    errors: any;
    onDelete: (index: number) => void;
    onUpdate: (index: number, key: string, value: any) => void;
}

const SubFormPoint = ({
    control,
    errors,
    disabled,
    route_point,
    index,
    onDelete,
    onUpdate,
}: Iprops) => {
    const { t } = useTranslation();
    let fieldPrefix = "route_points[" + index + "]";

    if (!errors.route_points) {
        errors.route_points = [];
    }

    return (
        <>
            <hr />
            <div
                className="sub_form_point"
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div className="first_row">
                    <div className="field-form mgl2 col-6">
                        <InputControlled
                            name={fieldPrefix + ".detail"}
                            label={t("form.transfer_step") + " " + (index + 1)}
                            required={true}
                            value={route_point.detail}
                            disabled={disabled}
                            control={control}
                            errors={errors}
                            multiline={true}
                            onChange={(value: any) =>
                                onUpdate(index, "detail", value)
                            }
                        />
                    </div>
                    <div className="field-form mgl2 col-1">
                    {!disabled &&
                        <Delete
                            className="delete_row"
                            onClick={() => onDelete(index)}
                        />
                    }
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubFormPoint;
