import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Client } from "../../../models/client";
import ActionTable from "../../../components/action-table/ActionTable";
import LinkArrayNumber from "../../../components/link-array-number/LinkArrayNumber";
import BodyTableRow from "../../../components/table/BodyTableRow";
import BodyTableCell from "../../../components/table/BodyTableCell";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import ClientService from "../../../services/client-service";
import ItemSubClient from "./ItemSubClient";
import { User, Roles } from "../../../models/user";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { ErrorForm } from "../../../components/ErrorForm";
import ModalDelete from "../../../components/modal/ModalDelete";

interface IItemClient {
    client: Client;
    user: User;
    onDeleteSuccess: () => void;
}

const ItemClient: React.FunctionComponent<IItemClient> = ({
    client,
    user,
    onDeleteSuccess,
}) => {
    const history = useHistory();
    const service = new ClientService();
    const { t } = useTranslation();
    const [openSubAccount, setOpenSubAccount] = useState(false);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [isOpen, setIsOpen] = useState(false);

    const deleteClient = () => {
        setIsOpen(true);
    };

    const confirmDeleteClient = async () => {
        try{
            await service.delete(client.id);
            toastr.success(t("success"), t("success_deleted_client"));
            onDeleteSuccess();
        } catch(error){
            setErrorApi(new ErrorFormData(error.response.data));
        }
       
    };

    function renderItemAdmin() {
        return (
            <>
                <BodyTableRow
                    nameClass={
                        openSubAccount || client.parent_id !== null
                            ? "subForm"
                            : ""
                    }
                >
                    <BodyTableCell align="center">
                        <div
                            className="link-table"
                            onClick={() => history.push(`client/${client.id}`)}
                        >
                            {client.company_name}
                        </div>
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {client?.division?.label}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {client.referent_name}
                    </BodyTableCell>
                    <BodyTableCell align="center">{client.email}</BodyTableCell>
                    <BodyTableCell align="center">
                        {client.getPhoneForList()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {client.parent_id !== null ? (
                            <div
                                className="hide-sub-account to-upper"
                                onClick={() => setOpenSubAccount(false)}
                            >
                                {t("back_sub_account")}
                            </div>
                        ) : (
                            <LinkArrayNumber
                                number={client.nbAccounts}
                                onChange={() =>
                                    client.subAccounts &&
                                    client.subAccounts?.length > 0 &&
                                    setOpenSubAccount(true)
                                }
                            />
                        )}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        <LinkArrayNumber
                            number={client.nbAddresses}
                            link={`client/${client.id}?tab=adresses`}
                        />
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {client.nbFolders}
                    </BodyTableCell>
                    {/*<BodyTableCell align="center"></BodyTableCell>
                    <BodyTableCell align="center"></BodyTableCell>*/}
                    <BodyTableCell align="right">
                        <ActionTable
                            deleteAction={() => deleteClient()}
                            updateAction={() =>
                                history.push(`client/${client.id}`)
                            }
                        />
                        <ErrorForm errorApi={errorApi} />
                        <ModalDelete
                            isOpen={isOpen}
                            closeDialog={() => setIsOpen(false)}
                            itemType="client"
                            itemCode={client.company_name}
                            handleDelete={() => confirmDeleteClient()}
                            isDeleteAction
                        />
                    </BodyTableCell>
                </BodyTableRow>
                {openSubAccount &&
                    client.subAccounts?.map((account: Client) => (
                        <ItemSubClient
                            key={`table_subclient_id_${account.id}`}
                            client={account}
                            onDeleteSuccess={() => onDeleteSuccess()}
                            setClose={() => setOpenSubAccount(false)}
                        />
                    ))}
            </>
        );
    }

    function renderItemClient() {
        return (
            <>
                <BodyTableRow>
                    <BodyTableCell align="center">
                        <div
                            className="link-table"
                            onClick={() =>
                                history.push(`client/${client.id}`)
                            }
                        >
                            {client.company_name}
                        </div>
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {client?.division?.label}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {client.referent_name}
                    </BodyTableCell>
                    <BodyTableCell align="center">{client.email}</BodyTableCell>
                    <BodyTableCell align="center">
                        {client.phone_number}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {client.mobile_number}
                    </BodyTableCell>
                    <BodyTableCell align="right">
                        <>
                            <ActionTable
                                {...(client.parent_id && user.isOwner(client)
                                    ? { deleteAction: () => deleteClient() }
                                    : {})}
                                updateAction={() =>
                                    history.push(`client/${client.id}`)
                                }
                            />
                            <ModalDelete
                                isOpen={isOpen}
                                closeDialog={() => setIsOpen(false)}
                                itemType="client"
                                itemCode={client.company_name}
                                handleDelete={() => confirmDeleteClient()}
                                isDeleteAction
                            />
                        </>
                    </BodyTableCell>
                </BodyTableRow>
                {openSubAccount &&
                    client.subAccounts?.map((account: Client) => (
                        <ItemSubClient
                            key={`table_subclient_id_${account.id}`}
                            client={account}
                            onDeleteSuccess={() => onDeleteSuccess()}
                            setClose={() => setOpenSubAccount(false)}
                        />
                    ))}
            </>
        );
    }
    return user.role === Roles.CLIENT ? renderItemClient() : renderItemAdmin();
};

export default ItemClient;
