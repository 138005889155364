import React from "react";
import { ReactSVG } from "react-svg";
import { css } from "glamor";
import './ImgInteriorVehicle.scss';
import { InteriorColor } from "../../../models/interior-color";

interface IInterieurColor {
    interiorType?: number;
    interiorColor?: InteriorColor | null;
    renderText? : boolean;
}

const ImgInteriorVehicle = (props: IInterieurColor) => {
    
    const svgStyle = css({
        ' path': {
          fill: props.interiorColor && props.interiorColor.hex_color ? props.interiorColor.hex_color : 'black'
        },
    }) 

    const getIconInteriorType = (interiorType: number) => {
        if(interiorType === 1) {
            return '/images/interior-types/leather-type.svg';
        } else if(interiorType === 2) {
            return '/images/interior-types/textile-type.svg';
        }
        return ''
    }

    return (
        <div className="vehicle_color_int">
             {props.interiorType && props.interiorColor ? <ReactSVG src={getIconInteriorType(props.interiorType)} {...svgStyle} /> : ''}
             {props.renderText ? <span>{props.interiorColor ? props.interiorColor.color : ''}</span> : '' }
        </div>
    );
}

ImgInteriorVehicle.defaultProps = {
    renderText: true
}

export default ImgInteriorVehicle;