import React from "react";

const BillingSvg = (props: any) => {
    return (
        <>
            <div className="base" style={{ position: "relative" }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.238"
                    height="18.984"
                    viewBox="0 0 14.238 18.984"
                >
                    <path
                        id="file-invoice-dollar-solid"
                        d="M13.979,3.893,10.349.26A.889.889,0,0,0,9.718,0H9.492V4.746h4.746V4.52A.887.887,0,0,0,13.979,3.893ZM8.306,5.043V0H.89A.888.888,0,0,0,0,.89v17.2a.888.888,0,0,0,.89.89H13.348a.888.888,0,0,0,.89-.89V5.933H9.2A.893.893,0,0,1,8.306,5.043ZM2.373,2.67a.3.3,0,0,1,.3-.3H5.636a.3.3,0,0,1,.3.3v.593a.3.3,0,0,1-.3.3H2.67a.3.3,0,0,1-.3-.3Zm0,2.966V5.043a.3.3,0,0,1,.3-.3H5.636a.3.3,0,0,1,.3.3v.593a.3.3,0,0,1-.3.3H2.67A.3.3,0,0,1,2.373,5.636Z"
                        transform="translate(0)"
                        fill="#2f2f2f"
                    />
                </svg>
            </div>

            <div
                className="euro"
                style={{ position: "absolute", left: "18px" }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.084"
                    height="11.084"
                    viewBox="0 0 11.084 11.084"
                >
                    <path
                        id="euro"
                        d="M6.325,3.852a1.736,1.736,0,0,0-.391-1.59,1.665,1.665,0,0,0-1.4-.421l-.075.01.576.586A.739.739,0,1,1,3.979,3.473L3.027,2.5a3.543,3.543,0,0,0-.266.24,3.485,3.485,0,0,0-.238.264l.95.934A.739.739,0,1,1,2.437,5l-.569-.559c0,.026-.008.052-.011.078a1.666,1.666,0,0,0,.421,1.4,1.728,1.728,0,0,0,1.561.4A.739.739,0,1,1,4.1,7.768a3.8,3.8,0,0,1-.678.062,3.053,3.053,0,0,1-2.192-.868A3.11,3.11,0,0,1,.389,4.347,4.131,4.131,0,0,1,.671,3.259l-.45-.443A.739.739,0,1,1,1.256,1.763l.209.206q.12-.139.25-.27t.272-.252L1.8,1.257A.739.739,0,0,1,2.853.221l.425.432A4.133,4.133,0,0,1,4.363.373a3.111,3.111,0,0,1,2.615.844,3.184,3.184,0,0,1,.8,2.917.739.739,0,1,1-1.45-.281Z"
                        transform="matrix(0.719, -0.695, 0.695, 0.719, 0, 5.451)"
                        fill="#fff"
                    />
                </svg>
            </div>
        </>
    );
};

export default BillingSvg;
