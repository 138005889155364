import { NotificationUser } from "../../../models/notificationUser";
import {  NotificationActionTypes, GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_ERROR, ADD_NOTIFICATION, UPDATE_NOTIFICATION } from "./types";

export function getNotifications(): NotificationActionTypes{
    return {
        type: GET_NOTIFICATIONS
    }
}

export function getNotificationsSuccess(payload: NotificationUser[]): NotificationActionTypes{
    return {
        type: GET_NOTIFICATIONS_SUCCESS,
        payload: payload
    }
}

export function getNotificationsError(): NotificationActionTypes{
    return {
        type: GET_NOTIFICATIONS_ERROR,
    }
}

export function addNotification(payload: NotificationUser): NotificationActionTypes{
    return {
        type: ADD_NOTIFICATION,
        payload: payload
    }
}

export function updateNotification(payload: NotificationUser): NotificationActionTypes{
    return {
        type: UPDATE_NOTIFICATION,
        payload: payload
    }
}