import { NotificationUser } from "../../../models/notificationUser";
import { ADD_NOTIFICATION, GET_NOTIFICATIONS, GET_NOTIFICATIONS_ERROR, GET_NOTIFICATIONS_SUCCESS, NotificationActionTypes, NotificationsState, UPDATE_NOTIFICATION } from "./types";

const initialState:  NotificationsState = {
    list: [],
    loading: false
}

export function notificationsReducer(
    state = initialState,
    action: NotificationActionTypes
): NotificationsState{
    switch(action.type) {
        case GET_NOTIFICATIONS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                list: action.payload,
                loading: false, 
            }
        }
        case GET_NOTIFICATIONS_ERROR: {
            return {
                ...state,
                ...{list: [], loading: false}
            }
        }
        case ADD_NOTIFICATION: {
            return {
                ...state,
                ...{list: [action.payload, ...state.list]}
            }
        }
        case UPDATE_NOTIFICATION: {
            const index = state.list.findIndex( (item: NotificationUser) => item.id === action.payload.id )
            return {
                ...state,
                list: [
                    ...state.list.slice(0,index),
                    action.payload,
                    ...state.list.slice(index+1)
                ]
            }
        }
        default:
            return state;
    }
}