import axios, { AxiosResponse } from "axios";
import { VehicleBrand } from "../models/vehicle-brand";
import { getBaseUrl, getParamsUrl } from "./setting-service";
import { DataPaginate } from "../models/utils/data-paginate";

export default class BrandService {


  list(filter?: string | null, page?: number | null, search?: string | null) {
    let paramsUrl = getParamsUrl([
      { key: "page", value: page },
      { key: "search", value: search },
      { key: "filter", value: filter },
    ]);

    return axios
      .get(`${getBaseUrl()}vehicle-brands${paramsUrl}`)
      .then((response: AxiosResponse) => {
        let res = response.data;
        res.data = res.data.map((brand: Object) => new VehicleBrand(brand));
        return new DataPaginate(res);
      });
  }

  all() {
    let paramsUrl = getParamsUrl([{ key: "filter", value: "all" }]);

    return axios
      .get(`${getBaseUrl()}vehicle-brands${paramsUrl}`)
      .then((response: AxiosResponse) =>
        response.data.data.map((brand: Object) => new VehicleBrand(brand))
      )
  }

  add(body: any) {
    return axios
      .post(`${getBaseUrl()}vehicle-brands`, body)
      .then((response: AxiosResponse) => new VehicleBrand(response.data))
      .catch();
  }

  update(body: any, id: number) {
    return axios
      .put(`${getBaseUrl()}vehicle-brands/${id}`, body)
      .then((response: AxiosResponse) => new VehicleBrand(response.data))
      .catch();
  }

  delete(id: number) {
    return axios.delete(`${getBaseUrl()}vehicle-brands/${id}`).then().catch();
  }
}
