import React from "react";

const MotoSvg = (props: any) => {
    return ( 
            <svg id="bike" xmlns="http://www.w3.org/2000/svg" width="41.701" height="29.321" viewBox="0 0 41.701 29.321">
              <g id="Groupe_4151" data-name="Groupe 4151">
                 <path id="Tracé_23730" data-name="Tracé 23730" d="M7.33,90.661a7.511,7.511,0,0,1,3,.66l1.115-1.662a10.641,10.641,0,0,0-5.339-1.441A1.221,1.221,0,0,1,4.887,87V84.552A8.562,8.562,0,0,1,13.439,76h2.443a1.222,1.222,0,0,1,0,2.443H14.661v2.443h3.665a1.222,1.222,0,0,1,1.016.544l1.266,1.9H30.867l.613-.92a3.413,3.413,0,0,1,2.845-1.524H40.48A1.221,1.221,0,0,1,41.7,82.109a6.118,6.118,0,0,1-5.43,6.072l-1.67,2.5a7.32,7.32,0,1,1-7.124,9.75H20.129a3.429,3.429,0,0,1-3.354-2.75l-.173-.865a10.631,10.631,0,0,0-3.208-5.678l-1.035,1.543A7.31,7.31,0,1,1,7.33,90.661Zm27.041,9.766H30.148A4.882,4.882,0,1,0,34.371,93.1a4.839,4.839,0,0,0-1.569.279l-.427.64a3.413,3.413,0,0,1-2.845,1.524h4.842a2.439,2.439,0,1,1,0,4.879ZM7.33,102.878a4.944,4.944,0,0,0,4.887-4.887,4.805,4.805,0,0,0-1.254-3.225l-2.619,3.9a1.221,1.221,0,0,1-2.028-1.36l2.619-3.906a4.3,4.3,0,0,0-1.605-.3,4.887,4.887,0,1,0,0,9.774Z" transform="translate(0 -76)" fill={props.fill}/>
              </g>
            </svg>
);}

export default MotoSvg;
