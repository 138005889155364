import axios, { AxiosResponse } from "axios";
import { Country } from "../models/country";
import { getBaseUrl } from "./setting-service";

export default class CountryService {
  all() {
    return axios
      .get(`${getBaseUrl()}countries`)
      .then((response: AxiosResponse) =>
        response.data.map((c: Country) => new Country(c))
      )
      .catch();
  }
}
