import React from "react";
import { IndicatorType } from "../../../components/indicator/Indicator";
import { useTranslation } from "react-i18next";
import NewIndicator from "../../../components/indicator/NewIndicator";
import UseStyles from "../../design-dependencies/UseStyles";

interface PropsKpisVehicles {
    nbCars: number;
    nbBus: number,
    nbMoto:number;
    popular: string;
}

const KpiVehicles = ({
    nbCars,
    nbBus,
    nbMoto,
    popular,
}: PropsKpisVehicles) => {
    const { t } = useTranslation();
    return (
        <div className="row">
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().blueGradient}
                    index={0}
                    text={t("cars")}
                    type={IndicatorType.Vehicle}
                    total={nbCars}
                />
            </div>
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().orangeGradient}
                    index={1}
                    text={t("bus_and_minibus")}
                    type={IndicatorType.Vehicle}
                    total={nbBus}
                />
            </div>
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().greyBg}
                    index={2}
                    text={t("moto")}
                    type={IndicatorType.Vehicle}
                    total={nbMoto}
                />
            </div>
        </div>
    );
};

export default KpiVehicles;
