import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";
import { findError } from "./helpers/ControllerHelper";

interface Iprops {
    label: string;
    value: any;
    disabled: boolean | false;
    name: string;
    className?: string
    required?: boolean | false;
    control?: any;
    errors: any;
    decorator?: any | null;
    multiline?: boolean | false;
    onChange?: (value: any) => void;
    autoFocus?: boolean | false;
}

const InputControlled = ({
    label,
    value,
    disabled,
    name,
    className,
    required,
    control,
    errors,
    decorator,
    multiline,
    onChange,
    autoFocus,
}: Iprops) => {
    const { t } = useTranslation();

    let fieldError = findError(errors, name);

    return (
        <>
            <Controller
                as={TextField}
                error={!!fieldError}
                helperText={
                    fieldError
                        ? fieldError.types
                            ? t(fieldError.types.message)
                            : t("errors.required", { field: label })
                        : ""
                }
                disabled={disabled}
                name={name}
                className={className}
                control={control}
                defaultValue={value}
                label={label}
                placeholder={label}
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                rules={{ required: required }}
                multiline={multiline}
                onChange={(e: any) => {
                    if (onChange) {
                        onChange(e[0].currentTarget.value);
                    }
                    return e[0].currentTarget.value;
                }}
                autoFocus={autoFocus}
            />
            {decorator && (
                // <div className="decorator-input" style={{ paddingTop: "15px" }}>
                <div className="decorator-input">
                    {decorator}
                </div>
            )}

            {fieldError && <p>{fieldError.message}</p>}
        </>
    );
};

export default InputControlled;
