import { Client, Grant, Grants } from "./client";
import { Deserializable } from "./interfaces/deserializable";
import { Provider } from "./provider";

export enum Roles {
    ADMIN = "admin",
    PROVIDER = "provider",
    CLIENT = "client",
}

export class User implements Deserializable {
    public id: number;
    public firstname: string;
    public lastname: string;
    public language: string;
    public avatar_url: string | null; // add
    public email: string;
    public birth_date: Date; // add
    public is_enabled: boolean;
    public role: Roles;
    public provider?: Provider;
    public provider_id: number;
    public client?: Client;
    public created_at: Date;
    public updated_at: Date;
    public mobile_number: string;
    public gender: string;
    public client_grants: Grant[];

    constructor(data: any) {
        this.id = data.id;
        this.firstname = data.firstname;
        this.lastname = data.lastname;
        this.language = data.language;
        this.email = data.email;
        this.avatar_url = data.img_url;
        this.is_enabled = data.is_enabled;
        this.role = data.role;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.gender = data.gender;
        this.provider_id = data.provider_id;
        this.birth_date = new Date(data.birth_date);
        this.mobile_number = data.mobile_number;
        if (data.provider) {
            this.provider = new Provider(data.provider);
        }
        if (data.client) {
            this.client = new Client(data.client);
        }
        this.client_grants = data.client_grants
            ? data.client_grants.map((grant: any) => {
                  return new Grant(grant);
              })
            : [];
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    isAdmin(): boolean {
        return this.role === Roles.ADMIN;
    }

    isClient(): boolean {
        return this.role === Roles.CLIENT;
    }

    isProvider(): boolean {
        return this.role === Roles.PROVIDER;
    }

    getFullName(): string {
        return this.firstname + " " + this.lastname;
    }

    getMainClientOwned(): Client[] {
        let clients: Client[] = [];

        this.client_grants.map((grant: any) => {
            if (grant.client?.parent_id === null && grant.level === "owner") {
                clients.push(grant.client);
            }
            return grant;
        });

        return clients;
    }

    isOwner(client: Client): boolean {
        return this.hasGrant(client, Grants.OWNER);
    }

    isManager(client: Client): boolean {
        return this.hasGrant(client, Grants.MANAGER);
    }

    isReader(client: Client): boolean {
        return this.hasGrant(client, Grants.READER);
    }

    isOnlyReader(): boolean {
        return (
            this.client_grants.filter(
                (grant: Grant) => grant.level !== Grants.READER
            ).length === 0
        );
    }

    canShowOffers(): boolean {
        return this.provider !== undefined && this.provider.is_tender;
    }

    private hasGrant(client: Client, level: string): boolean {
        return (
            this.client_grants.filter(
                (grant: Grant) =>
                    grant.client_id === client.id && grant.level === level
            ).length > 0
        );
    }
}
