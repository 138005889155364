import {
  createStyles,
  FormControl,
  InputBase,
  Select,
  Theme,
  withStyles,
} from "@material-ui/core";
import React from "react";

interface IDropdownProps {
  children: any;
  label?: any;
  value: any;
  onChange: (e: any) => void;
}

const Dropdown = ({ children, label, value, onChange }: IDropdownProps) => {
  return (
    <div className="actions">
      <span>{label}</span>
      <div className="dropdown">
        <FormControl variant="outlined" className="dropdown-actions">
          <Select
            native
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={value}
            onChange={(e) => onChange(e)}
            input={<BootstrapInput />}
          >
            {children}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 5,
      position: "relative",
      border: "1px solid #ced4da",
      marginTop: "5px",
      padding: "7px 10px 11px 10px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      width: "120px",
      fontSize: "14px",
      fontWeight: 400,
      "&:focus": {
        borderRadius: 5,
        borderColor: "#80bdff",
      },
    },
  })
)(InputBase);

export default Dropdown;
