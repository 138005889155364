import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorFormData } from "../../../../models/interfaces/errorForm";
import AuthService from "../../../../services/auth-service";
import { ErrorForm } from "../../../../components/ErrorForm";
import {toastr} from 'react-redux-toastr'
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import FormTitle from "../../../../components/form-title/FormTitle";
import SubmitComponent from "../../../../components/submit-component/SubmitComponent";

interface FormRegister{
    email: string;
    firstname: string;
    lastname: string;
}

interface IFormAdministrationProps{
    onAddUserSuccess: () => void
}

const FormAdministrator: React.FunctionComponent<IFormAdministrationProps> = ({onAddUserSuccess}) => {
    const { handleSubmit, errors, control, reset } = useForm<FormRegister>();
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState(false);
    const [isActivateButton,setIsActivateButton]=useState(true)
    const textValidate = t('validate'); 
    
    const onSubmit = async (data: any, e:any) => {
        setErrorApi(new ErrorFormData());
        const authService = new AuthService();
        setIsActivateButton(false)
        try{
            setDisabledForm(true);
            await authService.register(data);
            onAddUserSuccess();
            toastr.success(t('success'), t('success_add_admin'));
            setDisabledForm(false);
            reset();
        } catch(error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setIsActivateButton(true)
    };

    return (
        <>
            <FormTitle title={t("administrator_add")}/>
            <form className="form-react admin-form" onSubmit={handleSubmit(onSubmit)}>

                <div className="admin-form-left">
                    <div className="field-form fname-field mgl2">
                        <InputControlled
                            name="firstname"
                            label={t('firstname')}
                            value=""
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>

                    <div className="field-form lname-field mgt1 mgl2">
                        <InputControlled
                            name="lastname"
                            label={t('lastname')}
                            value=""
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>
                </div>

                <div className="admin-form-right">
                    <div className="field-form email-field">
                        <InputControlled
                            name="email"
                            label={t('email')}
                            value=""
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>
                    <SubmitComponent loading={false} text={textValidate} icon="add" mainClass="admin-submit" isActivate={isActivateButton}/>

                </div>

                <ErrorForm errorApi={errorApi} />
            </form>
        </>
    )

}

export default FormAdministrator;
