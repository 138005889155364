import { Deserializable } from "./interfaces/deserializable";
import { Folder } from "./folder";
import { Client } from "./client";

export class Invoice implements Deserializable {
    public id: number;
    public folder_id: number;
    public sent_at?: Date | null;
    public send_count?: number;
    public code: string;
    public pdf?: string;
    public pdf_en?: string;
    public status: string;
    public folder?: Folder;
    public created_by: number;
    public created_at: Date;
    public updated_at: Date;
    public responded_at?: Date;
    public responded_by?: number;
    public deadline_at?: Date | null;
    public price_excl_tax?: number;
    public price_incl_tax?: number;
    public price_margin?: number;
    public client?: Client | null;
    public datas?: {
        deposit: {
            currency: "";
            amount: "";
        };
        reduction: {
            currency: "";
            amount: "";
        };
        passengerMode: "";
        transfers: any[];
    };
    public deposit_paid_ratio?: number | null;
    public can_be_cancelled ?: boolean | false;
    public penalities: Penality[];

    constructor(data: any) {
        this.id = data.id;
        this.folder_id = data.folder_id;
        this.sent_at = (data.sent_at !== null) ? new Date(data.sent_at) : null;
        this.client = data.client ? new Client(data.client) : null;
        this.send_count = data.send_count;
        this.code = data.code;
        if (data.pdf) {
            this.pdf = data.pdf;
            this.pdf_en = data.pdf_en;
        }
        if (data.folder) {
            this.folder = new Folder(data.folder);
        }
        this.status = data.status;
        this.created_by = data.created_by;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        if (data.responded_at) {
            this.responded_at = new Date(data.responded_at);
        }
        this.responded_by = data.responded_by;
        if (data.datas) {
            this.datas = data.datas;
        }

        if(data.deadline_at){
            this.deadline_at = new Date(data.deadline_at);
        }

        this.price_excl_tax = data.price_excl_tax;
        this.price_incl_tax = data.price_incl_tax;
        this.price_margin = data.price_margin;

        if (data.deposit_paid_ratio >= 0) {
            this.deposit_paid_ratio = data.deposit_paid_ratio;
        } else {
            this.deposit_paid_ratio = null;
        }

        this.can_be_cancelled = data.can_be_cancelled ? true : false;

        this.penalities = data.penalities
        ? data.penalities.map((penality: any) => {
              return new Penality(penality);
          })
        : [];
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    getColorByStatus() {
        if (this.status === "draft") {
            return "#e0cd9c";
        } else if (this.status === "sent") {
            return "#a6a8b5";
        } else if (this.status === "lateness") {
            return "#d66666";
        } else if (this.status === "to_pay") {
            return "#FED128";
        } else if (this.status === "paid") {
            return "#b7e79d";
        }

        return "#ccc";
    }

    isSent() {
        return this.sent_at !== null;
    }

    isCancelled() {
        return this.status === 'cancelled';
    }

    isPaid() {
        return this.status === 'paid';
    }

    isLate() {
        return this.status === 'lateness';
    }

}

export class Penality implements Deserializable {
    public id: number;
    public invoice_id: number;
    public code: string;
    public amount: number;
    public comment?: string;
    public created_by: number;
    public created_at: Date;
    public updated_at: Date;
    public pdf: string;
    public pdf_en: string;

    constructor(data: any) {
        this.id = data.id;
        this.invoice_id = data.invoice_id;
        this.amount = data.amount;
        this.comment = data.comment ? data.comment : "";
        this.created_by = data.created_by;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.code = data.code;
        this.pdf = data.pdf;
        this.pdf_en = data.pdf_en;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}

