import {getBaseUrl} from "./setting-service";
import axios, {AxiosResponse} from "axios";
import {Reply} from "../models/reply";

export default class TicketReplyService {
    private ticketID: number;

    constructor(ticketID: number) {
        this.ticketID = ticketID;
    }

    // Get replies of a ticket from api
    all() {
        return axios
            .get(`${getBaseUrl()}tickets/${this.ticketID}/replies}`)
            .then((response: AxiosResponse) => {
                response.data.map((reply: Object) => new Reply(reply));
            })
            .catch();
    }

    // Post a reply inside a ticket
    post(body: any) {
        return axios
            .post(`${getBaseUrl()}tickets/${this.ticketID}/replies`, body)
            .then((response: AxiosResponse) => new Reply(response.data))
            .catch();
    }
}
