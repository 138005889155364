import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { User } from "../../../models/user";
import AdministratorService from "../../../services/administrator-service";
import Pagination from "../../../components/pagination/pagination";
import { DataPaginate } from "../../../models/utils/data-paginate";
import FormAdministrator from "./form-administrator/FormAdministrator";
import AccordionBox from "../../../components/accordion-box/AccordionBox";
import Icon from "@material-ui/core/Icon";
import FormTitle from "../../../components/form-title/FormTitle";
import "./Administrators.scss"
import Paper from "@material-ui/core/Paper";
import {TableBody, TableContainer, TableHead, TableRow} from "@material-ui/core";
import HeadTableCell from "../../../components/table/HeadTableCell";
import Table from "@material-ui/core/Table";
import BodyTableCell from "../../../components/table/BodyTableCell";
import IconClear from "@material-ui/icons/Delete";
import { toastr } from "react-redux-toastr";
import ModalDelete from "../../../components/modal/ModalDelete";

const Administrators: React.FunctionComponent = props => {
    const { t } = useTranslation();
    const [dataPaginate, setDataPaginate] = useState(new DataPaginate());
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [userToDelete, setUserToDelete] = useState<number>(-1);
    const administratorService = new AdministratorService();

    useEffect( () => {
        const fetchGetAdministrators = async () => {
            try{
                const administratorService = new AdministratorService();
                setDataPaginate(await administratorService.getAdministrators());
            } catch(error){
                console.log('error', error);
            }
        };

        fetchGetAdministrators();
    }, []);

    async function refreshAdministrators(newPage?: number) {
        if(newPage) {
            setPage(newPage);
        }
        try{
            setDataPaginate(await administratorService.getAdministrators(newPage ? newPage : page, search));
        } catch(error){
            console.log('error', error);
        }
    }

    async function deleteUser(id: number){
        try{
            await administratorService.deleteUser(id);
            toastr.success(t("success"), t("success_delete_administrator"));
            await refreshAdministrators();
        }  catch(error){
            toastr.success(t("error"), t("unexpected_error"));
        }
    }

    function renderAdministrators(){
        return dataPaginate && dataPaginate.data ? (
                <TableContainer component={Paper} style={{ width: "100%" }}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <HeadTableCell className="center" align="center">
                                    {t("email")}
                                </HeadTableCell>
                                <HeadTableCell className="testTh" align="center">
                                    {t("firstname")}
                                </HeadTableCell>
                                <HeadTableCell className="testTh" align="center">
                                    {t("lastname")}
                                </HeadTableCell>
                                <HeadTableCell className="testTh" align="center">
                                    {t("enabled")}
                                </HeadTableCell>
                                <HeadTableCell className="testTh" align="center">
                                    {t("created_at")}
                                </HeadTableCell>
                                <HeadTableCell className="testTh" align="center">
                                    {t("updated_at")}
                                </HeadTableCell>
                                <HeadTableCell className="testTh" align="center"></HeadTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { dataPaginate.data.map( ( user: User, index: number) => {
                                return (
                                    <TableRow key={`administrator_id_${user.id}`}>
                                        <BodyTableCell align="center" className="to-upper">
                                            {user.email}
                                        </BodyTableCell>
                                        <BodyTableCell align="center" className="to-upper">
                                            {user.firstname}
                                        </BodyTableCell>
                                        <BodyTableCell align="center" className="to-upper">
                                            {user.lastname}
                                        </BodyTableCell>
                                        <BodyTableCell align="center" className="to-upper">
                                            {user.is_enabled ? t('yes') : t('no')}
                                        </BodyTableCell>
                                        <BodyTableCell align="center" className="to-upper">
                                            {user.created_at.toLocaleDateString()}
                                        </BodyTableCell>
                                        <BodyTableCell align="center" className="to-upper">
                                            {user.updated_at.toLocaleDateString()}
                                        </BodyTableCell>
                                        <BodyTableCell align="center" className="to-upper">
                                            <IconClear onClick={() => {setUserToDelete(user.id); setShowModal(true)}} className="delete-action"/>
                                        </BodyTableCell>
                                    </TableRow>
                                )
                            }) }
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : '' 
    }

    const searchOnEnter = (event: { key: string; }) => {
        if(event.key === 'Enter'){
            refreshAdministrators(1)
        }
    };

    function renderSearch() {
        return (
            <div className="param-search">
                <input 
                    placeholder={t('search')} 
                    value={search} 
                    onChange={e => setSearch(e.target.value)}
                    onKeyPress={searchOnEnter}
                />
                <button onClick={ () => refreshAdministrators(1) }><Icon>{ 'search'}</Icon></button>
            </div>
        );
    }

    return (
        <>
            <AccordionBox title={t('nav.administrators').toUpperCase()} slotHeader={renderSearch()}>
                <div className="flex_row">
                    <div className="flex_column big_box">
                        <FormAdministrator onAddUserSuccess={ () => refreshAdministrators(1) }/>
                    </div>
        
                    <div className="flex_column small_box no-pad">
                        <FormTitle title={t("administrator_list")}/>
                        {renderAdministrators()}
                        <Pagination dataPaginate={dataPaginate} onPageChange={(page) => refreshAdministrators(page)} />
                    </div>
                </div>
            </AccordionBox>
            {showModal && 
                <ModalDelete 
                    isOpen={showModal} 
                    closeDialog={()=>setShowModal(false)} 
                    itemType={"administrator"} 
                    itemCode={""} 
                    isDeleteAction={true}
                    handleDelete={() => {deleteUser(userToDelete); setUserToDelete(-1); setShowModal(false)}}
                />
            }
        </>
    )
}

  
export default Administrators;
