import axios, { AxiosResponse } from "axios";
import { Grant } from "../models/client";
import { getBaseUrl } from "./setting-service";

export default class ClientGrantService {
    private clientId: number;

    constructor(clientId: number) {
        this.clientId = clientId;
    }

    private getRoute(): string {
        return `${getBaseUrl()}clients/${this.clientId}/grants`;
    }

    all() {
        return axios
            .get(`${this.getRoute()}`)
            .then((response: AxiosResponse) => {
                return response.data.map((g: Grant) => new Grant(g))
            });
    }

    add(body: any) {
        return axios
            .post(`${this.getRoute()}`, body)
            .then((response: AxiosResponse) => new Grant(response.data))
            .catch();
    }

    update(id: number, body: any) {
        return axios
            .put(`${this.getRoute()}/${id}`, body)
            .then((response: AxiosResponse) => new Grant(response.data))
            .catch();
    }

    delete(id: number) {
        return axios
            .delete(`${this.getRoute()}/${id}`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

}
