import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorFormData } from "../../../../models/interfaces/errorForm";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import SubmitComponent from "../../../../components/submit-component/SubmitComponent";
import { ErrorForm } from "../../../../components/ErrorForm";
import { Address } from "../../../../models/address";
import AddressService from "../../../../services/address-service";
import AutocompleteControlled from "../../../../components/fields-controlled/AutocompleteControlled";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import FormSection from "../../../../components/form-section/FormSection";
import { Country } from "../../../../models/country";
import ClientService from "../../../../services/client-service";
import { Client } from "../../../../models/client";

interface IPropsFormAddress {
    name: string;
    street: string;
    misc: string;
    post_code: string;
    city: string;
    intention_of: string;
    tva_number?: string;
    country_id: number;
}

interface IFormProps {
    countries: Array<Country>;
    client: Client;
    address?: Address;
    defaultAddress?: boolean;
}

const FormAddress = ({
    countries,
    client,
    address,
    defaultAddress = false,
}: IFormProps) => {
    const { t } = useTranslation();
    const { handleSubmit, errors, control, setValue } =
        useForm<IPropsFormAddress>();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [isActivateButton,setIsActivateButton]=useState(true)
    let history = useHistory();

    const onSubmit = async (data: any, e: any) => {
        setIsActivateButton(false)
        setErrorApi(new ErrorFormData());
        const addressService = new AddressService();
        const clientService = new ClientService();
        try {
            setLoadingSubmit(true);
            setDisabledForm(true);
            if (address) {
                await addressService.put(address.id, data);
                toastr.success(t("success"), t("success_save_billing_address"));
            } else {
                await clientService.addAddress(client.id, data, defaultAddress);
                toastr.success(t("success"), t("success_add_billing_address"));
            }
            const clientId = client.parent_id ? client.parent_id : client.id;
            history.push(`/app/client/${clientId}?tab=adresses`);
        } catch (error) {
            setDisabledForm(false);
            setLoadingSubmit(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setIsActivateButton(true)
    };
    return (
        <>
            <form
                className="form-react-provider"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormSection title={t("form.identity")} className="d-block d-lg-flex mt-3">
                    <div className="row row-md">
                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <InputControlled
                                    name="name"
                                    label={t("form.address_name")}
                                    value={address ? address.name : ""}
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <InputControlled
                                    name="intention_of"
                                    label={t("form.intention_of")}
                                    value={address ? address.intention_of : ""}
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <AutocompleteControlled
                                    label={t("form.country")}
                                    name="country"
                                    disabled={disabledForm}
                                    listItems={countries ? countries : []}
                                    value={address ? address?.country : null}
                                    required={true}
                                    getOptionLabel={(option: any) => option.label}
                                    control={control}
                                    errors={errors}
                                    onChange={(e: any) => setValue("country_id", e.id)}
                                />
                            </div>
                        </div>
                    </div>
                </FormSection>
                <FormSection title={t("form.contact")}>
                    <div className="row row-md">
                        <div className="col-12 col-lg-6 col-xl-3">
                            <div className="form-group">
                                <InputControlled
                                    name="street"
                                    label={t("form.street")}
                                    value={address ? address.street : ""}
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-3">
                            <div className="form-group">
                                <InputControlled
                                    name="misc"
                                    label={t("form.misc")}
                                    value={address ? address.misc : ""}
                                    required={false}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-3">
                            <div className="form-group">
                                <InputControlled
                                    name="post_code"
                                    label={t("form.post_code")}
                                    value={address ? address.post_code : ""}
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-3">
                            <div className="form-group">
                                <InputControlled
                                    name="city"
                                    label={t("form.city")}
                                    value={address ? address.city : ""}
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                    </div>
                </FormSection>
                <FormSection
                    title={t("form.miscellaneous")}
                    className="divers"
                >
                    <div className="row row-md">
                        <div className="col">
                            <div className="form-group">
                                <InputControlled
                                    name="tva_number"
                                    label={t("form.tva_number")}
                                    value={address ? address.tva_number : ""}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                    </div>
                </FormSection>
                <SubmitComponent
                    loading={loadingSubmit}
                    text={t("form.validate")}
                    isActivate={isActivateButton}
                />

                <ErrorForm errorApi={errorApi} />
            </form>
        </>
    );
};

export default FormAddress;
