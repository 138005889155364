import axios, { AxiosResponse } from 'axios';
import { getBaseUrl, getParamsUrl } from './setting-service';
import { DataPaginate } from '../models/utils/data-paginate';
import { VehicleModel } from '../models/vehicle-model';

export default class ModelService{
    list(page: number | null = null, brandId: string | null = null, search?: string) {
        let paramsUrl = getParamsUrl([{key: 'page', value: page}, {key: 'brand_id', value: brandId}, {key: 'search', value: search}])
        
        return axios.get(`${getBaseUrl()}vehicle-models${paramsUrl}`)
            .then( (response:AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map( (brand:Object)=> new VehicleModel(brand));
                
                return new DataPaginate(res);
            });
    }

    all() {
        let paramsUrl = getParamsUrl([{key: 'filter', value: 'all'}])
        
        return axios.get(`${getBaseUrl()}vehicle-models${paramsUrl}`)
            .then( (response:AxiosResponse) => response.data.data.map( (model:Object)=> new VehicleModel(model)));
    }

    get(modelId: number) {
        return axios.get(`${getBaseUrl()}vehicle-models/${modelId}`)
            .then( (response: AxiosResponse) => {
                return new VehicleModel(response.data);
            } )
    }

    add(body: any, brandId: number) {
        return axios.post(`${getBaseUrl()}vehicle-brands/${brandId}/vehicle-models`, body)
            .then( (response:AxiosResponse) => new VehicleModel(response.data))
            .catch();
    }

    update(body: any, brandId: number, modelId: number) {
        return axios.put(`${getBaseUrl()}vehicle-brands/${brandId}/vehicle-models/${modelId}`, body)
            .then( (response:AxiosResponse) => new VehicleModel(response.data) )
            .catch();
    }

    delete(id:number) {
        return axios.delete(`${getBaseUrl()}vehicle-models/${id}`)
            .then()
            .catch();
    }
}