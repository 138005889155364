import { Deserializable } from "./interfaces/deserializable";

export class VehicleBrand implements Deserializable {
    public id: number;
    public name: string;
    public img_url: string;

    constructor(data:any){
        this.id = data.id;
        this.name = data.name
        this.img_url = data.img_url
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }
}