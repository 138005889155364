import React, { useCallback, useEffect, useState } from "react";
import { DataPaginate } from "../../../models/utils/data-paginate";
import { useTranslation } from "react-i18next";
import Pagination from "../../../components/pagination/pagination";
import VehicleService from "../../../services/vehicle-service";
import { Vehicle } from "../../../models/vehicle";
import {
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
} from "@material-ui/core";
import ItemVehicle from "./ItemVehicle";
import GlobalSearch from "../../../components/global-search/globalSearch";
import { VehicleFilters } from "../../../models/utils/filters/vehicles/vehicle-filters";
import FiltersVehicle from "./FiltersVehicle";
import { FiltersEnabled } from "../../../models/utils/filters/filter-enabled";
import Paper from "@material-ui/core/Paper";
import KpiContentSeparator from "../../../components/kpi-content-separator/KpiContentSeparator";
import "./ListVehicles.scss";
import HeadTableCell from "../../../components/table/HeadTableCell";
import { debounce } from "lodash";
import { FilterType } from "../../../components/filter-column/filterColumn";
import { useLocation } from "react-router-dom";

const ListVehicles: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const [dataPaginate, setDataPaginate] = useState(new DataPaginate());
    const [filters, setFilters] = useState<VehicleFilters>();
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(new Date());
    const query = new URLSearchParams(useLocation().search);
    const provider_name = query.get("providerName");

    const [filtersEnabled, setFiltersEnabled] = useState<FiltersEnabled>(
        new FiltersEnabled(
            provider_name
                ? [
                      {
                          key: "provider",
                          value: provider_name,
                          type: FilterType.Unique,
                      },
                  ]
                : []
        )
    );

    const fetchAllVehicles = async () => {
        if (!loading) setLoading(true);
        try {
            const vehicleService = new VehicleService();
            setDataPaginate(
                await vehicleService.all(currentPage, search, filtersEnabled)
            );
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const debounceQuery = useCallback(debounce(fetchAllVehicles, 500), [
        currentPage,
        search,
        filtersEnabled,
        date,
    ]);

    useEffect(() => {
        debounceQuery();
        return debounceQuery.cancel;
    }, [currentPage, search, filtersEnabled, date, debounceQuery]);

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const vehicleService = new VehicleService();
                setFilters(await vehicleService.getFilter());
            } catch (error) {
                setLoading(false);
            }
        };

        fetchFilters();
    }, []);

    const refresh = async () => {
        try {
            const vehicleService = new VehicleService();
            setDataPaginate(
                await vehicleService.all(currentPage, search, filtersEnabled)
            );
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("error", error);
        }
    };

    function onPageChange(newPage?: number) {
        if (newPage) {
            setLoading(true);
            setCurrentPage(newPage);
        }
    }

    return (
        <>
            <GlobalSearch
                onSearch={(value: string) => setSearch(value)}
                onResetFilter={() => setDate(new Date())}
            />
            <FiltersVehicle
                filtersEnabled={filtersEnabled}
                filters={filters}
                setFilters={(filters) => setFiltersEnabled(filters)}
                company_name={provider_name ? provider_name : undefined}
            />
            <KpiContentSeparator />
            {dataPaginate && dataPaginate.data?.length !== 0 && !loading ? (
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <HeadTableCell
                                        align="center"
                                        className="testTh"
                                    >
                                        {t("vehicle_types")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("brand")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("model")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("year")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("exterior")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("interior")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("number_of_seats")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("options")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("insurance_certificate")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("owner")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("licence_number")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataPaginate.data && dataPaginate.data.map(
                                    (vehicle: Vehicle, index: number) => (
                                        <ItemVehicle
                                            key={`table_vehicle_id_${vehicle.id}`}
                                            vehicle={vehicle}
                                            onDeleteSuccess={() => refresh()}
                                        />
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Pagination
                        dataPaginate={dataPaginate}
                        onPageChange={(page) => onPageChange(page)}
                    />
                </>
            ) : loading ? (
                <CircularProgress />
            ) : (
                <div>{t("no_items")}</div>
            )}
        </>
    );
};

export default ListVehicles;
