import { makeStyles } from "@material-ui/core/styles";
import Colors from "./Colors";

const UseStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    blueGradient: {
        backgroundImage: "linear-gradient(to bottom, #1482EF 0%, #0065C9 100%)",
    },
    orangeGradient: {
        backgroundImage: "linear-gradient(to bottom, #FF8F63 0%, #FF6F37 100%)",
    },
    pinkGradient: {
        backgroundImage: "linear-gradient(to bottom, #FC356B 0%, #FE6D6D 100%)",
    },
    yellowGradient: {
        backgroundImage: "linear-gradient(to bottom, #FFDD26 0%, #FFBC31 100%)",
    },
    cyanGradient: {
        backgroundImage: "linear-gradient(to bottom, #31D0FF 0%, #07A9D9 100%)",
    },
    greenGradient: {
        backgroundImage: "linear-gradient(to bottom, #4DFF31 0%, #20D907 100%)",
    },
    blueBg: {
        backgroundColor: Colors.blue,
    },
    greyBg: {
        backgroundColor: Colors.grey,
    },
    greyBgLight: {
        backgroundColor: Colors.greyClear
    },
    blueBgLight: {
        backgroundColor: Colors.blueLight,
    },
    blueTextColor: {
        color: "#1757FB",
    },
    cyanBg: {
        backgroundColor: Colors.cyan,
    },
    redBg: {
        backgroundColor: Colors.red,
    },
    orangeBg: {
        backgroundColor: Colors.orange,
    },
    yellowBg: {
        backgroundColor: Colors.yellow,
    },
    greenBg: {
        backgroundColor: Colors.green,
    },
    greenLightBg: {
        backgroundColor: Colors.greenLight,
    },
    mintBg: {
        backgroundColor: Colors.mint,
    },
}));

export default UseStyles;
