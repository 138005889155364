import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import {DataPaginate} from "../../../models/utils/data-paginate";
import TicketService from "../../../services/ticket-service";
import {
    CircularProgress,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import HeadTableCell from "../../../components/table/HeadTableCell";
import Pagination from "../../../components/pagination/pagination";
import {Ticket} from "../../../models/ticket";
import ItemTicket from "./ItemTicket";
import GlobalSearch from "../../../components/global-search/globalSearch";
import {FiltersEnabled} from "../../../models/utils/filters/filter-enabled";
import {TicketFilters} from "../../../models/utils/filters/tickets/ticket-filters";
import FiltersTickets from "./FiltersTickets";
import {ErrorForm} from "../../../components/ErrorForm";
import {ErrorFormData} from "../../../models/interfaces/errorForm";
import {debounce} from "lodash";

const ListTickets = () => {
    const {t} = useTranslation();
    const [dataPaginate, setDataPaginate] = useState(new DataPaginate());
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState<string>();
    const [filters, setFilters] = useState<TicketFilters>();
    const [filtersEnabled, setFiltersEnabled] = useState<FiltersEnabled>(new FiltersEnabled());
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [date, setDate] = useState(new Date());

    const fetchTickets = async () => {
        if (!loading) setLoading(true);
        try {
            const ticketService = new TicketService();
            setDataPaginate(
                await ticketService.all(currentPage, search, filtersEnabled)
            );
        } catch (error) {
            console.log(error);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setLoading(false);
    };

    const debounceQuery = useCallback(
        debounce(fetchTickets, 500),
        [currentPage, search, filtersEnabled, date]
    );

    useEffect(() => {
        // Fetch list of tickets with search and filters
        debounceQuery();
        return debounceQuery.cancel;
    }, [currentPage, search, filtersEnabled, date, debounceQuery]);

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const ticketService = new TicketService();
                setFilters(await ticketService.getFilter());
            } catch (e) {
                console.log(e);
            }
        };
        fetchFilters();
    }, [])

    // Allow to change de state of the current page
    function onPageChange(newPage?: number) {
        if (newPage) {
            setLoading(true);
            setCurrentPage(newPage);
        }
    }

    function handleFiltersChange(filters: FiltersEnabled) {
        setFiltersEnabled(filters);
        setCurrentPage(1);
    }

    return (
        <>
            <GlobalSearch
                onSearch={(value: string) => {
                    setSearch(value);
                }}
                onResetFilter={() => setDate(new Date())}
            />
            <FiltersTickets
                filtersEnabled={filtersEnabled}
                filters={filters}
                setFilters={(filters) => handleFiltersChange(filters)}
            />
            {dataPaginate?.data && dataPaginate.data.length !== 0 ? (
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <HeadTableCell>
                                        {t("categories")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("technical_problem")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("email")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("problem_date")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("states")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataPaginate.data
                                .map((ticket: Ticket) => (
                                    <ItemTicket
                                        key={ticket.id}
                                        ticket={ticket}
                                        onDeleteSuccess={() => fetchTickets()}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        dataPaginate={dataPaginate}
                        onPageChange={(page) => onPageChange(page)}
                    />
                </>
            ) : loading ? (
                <CircularProgress/>
            ) : (
                <div>{t("no_items")}</div>
            )}
            <ErrorForm errorApi={errorApi}/>
        </>
    );
};

export default ListTickets;
