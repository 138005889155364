import {connect} from "react-redux";
import {User} from "../../models/user";
import {useTranslation} from "react-i18next";
import ListTickets from "./list-tickets/ListTickets";
import React from "react";
import ContentSection from "../../components/content-section/ContentSection";
import {ExportType} from "../../components/content-section/export-action/ExportAction";
import KpiTicket from "./kpi-ticket/KpiTicket";

interface IPropsSupportPage {
    user: User;
}

function SupportPage({user}: IPropsSupportPage) {
    const {t} = useTranslation();

    // Return the appropriate content of the page
    return (
        <>
            <ContentSection
                title={t("support")}
                exportType={ExportType.Ticket}
                addAction={user.role !== 'admin' ? 'support' : ''}
            >
                <KpiTicket/>
                <ListTickets/>
            </ContentSection>
        </>
    )
}

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(SupportPage);