import { Checkbox } from "@material-ui/core";
import React from "react";
import BodyTableCell from "../../../components/table/BodyTableCell";
import BodyTableRow from "../../../components/table/BodyTableRow";
import { NotificationUser } from "../../../models/notificationUser";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as notificationHelper from "../../../utils/NotificationHelper";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

interface IPropsItem {
    notif: NotificationUser;
    checkboxs: any;
    handleCheckbox: (e: any) => void;
}

const ItemTableNotification = ({
    notif,
    checkboxs,
    handleCheckbox,
}: IPropsItem) => {
    const notifId = notif.id.toString();
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <BodyTableRow>
            <BodyTableCell align="center">
                <Checkbox
                    id={notifId}
                    checked={
                        checkboxs.selections.includes(notifId) ? true : false
                    }
                    onChange={(e) => handleCheckbox(e)}
                />
            </BodyTableCell>
            <BodyTableCell>
                <div className="upload-image-table">{notif.content}</div>
            </BodyTableCell>
            <BodyTableCell align="center">
                {notif.created_at.toLocaleDateString()}
            </BodyTableCell>
            <BodyTableCell align="center">
                {notif.created_at.toLocaleTimeString()}
            </BodyTableCell>
            <BodyTableCell align="center">
                <VisibilityIcon
                    onClick={() => notificationHelper.showNotification(notif, dispatch, history)}
                    style={{ fontSize: 20, cursor: "pointer" }}
                />
            </BodyTableCell>
        </BodyTableRow>
    );
};

export default ItemTableNotification;
