import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import KpiVehicles from "./kpi-vehicles/KpiVehicles";
import ContentSection from "../../components/content-section/ContentSection";
import ListVehicles from "./list-vehicles/ListVehicles";
import { ErrorFormData } from "../../models/interfaces/errorForm";
import { ErrorForm } from "../../components/ErrorForm";
import VehicleService from "../../services/vehicle-service";
import WhiteButton from "../../components/white-button/WhiteButton";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { User } from "../../models/user";

interface IPropsVehiclesPage {
    user: User;
}

const VehiclesPage: React.FunctionComponent<IPropsVehiclesPage> = ({
    user,
}) => {
    const { t } = useTranslation();
    const [dataKpis, setDataKpis] = useState<any>({
        Berline: "-",
        Bus: "-",
        Minibus: "",
        Limousine: "",
        Minivan: "",
        popular: "-",
    });
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const query = new URLSearchParams(useLocation().search);
    const providerId = query.get("providerId");
    const history = useHistory();

    const fetchKpis = async () => {
        try {
            const vehicleService = new VehicleService();
            setDataKpis(await vehicleService.getKpis(providerId));
        } catch (e) {
            setErrorApi(new ErrorFormData(e.response.data));
        }
    };

    const renderButtonAdd = (label: string, url: string) => {
        return user.isAdmin() && providerId ? (
            <WhiteButton
                className="btn-rounded"
                variant="contained"
                color="primary"
                onClick={() => history.push(url)}
            >
                {label}
            </WhiteButton>
        ) : (
            <></>
        );
    };

    useEffect(() => {
        fetchKpis();
    }, []);

    return (
        <>
            <ContentSection
                title={t("nav.vehicles")}
                addAction={"vehicle"}
                suppContent={renderButtonAdd(
                    t("add_vehicle"),
                    `/app/providers/${providerId}/vehicles/add`
                )}
            >
                <KpiVehicles
                    nbCars={
                        dataKpis.Berline + dataKpis.Limousine + dataKpis.Minivan
                    }
                    nbBus={dataKpis.Bus + dataKpis.Minibus}
                    nbMoto={dataKpis.Moto}
                    popular={dataKpis.Popular}
                />
                <ListVehicles />
            </ContentSection>
            <ErrorForm errorApi={errorApi} />
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(VehiclesPage);
