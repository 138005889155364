import axios, { AxiosResponse } from "axios";
import { Division } from "../models/client";
import {getBaseUrl, getParamsUrl} from "./setting-service";
import {DataPaginate} from "../models/utils/data-paginate";

export default class DivisionService {
    list(filter?: string | null, page?: number | null, search?: string | null) {
        let paramsUrl = getParamsUrl([
            { key: "page", value: page },
            { key: "search", value: search },
            { key: "filter", value: filter },
        ]);

        return axios
            .get(`${getBaseUrl()}divisions${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map((division: Object) => new Division(division));
                return new DataPaginate(res);
            });
    }

    all() {
        let paramsUrl = getParamsUrl([{ key: "filter", value: "all" }]);

        return axios
            .get(`${getBaseUrl()}divisions${paramsUrl}`)
            .then((response: AxiosResponse) =>
                response.data.data.map((division: Object) => new Division(division))
            )
    }

    add(body: any) {
        return axios
            .post(`${getBaseUrl()}divisions`, body)
            .then((response: AxiosResponse) => new Division(response.data))
            .catch();
    }

    update(body: any, id: number) {
        return axios
            .put(`${getBaseUrl()}divisions/${id}`, body)
            .then((response: AxiosResponse) => new Division(response.data))
            .catch();
    }

    delete(id: number) {
        return axios.delete(`${getBaseUrl()}divisions/${id}`).then().catch();
    }
}
