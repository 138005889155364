import axios, { AxiosResponse } from "axios";
import { User } from "../models/user";
import { getBaseUrl, getParamsUrl } from "./setting-service";
import { DataPaginate } from "../models/utils/data-paginate";

export default class AdministratorService {
    getAdministrators(
        page: number | null = null,
        search: string | null = null,
        limit?: string | null
    ) {
        let paramsUrl = getParamsUrl([
            { key: "page", value: page },
            { key: "search", value: search },
            { key: "limit", value: limit },
        ]);

        return axios
            .get(`${getBaseUrl()}administrators${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map((user: Object) => new User(user));

                return new DataPaginate(res);
            });
    }

    deleteUser(id: number) {
        return axios.delete(`${getBaseUrl()}users/${id}`);
    }
}
