import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BodyTableRow from "../../../components/table/BodyTableRow";
import BodyTableCell from "../../../components/table/BodyTableCell";
import { format } from "../../../utils/AmountHelper";
import { connect } from "react-redux";
import { Credit } from "../../../models/credit";
import ModalPdf from "../../../components/previouwing/modal-pdf/ModalPdf";
import { documentType } from "../../../models/utils/document-type";
import { loadPdf } from "../../../utils/PdfReaderHelper";

interface ItemProps {
    credit: Credit;
}

const ItemCredit: React.FunctionComponent<ItemProps> = ({ credit }) => {
    const { t } = useTranslation();

    const [pdf, setPdf] = useState<any>();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openPdf = async () => {
        if (credit) {
            if (credit.pdf) {
                let fileURL = await loadPdf(credit);
                setPdf(fileURL);
                setIsOpen(true);
            }
        }
    };

    return (
        <>
            <BodyTableRow key={credit.id}>
                <BodyTableCell className="img-index-driver ">
                    {credit.code}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {credit.folder?.client && credit.folder.client.company_name}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {format(credit.value)}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {credit.closed ? t("closed") : t("opened")}
                </BodyTableCell>
                {/*<BodyTableCell align="center">
                    {!credit.invoice_id ? format(credit.amount) : ''}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {!credit.invoice_id ? credit.expiration?.toLocaleDateString() : ''}
                    </BodyTableCell>*/}
                <BodyTableCell align="left">{credit.comment}</BodyTableCell>
                {/*<BodyTableCell align="left">
                    {credit.pdf &&
                        <Button
                            className="pdf preview-button"
                            style={{ minWidth: "25px", backgroundColor: "#ccc" }}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => openPdf()}
                        >
                            <span>{t("pdf")}</span>
                        </Button>
                    }
                </BodyTableCell>*/}
            </BodyTableRow>

            {pdf && isOpen && credit && (
                <ModalPdf
                    closeDialog={() => setIsOpen(false)}
                    pdf={pdf}
                    isOpen={isOpen}
                    code={credit.code}
                    type={documentType.INVOICE}
                />
            )}
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(ItemCredit);
