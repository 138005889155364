import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { documentType } from "../../../models/utils/document-type";
import { LineEstimate } from "../../../models/utils/line-estimate/line-estimate";
import { format } from "../../../utils/AmountHelper";
import ArrayHelper from "../../../utils/ArrayHelper";
import FormEdit from "./form-edit/FormEdit";

import "./Recap.scss";

interface IProps {
    lines: LineEstimate[];
    onEdit?: boolean;
    plus?: any;
    setPlus?: (e: any) => void;
    type?: documentType;
    alreadyPaid?: number;
}
export default function Recap({
    lines,
    onEdit,
    plus,
    setPlus,
    type = documentType.QUOTE,
    alreadyPaid = 0,
}: IProps) {
    const { t } = useTranslation();

    const [ht, setHt] = useState(0);
    const [total, setTotal] = useState(0);
    const [totalTTC, setTotalTCC] = useState(0);
    const [itemsTva, setItemsTva] = useState<any[]>([]);

    const [deposit, setDeposit] = useState(0);
    const [reduction, setReduction] = useState(0);

    useEffect(() => {
        let totalHt = 0;
        let allTvas: any[] = [];
        if (lines && lines.length) {
            lines.forEach((line) => {
                if (line.isDetail === false && line.sellingPrice) {
                    totalHt += line.sellingPrice;
                    allTvas = [
                        ...allTvas,
                        { rateTva: line.rateTva, amount: line.sellingPrice },
                    ];
                }
            });
            const ArrayGroupedTva = ArrayHelper.groupBy(allTvas, "rateTva");
            const allTvasGrouped = Object.entries(ArrayGroupedTva)
                .map((groupTva: any[]) => {
                    return {
                        rateTva: groupTva[0],
                        amount: groupTva[1]
                            .map((itemTva: any) => Number(itemTva.amount))
                            .reduce(
                                (acc: number, current: number) => acc + current
                            ),
                        tva: groupTva[1]
                            .map(
                                (itemTva: any) =>
                                    itemTva.amount * (itemTva.rateTva / 100)
                            )
                            .reduce(
                                (acc: number, current: number) => acc + current
                            ),
                    };
                })
                .flat();
            setItemsTva(allTvasGrouped);
            const totalTva = allTvasGrouped.length
                ? allTvasGrouped
                      .map((itemTva) => (itemTva.tva ? Number(itemTva.tva) : 0))
                      .reduce((acc: number, current: number) => acc + current)
                : 0;
            setHt(totalHt);
            setTotal(totalHt + totalTva);
        }
    }, [lines, plus]);

    useEffect(() => {
        let updatedReduction = plus
            ? getAmountOrPourcentage(plus.reduction, total)
            : 0;
        let updatedDeposit = plus
            ? getAmountOrPourcentage(plus.deposit, total - updatedReduction)
            : 0;

        setReduction(updatedReduction);
        setDeposit(updatedDeposit);
        setTotalTCC(total - updatedReduction - updatedDeposit - alreadyPaid);
    }, [total, plus, alreadyPaid]);

    function getAmountOrPourcentage(item: any, total: number): number {
        if (total > 0) {
            if (item.currency === "money") {
                return item.amount;
            }
            return (item.amount * total) / 100;
        }

        return 0;
    }

    return (
        <>
            <div className="recap-container">
                <div className="recap-content">
                    <div className="ht_amount line-recap">
                        <div className="label-section">
                            <span className="label">{t("ht_amount")}</span>
                        </div>
                        <span className="value">{format(ht)}</span>
                    </div>
                    {itemsTva.map((itemTva) => {
                        return (
                            <div
                                key={`item_tva_${itemTva.rateTva}`}
                                className="tva line-recap"
                            >
                                <div className="label-section">
                                    <span className="label">
                                        {t("form.tva")} ({itemTva.rateTva}%)
                                    </span>
                                </div>
                                <span className="value">
                                    {format(itemTva.tva)}
                                </span>
                            </div>
                        );
                    })}

                    <div className="total line-recap">
                        <div className="label-section">
                            <span className="label">{t("total")}</span>
                        </div>
                        <span className="value">{format(total)}</span>
                    </div>
                    {setPlus && (
                        <FormEdit
                            isEditing={onEdit}
                            setPlus={(e: any) => setPlus(e)}
                            plus={plus}
                            deposit={deposit}
                            reduction={reduction}
                            type={type}
                        />
                    )}
                    <div className="ttc_total line-recap">
                        <div className="label-section">
                            <span className="label">{t("amount_to_pay")}</span>
                        </div>
                        <span className="value">{format(totalTTC)}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
