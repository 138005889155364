import React from "react";
import "./DriverNote.scss";
import { useTranslation } from "react-i18next";
import { Driver } from "../../../models/driver";

interface IPopupDriver {
    driver: Driver;
}

const DriverNote = ({ driver }: IPopupDriver) => {
    const { t } = useTranslation();
    return (
        <div className="driver_note_popup">
            <div className="top-info section">
                <div className="info_part">
                    <span className="label">{t("note")}</span>
                    <span>{driver.notes ? driver.notes : "-"}</span>
                </div>
            </div>
        </div>
    );
};

export default DriverNote;
