import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../../../components/form-title/FormTitle";
import { useForm } from "react-hook-form";
import { ErrorFormData } from "../../../../models/interfaces/errorForm";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SubmitComponent from "../../../../components/submit-component/SubmitComponent";
import { ErrorForm } from "../../../../components/ErrorForm";
import { Client, Division } from "../../../../models/client";
import { Address } from "../../../../models/address";
import ClientTypeService from "../../../../services/client-type-service";
import { ClientType } from "../../../../models/client-type";
import AccountService from "../../../../services/account-service";
import AutocompleteControlled from "../../../../components/fields-controlled/AutocompleteControlled";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import ClientService from "../../../../services/client-service";
import { DataPaginate } from "../../../../models/utils/data-paginate";
import DivisionService from "../../../../services/division-service";
import Switch from "@material-ui/core/Switch";

interface IPropsFormClient {
    company_name: string;
    client_type: string;
    client_type_id: number;
    referent_name: string;
    email: string;
    phone_number: string;
    mobile_number: string;
    address: Address;
    division: string;
    division_id: number;
    is_tender: boolean;
}

interface IFormProps {
    clientId: number;
    account?: Client;
}

const FormAccount: React.FunctionComponent<IFormProps> = ({
    clientId,
    account,
}: IFormProps) => {
    const { t } = useTranslation();
    const { register, handleSubmit, errors, control } =
        useForm<IPropsFormClient>();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState(false);
    const [typeList, setTypeList] = useState<ClientType[]>();
    const [adressPaginate, setAdressPaginate] = useState(new DataPaginate());
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [switchs, setSwitchs] = useState<any>({
        is_tender: !!account?.is_tender || false,
    });
    const [isActivateButton,setIsActivateButton]=useState(true)
    // const [isTender, setIsTender] = useState<any>(
    //     account?.is_tender ? account?.is_tender : false
    // );
    let history = useHistory();

    useEffect(() => {
        const getTypes = async () => {
            try {
                const dataService = new ClientTypeService();
                setTypeList(await dataService.all());
            } catch (error) {
                console.log("error", error);
            }
        };
        getTypes();
    }, []);

    useEffect(() => {
        const getAdresses = async () => {
            if (clientId) {
                try {
                    const clientService = new ClientService();
                    setAdressPaginate(await clientService.addresses(clientId));
                } catch (error) {
                    console.log("error", error);
                }
            }
        };
        getAdresses();
    }, [clientId]);

    useEffect(() => {
        const getDivisions = async () => {
            if (clientId) {
                try {
                    const divisionService = new DivisionService();
                    setDivisions(await divisionService.all());
                } catch (error) {
                    console.log("error", error);
                }
            }
        };
        getDivisions();
    }, [clientId]);
    const onChangeSwitchs = (event: any) => {
        const { name, checked } = event.currentTarget;
        // if (name === "is_tender") {
        //     setIsTender(checked);
        // }

        setSwitchs({ ...switchs, [name]: checked });
    };

    const onSubmit = async (data: any, e: any) => {
        setErrorApi(new ErrorFormData());
        setIsActivateButton(false)
        const service = new AccountService(clientId);
        try {
            setDisabledForm(true);
            if (account) {
                await service.update(account.id, data);
                toastr.success(t("success"), t("success_add_sub_account"));
                history.push("/app/client/" + clientId);
            } else {
                await service.add(data);
                toastr.success(t("success"), t("success_save_sub_account"));
                history.push("/app/client/" + clientId);
            }
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setIsActivateButton(true)
    };

    return (
        <>
            <form
                className="form-react-provider"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormTitle title={t("form.identity")} />
                <div className="provider-form-part">
                    <div className="field-form mgl2">
                        <InputControlled
                            name="company_name"
                            label={t("form.company_name")}
                            value={account?.company_name}
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>
                    <div className="field-form mgl2">
                        <AutocompleteControlled
                            label={t("form.company_type")}
                            name="client_type"
                            disabled={disabledForm}
                            listItems={typeList ? typeList : []}
                            value={account?.client_type}
                            required={true}
                            getOptionLabel={(option: any) => option.label}
                            control={control}
                            errors={errors}
                        />
                    </div>
                    <div className="field-form  mgl2">
                        <InputControlled
                            name="referent_name"
                            label={t("form.referent_name")}
                            value={account?.referent_name}
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>
                </div>
                <FormTitle title={t("form.contact")} />
                <div className="provider-form-part">
                    <div className="field-form mgl2">
                        <InputControlled
                            name="phone_number"
                            label={t("form.phone_number")}
                            value={account?.phone_number}
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>
                    <div className="field-form mgl2">
                        <InputControlled
                            name="mobile_number"
                            label={t("form.mobile_number")}
                            value={account?.mobile_number}
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>
                    <div className="field-form mgl2 client-mail col-xl-4">
                        <InputControlled
                            name="email"
                            label={t("form.email")}
                            value={account?.email}
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>

                    {adressPaginate && adressPaginate.data && (
                        <div className="field-form mgl4">
                            <AutocompleteControlled
                                label={t("form.billing_adress")}
                                name="address"
                                disabled={disabledForm}
                                listItems={adressPaginate.data}
                                value={
                                    account?.address ? account.address : null
                                }
                                getOptionLabel={(option: any) =>
                                    option.toString()
                                }
                                control={control}
                                errors={errors}
                            />
                        </div>
                    )}
                </div>
                <FormTitle title={t("form.miscellaneous")} />
                <div className="provider-form-part mgt1">
                    <div className="field-form mgl2">
                        <AutocompleteControlled
                            label={t("form.division")}
                            name="division"
                            disabled={disabledForm}
                            listItems={divisions ? divisions : []}
                            value={account?.division}
                            required={true}
                            getOptionLabel={(option: any) => option.label}
                            control={control}
                            errors={errors}
                        />
                    </div>

                    <div className="field-form mgl4 provider-is-tender switchs mgr4 col-4">
                        <FormControlLabel
                            className="switch-label-top mx-0"
                            control={
                                <Switch
                                    inputRef={register}
                                    name="is_tender"
                                    className="switch-ios"
                                    onChange={onChangeSwitchs}
                                    checked={switchs.is_tender}
                                />
                            }
                            label={t("form.is_tender")}
                            labelPlacement="top"
                        />
                        {errors.is_tender && <p>{errors.is_tender.message}</p>}
                    </div>
                </div>
                <SubmitComponent text={t("form.validate")} isActivate={isActivateButton} />

                <ErrorForm errorApi={errorApi} />
            </form>
        </>
    );
};

export default FormAccount;
