import React from "react";
import {useTranslation} from "react-i18next";
import {FilterType,} from "../../../components/filter-column/filterColumn";
import {FiltersEnabled} from "../../../models/utils/filters/filter-enabled";
import {TextField} from "@material-ui/core";

interface IPropsFiltersTransfers {
    filtersEnabled?: FiltersEnabled;
    setFilters: (filters: FiltersEnabled) => void;
}

const FitlersTransfers = ({
                              filtersEnabled,
                              setFilters,
                          }: IPropsFiltersTransfers) => {
    const {t} = useTranslation();
    const styles = {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row",
    } as React.CSSProperties;

    const changeValue = (name: string, value: any, type: FilterType) => {
        if (filtersEnabled) {
            const newFiltersEnabled = filtersEnabled.addFilter(
                name,
                value,
                type
            );
            setFilters(new FiltersEnabled(newFiltersEnabled));
        }
    };
    return (
        <div className="filter-std-row" style={styles}>
            <>
                <TextField
                    onChange={(e) =>
                        changeValue("ref", e.currentTarget.value, FilterType.Unique)
                    }
                    type="text"
                    placeholder={t('Ref')}
                    className="shadow-form-control"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    onChange={(e) =>
                        changeValue("nbPassenger", e.currentTarget.value, FilterType.Unique)
                    }
                    type="number"
                    placeholder={t('nb_passengers')}
                    className="shadow-form-control"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    onChange={(e) =>
                        changeValue("driver", e.currentTarget.value, FilterType.Unique)
                    }
                    type="text"
                    placeholder={t('drivers')}
                    className="shadow-form-control"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    onChange={(e) =>
                        changeValue("departure_date", e.currentTarget.value, FilterType.Unique)
                    }
                    id="date"
                    label={t('departure_date')}
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className="shadow-form-control-date"
                />
                <TextField
                    onChange={(e) => {
                        changeValue("arrival_date", e.currentTarget.value, FilterType.Unique)
                    }}
                    id="date"
                    label={t('arrival_date')}
                    type="date"
                    className="shadow-form-control-date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{width: "10rem", marginRight: "1.5rem"}}
                />
                <TextField
                    onChange={(e) =>
                        changeValue("departure_location", e.currentTarget.value, FilterType.Unique)
                    }
                    type="text"
                    placeholder={t('departure_location')}
                    className="shadow-form-control"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    onChange={(e) =>
                        changeValue("arrival_location", e.currentTarget.value, FilterType.Unique)
                    }
                    placeholder={t('arrival_location')}
                    type="text"
                    className="shadow-form-control"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </>
        </div>
    );
};

export default FitlersTransfers;
