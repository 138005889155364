import axios, {AxiosResponse} from 'axios';
import {getBaseUrl, getParamsUrl} from './setting-service';
import {VehicleColor} from '../models/vehicle-color';
import {ExteriorColor} from '../models/exterior-color';
import {InteriorColor} from '../models/interior-color';
import {DataPaginate} from "../models/utils/data-paginate";

export default class VehicleColorService {
    readonly INTERIOR_TYPE = 0;
    readonly EXTERIOR_TYPE = 1;

    list(filter?: string | null, page?: number | null, search?: string | null) {
        let paramsUrl = getParamsUrl([
            {key: "page", value: page},
            {key: "search", value: search},
            {key: "filter", value: filter},
        ]);

        return axios
            .get(`${getBaseUrl()}vehicle-colors${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map((color: Object) => new VehicleColor(color));
                return new DataPaginate(res);
            });
    }

    all() {
        let paramsUrl = getParamsUrl([{key: "filter", value: "all"}]);

        return axios.get(`${getBaseUrl()}vehicle-colors${paramsUrl}`)
            .then((response: AxiosResponse) => response.data.data.map((vehicleColor: Object) => new VehicleColor(vehicleColor)));
    }

    getInteriorColors(list: VehicleColor[]) {
        const exteriorColors = list.filter(VehicleColor => VehicleColor.type === this.INTERIOR_TYPE);

        return exteriorColors.map(exteriorColor => new ExteriorColor(exteriorColor));
    }

    getExteriorColors(list: VehicleColor[]) {
        const interiorColors = list.filter(VehicleColor => VehicleColor.type === this.EXTERIOR_TYPE)

        return interiorColors.map(interiorColor => new InteriorColor(interiorColor));
    }

    add(body: any) {
        return axios
            .post(`${getBaseUrl()}vehicle-colors`, body)
            .then((response: AxiosResponse) => new VehicleColor(response.data))
            .catch();
    }

    update(body: any, id: number) {
        return axios
            .put(`${getBaseUrl()}vehicle-colors/${id}`, body)
            .then((response: AxiosResponse) => new VehicleColor(response.data))
            .catch();
    }

    delete(id: number) {
        return axios.delete(`${getBaseUrl()}vehicle-colors/${id}`).then().catch();
    }
}
