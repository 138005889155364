import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const WhiteButton = withStyles((theme) => ({
    root: {
        color: '#707070',
        backgroundColor: '#fff',

        '&:hover': {
            backgroundColor: '#707070',
            color: '#fff',
        },
    },
}))(Button);

export default WhiteButton;