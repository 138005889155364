import { User } from "../../../models/user";

export interface AuthState {
    loading: boolean,
    access_token: string | null;
    token_type: string | null;
    expires_in: number | null;
    error: string | null;
    errors: string | null;
    user : User | null;
    is_authentificated: boolean | null
}

export interface LoginSuccess{
  access_token: string;
  token_type: string;
  expires_in: number;
}

export interface LoginError{
  error: string,
  errors: Array<String>
}

export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_SUCESS_USER = 'LOGIN_SUCCESS_USER'
export const LOGIN_ERROR_USER = 'LOGIN_ERROR_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const GET_USER_FETCH_ACTION = 'GET_USER_FETCH_ACTION'
export const GET_USER_SUCCESS_ACTION = 'GET_USER_SUCCESS_ACTION'
export const GET_USER_FAIL_ACTION = 'GET_USER_FAIL_ACTION'

interface LoginAction {
  type: typeof LOGIN_USER
}

interface LoginSuccessAction {
  type: typeof LOGIN_SUCESS_USER,
  payload: LoginSuccess
}

interface LoginErrorAction {
  type: typeof LOGIN_ERROR_USER,
  payload: LoginError
}

interface LogoutAction {
  type: typeof LOGOUT_USER
}

interface GetUserFetchAction {
  type: typeof GET_USER_FETCH_ACTION
}

interface GetUserSuccessAction {
  type: typeof GET_USER_SUCCESS_ACTION,
  payload: User
}

interface GetUserFailAction {
  type: typeof GET_USER_FAIL_ACTION,
  payload: any
}

export type AuthActionTypes = LoginAction | LoginSuccessAction | LoginErrorAction | LogoutAction
                            |  GetUserFetchAction | GetUserSuccessAction | GetUserFailAction