import { Deserializable } from "./interfaces/deserializable";

export class Language implements Deserializable {
    public id: number;
    public label: string;
    public iso_code: string;
    public logo_key: string;
    public created_at: Date;
    public updated_at: Date;

    constructor(data:any) {
        this.id = data.id;
        this.label = data.label;
        this.iso_code = data.iso_code;
        this.logo_key = data.logo_key;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}