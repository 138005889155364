import axios, { AxiosResponse } from "axios";
import { getBaseUrl } from "./setting-service";
import { Language } from "../models/language";

export default class LanguageService {
    all() {
        return axios
            .get(`${getBaseUrl()}languages`)
            .then((response: AxiosResponse) => {
                console.log(response.data)
                let res = response.data.map(
                    (language: Object) => new Language(language)
                    
                );
                return res;
            });
    }

    add(body:any){
        return axios
        .post(`${getBaseUrl()}languages`,body)
        .then((response: AxiosResponse) => new Language(response.data))
      }

    delete(id: number) {
        return axios.delete(`${getBaseUrl()}languages/${id}`).then();
    }
}
