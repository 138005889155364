import i18next from "i18next";
import { FilterType } from "../components/filter-column/filterColumn";

export function getBaseUrl(){
    return process.env.REACT_APP_API_URL+'api/v1/'+getLocale()+'/coordinators/'+getPrefixCoordinator()+'/';
}

interface ParamUrl {
    key: string,
    value: any,
    type?: FilterType
}

export function getParamsUrl(params: Array<ParamUrl>){
    const paramsFilled = params.filter( value => value.value != null && value.value !== "");
    const arrayParams = paramsFilled.map( paramUrl => `${paramUrl.key}${paramUrl.type === FilterType.Multiple ? '[]': ''}=${paramUrl.value}`);
    const nbParamsFilled = arrayParams.length;
    let paramsUrl = nbParamsFilled === 1 ? arrayParams[0] + '&' : arrayParams.join('&');
    paramsUrl = nbParamsFilled <= 1 ? paramsUrl.substring(0, paramsUrl.length - 1) : paramsUrl

    return paramsUrl !== '' ? `?${paramsUrl}` : '';
}

export function getLocale() {
    let i18nLang =  i18next.language;
    let userLang = navigator.language; 
    if(i18nLang){
        return i18nLang.substring(0, 2);
    } else if(userLang){
        return userLang.substring(0, 2);
    }

    return 'en';
}

function getPrefixCoordinator() {
    return 'sparklanes';
}


