import { AuthActionTypes, LOGIN_USER, LOGOUT_USER, LOGIN_SUCESS_USER, LoginSuccess, LoginError, LOGIN_ERROR_USER, GET_USER_FETCH_ACTION, GET_USER_SUCCESS_ACTION, GET_USER_FAIL_ACTION } from "./types";
import { Dispatch } from "redux";
import AuthService from "../../../services/auth-service";

export function login(): AuthActionTypes{
    return {
        type: LOGIN_USER
    }
}

export function loginSuccess(payload: LoginSuccess): AuthActionTypes{
    return {
        type: LOGIN_SUCESS_USER,
        payload: payload
    }
}

export function loginError(payload: LoginError): AuthActionTypes{
    return {
        type: LOGIN_ERROR_USER,
        payload: payload
    }
}

export function logout(): AuthActionTypes{
    return {
        type: LOGOUT_USER,
    }
}

export const getUser = () => {
    return async (dispatch: Dispatch) => {
        dispatch({ type: GET_USER_FETCH_ACTION})
        const authService = new AuthService();
        try{
            const user = await authService.getUser();
            dispatch({ type: GET_USER_SUCCESS_ACTION, payload: user })

        } catch(error){
            dispatch({ type: GET_USER_FAIL_ACTION})
        }
    }
}