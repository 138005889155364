import axios, {AxiosResponse} from "axios";
import {NotificationUser} from "../models/notificationUser";
import {getBaseUrl, getParamsUrl} from "./setting-service";
import {FiltersEnabled} from "../models/utils/filters/filter-enabled";
import {DataPaginate} from "../models/utils/data-paginate";

export default class NotificationService {
    // Get notifications paginated with the key paginate that helps api to understand that datas are paginated.
    // We use the same method on the api for paginated data and not paginated data.
    getAll(
        page?: number | null,
        search?: string | null,
        filter?: string | null,
        filters?: FiltersEnabled | null,
    ) {
        let paramsUrl = "";

        if (filters)
            paramsUrl = getParamsUrl([
                {key: "page", value: page},
                {key: "search", value: search},
                {key: "filter", value: filter},
                {key: "paginate", value: 1},
                ...filters.list,
            ]);
        else {
            paramsUrl = getParamsUrl([
                {key: "page", value: page},
                {key: "search", value: search},
                {key: "filter", value: filter},
                {key: "paginate", value: 1},
            ]);
        }
        return axios
            .get(`${getBaseUrl()}notifications${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map((notification: Object) => new NotificationUser(notification));

                return new DataPaginate(res);
            });
    }

    // List of notifications without pagination
    list(
        filter?: string | null,
        filters?: FiltersEnabled | null,
    ) {
        let paramsUrl = "";

        if (filters)
            paramsUrl = getParamsUrl([
                {key: "filter", value: filter},
                ...filters.list,
            ]);
        else {
            paramsUrl = getParamsUrl([
                {key: "filter", value: filter},
            ]);
        }
        return axios
            .get(`${getBaseUrl()}notifications${paramsUrl}`)
            .then(
                (response: AxiosResponse) => response.data.map((notif: Object) => new NotificationUser(notif))
            );
    }

    getCount() {
        return axios
            .get(`${getBaseUrl()}notifications/countUnRead`)
            .then((response: AxiosResponse) => response.data)
    }

    setVisibility(notif: NotificationUser, body: any) {
        return axios
            .patch(`${getBaseUrl()}notifications/${notif.id}`, body)
            .then(
                (response: AxiosResponse) => new NotificationUser(response.data)
            )
    }

}
