import React from "react";
import FormSection from "../../../../components/form-section/FormSection";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import { RoutePoint, Transfer } from "../../../../models/transfer";
import { useTranslation } from "react-i18next";
import { VehicleType } from "../../../../models/vehicle-type";

interface Iprops {
    transfer: Transfer;
    control: any;
    errors: any;
    vehicleType?: VehicleType;
}

const RecapFolder = ({ transfer, control, errors, vehicleType }: Iprops) => {
    const { t } = useTranslation();
    return (
        <FormSection title={t("object_request")} className="recap-form-amount">
            <div className="field-form col-12 col-lg-4 col-xl-2">
                <InputControlled
                    name="nb_passengers"
                    label={t("nb_passengers")}
                    value={transfer?.nb_passenger}
                    required={false}
                    disabled={true}
                    control={control}
                    errors={errors}
                />
            </div>
            <div className="field-form col-12 col-lg-4 col-xl-2">
                <InputControlled
                    name="departure_date"
                    label={t("departure_date")}
                    value={transfer.departure_datetime?.toLocaleDateString()}
                    required={false}
                    disabled={true}
                    control={control}
                    errors={errors}
                />
            </div>
            <div className="field-form col-12 col-lg-4 col-xl-2">
                <InputControlled
                    name="departure_time"
                    label={t("departure_time")}
                    value={transfer.departure_datetime?.toLocaleTimeString()}
                    required={false}
                    disabled={true}
                    control={control}
                    errors={errors}
                />
            </div>
            <div className="field-form col-12 col-lg-4 col-xl-2">
                <InputControlled
                    name="departure_location"
                    label={t("departure_location")}
                    value={transfer?.departure_location}
                    required={false}
                    disabled={true}
                    control={control}
                    errors={errors}
                />
            </div>
            {transfer?.route_points ? (
                transfer.route_points.map(
                    (route: RoutePoint, index: number) => (
                        <div
                            className="field-form"
                            style={index === 1 ? { marginLeft: "20px" } : {}}
                        >
                            <InputControlled
                                name={"transfer_step_" + index}
                                label={
                                    t("form.transfer_step") + " " + (index + 1)
                                }
                                required={true}
                                value={route.detail}
                                disabled={true}
                                control={control}
                                errors={errors}
                            />
                        </div>
                    )
                )
            ) : (
                <></>
            )}
            <div className="field-form col-12 col-lg-4 col-xl-2">
                <InputControlled
                    name="arrival_location"
                    label={t("arrival_location")}
                    value={transfer?.arrival_location}
                    required={false}
                    disabled={true}
                    control={control}
                    errors={errors}
                />
            </div>
            <div className="field-form col-12 col-lg-4 col-xl-2">
                {/*<InputControlled
                    name="info"
                    label={t("custom")}
                    value={transfer.infos ? transfer?.infos : "-"}
                    required={false}
                    disabled={true}
                    control={control}
                    errors={errors}
                />*/}
            </div>
            <div className="field-form col-12 col-lg-4 col-xl-2">
                {vehicleType ? (
                    <InputControlled
                        name="vehicle_type"
                        label={t("vehicle_type")}
                        value={vehicleType?.label}
                        required={false}
                        disabled={true}
                        control={control}
                        errors={errors}
                    />
                ) : (
                    <></>
                )}
            </div>
            <div className="field-form col-12 col-md-6 col-xl-3">
                <InputControlled
                    disabled={true}
                    label={t("is_tender")}
                    name="is_tender"
                    control={control}
                    required={false}
                    errors={errors}
                    value={
                        transfer.isTender()
                            ? `${t("form." + transfer.getTypeTender())} (${
                                  transfer.tender_price ||
                                  transfer.min_estimate +
                                      " - " +
                                      transfer.max_estimate
                              } ${t("money_symbol")})`
                            : t("no")
                    }
                />
            </div>
        </FormSection>
    );
};

export default RecapFolder;
