import React from "react";
import { DataPaginate } from "../../../../models/utils/data-paginate";
import { useTranslation } from "react-i18next";
import { VehicleBrand } from "../../../../models/vehicle-brand";
import Pagination from "../../../../components/pagination/pagination";
import ItemBrand from "./ItemBrand";

interface IListBrandsProps {
    dataPaginate: DataPaginate;
    loading?: boolean;
    onPageChange: (page: number) => void;
    onUpdateSuccess: (vehicleBrand: VehicleBrand) => void;
    onDeleteSuccess: () => void;
}

const ListBrands: React.FunctionComponent<IListBrandsProps> = ({
    dataPaginate,
    loading,
    onPageChange,
    onUpdateSuccess,
    onDeleteSuccess,
}) => {
    const { t } = useTranslation();
    return (
        <>
            {dataPaginate && dataPaginate.data ? (
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>{t("brand")}</th>
                                <th className="header-margin">{t("logo")}</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {dataPaginate.data.map(
                                (brand: VehicleBrand, index: number) => {
                                    return (
                                        <tr
                                            key={`vehicle-brand-${brand.id}-${index}`}
                                        >
                                            <ItemBrand
                                                onUpdateSuccess={(
                                                    vehicleBrand
                                                ) =>
                                                    onUpdateSuccess(
                                                        vehicleBrand
                                                    )
                                                }
                                                onDeleteSuccess={() =>
                                                    onDeleteSuccess()
                                                }
                                                vehicleBrand={brand}
                                            />
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                    <Pagination
                        dataPaginate={dataPaginate}
                        onPageChange={(page) => onPageChange(page)}
                    />
                </>
            ) : loading ? (
                <div>{t("loading")}</div>
            ) : (
                <div>{t("no_items")}</div>
            )}
        </>
    );
};

export default ListBrands;
