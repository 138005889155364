import {getBaseUrl, getParamsUrl} from "./setting-service";
import {DataPaginate} from "../models/utils/data-paginate";
import {Ticket} from "../models/ticket";
import axios, {AxiosResponse} from "axios";
import {FiltersEnabled} from "../models/utils/filters/filter-enabled";
import {TicketFilters} from "../models/utils/filters/tickets/ticket-filters";

export default class TicketService {

    // Get every tickets with pagination, search and filter params
    all(page?: number | null, search?: string | null, filters?: FiltersEnabled | null) {
        let paramsUrl = "";

        if (filters)
            paramsUrl = getParamsUrl([
                {key: "page", value: page},
                {key: "search", value: search},
                ...filters.list,
            ]);
        else {
            paramsUrl = getParamsUrl([
                {key: "page", value: page},
                {key: "search", value: search},
            ]);
        }
        return axios
            .get(`${getBaseUrl()}tickets${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map(
                    (ticket: Object) => new Ticket(ticket)
                );
                return new DataPaginate(res);
            })
    }

    // Get one ticket with id params
    get(id: number) {
        return axios
            .get(`${getBaseUrl()}tickets/${id}`)
            .then((response: AxiosResponse) => new Ticket(response.data))
            .catch();
    }

    getFilter() {
        return axios
            .get(`${getBaseUrl()}tickets/filters`)
            .then((response: AxiosResponse) => new TicketFilters(response.data));
    }

    // Allow Api to send mail thanks to the id param of the ticket
    sendMail(id: number, body: any) {
        return axios
            .post(`${getBaseUrl()}tickets/${id}/send-mail`, body)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    // Post one ticket with body params
    post(body: any) {
        return axios
            .post(`${getBaseUrl()}tickets`, body)
            .then((response: AxiosResponse) => new Ticket(response.data))
            .catch();
    }

    // Update(patch) one ticket with body and id params
    patch(body: any, id: number) {
        return axios
            .patch(`${getBaseUrl()}tickets/${id}`, body)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    // Change the state of the ticket without body params
    changeState(id: number) {
        return axios
            .patch(`${getBaseUrl()}tickets/${id}/change-state`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    // Delete one ticket with id params
    delete(id: number) {
        return axios
            .delete(`${getBaseUrl()}tickets/${id}`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    // Get list of Kpis of the list of the ticket that the user can see in his list of tickets
    getKpis() {
        return axios
            .get(`${getBaseUrl()}tickets/kpis`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }
}