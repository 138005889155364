import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

interface IProps {
    lat: number;
    long: number;
    zoom?: number;
    width?: number;
    height?: number;
}

const Map: React.FunctionComponent<IProps> = (props) => {
    const api_key = process.env.REACT_APP_GMAP_KEY;
    const position = {
        lat: Number(props.lat),
        lng: Number(props.long),
    };

    return api_key ? (
        <>
            <LoadScript googleMapsApiKey={api_key}>
                <GoogleMap
                    mapContainerStyle={{
                        width: props.width ? props.width + "px" : "auto",
                        height: (props.height ? props.height : 400) + "px",
                    }}
                    center={position}
                    zoom={props.zoom ? props.zoom : 1}
                >
                    <Marker position={position} />
                </GoogleMap>
            </LoadScript>
        </>
    ) : (
        <> Missing API KEY </>
    );
};

export default Map;
