import React from "react";

const SpeakerSvg = (props: any) => {
    return (
        <svg
            id="call-to-action"
            xmlns="http://www.w3.org/2000/svg"
            width="20.527"
            height="20.527"
            viewBox="0 0 20.527 20.527"
        >
            <path
                id="Tracé_23846"
                data-name="Tracé 23846"
                d="M108.176,362.419l-.135,0,1.585,5.561a.6.6,0,0,0,.578.436h3.047a.6.6,0,0,0,.581-.756l-1.236-4.621-1.582-.618h-2.839Z"
                transform="translate(-103.709 -347.887)"
                fill={props.fill}
            />
            <path
                id="Tracé_23847"
                data-name="Tracé 23847"
                d="M456.834,130.037a4.072,4.072,0,0,0-2.3-3.664V133.7A4.072,4.072,0,0,0,456.834,130.037Z"
                transform="translate(-436.307 -121.306)"
                fill={props.fill}
            />
            <path
                id="Tracé_23848"
                data-name="Tracé 23848"
                d="M209.183,68.014l-3.667,1.434v9.489l3.667,1.434Z"
                transform="translate(-197.276 -65.287)"
                fill={props.fill}
            />
            <path
                id="Tracé_23849"
                data-name="Tracé 23849"
                d="M3.943,111.509a4.465,4.465,0,0,0,0,8.868Z"
                transform="translate(0 -107.038)"
                fill={props.fill}
            />
            <path
                id="Tracé_23850"
                data-name="Tracé 23850"
                d="M128.353,110.742h1.891v8.93h-1.891Z"
                transform="translate(-123.207 -106.302)"
                fill={props.fill}
            />
            <path
                id="Tracé_23851"
                data-name="Tracé 23851"
                d="M329.978,1.995a2,2,0,0,0-3.991,0v13.82a2,2,0,0,0,3.991,0Z"
                transform="translate(-312.917)"
                fill={props.fill}
            />
        </svg>
    );
};

export default SpeakerSvg;
