export class ErrorFormData {
    
    error?: string | null;
    errors?: Array<String> | null;
    exception?: string | null;

    constructor(data?:any){
        this.error = data && data.error ? data.error : null;
        this.exception = data && data.exception ? data.exception : null;
        this.errors = data && data.errors ? Object.keys(data.errors).map(key => data.errors[key]).flat() : null;
    }

    reset() {
        this.error = null;
        this.errors = null;
    }

    removeError() {
        this.error = null;
    }
}