import axios, { AxiosResponse } from 'axios';

import { getBaseUrl, getParamsUrl } from './setting-service';
import { DataPaginate } from '../models/utils/data-paginate';
import { Folder } from '../models/folder';
import {FiltersEnabled} from "../models/utils/filters/filter-enabled";
import {FolderFilters} from "../models/utils/filters/folders/folder-filters";

export default class FolderService {

    all(page?: number | null, search?: string | null, filter?: string | null, filters?: FiltersEnabled | null) {
        let paramsUrl = "";

        if (filters)
            paramsUrl = getParamsUrl([
                {key: "page", value: page},
                {key: "search", value: search},
                {key: "filter", value: filter},
                ...filters.list
            ]);
        else {
            paramsUrl = getParamsUrl([
                {key: "page", value: page},
                {key: "search", value: search},
                {key: "filter", value: filter},
            ]);
        }
        return axios.get(`${getBaseUrl()}folders${paramsUrl}`)
            .then( (response:AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map( (client:Object)=> new Folder(client));
                
                return new DataPaginate(res);
            });
    }

    get(id: number) {
        return axios.get(`${getBaseUrl()}folders/${id}`)
            .then((response:AxiosResponse) => new Folder(response.data))
            .catch();
    }

    getFilter() {
        return axios
            .get(`${getBaseUrl()}folders/filters`)
            .then((response: AxiosResponse) => {
                return new FolderFilters(response.data);
            });
    }

    add(body: any) {
        return axios.post(`${getBaseUrl()}folders`, body)
            .then((response:AxiosResponse) => new Folder(response.data))
            .catch();
    }

    update(id: number, body: any) {
        console.log(body)
        return axios.put(`${getBaseUrl()}folders/${id}`, body)
            .then((response:AxiosResponse) => new Folder(response.data))
            .catch();
    }

    delete(id: number) {
        return axios.delete(`${getBaseUrl()}folders/${id}`)
            .then((response:AxiosResponse) => response.data)
            .catch();
    }

    getKpis() {
        return axios.get(`${getBaseUrl()}folders/kpis`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    removeDeposit(folderId: number, depositId: number) {
        return axios.delete(`${getBaseUrl()}folders/${folderId}/deposits/${depositId}`)
            .then((response:AxiosResponse) => response.data)
            .catch();
    }
}