import React from "react";

export default function ConfidentialityApp() {
    return (
        <div className="container-login-page">
            <div className="login-page">
                <div className="container-login">
                    <div className="container-text">
                        <h1 className="to-upper text">
                            Politique de Confidentialité et Conditions Générales
                            d’Utilisation des services de la société SPARK LANES
                        </h1>
                    </div>
                    <div className="login flex_center">
                        <div
                            className="form-container"
                            style={{
                                width: "100%",
                                height: "600px",
                                overflow: "scroll",
                            }}
                        >
                            <div className="admin-logo-section">
                                <img
                                    style={{
                                        width: "500px",
                                        marginBottom: "30px",
                                    }}
                                    className="logo"
                                    src={`/images/login/images/admin-logo.png`}
                                    alt=""
                                />
                            </div>
                            <div className="form-content">
                                <div className="lorem">
                                    <p>
                                        Les présentes conditions générales («
                                        conditions ») et politiques de confidentialité s’appliquent à
                                        l’utilisation de l’application mobile
                                        et/ou au site internet de la société
                                        SPARK LANES. Les services application
                                        mobile et site web vous permettent de
                                        solliciter des services auprès de tiers
                                        prestataires par l’intermédiation de la
                                        société SPARK LANES. Pour les services
                                        réalisés par un tiers prestataire, un
                                        contrat vous lie directement avec le
                                        tiers prestataire, SPARK LANES agissant
                                        en tant que plateforme informatique de
                                        mise en relation pour vous permettre de
                                        solliciter et de recevoir ces services.
                                        S’agissant des services de transports
                                        fournis par les tiers prestataires,
                                        utilisant l’application ou le site
                                        internet de la société SPARK LANES, la
                                        société SPARK LANES, en sa qualité de
                                        plateforme informatique de mise en
                                        relation, vous garantit leur bonne
                                        exécution dans les conditions prévues au
                                        sein des présentes conditions.
                                    </p>

                                    <h2>DÉFINITIONS</h2>

                                    <p>
                                        Les « tiers prestataires » sont des
                                        entités indépendantes, notamment les
                                        prestataires de transport indépendants,
                                        les partenaires de livraison, les
                                        prestataires de logistique qui utilisent
                                        l’application mobile ou le site internet
                                        de la société SPARK LANES.
                                    </p>
                                    <p>
                                        Les « prestations de services réalisés
                                        par un tiers prestataire » comprennent
                                        notamment les services et les biens dans
                                        le domaine du transport, de la
                                        livraison, de la logistique, de la
                                        mobilité, qui vous sont fournis par des
                                        tiers prestataires, tiers indépendants,
                                        tels que notamment les services de
                                        transport. Ces services sont mis à votre
                                        disposition par l’intermédiaire de la
                                        société SPARK LANES{" "}
                                    </p>

                                    <h2>CONDITIONS</h2>
                                    <h2>Article 1 - principes généraux</h2>
                                    <p>
                                        La société SPARK LANES agit en qualité
                                        de plateforme informatique de mise en
                                        relation pour l’utilisation de
                                        prestations de services réalisées par
                                        des tiers. Dès lors que les prestations
                                        réalisées par des tiers prestataires de
                                        transport sont accessibles via
                                        l’application mobile et/ou site internet
                                        de la société SPARK LANES, la société
                                        SPARK LANES s’assure, dans les
                                        conditions prévues à l’article 14 des
                                        présentes, de la bonne exécution desdits
                                        services de transport par les tiers
                                        prestataires.
                                    </p>

                                    <h2>
                                        Article 2 - Services d’application et de
                                        site web
                                    </h2>
                                    <p>
                                        Les services qui vous sont fournis par
                                        la société SPARK LANES incluent :
                                    </p>
                                    <ul>
                                        <li>
                                            L’accès et l’utilisation à
                                            l’application mobile "Sparklanes"
                                            et/ou au site internet de la société
                                            SPARK LANES vous permettant de
                                            solliciter et de bénéficier de
                                            prestations réalisées par un tiers
                                            prestataire.
                                        </li>
                                        <li>
                                            Les services de gestion des
                                            paiements, y compris de facturation
                                            pour le compte de tiers
                                            prestataires, nous permettant
                                            d’effectuer l’établissement des
                                            factures et de reverser les sommes
                                            payées sur le compte bancaire des
                                            tiers prestataires pour les
                                            prestations de services réalisées
                                            par ces derniers.
                                        </li>
                                        <li>
                                            Les présentes conditions régissent
                                            votre utilisation de l’application
                                            et du site web de la société SPARK
                                            LANES.
                                        </li>
                                    </ul>
                                    <p>
                                        L’application et/ou le site internet
                                        mobile de la société SPARK LANES seront
                                        mis à votre disposition SPARK LANES dans
                                        le cadre d’un usage personnel et non
                                        commercial vous permettant de solliciter
                                        et de contracter des prestations de
                                        services auprès de tiers prestataires de
                                        services par l’intermédiaire de
                                        l’application mobile et/ou le site
                                        internet de la société SPARK LANES.
                                    </p>

                                    <h2>
                                        Article 3 - Prestations de services
                                        réalisées par des tiers prestataires
                                    </h2>
                                    <p>
                                        Pour les prestations de services
                                        réalisées par des tiers prestataires, la
                                        société SPARK LANES agit en qualité de
                                        plateforme informatique de mise en
                                        relation entre vous et les tiers
                                        prestataires pour permettre aux tiers
                                        prestataires de vous fournir leurs
                                        prestations de services. Tous les tiers
                                        prestataires sont des sociétés ou des
                                        entrepreneurs indépendants, sans lien de
                                        dépendance salariale ou capitalistique
                                        avec la société SPARK LANES.{" "}
                                    </p>
                                    <p>
                                        La connexion en ligne et à proximité de
                                        tiers prestataires également connectés à
                                        l’application mobile et/ou au site
                                        internet de SPARK LANES permet la
                                        réception des informations sur les
                                        services proposés par les tiers
                                        prestataires avant toute décision de
                                        sollicitation.
                                    </p>
                                    <p>
                                        Les tiers prestataires conservent la
                                        liberté de contracter ou de ne pas
                                        contracter.
                                    </p>
                                    <p>
                                        Pour ce certaines prestations de service
                                        réalisées par des tiers prestataires,
                                        vous pouvez être amené à accepter des
                                        conditions générales distinctes avant de
                                        bénéficier de ces prestations de
                                        services réalisés par des tiers
                                        prestataires.
                                    </p>

                                    <h2>
                                        Article 5 - Utilisation de l’application
                                        mobile et/ou du site internet SPARK
                                        LANES
                                    </h2>
                                    <p>
                                        L’application mobile et/ou le site
                                        internet de la société SPARK LANES sont
                                        téléchargeables ou accessibles sur la
                                        plupart des appareils mobiles disposant
                                        d’une connexion internet et équipés de
                                        systèmes d’exploitation tels qu’Android
                                        ou IOS.
                                    </p>
                                    <p>
                                        Il vous incombe d’obtenir l’accès au
                                        réseau nécessaire à l’utilisation de
                                        l’application et/ou du site Web et tous
                                        les tarifs et frais de votre prestataire
                                        de réseau mobile sont à votre charge, y
                                        compris pour les DATA afférentes à
                                        l’utilisation de l’application mobile
                                        et/ou du site internet de la société
                                        SPARK LANES.{" "}
                                    </p>
                                    <p>
                                        Il vous incombe également d’accéder à
                                        des appareils et systèmes d’exploitation
                                        compatibles avec l’utilisation de
                                        l’application et/ou du site Web, y
                                        compris les mises à jour logicielles ou
                                        matérielles.
                                    </p>
                                    <p>
                                        Afin de pouvoir utiliser l’application
                                        et/ou le site Web, vous devez vous
                                        enregistrer dans l’application mobile
                                        et/ou sur le site internet de SPARK
                                        LANES et créer un compte.{" "}
                                    </p>
                                    <p>
                                        Vous devez nous fournir certaines
                                        informations personnelles pour vous
                                        inscrire, notamment votre numéro de
                                        téléphone et votre adresse électronique.
                                        Pour utiliser l’application et/ou le
                                        site web de de la société SPARK LANES ou
                                        bénéficier de prestations de services
                                        réalisées par des tiers prestataires,
                                        vous devrez également indiquer au moins
                                        un moyen de paiement valide, de même que
                                        des informations telles que votre
                                        adresse et des documents supplémentaires
                                        avant d’utiliser certains services
                                        réalisés par des tiers prestataires.
                                    </p>
                                    <p>
                                        Aucune obligation d’utilisation de
                                        l’application et/ou du site web de la
                                        société SPARK LANES n’est prévue et vous
                                        restez libres de cesser d’utiliser
                                        l’application mobile et/ou le site
                                        internet de SPARK LANES, sans
                                        information préalable.
                                    </p>
                                    <p>
                                        En cas de violation manifeste des
                                        obligations prévues aux articles 5, 6, 7
                                        et 10 des présentes conditions, SPARK
                                        LANES se réserve le droit de restreindre
                                        temporairement ou définitivement l’accès
                                        et l’utilisation de l’application et du
                                        site web SPARK LANES, notamment en cas
                                        réclamation lié à des actes illégaux.{" "}
                                    </p>

                                    <h2>
                                        Article 6 - Les obligations des
                                        utilisateurs
                                    </h2>
                                    <p>
                                        Lors de l’utilisation de l’application
                                        mobile et/ou du site internet de la
                                        société SPARK LANES, vous devez
                                        respecter l’ensemble de la
                                        réglementation applicable. Vous ne
                                        pouvez utiliser l’application mobile
                                        et/ou le site internet de la société
                                        SPARK LANES qu’à des fins licites et
                                        pour lesquelles ils ont été conçus. Vous
                                        ne devez pas faire un mauvais usage de
                                        l’application mobile et/ou du site
                                        internet de la société SPARK LANES
                                        notamment à des fins frauduleuses envers
                                        SPARK LANES ou envers les tiers
                                        prestataires.{" "}
                                    </p>
                                    <p>
                                        Vous êtes tenus de produire des
                                        informations précises et complètes lors
                                        de la création du compte et lors de
                                        l’utilisation de l’application mobile
                                        et/ou du site internet de SPARK LANES.
                                    </p>
                                    <p>
                                        Le compte-client est strictement
                                        personnel. Il ne peut être ni loué ni
                                        partagé.{" "}
                                    </p>
                                    <p>Lors de la création du compte :</p>
                                    <ul>
                                        <li>
                                            Vous ne pouvez bénéficier que d’un
                                            seul compte et il vous est personnel
                                            ;
                                        </li>
                                        <li>
                                            Vous devez vous assurer de la
                                            confidentialité de vos codes d’accès
                                            et ne les communiquer qu’à des
                                            personnes habilitées ;
                                        </li>
                                        <li>
                                            Les informations relatives à votre
                                            compte doivent être exactes,
                                            complètes et à jour ;
                                        </li>
                                        <li>
                                            Toute suspicion d’utilisation
                                            frauduleuse de votre compte doit
                                            faire l’objet d’une information
                                            auprès de la société SPARK LANES ;
                                        </li>
                                    </ul>
                                    <p>
                                        En cas de non-respect de ces conditions,
                                        vous resterez responsable du paiement de
                                        tous les frais pouvant être dus en
                                        raison de la fourniture de services par
                                        un tiers prestataires par
                                        l’intermédiaire de votre compte, sauf si
                                        l’accès et l’utilisation de votre compte
                                        résultent d’un cas de force majeure ou
                                        du fait d’un tiers présentant les
                                        caractéristiques de la force majeure
                                        (fraude ou piratage informatique).{" "}
                                    </p>

                                    <h2>Article 7 – Paiement et frais</h2>
                                    <h2>
                                        7.1 - Frais liés à l’application mobile
                                        et/ou le site internet de SPARK LANES :
                                    </h2>
                                    <p>
                                        Vous ne serez pas facturé pour
                                        l’utilisation de l’application mobile
                                        et/ou du site internet de la société
                                        SPARK LANES.{" "}
                                    </p>
                                    <p>
                                        Toutefois, la société SPARK LANES se
                                        réserve le droit de prévoir des frais
                                        d’utilisation, une information préalable
                                        vous étant alors communiquée par écrit
                                        dans un délai raisonnable. En cas de
                                        désaccord, vous disposerez du droit de
                                        résilier les présentes conditions
                                        d’utilisation avant que ces frais ne
                                        soient introduits, mais vous ne pourrez
                                        plus accéder à l’application mobile
                                        et/ou au site internet de de la société
                                        SPARK LANES ou les utiliser.
                                    </p>
                                    <p>
                                        Des frais peuvent également être prévus
                                        pour des fonctionnalités spécifiques de
                                        l’application mobile et/ou le site
                                        internet de la société SPARK LANES. En
                                        cas de désaccord, vous disposerez du
                                        droit de résilier les présentes
                                        conditions avant que ces frais ne soient
                                        introduits. Ces fonctionnalités peuvent
                                        être soumises à des conditions
                                        distinctes en fonction de leur nature.
                                    </p>

                                    <h2>
                                        7.2 - Frais liés aux prestations
                                        réalisées par un tiers prestataire
                                    </h2>
                                    <p>
                                        Si vous faites une sollicitation au
                                        moyen de l’application mobile et/ou du
                                        site internet de SPARK LANES, vous
                                        acceptez de payer les frais applicables,
                                        ainsi que tous les frais liés à votre
                                        compte dans les conditions décrites à
                                        l’article 6 ci-dessus.
                                    </p>
                                    <p>
                                        En fonction de la prestation de services
                                        réalisée par le tiers prestataire
                                        sollicité, du lieu où il est fourni, le
                                        montant total des frais relatifs à votre
                                        sollicitation est calculé et affiché sur
                                        l’application mobile et/ou le site
                                        internet de SPARK LANES avant que vous
                                        ne confirmiez votre commande.{" "}
                                    </p>
                                    <p>
                                        Des frais et/ou des pénalités
                                        supplémentaires peuvent vous être
                                        facturés dans les conditions décrites à
                                        l’article 7 par les tiers prestataires
                                        en raison de votre utilisation ou
                                        mauvaise utilisation des Services
                                        réalisés par les Tiers.
                                    </p>
                                    <p>
                                        Si les prestations proposées par un
                                        tiers prestataires ne peuvent être
                                        effectuées en raison d’un fait vous
                                        étant imputable, l’ensemble des frais
                                        sont susceptibles de vous être facturés,
                                        sauf si vous justifiez que ce n’est pas
                                        en raison d’un fait qui vous est
                                        imputable que la prestation n’a pas pu
                                        être réalisée.
                                    </p>
                                    <p>
                                        Une fois que la prestation du tiers
                                        prestataire a été réalisée, une facture
                                        est émise directement au nom du tiers
                                        prestataire ou à votre nom, selon le
                                        cas. La facture comprendra la TVA, le
                                        cas échéant.
                                    </p>

                                    <h2>7.3 - Autres frais générés</h2>
                                    <p>
                                        Les frais sont susceptibles d’être
                                        modifiés dans l’application mobile et/ou
                                        le site internet de SPARK LANES. Toute
                                        modification fait l’objet d’une
                                        information préalable par mail ou sur
                                        l’application mobile et/ou le site
                                        internet de SPARK LANES. L’acceptation
                                        de ces modifications est nécessaire pour
                                        pouvoir continuer d’utiliser le service.
                                        En cas de refus, l’utilisation de
                                        l’application mobile et/ou le site
                                        internet de SPARK LANES sera suspendue.
                                    </p>
                                    <p>
                                        Sauf accord contraire, tous les frais
                                        sont dus immédiatement après que vous
                                        avez effectué votre demande et le
                                        paiement sera réalisé en utilisant le
                                        moyen de paiement enregistré sur votre
                                        compte, un reçu vous est adressé par
                                        courrier électronique.{" "}
                                    </p>
                                    <p>
                                        Les frais s’entendent toutes taxes
                                        comprises, TVA incluse.
                                    </p>

                                    <h2>Article 8 - Annulation de commande</h2>
                                    <p>
                                        Vous ne disposez d’aucun droit d’annuler
                                        la demande de prestations de services
                                        par un tiers prestataire, une fois la
                                        demande validée sur l’application et/ou
                                        le site web de la société SPARK LANES.
                                    </p>
                                    <p>
                                        En aucune façon, la société SPARK LANES
                                        peut intervenir auprès d’un tiers
                                        prestataire pour solliciter une
                                        annulation de commande.
                                    </p>
                                    <p>
                                        Seuls les tiers prestataires peuvent
                                        vous permettre d’annuler la demande,
                                        mais des frais d’annulation peuvent vous
                                        être facturés.
                                    </p>

                                    <h2>
                                        Article 9 - Licence d’utilisation de
                                        l’application
                                    </h2>
                                    <p>
                                        Sous réserve du respect des présentes
                                        conditions, nous vous accordons une
                                        licence personnelle, mondiale, non
                                        exclusive, incessible, révocable et ne
                                        pouvant faire l’objet d’aucune
                                        sous-licence, afin d’installer et/ou
                                        d’utiliser l’application mobile et/ou le
                                        site internet de la société SPARK LANES
                                        sur votre appareil, pour votre seul
                                        usage et uniquement pour vous permettre
                                        d’accéder aux informations mises à
                                        disposition au moyen l’application
                                        mobile et/ou du site internet de la
                                        société SPARK LANES et de les utiliser.
                                        Tous les droits non expressément
                                        accordés dans ces conditions sont
                                        réservés par la société SPARK LANES.
                                    </p>

                                    <h2>
                                        Article 10 - Propriété intellectuelle
                                    </h2>
                                    <p>
                                        Tous les droits qui ne sont pas
                                        expressément accordés dans les présentes
                                        conditions sont réservés à ola société
                                        SPARK LANES. L’application mobile et/ou
                                        le site internet de la société SPARK
                                        LANES, nos appareils et toutes les
                                        données recueillies au moyen de
                                        l’application mobile et/ou du site
                                        internet de la société SPARK LANES sont
                                        et restent notre propriété. Vous ne
                                        pouvez pas donc pas concéder une
                                        licence, une sous-licence, copier,
                                        modifier, distribuer, créer, vendre,
                                        revendre, céder ou louer une quelconque
                                        partie de l’application mobile et/ou du
                                        site internet de la société SPARK LANES,
                                        faire de l’ingénierie inverse ou tenter
                                        d’extraire le code source de
                                        l’application mobile et/ou du site
                                        internet de la société SPARK LANES.
                                    </p>

                                    <h2>Article 11. Données personnelles</h2>
                                    <p>
                                        Dans le cadre de l’utilisation
                                        l’application mobile et/ou du site
                                        internet de la société SPARK LANES, nous
                                        recueillons, utilisons et communiquons
                                        des informations vous concernant. Des
                                        déclarations de confidentialité
                                        supplémentaires peuvent s’appliquer, y
                                        compris celles de tiers responsables de
                                        traitement, pour les services fournis
                                        par SPARK LANES.
                                    </p>
                                    <p>
                                        Les données concernant votre compte
                                        utilisateur sont créer depuis une
                                        interface d'administration, vous pouvez
                                        éditer celle-ci à tout moment, ainsi que
                                        demander la suppression de celle-ci.
                                        Elles sont stockés en base de données,
                                        et les informations sensible tel que le
                                        mot de passe sont cryptés.
                                    </p>
                                    <p>
                                        L'application mobile Sparklanes collecte
                                        vos données de localisations pour
                                        permettre le suivi des trajets aux
                                        clients même lorsque l'application est
                                        fermée ou non utilisée.{" "}
                                    </p>

                                    <h2>Article 12. Vos responsabilités </h2>
                                    <p>
                                        Votre responsabilité peut être engagée
                                        pour tout dommage résultant d’une
                                        violation des présentes conditions,
                                        d’une mauvaise utilisation de
                                        l’application mobile et/ou du site
                                        internet de la société SPARK LANES ou
                                        d’une violation de toute disposition
                                        légale ou des droits d’un tiers. Il vous
                                        appartient de réaliser et conserver les
                                        copies des données disponibles sur votre
                                        compte qui vous sont nécessaires.
                                    </p>

                                    <h2>
                                        Article - 13. Absence de responsabilité
                                        et limitation de responsabilité
                                    </h2>
                                    <p>
                                        L’application mobile et/ou le site
                                        internet de la société SPARK LANES
                                        peuvent faire l’objet de limitations, de
                                        retards et/ou d’autres problèmes dus à
                                        l’utilisation d’internet et des
                                        communications électroniques fournis par
                                        un tiers prestataire, du fait
                                        imprévisible et insurmontable d'un tiers
                                        ou de la survenance d'un cas de force
                                        majeure.{" "}
                                    </p>
                                    <p>
                                        L’application mobile et/ou le site
                                        internet de la société SPARK LANES
                                        peuvent faire l’objet d’interruptions
                                        dans le cadre de maintenances pouvant
                                        impacter votre utilisation des services
                                        et pour lesquelles vous serez informés
                                        le cas échéant.
                                    </p>
                                    <p>
                                        Lors de l’utilisation de l’application
                                        mobile et/ou du site internet de la
                                        société SPARK LANES, notre
                                        responsabilité ne saurait être engagée
                                        au titre des dommages indirects en ce
                                        compris : perte de chiffre d’affaires ;
                                        perte d’accords ou de contrats ; perte
                                        d’économies escomptées ; perte de
                                        données ou d’informations et perte de
                                        clientèle.{" "}
                                    </p>
                                    <p>
                                        Nous ne sommes pas responsables des
                                        retards ou des défaillances d’exécution
                                        résultant d’un cas de force majeure.
                                        Nous déclinons également toute
                                        responsabilité et toute garantie pour
                                        tout acte ou omission de votre part ou
                                        de celle d’un tiers.
                                    </p>
                                    <p>
                                        Lors de l’utilisation de l’application
                                        mobile et/ou du site internet de SPARK
                                        LANES, dans l’hypothèse de l’engagement
                                        de notre responsabilité totale envers
                                        vous pour l’utilisation de l’application
                                        mobile et/ou du site internet de SPARK
                                        LANES, cette responsabilité sera limitée
                                        à cinq cents euros (500 €).{" "}
                                    </p>
                                    <p>
                                        La responsabilité de la société SPARK
                                        LANES ne saurait être engagée en tout ou
                                        partie en cas d'inexécution ou de
                                        mauvaise exécution des prestations de
                                        services lorsque cette inexécution ou
                                        mauvaise exécution vous est imputable,
                                        ou lorsque cette inexécution ou mauvaise
                                        exécution résulte d'un fait imprévisible
                                        et insurmontable d'un tiers étranger à
                                        la fourniture des services, ou encore en
                                        cas de force majeure.
                                    </p>

                                    <h2>Article 14 - Résiliation du service</h2>
                                    <p>
                                        L’utilisation de l’application mobile
                                        et/ou du site internet de la société
                                        SPARK LANES est libre pour le client. La
                                        fermeture du compte par le client
                                        entraine la résiliation des présentes
                                        conditions.
                                    </p>
                                    <p>
                                        La société SPARK LANES se réserve le
                                        droit résilier les présentes conditions
                                        et l’accès à l’application mobile et/ou
                                        au site internet de la société SPARK
                                        LANES en vous en informant
                                        préalablement, par écrit et dans un
                                        délai raisonnable si vous ne respectez
                                        pas vos obligations prévues aux articles
                                        5 « Utilisation de l’application mobile
                                        et/ou du site internet SPARK LANES », 6
                                        « les obligations des utilisateurs », 7
                                        « Paiement et frais », 10 « Propriété
                                        intellectuelle » des conditions
                                        d’utilisation.
                                    </p>
                                    <p>
                                        Vous pouvez, dès réception de la
                                        notification de résiliation, formuler
                                        des observations en vue du
                                        rétablissement de votre compte
                                        utilisateur. Le service sera interrompu
                                        le temps nécessaire à la vérification
                                        des manquements.
                                    </p>
                                    <p>
                                        En cas d’inefficacité du moyen de
                                        paiement pour le règlement des frais
                                        dus, une information écrite vous est
                                        adressée, une suspension de l’accès
                                        pouvant être envisagée jusqu’au parfait
                                        paiement des sommes dues.{" "}
                                    </p>
                                    <p>
                                        Nonobstant la résiliation des présentes,
                                        la société SPARK LANES se réserve le
                                        droit d’en poursuivre le paiement après
                                        la résiliation.
                                    </p>

                                    <h2>
                                        Article 15 - Modifications et validité
                                        des clauses
                                    </h2>
                                    <p>
                                        La société SPARK LANES dispose du droit
                                        de modifier les présentes conditions,
                                        sous condition d’une information
                                        préalable communiquée dans un délai
                                        raisonnable.{" "}
                                    </p>
                                    <p>
                                        Une fois acceptées, ces nouvelles
                                        conditions s’appliqueront. En cas de
                                        désaccord sur les nouvelles conditions,
                                        vous êtes libre de clôturer votre compte
                                        conformément à l’article 14.
                                    </p>
                                    <p>
                                        Si l’une des clauses des présentes
                                        conditions n’avérait nulle ou
                                        inapplicable en vertu d’un changement de
                                        législation, les parties conviennent que
                                        les autres dispositions des conditions
                                        générales demeurent applicables. Toute
                                        clause invalide, illégale ou inopposable
                                        sera considérée comme supprimée.
                                    </p>
                                    <p>
                                        L’utilisation de l’application mobile
                                        et/ou du site internet de la société
                                        SPARK LANES est personnelle et ne peut
                                        faire l’objet d’aucune cession ou
                                        sous-traitance.
                                    </p>
                                    <p>
                                        Les présentes conditions ne peuvent
                                        profiter à aucun tiers, sauf dans les
                                        cas prévus par les présentes Conditions.
                                    </p>

                                    <h2>
                                        Article 16 – Protection des données à
                                        caractère personnel
                                    </h2>
                                    <p>
                                        La Société SPARK LANES s'engage à
                                        respecter la confidentialité des données
                                        personnelles communiquées par
                                        l’utilisateur sur l’application et/ou le
                                        site de la société SPARK LANES et à les
                                        traiter dans le respect de la loi
                                        Informatique et Libertés du 6 janvier
                                        1978.
                                    </p>
                                    <p>
                                        La société SPARK LANES vous rappelle que
                                        conformément à la règlementation entrée
                                        en vigueur le 25 mai 2018 vous disposez
                                        d’un certain nombre de droits concernant
                                        vos données personnelles.{" "}
                                    </p>
                                    <p>
                                        L’ouverture d’un compte de mise en
                                        relation auprès de la société SPARK
                                        LANES entraine de facto le consentement
                                        à la collecte de données personnelles
                                        nécessaires au fonctionnement de
                                        l’application et à l’exécution du
                                        contrat de prestations de services.{" "}
                                    </p>
                                    <p>
                                        Les destinataires de ces données
                                        personnelles sont strictement limités à
                                        la bonne exécution des contrats de
                                        prestations de service.
                                    </p>
                                    <p>
                                        Les données personnelles sont conservées
                                        tant le compte d’utilisateur est actif
                                        sur l’application et/ou le site internet
                                        de la société SPARK LANES. En cas de
                                        résiliation du compte tant l’initiative
                                        de la société SPARK LANES que de
                                        l’utilisateur, les données personnelles
                                        sont intégralement supprimées.{" "}
                                    </p>
                                    <p>Vous disposez dans ce cadre :</p>
                                    <ul>
                                        <li>
                                            D’un droit de nous solliciter sur la
                                            manière dont vos données
                                            personnelles sont collectées et
                                            traitées.
                                        </li>
                                        <li>
                                            D’un droit d’accès à vos données
                                            personnelles ;
                                        </li>
                                        <li>
                                            D’un droit d’opposition à la
                                            collecte et au traitement de vos
                                            données personnelles ;
                                        </li>
                                        <li>
                                            D’un droit de rectification lorsque
                                            vos données personnelles collectées
                                            sont inexactes ;
                                        </li>
                                        <li>
                                            D’un droit à faire valoir une
                                            limitation du traitement de vos
                                            données personnelles ;
                                        </li>
                                        <li>
                                            D’un droit d’opposition à tout
                                            traitement automatisé de vos données
                                            personnelles aux fins d’analyses
                                            statistiques ;
                                        </li>
                                        <li>
                                            D’un droit de récupérer vos données
                                            personnelles aux fins de
                                            transmission à un tiers de votre
                                            choix et d’un droit de suppression
                                            définitive ;
                                        </li>
                                        <li>
                                            D’un droit à l’effacement de vos
                                            données personnelles.
                                        </li>
                                    </ul>

                                    <p>
                                        Les données personnelles sont traitées
                                        par la société SPARK LANES et sous sa
                                        seule responsabilité.
                                    </p>
                                    <p>
                                        Conformément à la loi Informatique et
                                        Libertés du 6 janvier 1978,
                                        l’utilisateur peut exercer ses droits en
                                        adressant sa demande par courrier
                                        électronique à l'adresse
                                        contact@sparklanes.com ou par courrier
                                        postal à l'adresse Spark Lanes, 11 rue
                                        poissonnière, 60500 Vineuil Saint
                                        Firmin.
                                    </p>

                                    <h2>
                                        Article 17 - Règlement des différends
                                    </h2>
                                    <p>
                                        Dans le cas d’un différend ou d’une
                                        demande découlant du présent contrat ou
                                        en relation avec celui-ci ou avec son
                                        inexécution, les parties contractantes
                                        se consulteront et négocieront et,
                                        reconnaissant leurs intérêts mutuels,
                                        tenteront de trouver une solution
                                        satisfaisante. Si les parties ne
                                        réussissent pas à formuler un accord
                                        dans une période de 60 jours après
                                        notification donnée par l’une quelconque
                                        des parties, tout différend ou demande
                                        non résolu pourra être porté devant le
                                        juridiction compétente.
                                    </p>

                                    <h2>
                                        Article 18 - Droit applicable et
                                        juridiction compétente{" "}
                                    </h2>
                                    <p>
                                        L’interprétation, la validité et
                                        l’exécution du présent Contrat sont
                                        soumises au droit français.
                                    </p>
                                    <p>
                                        Tout litige, différend ou toute
                                        réclamation découlant des présentes
                                        conditions, y compris les questions
                                        portant sur leur existence, leur
                                        exécution, leur interprétation, leur
                                        validité ou leur annulation, la
                                        résiliation ou la nullité de celles-ci,
                                        est soumis à la compétence exclusive du
                                        Tribunal de Commerce de COMPIEGNE même
                                        en cas d’appel en garantie ou de
                                        pluralité de défendeurs.
                                    </p>

                                    <p>
                                        Le présent contrat est régi par la loi
                                        française. En cas de contestation ou de
                                        litige, quelle qu’en soit la nature ou
                                        l’origine, seuls les Tribunaux dont
                                        dépend le siège social de SPARK LANES
                                        sont compétents.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
