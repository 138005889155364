import React from "react";
import { useTranslation } from "react-i18next";
import { Extra } from "../../../../models/folder";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import { Delete } from "@material-ui/icons";
import "./SubFormExtra.scss";

interface Iprops {
    extra: Extra;
    index: number;
    disabled: boolean;
    control: any;
    errors: any;
    onDelete: (index: number) => void;
    onUpdate: (index: number, key: string, value: any) => void;
}

const SubFormExtra = ({
    control,
    errors,
    disabled,
    extra,
    index,
    onDelete,
    onUpdate,
}: Iprops) => {
    const { t } = useTranslation();

    if (!errors.extras) {
        errors.extras = [];
    }

    let fieldPrefix = "extras[" + index + "]";

    return (
        <>
            <div className="form-part-align-center">
                <div className="row row-md mb-3">
                    <div className="col-auto">
                        <div className="form-group">
                            <InputControlled
                                name={fieldPrefix + ".type"}
                                label={t("form.extra_type")}
                                value={extra?.type}
                                required={true}
                                disabled={disabled}
                                control={control}
                                errors={errors}
                                onChange={(value: any) =>
                                    onUpdate(index, "type", value)
                                }
                            />
                        </div>
                    </div>
                    <div className="col-12 d-xl-none"></div>
                    <div className="col col-xl">
                        <div className="form-group decorator">
                            <InputControlled
                                name={fieldPrefix + ".price"}
                                label={t("form.ht_price")}
                                value={extra?.price}
                                required={true}
                                disabled={disabled}
                                control={control}
                                errors={errors}
                                decorator={<span>€</span>}
                                onChange={(value: any) =>
                                    onUpdate(index, "price", value)
                                }
                            />
                        </div>
                    </div>
                    <div className="col col-xl">
                        <div className="form-group decorator mr-3">
                            <InputControlled
                                name={fieldPrefix + ".tva"}
                                label={t("form.tva")}
                                value={extra?.tva}
                                required={true}
                                disabled={disabled}
                                control={control}
                                errors={errors}
                                decorator={<span>%</span>}
                                onChange={(value: any) => onUpdate(index, "tva", value)}
                            />
                        </div>
                    </div>
                    <div className="col-auto align-self-center">
                        <div className="form-group">
                            {!disabled &&
                                <Delete
                                    style={{ cursor: "pointer" }}
                                    onClick={() => onDelete(index)}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubFormExtra;
