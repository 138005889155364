import React from "react";
import { DataPaginate } from "../../../../models/utils/data-paginate";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../components/pagination/pagination";
import {VehicleColor} from "../../../../models/vehicle-color";
import ItemColor from "./ItemColor";

interface IListColorsProps {
    dataPaginate: DataPaginate;
    loading?: boolean;
    onPageChange: (page: number) => void;
    onUpdateSuccess: (vehicleColor: VehicleColor) => void;
    onDeleteSuccess: () => void;
}

const ListColors: React.FunctionComponent<IListColorsProps> = ({
                                                                   dataPaginate,
                                                                   loading,
                                                                   onPageChange,
                                                                   onUpdateSuccess,
                                                                   onDeleteSuccess,
                                                               }) => {
    const { t } = useTranslation();
    return (
        <>
            {dataPaginate && dataPaginate.data ? (
                <>
                    <table>
                        <thead>
                        <tr>
                            <th>{t("color")}</th>
                            <th>{t("type")}</th>
                            <th className="header-margin">{t("logo")}</th>
                            <th>{t("hex_color")}</th>
                            <th />
                        </tr>
                        </thead>
                        <tbody>
                        {dataPaginate.data.map(
                            (color: VehicleColor, index: number) => {
                                return (
                                    <tr
                                        key={`vehicle-color-${color.id}-${index}`}
                                    >
                                        <ItemColor
                                            onUpdateSuccess={(
                                                vehicleColor
                                            ) =>
                                                onUpdateSuccess(
                                                    vehicleColor
                                                )
                                            }
                                            onDeleteSuccess={() =>
                                                onDeleteSuccess()
                                            }
                                            vehicleColor={color}
                                        />
                                    </tr>
                                );
                            }
                        )}
                        </tbody>
                    </table>
                    <Pagination
                        dataPaginate={dataPaginate}
                        onPageChange={(page) => onPageChange(page)}
                    />
                </>
            ) : loading ? (
                <div>{t("loading")}</div>
            ) : (
                <div>{t("no_items")}</div>
            )}
        </>
    );
};

export default ListColors;
