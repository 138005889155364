import React, { useState, useEffect } from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import FolderService from "../../services/folder-service";
import { Folder } from "../../models/folder";
import FormFolder from "./form-folder/FormFolder";
import { Beneficiary } from "../../models/beneficiary";
import { Client } from "../../models/client";
import ClientService from "../../services/client-service";
import BeneficiaryService from "../../services/beneficiary-service";
import { AppBar, CircularProgress, Tab, Tabs } from "@material-ui/core";
import { ErrorForm } from "../../components/ErrorForm";
import { ErrorFormData } from "../../models/interfaces/errorForm";
import "./FolderFormPage.scss";
import { connect } from "react-redux";
import { Roles, User } from "../../models/user";
import { getPrimaryColorButton } from "../design-dependencies/ColorHelper";
import ListTransfers from "../transfer/list-transfers/ListTransfers";
import EstimatePage from "./estimate-page/EstimatePage";
import ModalAddTransfers from "../transfer/modal-add-transfers/ModalAddTransfers";
import WhiteButton from "../../components/white-button/WhiteButton";
import DepositPage from "./deposit/DepositPage";
import PaymentService from "../../services/payment-service";
import { DataPaginate } from "../../models/utils/data-paginate";
import ListPayments from "../payments/ListPayments";
import { format } from "../../utils/AmountHelper";
import InvoicePage from "./invoice-page/InvoicePage";
import CreditCreate from "./credit/CreditCreate";

enum EnumTabFolder {
    folder = 0,
    transfers = 1,
    quote = 2,
    deposit = 3,
    invoice = 4,
    payments = 5,
}

interface IProps {
    user: User;
}
function FolderFormPage({ user }: IProps) {
    const { t } = useTranslation();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [datas, setDatas] = useState<Folder>();
    const [payments, setPayments] = useState<DataPaginate>();
    const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentPagePayment, setCurrentPagePayment] = useState(1);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    function getInitialTab() {
        const tab =
            EnumTabFolder[query.get("tab") as keyof typeof EnumTabFolder];

        return tab ? tab : EnumTabFolder.folder;
    }
    const [currentTab, setCurrentTab] = useState(getInitialTab());

    useEffect(() => {
        const fetchDatas = async () => {
            try {
                setLoading(true);
                if (id) {
                    const folderService = new FolderService();
                    setDatas(await folderService.get(id));

                    const paymentService = new PaymentService();
                    setPayments(
                        await paymentService.all(
                            currentPagePayment,
                            null,
                            null,
                            id
                        )
                    );
                }

                const clientService = new ClientService();
                const clients = await clientService.getClientsForForm(user);
                setClients(clients);
                const benefService = new BeneficiaryService();
                const beneficiaries = await benefService.all(null, null, "all");
                setBeneficiaries(beneficiaries?.data);

                setLoading(false);
            } catch (error) {
                setErrorApi(new ErrorFormData(error.response.data));
            }
        };
        fetchDatas();
    }, [id, currentPagePayment, user]);

    const loadDatas = async () => {
        const folderService = new FolderService();
        setDatas(await folderService.get(id));

        const paymentService = new PaymentService();
        setPayments(
            await paymentService.all(currentPagePayment, null, null, id)
        );
    };

    const depositRemoved = async () => {
        loadDatas();
        setCurrentTab(0);
    };

    useEffect(() => {
        const fetchPaginatePayments = async () => {
            try {
                if (id) {
                    const paymentService = new PaymentService();
                    setPayments(
                        await paymentService.all(
                            currentPagePayment,
                            null,
                            null,
                            id
                        )
                    );
                }
            } catch (error) {
                setErrorApi(new ErrorFormData(error.response.data));
            }
        };
        fetchPaginatePayments();
    }, [currentPagePayment, id]);

    const renderButtonAddTransfer = () => {
        return !datas || //hidden during loading
            user.isProvider() ||
            (datas.client && user.isClient() && user.isReader(datas.client)) ? ( //client not reader
            <></>
        ) : (
            <WhiteButton
                onClick={() => setShowModal(true)}
                className="btn-rounded"
                variant="contained"
                color="primary"
            >
                {t("add_a_transfer")}
            </WhiteButton>
        );
    };

    const colorTabSelected = getPrimaryColorButton(user?.role);
    const getStyleTab = (tabIndex: number) => {
        if (tabIndex === currentTab) {
            return {
                background: colorTabSelected,
                color: "white",
            };
        }
    };
    return (
        <>
            { !user.isProvider() && 
            <>
                {id && (
                    <AppBar position="static">
                        <Tabs
                            value={currentTab}
                            onChange={(event, newValue) => setCurrentTab(newValue)}
                            aria-label="simple tabs example"
                            className="form-tabs"
                        >
                            <Tab
                                style={getStyleTab(EnumTabFolder.folder)}
                                label={t("entity.folder")}
                            />
                            <Tab
                                style={getStyleTab(EnumTabFolder.transfers)}
                                label={t("nav.transfers")}
                            />
                            <Tab
                                style={getStyleTab(EnumTabFolder.quote)}
                                label={t("nav.estimate")}
                                disabled={!(datas?.quote_id || user.isAdmin())}
                            />
                            <Tab
                                style={getStyleTab(EnumTabFolder.deposit)}
                                label={t("deposit")}
                                disabled={!datas?.deposit}
                            />
                            <Tab
                                style={getStyleTab(EnumTabFolder.invoice)}
                                label={t("invoice")}
                                disabled={!datas || !datas.invoice}
                            />
                            <Tab
                                style={getStyleTab(EnumTabFolder.payments)}
                                label={t("payments")}
                                disabled={!(payments?.data.length > 0)}
                            />
                        </Tabs>
                    </AppBar>
                )}
                {currentTab === EnumTabFolder.folder && (
                    <ContentSection
                        style={id ? { borderTopLeftRadius: 0 } : {}}
                        title={
                            id
                                ? t("edit") + " " + t("entity.folder")
                                : t("add") + " " + t("entity.folder")
                        }
                        returnAction={true}
                    >
                        {loading ? (
                            <CircularProgress />
                        ) : datas ? (
                            <>
                                <FormFolder
                                    clients={clients}
                                    allBeneficiaries={beneficiaries}
                                    folder={datas}
                                />
                            </>
                        ) : (
                            <FormFolder
                                clients={clients}
                                allBeneficiaries={beneficiaries}
                            />
                        )}
                        <ErrorForm errorApi={errorApi} />
                    </ContentSection>
                )}

                {currentTab === EnumTabFolder.transfers && (
                    <ContentSection
                        style={id ? { borderTopLeftRadius: 0 } : {}}
                        title={datas ? datas.code : t("loading")}
                        suppContent={renderButtonAddTransfer()}
                    >
                        <ListTransfers
                            folderId={id}
                            addTransfer={() => setShowModal(true)}
                            nbImports={0}
                            user={user}
                        />
                    </ContentSection>
                )}
                {currentTab === EnumTabFolder.deposit && datas?.deposit && (
                    <DepositPage
                        folder={datas}
                        isAdmin={user.role === Roles.ADMIN}
                        refreshPayments={() => loadDatas()}
                        afterRemove={() => depositRemoved()}
                    />
                )}

                {!loading && currentTab === EnumTabFolder.quote && (
                    <EstimatePage
                        quoteId={datas?.quote_id}
                        afterUpdate={async () => loadDatas()}
                    />
                )}

                {currentTab === EnumTabFolder.invoice && datas && datas.invoice && (
                    <InvoicePage
                        invoiceId={datas.invoice.id}
                        refreshPayments={() => loadDatas()}
                        folder={datas}
                    />
                )}
                {currentTab === EnumTabFolder.payments && payments && (
                    <ContentSection
                        style={id ? { borderTopLeftRadius: 0 } : {}}
                        title={
                            datas?.total_payments
                                ? t("total") + " " + format(datas.total_payments)
                                : ""
                        }
                    >
                        <ListPayments
                            payments={payments}
                            setCurrentPage={(e: any) => setCurrentPagePayment(e)}
                        />
                        {datas?.invoice?.isPaid() && 
                            <CreditCreate folder={datas} user={user} />
                        }
                    </ContentSection>
                )}
                {user.role !== Roles.PROVIDER && (
                    <ModalAddTransfers
                        folderId={id}
                        isOpen={showModal}
                        closeModal={() => setShowModal(false)}
                        importSuccess={() => setShowModal(false)}
                    />
                )}
            </> } 
            {user.isProvider() && <h1>Accès interdit !</h1>}
        </>
       
    );
}

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(FolderFormPage);
