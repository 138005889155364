import React, {useState, useEffect, useCallback} from "react";
import "./ListClients.scss";
import ItemClient from "./ItemClient";
import ClientService from "../../../services/client-service";
import {
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {DataPaginate} from "../../../models/utils/data-paginate";
import {Client} from "../../../models/client"
import Pagination from "../../../components/pagination/pagination";
import GlobalSearch from "../../../components/global-search/globalSearch";
import Paper from "@material-ui/core/Paper";
import KpiContentSeparator from "../../../components/kpi-content-separator/KpiContentSeparator";
import {connect} from "react-redux";
import HeadTableCell from "../../../components/table/HeadTableCell";
import {User, Roles} from "../../../models/user";
import {debounce} from "lodash";

interface IProps {
    user: User;
}

function ListClients({user}: IProps) {
    const {t} = useTranslation();
    const [dataPaginate, setDataPaginate] = useState(new DataPaginate());
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(new Date());

    const fetchAllClients = async () => {
        try {
            if (!loading) setLoading(true);
            const dataService = new ClientService();
            setDataPaginate(await dataService.all(currentPage, search));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("error", error);
        }
    };

    const debounceQuery = useCallback(
        debounce(fetchAllClients, 500),
        [currentPage, search, date]
    );

    useEffect(() => {
        debounceQuery();
        return debounceQuery.cancel;
    }, [currentPage, search, date, debounceQuery]);

    function onPageChange(newPage?: number) {
        if (newPage) {
            setLoading(true);
            setCurrentPage(newPage);
        }
    }

    function renderTableAdmin() {
        return (
            <>
                <div className="table-sub-table">
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <HeadTableCell align="center">
                                        {t("company_name")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("management_entity")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("referent_name")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("email")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("phone")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("account")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("address")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("folders")}
                                    </HeadTableCell>
                                    {/*<HeadTableCell align="center">
                                        {t("annual_sales_revenues")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("client_outstanding")}
                                    </HeadTableCell>*/}
                                    <HeadTableCell align="center"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataPaginate.data
                                .map(
                                    (client: Client, index: number) => (
                                        <ItemClient
                                            key={`table_client_id_${client.id}`}
                                            client={client}
                                            user={user}
                                            onDeleteSuccess={() =>
                                                fetchAllClients()
                                            }
                                        />
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>
        )
    }

    function renderTableClient() {
        return (
            <>
                <div className="table-sub-table">
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <HeadTableCell align="center">
                                        {t("company_name")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("management_entity")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("referent_name")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("email")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("form.phone_number")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center">
                                        {t("form.mobile_number")}
                                    </HeadTableCell>
                                    <HeadTableCell align="center"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataPaginate.data
                                .map(
                                    (client: Client, index: number) => (
                                        <ItemClient
                                            key={`table_client_id_${client.id}`}
                                            client={client}
                                            user={user}
                                            onDeleteSuccess={() =>
                                                fetchAllClients()
                                            }
                                        />
                                    )
                                )}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>
        )
    }

    return (
        <>
            <GlobalSearch
                onSearch={(value: string) => setSearch(value)}
                onResetFilter={() => setDate(new Date())}
            />
            <KpiContentSeparator/>
            {dataPaginate &&
            dataPaginate.data?.length !== 0 &&
            !loading ? (
                    <>
                        {user.role === Roles.CLIENT
                            ? renderTableClient()
                            : renderTableAdmin()}
                        <Pagination
                            dataPaginate={dataPaginate}
                            onPageChange={(page) => onPageChange(page)}
                        />
                    </>
                )
                : loading ? (
                    <CircularProgress/>
                ) : (
                    <div>{t("no_items")}</div>
                )}
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(ListClients);
