import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {ErrorFormData} from "../../../../models/interfaces/errorForm";
import {ErrorForm} from "../../../../components/ErrorForm";
import {toastr} from 'react-redux-toastr'
import {Grid} from "@material-ui/core";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import FormTitle from "../../../../components/form-title/FormTitle";
import SubmitComponent from "../../../../components/submit-component/SubmitComponent";
import './FormDivision.scss';
import DivisionService from "../../../../services/division-service";

interface IFormModel {
    label: string;
}

interface IFormProps {
    onAddSuccess: () => void,
}

const FormDivision: React.FunctionComponent<IFormProps> = ({onAddSuccess}) => {
    const {handleSubmit, errors, control} = useForm<IFormModel>();
    const {t} = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState(false);
    const [isActivateButton, setIsActivateButton] = useState(true)
    const textAdd = t('add');

    const onSubmit = async (data: any, e: any) => {
        setErrorApi(new ErrorFormData());
        const divisionService = new DivisionService();
        try {
            setIsActivateButton(false)
            setDisabledForm(true);

            await divisionService.add(data);
            onAddSuccess();
            toastr.success(t('success'), t('success_add_model'));
            setDisabledForm(false);
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setIsActivateButton(true)
    };

    return (
        <>
            <FormTitle title={t("division_add")} margin={true}/>
            <form className="form-react" onSubmit={handleSubmit(onSubmit)}>
                <Grid className="col-inputs" xs={12}>
                    <div className="field-form input-division mgr1 mgl2">
                        <InputControlled
                            name="label"
                            label={t('label')}
                            value=""
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>
                </Grid>
                <SubmitComponent loading={false} text={textAdd} icon="add" mainClass="client-details-submit"
                                 isActivate={isActivateButton}/>

                {errors.label && <p>{errors.label.message}</p>}
                <ErrorForm errorApi={errorApi}/>
            </form>
        </>
    )

};

export default FormDivision;
