import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import InputControlled from "../../../components/fields-controlled/InputControlled";
import React, {useState} from "react";
import {connect} from "react-redux";
import {User} from "../../../models/user";
import TicketService from "../../../services/ticket-service";
import {toastr} from "react-redux-toastr";
import {ErrorFormData} from "../../../models/interfaces/errorForm";
import Modal from "../../../components/modal/Modal";
import {useParams} from "react-router-dom";
import SubmitComponent from "../../../components/submit-component/SubmitComponent";
import {ErrorForm} from "../../../components/ErrorForm";
import './ModalSendMailTicket.scss';

interface IPropsFormSubmitTicketEmail {
    email: string;
}

interface IProps {
    user: User;
    isOpen: boolean
    closeModal: () => void;
}

const ModalSendMailTicket = ({user, isOpen, closeModal}: IProps) => {
    const {t} = useTranslation();
    const {id} = useParams();
    const [errorApi, setErrorApi] = useState<ErrorFormData>(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        errors,
        control,
    } = useForm<IPropsFormSubmitTicketEmail>();
    const [isActivateButton,setIsActivateButton]=useState(true)
    const onSubmit = async (data: any, e: any) => {
        setIsActivateButton(false)
        setLoadingSubmit(true);
        setDisabledForm(true);
        const ticketService = new TicketService();
        // We add the type and desc to the data object
        try {
            await ticketService.sendMail(id, data);
            toastr.success(t("success"), t("success_ticket_send_email"));
            setLoadingSubmit(false);
            closeModal();
        } catch (e) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(e.response.data));
            setLoadingSubmit(false);
        }
        setIsActivateButton(true)
    }

    return (
        <Modal label={t('ticket_send_email')} isOpen={isOpen} closeModal={() => closeModal()}>

            <form className="form-submit-ticket p-5" onSubmit={handleSubmit(onSubmit)}>

                <div className="field-form mgl2">
                    <InputControlled
                        name="email"
                        label={t("form.email")}
                        value={user.email}
                        required={true}
                        disabled={disabledForm}
                        control={control}
                        errors={errors}
                        {...register('email')}
                    />
                </div>
                <SubmitComponent
                    loading={loadingSubmit}
                    text={t("form.validate")}
                    isActivate={isActivateButton}
                />
                <ErrorForm errorApi={errorApi}/>
            </form>
        </Modal>
    )
}


function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(ModalSendMailTicket);