import { createStyles, TableRow, withStyles } from "@material-ui/core";
import React from "react";

interface IBodyTableRow {
    children?: any;
    nameClass?: string;
}

const BodyTableRow = ({ children, nameClass }: IBodyTableRow) => {
    return (
        <StyledTableRow className={nameClass ? nameClass : ""}>
            {children}
        </StyledTableRow>
    );
};

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            height: "95px",
            padding: "0 16px",
            "&:nth-of-type(even)": {
                backgroundColor: "#F6F8FA",
            },
        },
    })
)(TableRow);

export default BodyTableRow;
