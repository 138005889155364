import React, { useEffect, useState } from 'react';
import './Boards.scss';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import KpiContentSeparator from '../../../components/kpi-content-separator/KpiContentSeparator';
import {
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Paper,
    Button,
} from "@material-ui/core";
import HeadTableCell from "../../../components/table/HeadTableCell";
import { Folder } from '../../../models/folder';
import DashboardService from '../../../services/dashboard-service';
import BodyTableRow from '../../../components/table/BodyTableRow';
import BodyTableCell from '../../../components/table/BodyTableCell';
import { Roles } from '../../../models/user';


const CurrentFolders: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  let { url } = useRouteMatch();

  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState<Folder[]>([]);

  useEffect(() => {
    const fetchAll = async () => {
        try {
            const dataService = new DashboardService();

            setDatas(await dataService.folders());
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("error", error);
        }
    };
    fetchAll();
}, []);
  
    function renderItem(item: Folder) {
        return (
                <BodyTableRow key={item.id}>
                    <BodyTableCell align="center">{item.code}</BodyTableCell>
                    <BodyTableCell align="center">
                        {item.client?.company_name}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {item.start_date?.toLocaleDateString()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {item.end_date?.toLocaleDateString()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        <Button
                            style={{
                                backgroundColor: item.getColorStatus(Roles.CLIENT),
                            }}
                            className="btn-rounded mr-2"
                            variant="contained"
                            onClick={() => 
                                history.push(`${url}/folder/${item.id}/edit`)
                            }
                        >
                            {t(`status.client.${item.status}`)}
                        </Button>
                    </BodyTableCell>
                </BodyTableRow>
        );
    }

  return (
      <div className="board">
            <h3>{t("board.current_folders")}</h3>
            <KpiContentSeparator />

            {loading
                ? <CircularProgress />
                : <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <HeadTableCell align="center">
                                    {t("num_folder")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("client")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("form.start_date")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("form.end_date")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("folder_status")}
                                </HeadTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {datas.map(
                                (folder: Folder) => (
                                    renderItem(folder)
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            


      </div>  
  );
}

export default CurrentFolders;

