import React from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import { User } from "../../models/user";
import { connect } from "react-redux";
import KpiInvoice from "./kpi-invoices/KpiInvoice";
import ListInvoices from "./list-invoices/ListInvoices";
import { ExportType } from "../../components/content-section/export-action/ExportAction";

interface IPropsInvoicePage {
    user: User;
}

const InvoicesPage = ({ user }: IPropsInvoicePage) => {
    const { t } = useTranslation();

    //Return the appropriate content
    return (
        <>
            <div className="invoice-list">
                <ContentSection title={t("nav.invoices")}
                    {... user.isAdmin()
                        ? {
                            exportType: ExportType.Invoice,
                        }
                        : {}
                    }
                >
                    <KpiInvoice user={user} />
                    <ListInvoices user={user} />
                </ContentSection>
            </div>
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(InvoicesPage);
