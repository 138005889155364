import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NewIndicator, {
    IndicatorType,
} from "../../../components/indicator/NewIndicator";
import UseStyles from "../../design-dependencies/UseStyles";
import InvoiceService from "../../../services/invoice-service";
import { User } from "../../../models/user";

interface IKpiInvoice {
    paid: number;
    lateness: number;
    draft: number;
    to_pay:number;
}

interface IProps {
    user: User
}
const KpiInvoice = ({ user }: IProps) => {
    const [kpis, setKpis] = useState<IKpiInvoice>();
    const { t } = useTranslation();

    useEffect(() => {
        const getKpis = async () => {
            try {
                const service = new InvoiceService();
                let res = await service.getKpis();
                setKpis(res);
            } catch (error) {
                console.log("error", error);
            }
        };

        getKpis();
    }, []);

    return (
        <div className="row">
        {user.isAdmin()
            ? <>
                {renderPaid()}
                {renderLateness()}
                {renderToBill()}
            </>
            : <>
                {renderToPay()}
                {renderLateness()}
                {renderPaid()}
            </>
        }
        </div>
    );

    function renderPaid() {
        return (
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().mintBg}
                    index={0}
                    text={t("invoice_status.paid")}
                    type={IndicatorType.Invoice}
                    total={kpis?.paid ? kpis.paid : 0}
                    isMoney
                />
            </div>
        )
    }

    function renderLateness() {
        return (
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().redBg}
                    index={1}
                    text={t("invoice_status.lateness")}
                    type={IndicatorType.Invoice}
                    total={kpis?.lateness ? kpis.lateness : 0}
                    isMoney
                />
            </div>
        )
    }

    function renderToBill() {
        return (
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().yellowGradient}
                    index={2}
                    text={t("invoice_status.to_billed")}
                    type={IndicatorType.Invoice}
                    total={kpis?.draft ? kpis.draft : 0}
                    isMoney
                />
            </div>
        )
    }

    function renderToPay() {
        return (
            <div className="media-icon-box-col col-12 col-lg-6 col-xl-3">
                <NewIndicator
                    className={UseStyles().yellowGradient}
                    index={2}
                    text={t("invoice_status.to_pay")}
                    type={IndicatorType.Invoice}
                    total={kpis?.to_pay ? kpis.to_pay : 0}
                    isMoney
                />
            </div>
        )
    }
};

export default KpiInvoice;
