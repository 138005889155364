import React from "react";
import { ErrorFormData } from "../models/interfaces/errorForm";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import './ErrorForm.scss'
interface propsErrorForm{
    errorApi?: ErrorFormData
}

function renderErrors(errors:Array<String>|null){
    return(
        <ul>
            {errors && errors.map( (value, index) => {
                return <li className='error-form-list'key={index}>{value}</li>
            } )}
        </ul>
    ) 
}

export const ErrorForm: React.FunctionComponent<propsErrorForm> = (props) => {
    const { t } = useTranslation();

    if(props.errorApi && props.errorApi.error) {
        toastr.error(t('error'), props.errorApi.error);
    }

    if(props.errorApi && props.errorApi.exception){
        toastr.error(t('error'), t('unexpected_error'));
    }

    return (
        <div>
            {props.errorApi && props.errorApi.errors ? renderErrors(props.errorApi.errors) : ''}
        </div>
    );
}