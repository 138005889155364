import {Deserializable} from "./interfaces/deserializable";

export class Department implements Deserializable {
    public id: number;
    public nom: string;
    public code: string;
    public codeRegions: string

    constructor(data: any) {
        this.id = data.id;
        this.nom = data.nom;
        this.code = data.code;
        this.codeRegions = data.codeRegions;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
