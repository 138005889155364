import React from "react";
import {useTranslation} from "react-i18next";
import { Incident, IncidentType } from "../../models/incident";
import './IconIncident.scss'
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import ErrorIcon from '@material-ui/icons/Error';
import { Tooltip, withStyles } from "@material-ui/core";

interface IProps {
    incident: Incident;
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
  

export default function IconIncident({incident}: IProps) {
    const { t } = useTranslation();

    function getIconIncidentType(){
        if(incident.code === IncidentType.Lateness){
            return <WatchLaterIcon />
        } else if(incident.code === IncidentType.Technical){
            return <DirectionsCarIcon />
        } else if(incident.code === IncidentType.Other){
            return <ErrorIcon />
        }

        return <></>
    }

    return (
        <HtmlTooltip
            title={
                <div className="tooltip-incident">
                    <strong>{t('incident')} - {t((`incident_type.${incident.code}`))}</strong>
                    <span>{incident.comment}</span>
                </div>
            }
        >
            <div className="incident-icon">
                {getIconIncidentType()}       
            </div>
        </HtmlTooltip>
    );
};


