import React from "react";
import {useTranslation} from "react-i18next";
import FilterColumn, {
    FilterType,
} from "../../../components/filter-column/filterColumn";
import {FiltersEnabled} from "../../../models/utils/filters/filter-enabled";
import {FolderFilters} from "../../../models/utils/filters/folders/folder-filters";
import {TextField} from "@material-ui/core";

interface IPropsFiltersFolders {
    filters?: FolderFilters;
    filtersEnabled?: FiltersEnabled;
    setFilters: (filters: FiltersEnabled) => void;
}

const FiltersFolders = ({
                            filters,
                            filtersEnabled,
                            setFilters,
                        }: IPropsFiltersFolders) => {
    const {t} = useTranslation();
    const styles = {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row",
    } as React.CSSProperties;

    const changeValue = (name: string, value: any, type: FilterType) => {
        if (filters && filtersEnabled) {
            const newFiltersEnabled = filtersEnabled.addFilter(
                name,
                value === t("no") ? '0' : value,
                type
            );
            setFilters(new FiltersEnabled(newFiltersEnabled));
        }
    };
    return (
        <div className="filter-std-row" style={styles}>
            {filters && (
                <>
                    <FilterColumn
                        changeValue={(name, value, type) =>
                            changeValue(name, value, type)
                        }
                        filters={filters.status}
                        label={t("status_word")}
                        name="status"
                        type={FilterType.Multiple}
                        width={200}
                    />
                    <TextField
                        onChange={(e) =>
                            changeValue("start_dates", e.currentTarget.value, FilterType.Unique)
                        }
                        label={t('from')}
                        type="date"
                        className="shadow-form-control-date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        onChange={(e) =>
                            changeValue("end_dates", e.currentTarget.value, FilterType.Unique)
                        }
                        label={t('to')}
                        type="date"
                        className="shadow-form-control-date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default FiltersFolders;
