import React from "react";
import "./NoProviderAssigned.scss";
import { useTranslation } from "react-i18next";

interface IPropsModal {
    isTender: boolean;
}
const NoProviderAssigned = ({ isTender }: IPropsModal) => {
    const { t } = useTranslation();

    return (
        <div className="no_assigned_popup">
            <div className="info-section">
                <div className="text">
                    {!isTender
                        ? t("choose_provider_transfer")
                        : t("wait_provider_response_tender")}
                </div>
            </div>
        </div>
    );
};

export default NoProviderAssigned;
