import React from "react";
import {
    Button,
    CircularProgress,
    FormControlLabel,
    Icon,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import "./Actions.scss";
import { useTranslation } from "react-i18next";
import { User } from "../../../models/user";
import { ReactSVG } from "react-svg";
import Colors from "../../../containers/design-dependencies/Colors";
import { Quote } from "../../../models/quote";

interface IProps {
    setOnEdit: (e: any) => void;
    onEdit: any;
    setView: (e: any) => void;
    view: any;
    user: User;
    quote: Quote;
    onClickSend: () => void;
    onClickUpdate: () => void;
    onClickPdf: () => void;
    onclickCancel: () => void;
    onClickAccept: () => void;
    onClickDecline: () => void;
    pdfLoading: boolean;
    isSending: boolean;
    hasDepositsPayment: any;
    setShowModalCancel: () => void;
}
export default function Actions({
    setOnEdit,
    onEdit,
    setView,
    view,
    user,
    quote,
    onClickSend,
    onClickUpdate,
    onClickPdf,
    onclickCancel,
    onClickAccept,
    onClickDecline,
    pdfLoading,
    isSending,
    hasDepositsPayment,
    setShowModalCancel,
}: IProps) {
    const { t } = useTranslation();

    const handleChangeRadio = (event: any) => {
        setView(event.target.value);
    };
    const canUpdateQuote = () => {
        return user.isAdmin() && quote.isDraft();
    };
    const showEdit = () => {
        return user.isAdmin() && !quote.isSent();
    };
    const showPreview = () => {
        return user.isAdmin() || quote.isSent();
    };
    const showSend = () => {
        return showEdit();
    };
    const showValidation = () => {
        return (
            quote.waitingResponse() &&
            quote.folder.client &&
            (!user.isClient() || !user.isReader(quote.folder.client))
        );
    };
    const showCancel = () => {
        return user.isAdmin() && quote.canBeCancelled();
    };
    console.log(hasDepositsPayment);
    return (
        <div className="actions-section-previouwing">
            <div
                className="label-status to-upper"
                style={{ backgroundColor: quote.getColorByStatus() }}
            >
                {t(`quote_status_${user.role}.${quote.status}`)}
            </div>
            <div className="actions">
                <>
                    {showEdit() && (
                        <Button
                            className="edit preview-button"
                            style={{ minWidth: "15px" }}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => setOnEdit(!onEdit)}
                        >
                            <Icon>edit</Icon>
                        </Button>
                    )}

                    {showEdit() && (
                        <div className="bill-views">
                            <RadioGroup
                                style={{
                                    flexDirection: "row",
                                    flexWrap: "nowrap",
                                }}
                                aria-label="detail view"
                                name="view"
                                value={view}
                                onChange={handleChangeRadio}
                            >
                                <FormControlLabel
                                    value="detail"
                                    control={<Radio />}
                                    label={t("details_preview")}
                                />
                                <FormControlLabel
                                    value="global"
                                    control={<Radio />}
                                    label={t("global_preview")}
                                />
                                {/*<FormControlLabel value="simple" control={<Radio />} label={t("simple_preview")} />*/}
                            </RadioGroup>
                        </div>
                    )}

                    {showValidation() && (
                        <>
                            <Button
                                onClick={() => onClickDecline()}
                                className="btn-form btn-icon mr-2 submit-input"
                                variant="contained"
                                style={{
                                    backgroundColor: Colors.red,
                                    minHeight: "35px",
                                }}
                                size="large"
                                type="button"
                            >
                                {t("decline")}{" "}
                                <ReactSVG src="/images/app_icons/icon-delete.svg" />
                            </Button>

                            <Button
                                onClick={() => onClickAccept()}
                                className="btn-form btn-icon mr-2 submit-input"
                                variant="contained"
                                color="primary"
                                style={{ minHeight: "35px" }}
                                size="large"
                                type="button"
                            >
                                {t("accept")} <Icon>{"done"}</Icon>
                            </Button>
                        </>
                    )}

                    {showPreview() && (
                        <Button
                            className="pdf preview-button"
                            style={{ minWidth: "25px" }}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => onClickPdf()}
                        >
                            {!pdfLoading ? (
                                <span>{t("pdf")}</span>
                            ) : (
                                <CircularProgress />
                            )}
                        </Button>
                    )}

                    {canUpdateQuote() && (
                        <Button
                            className="pdf preview-button"
                            style={{ minWidth: "25px" }}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => onClickUpdate()}
                        >
                            {!pdfLoading ? (
                                <Icon>save</Icon>
                            ) : (
                                <CircularProgress />
                            )}
                        </Button>
                    )}

                    {showSend() && (
                        <Button
                            className="btn-form btn-icon send-bill"
                            style={{ minWidth: "100px" }}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => onClickSend()}
                        >
                            {!isSending ? (
                                <>
                                    <span>{t("send")}</span>
                                    <Icon>email</Icon>
                                </>
                            ) : (
                                <CircularProgress />
                            )}
                        </Button>
                    )}

                    {showCancel() && hasDepositsPayment && (
                        <Button
                            className="btn-form btn-icon send-bill"
                            style={{ minWidth: "100px" }}
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() =>
                                hasDepositsPayment.length > 0
                                    ? setShowModalCancel()
                                    : onclickCancel()
                            }
                        >
                            {!isSending ? (
                                <>
                                    <span>{t("cancel")}</span>
                                    <Icon>delete</Icon>
                                </>
                            ) : (
                                <CircularProgress />
                            )}
                        </Button>
                    )}
                </>
            </div>
        </div>
    );
}
