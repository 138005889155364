import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { findError } from "./helpers/ControllerHelper";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./CountryPhoneControlled.scss";

interface Iprops {
    label: string;
    value: any;
    disabled: boolean | false;
    name: string;
    required?: boolean | false;
    control?: any;
    errors: any;
    decorator?: any | null;
    country?: string;
    onChange?: (value: any) => void;
}

const CountryPhoneControlled = (props: Iprops) => {
    const { t } = useTranslation();
    let fieldError = findError(props.errors, props.name);
     
    return (
        <>
            <Controller
             
                error={!!fieldError}
                helperText={
                    fieldError
                    ? fieldError.types
                        ? t(fieldError.types.message)
                        :  t("errors.required", { field: props.label })
                    :
                    ""
                }
                label={props.label}
                disabled={props.disabled}
                name={props.name}
                control={props?.control}
                defaultValue={props.value}
                value={props.value}
                placeholder={props.label}
                rules={{ required: props.required }}
                as={
                    <PhoneInput
                        country={props.country ? props.country : "fr"}
                    />
                }
                onChange={(e:any) => {
                    if (props.onChange) {
                      props.onChange(e[0]);
                    }
                    return e[0];
                }}
            />
            {props.decorator && (
            <div style={{ paddingTop: "15px" }}>
                {props.decorator}
            </div>
            )}
            {fieldError && <p style={{color:"red", fontSize:14}}> {t("errors.required", { field: props.label })}</p>}
        </>
    );
};

export default CountryPhoneControlled;
