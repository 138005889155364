import axios, { AxiosResponse } from "axios";
import { Client } from "../models/client";
import { getBaseUrl, getParamsUrl } from "./setting-service";
import { DataPaginate } from "../models/utils/data-paginate";

export default class AccountService {
    private clientId: number;

    constructor(clientId: number) {
        this.clientId = clientId;
    }

    private getRoute(): string {
        return `${getBaseUrl()}clients/${this.clientId}/accounts`;
    }

    all(page?: number | null) {
        let paramsUrl = getParamsUrl([{ key: "page", value: page }]);

        return axios
            .get(`${this.getRoute()}${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map(
                    (account: Object) => new Client(account)
                );
                return new DataPaginate(res);
            });
    }

    get(id: number) {
        return axios
            .get(`${this.getRoute()}/${id}`)
            .then((response: AxiosResponse) => new Client(response.data))
            .catch();
    }

    add(body: any) {
        return axios
            .post(`${this.getRoute()}`, body)
            .then((response: AxiosResponse) => new Client(response.data))
            .catch();
    }

    update(id: number, body: any) {
        return axios
            .put(`${this.getRoute()}/${id}`, body)
            .then((response: AxiosResponse) => new Client(response.data))
            .catch();
    }

    delete(id: number) {
        return axios
            .delete(`${this.getRoute()}/${id}`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    activate(id: number, status: boolean) {
        return axios
            .post(`${this.getRoute()}/${id}/activate/${status ? 1 : 0}`)
            .then((response: AxiosResponse) => new Client(response.data))
            .catch();
    }
}
