const Colors = {
    blue: "#107DE8",
    blueLight: "rgba(17, 126, 234, 0.12)",
    cyan: "#00C6FB",
    red: "#F36666",
    orange: "#FE8456",
    green: "#1ABCB0",
    greenLight: '#A1E89B',
    mint: "#A1E89B",
    yellow: "#FED128",
    grey: "#707070",
    greyClear:"#a6a9b7",
    primaryColorAdmin: "#2F2F2F",
    primaryColorProvider: "#415164",
    primaryColorClient: "#E8B01B",
    white:"#ffffff"
};

export default Colors;
