import React from "react";
import { User, Roles } from "../../models/user";
import "./LeftSideNav.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import AuthService from "../../services/auth-service";
import { history } from "../../containers/app/App";
import { logout } from "../../redux/store/auth/actions";
import { connect } from "react-redux";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import CheckPermissions from "../../utils/CheckPermissions";
import { LeftSideNavAdministrator } from "./LeftSideNavAdministrator";
import { LeftSideNavProvider } from "./LeftSideNavProvider";
import { LeftSideNavClient } from "./LeftSideNavClient";

interface ILeftSideMenuProps {
    user: User;
}

interface IMapDispatchToProps {
    logout: () => void;
}

type LeftSideMenuProps = ILeftSideMenuProps & IMapDispatchToProps;

const LeftSideMenu: React.FunctionComponent<LeftSideMenuProps> = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <Drawer className="app-drawer" variant="permanent">
                <Toolbar />
                {renderUser(props.user, t)}

                <div className="app-aside-menu d-flex align-items-start flex-column">
                    <CheckPermissions permissions={[Roles.ADMIN]}>
                        <LeftSideNavAdministrator />
                    </CheckPermissions>

                    <CheckPermissions permissions={[Roles.PROVIDER]}>
                        <LeftSideNavProvider user={props.user} />
                    </CheckPermissions>

                    <CheckPermissions permissions={[Roles.CLIENT]}>
                        <LeftSideNavClient user={props.user} />
                    </CheckPermissions>

                    <List className="asm-list asml-bottom mt-auto">

                     <CheckPermissions permissions={[Roles.ADMIN]}>
                       <Link className="side-nav-link" to="/app/parameter">
                         <ListItem button>
                            <ListItemIcon>
                                <img
                                    src={require("../menu-icons/settings.svg")}
                                    alt="Paramètres"
                                />
                            </ListItemIcon>
                            <ListItemText primary={t("nav.parameter")}/>
                         </ListItem>
                        </Link>
                      </CheckPermissions>
                      
                      <a className="side-nav-link" onClick={() => logoutUser(props)} href="#logout">
                        <ListItem button>
                            <ListItemIcon>
                                <img
                                    src={require("../menu-icons/logout.svg")}
                                    alt="Déconnexion"
                                />
                            </ListItemIcon>
                            <ListItemText primary={t("nav.logout")}/>
                        </ListItem>
                      </a>

                    </List>
                </div>
            </Drawer>
        </>
    );
};

function renderUser(user: User, t: TFunction) {
    if (user) {
        return (
            <div className="list-baseline">
                <span className="hello">{t("hello")}, </span>
                {user.firstname} {user.lastname}
            </div>
        );
    }
    return "";
}

function logoutUser(props: LeftSideMenuProps) {
    const authService = new AuthService();
    authService.logout().then(() => {
        props.logout();
        let element = document.getElementsByTagName("body");
        if (element) {
            element[0].classList.remove("loaded");
        }
        history.push("/login");
    });
}

const actionCreators = {
    logout,
};

export default connect(null, actionCreators)(LeftSideMenu);
